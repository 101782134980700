import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IrisSkeletonLoaderComponent } from './skeleton-loader.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    IrisSkeletonLoaderComponent,
  ],
  exports: [
    IrisSkeletonLoaderComponent,
  ],
})
export class IrisSkeletonLoaderComponentModule { }
