import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { IrisCurrentUserNotificationStore } from '@iris/common/services/stores/current-user-notifications.store';
import { MatDialog } from '@angular/material/dialog';
import { IrisNotificationsUnlockModalComponent } from './notifications-unlock-modal/notifications-unlock-modal.component';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';

@Component({
  selector: 'iris-header-notifications',
  templateUrl: './header-notifications.component.html',
  styleUrls: ['./header-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IrisHeaderNotificationsComponent implements OnInit, OnDestroy {

  notifications: IrisNotificationI[] = [];
  notificationBellCss: unknown;
  private readonly _destroyedSubject = new Subject<void>();

  get env(): IrisEnv {
    return this.envService.env;
  }

  constructor(
    private readonly notificationsStore: IrisCurrentUserNotificationStore,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly envService: IrisEnvironmentService,
  ) {
  }

  ngOnInit(): void {
    this.notificationsStore.initStore();
    this.notificationsStore.unreadNotifications$.pipe(
      tap((notifications) => {
        this.notifications = notifications;
        this.notificationBellCss = null;
        if (notifications.length > 0) {
          notifications.sort(
            (a, b) => b.notificationPriority.weight - a.notificationPriority.weight,
          );

          this.notificationBellCss = this.createPriorityBellAfterAndAlertifyCss();
        }
        this.changeDetector.detectChanges();
      }),
      takeUntil(this._destroyedSubject),
    ).subscribe();

    this.notificationsStore.getUnlockRequestedNotifications().pipe(
      tap((data) => {
        this.dialog.open(IrisNotificationsUnlockModalComponent, {
          data: { user: data.user, protocolId: data.protocolId },
        });
      }),
      takeUntil(this._destroyedSubject),
    ).subscribe();
  }

  createPriorityBellAfterAndAlertifyCss(): unknown {
    let styles = { };
    if (this.notifications.length > 0) {
      styles = {
        'background-color': this.notifications[0].notificationPriority.color,
        'border-color': this.notifications[0].notificationPriority.color,
      };
    }
    return styles;
  }

  checkUnreadNotifications(): boolean {
    if (!this.notifications || this.notifications.length === 0) { return false; }
    return this.notifications.some(n => !n.read);
  }

  markAsRead(event: Event, notification: IrisNotificationI): void {
    event.stopPropagation();
    this.notificationsStore.setAsRead(notification);
  }

  markAllAsRead(event: Event): void {
    event.stopPropagation();
    if (this.notifications) {
      this.notificationsStore.setAllAsRead();
    }
  }

  fixMessage(message: string): string {
    return message.replace('{{config.baseUrl}}', '').replace('{{config.apiUrl}}', this.env.apiUrl);
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
