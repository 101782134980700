import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IrisFieldTextComponent } from './field-text/field-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IrisFieldEditorComponent } from './field-editor/field-editor.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { IrisFieldCheckboxComponent } from './field-checkbox/field-checkbox.component';
import { IrisFieldSelectComponent } from './field-select/field-select.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule as MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { IrisFieldDepartmentComponent } from './field-department/field-department.component';
import { IrisFieldDepartmentModalComponent } from './field-department/field-department-modal/field-department-modal.component';
import { IrisFieldPasswordComponent } from './field-password/field-password.component';
import { IrisFieldTextTranslationsModalComponent } from './field-text/field-text-translations-modal/field-text-translations-modal.component';
import { IrisFieldNumberComponent } from './field-number/field-number.component';
import { IrisFieldColorComponent } from './field-color/field-color.component';
import { IrisCommonDirectivesModule } from '../directives-common/common-directives.module';
import { IrisFieldShareSelectComponent } from './field-share-select/field-share-select.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorSwatchesModule } from 'ngx-color/swatches';
import { IrisDialogColorComponent } from './field-color/dialog-color/dialog-color.component';
import { IrisCommonPipesModule } from '../pipes-common/common-pipes.module';
import { IrisFieldMultipleStateCheckboxComponent } from './field-multiple-state-checkbox/field-multiple-state-checkbox.component';
import { IrisModalContentModule } from '@iris/common/modules/iris-modal-content/iris-modal-content.module';
import { IrisFieldTextareaComponent } from './field-textarea/field-textarea.component';
import { IrisFieldChipsComponent } from './field-chips/field-chips.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IrisFieldInlineEditingComponent } from './field-inline-editing/field-inline-editing.component';
import { IrisFieldButtonGroupSelectorComponent } from '@iris/common/modules/fields/field-button-group-selector/button-group-selector.component';
import { IrisFieldDatetimeModule } from '@iris/common/modules/fields/field-datetime/field-datetime.module';
import { SafeHtmlPipeModule } from '../pipes-common/pipes/dom-sanitizer/safe-html.module';
import { IrisFieldTranslateComponent } from './field-translate/field-translate.component';
import { IrisFieldTranslateModule } from '@iris/common/modules/fields/field-translate/field-translate.module';
import { PortalModule } from '@angular/cdk/portal';
import { IrisInfoIconComponent } from '../ui/modules/info-icon/info-icon.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    EditorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSlideToggleModule,
    IrisCommonDirectivesModule,
    ColorSketchModule,
    ColorSwatchesModule,
    IrisCommonPipesModule,
    IrisModalContentModule,
    IrisFieldDatetimeModule,
    MatTooltipModule,
    SafeHtmlPipeModule,
    IrisFieldTranslateModule,
    PortalModule,
    IrisInfoIconComponent,
    IrisFieldButtonGroupSelectorComponent,
  ],
  declarations: [
    IrisFieldTextComponent,
    IrisFieldEditorComponent,
    IrisFieldCheckboxComponent,
    IrisFieldSelectComponent,
    IrisFieldChipsComponent,
    IrisFieldDepartmentComponent,
    IrisFieldDepartmentModalComponent,
    IrisFieldPasswordComponent,
    IrisFieldTextTranslationsModalComponent,
    IrisFieldNumberComponent,
    IrisFieldColorComponent,
    IrisFieldShareSelectComponent,
    IrisDialogColorComponent,
    IrisFieldMultipleStateCheckboxComponent,
    IrisFieldTextareaComponent,
    IrisFieldInlineEditingComponent,
  ],
  exports: [
    IrisFieldDatetimeModule,
    IrisFieldTextComponent,
    IrisFieldEditorComponent,
    IrisFieldCheckboxComponent,
    IrisFieldSelectComponent,
    IrisFieldChipsComponent,
    IrisFieldDepartmentComponent,
    IrisFieldPasswordComponent,
    IrisFieldNumberComponent,
    IrisFieldColorComponent,
    IrisFieldShareSelectComponent,
    IrisFieldMultipleStateCheckboxComponent,
    IrisFieldTextareaComponent,
    IrisFieldInlineEditingComponent,
    IrisFieldTranslateComponent,
  ],
})
export class IrisFieldsModule {
}
