import { Pipe, PipeTransform } from '@angular/core';
import { IrisUnitsService } from '@iris/common/services/units.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'irisUnits',
})
export class IrisUnitsPipe implements PipeTransform {
  constructor(private readonly unitsService: IrisUnitsService) {
  }

  transform(value: string, direction: 'short' | 'long' = 'short', isWrapped = false): Observable<string> {
    return this.unitsService.convertedUnits$.pipe(
      map(units => {
        const unitObj = units[value];
        const unitValue = direction === 'short' ? unitObj.i18nUnitShort : unitObj.i18nUnitLong;
        return isWrapped ? `[${unitValue}]` : `${unitValue}`;
      }));

  }
}
