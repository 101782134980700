import { Injectable } from '@angular/core';
import { IrisProjectI } from '@iris/common/models';
import { IrisProjectDetailsViewModel } from '@iris/modules/projects/details/models/iris-project-details.view-model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class IrisProjectsUtils {

  constructor(
    private readonly translate: TranslateService,
  ) { }

  createCostCenterString(project: IrisProjectDetailsViewModel | IrisProjectI): string {
    return `${project.ld || ''} ${project.fco || ''} ${project.kost || ''}`.trim();
  }

  showProgress(progress: number, label: string): string {
    if (!progress && !label) { return ''; }
    const labelText = this.translate.instant(label);
    return `${progress}% – ${labelText}`;
  }
}
