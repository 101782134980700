<span #icon
			*ngIf="message"
      class="info-icon-center"
			data-test="info-icon"
			[ngxTippy]="infoTemplate"
			[tippyProps]="tooltipProps">
	<i class="fal fa-info-circle"></i>
</span>

<ng-template #infoTemplate>
  <div class="info-icon-multiline">{{message | translate}}</div>
</ng-template>
