import { isEmpty } from 'lodash';
import isNil from 'lodash/isNil';
import { IrisLocaleService } from '@iris/common/modules/locale/services/locale.service';

export function transformTranslate(value: any, translations, defaultValue?, currLocale?, localeService?: IrisLocaleService): any {
  let translatedValue;
  let locale = 'en';

  if (!isNil(currLocale)) {
    locale = currLocale;
  } else if (!isEmpty(localeService)) {
    const currentLocaleFromService = localeService.getCurrentLocale();
    if (!isNil(currentLocaleFromService)) {
      locale = currentLocaleFromService;
    }
  }

  if (locale && translations && translations[locale]) {
    translatedValue = translations[locale];
  }

  return translatedValue || defaultValue || value;
}
