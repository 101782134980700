import { RxStomp, RxStompConfig, RxStompState } from '@stomp/rx-stomp';
import { lastValueFrom } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { ActivationState } from '@stomp/stompjs';

export class WatchedRxStomp extends RxStomp {
  private readonly interval = 120;            // In sec
  private readonly maxSuccessiveAttempts = 3; // 3 attempts every 120 seconds
  private readonly maxAttempts = 3;           // for a total of 3 * 3 times
  private attempt = 0;
  private successiveAttempt = 0;

  watchConnectionState(): void {
    this.connectionState$.pipe(
      filter(status => status === RxStompState.CLOSED),
      take(this.maxSuccessiveAttempts + 1),
      tap(() => {
        if (++this.successiveAttempt <= this.maxSuccessiveAttempts) { return; }

        void this.deactivate();

        if (++this.attempt >= this.maxAttempts) { return; }

        setTimeout(() => {
          this.successiveAttempt = 0;
          this.watchConnectionState();
          this.activate();
        }, this.interval * 1000);
      }),
    ).subscribe();
  }
}

export function watchedStompServiceFactory<T extends WatchedRxStomp>(
  serviceType: new () => T,
  authFacade: AuthFacade,
  webSocketFactoryProvider: (authToken: string) => RxStompConfig['webSocketFactory'],
): T {
  const rxStomp = new serviceType();

  rxStomp.configure({
    beforeConnect: async (rxClient: T): Promise<void> => {
      const token = await lastValueFrom(authFacade.actualToken());
      if (token) {
        rxClient.configure({
          webSocketFactory: webSocketFactoryProvider(token),
          connectHeaders: { 'Authorization': `Bearer ${token}` },
        });
      } else {
        // mark client inactive to avoid attempt to connect without proper configuration
        rxClient.stompClient.state = ActivationState.INACTIVE;
      }
    },
    heartbeatIncoming: 0,
    heartbeatOutgoing: 20000,
    reconnectDelay: 1000,
    // It can be very useful sometimes.
    // debug: (msg: string): void => {
    //   console.warn(new Date(), msg);
    // },
  });

  rxStomp.watchConnectionState();
  rxStomp.activate();
  return rxStomp;
}
