import { Injectable } from '@angular/core';
import { IrisUnitConverted } from '@iris/common/models/irisUnit';
import { tap } from 'rxjs';
import { IrisGlobalSandbox } from '../redux/global.sandbox';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IrisUnitsService {
  units$ = this.globalSandbox.units$;
  units: { [unitKey: string]: IrisUnitConverted } = {};

  convertedUnits$ = this.globalSandbox.units$.pipe(map(this.convertUnits.bind(this)));

  constructor(
    private readonly globalSandbox: IrisGlobalSandbox,
  ) {
    this.units$.pipe(tap(units => {
      if (!units) { return; }
      this.units = this.convertUnits(units);
    })).subscribe();
  }

  private convertUnits(units): { [unitKey: string]: IrisUnitConverted } {

    return Object.keys(units).reduce((res, u) => {
      res[u] = { ...units[u] };
      res[u].possibleConvert = [].concat(units[u].possibleConvert);
      for (const i in res[u].possibleConvert) {
        if (res[u].possibleConvert.hasOwnProperty(i)) {
          const conv_unit = res[u].possibleConvert[i];
          res[u].possibleConvert[i] = { ...units[conv_unit] };
          delete res[u].possibleConvert[i].possibleConvert;
        }
      }
      return res;
    }, {});
  }

  getUnits(): { [unitKey: string]: IrisUnitConverted } {
    return this.units;
  }

  getUnitsAsArray(): IrisUnitConverted[] {
    return Object.values(this.units);
  }
}
