import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LanguageSelectComponent } from './language-select.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    MatButtonToggleModule,
    FormsModule,
  ],
  declarations: [
    LanguageSelectComponent,
  ],
  exports: [
    LanguageSelectComponent,
  ],
})
export class LanguageSelectModule { }
