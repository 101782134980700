import { ValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class MaxLengthValidationMessageResolver implements ValidationMessageResolver<{ requiredLength: unknown }> {
  readonly errorCode = 'maxlength';

  constructor(
    private readonly translateService: TranslateService,
  ) { }

  getMessage(error?: { requiredLength: unknown }): string {
    return this.translateService.instant('label.validation.maxLength.value', { value: error?.requiredLength });
  }
}
