<div class="dd-filter sidebar-menu-filter">
  <div class="dd-filter__scroll">
    <div class="dd-filter__close">
      <button class="btn btn-link" (click)="setFilterVisibility(false)">
        <i class="fal fa-2x fa-times"></i>
      </button>
    </div>
    <div class="dd-filter__ttl">{{'label.Filter' | translate}}</div>
    <div class="dd-filter__fltr">
      <ng-container *ngTemplateOutlet="formTemplate;context:ctx"></ng-container>
    </div>
  </div>
  <div class="dd-filter__bottom">
    <button class="btn btn-success" (click)="applyFilter()" [disabled]="isFilterNotValid">
      {{'label.ApplyFilter' | translate}}
    </button>
    <button class="btn btn-secondary" (click)="openSaveFilterModal()" [disabled]="isFilterNotValid">
      {{'label.SaveFilter' | translate}}
    </button>
    <button class="btn btn-default" (click)="resetFilterToDefault()" [matTooltip]="'label.ResetFilter' | translate">
      {{'label.Reset' | translate}}
    </button>
  </div>
</div>
