import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IrisLoaderService } from '@iris/common/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { IrisAuthService } from '@iris/modules/auth/utils/auth.service';

const LOADER_PLACE = '.login-form';

@Component({
  templateUrl: './restore-password.component.html',
})
export class RestorePasswordComponent implements OnInit, OnDestroy{
  error: string;
  message: string;
  newPassword: string;
  confirmPassword: string;
  private resetId: string;
  private resetHash: string;
  _destroyed = new Subject<void>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: IrisAuthService,
    private readonly translateService: TranslateService,
    private readonly loader: IrisLoaderService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(
      tap(params => {
        const { id, hash } = params;
        this.resetId = id;
        this.resetHash = hash;
      }),
      takeUntil(this._destroyed),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  resetPassword(): void {
    this.clearAlerts();
    if (!this.newPassword || !this.confirmPassword) {
      return;
    }
    if (this.newPassword != this.confirmPassword) {
      this.error = this.translateService.instant('error.PasswordsDoNotMatch');
      return;
    }
    this.loader.start(LOADER_PLACE);
    this.authService.resetPassword(this.resetId, this.resetHash, this.newPassword, this.confirmPassword).pipe(
      tap(() => {
        this.router.navigate(['login']);
      }),
      catchError((err) => {
        this.loader.stop(LOADER_PLACE);
        return this.error = this.translateService.instant(err.error?.messages[0] || err.message);
      }),
    ).subscribe();
  }

  private clearAlerts(): void {
    this.error = null;
    this.message = null;
  }
}
