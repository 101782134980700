import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IrisModalAboutComponent } from '@iris/common/modules/about/iris-modal-about.component';
import { HelpUrlSettingsService } from '@iris/modules/administration/administration-list/appearance-page/help-url-settings/help-url-settings.service';

@Component({
  selector: 'iris-header-help',
  templateUrl: './header-help.component.html',
  styleUrls: ['./header-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderHelpComponent implements OnInit {

  helpCenterUrl$ = this.helpUrlSettingsService.helpUrl$;

  constructor(
    private readonly helpUrlSettingsService: HelpUrlSettingsService,
    public readonly dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.helpUrlSettingsService.getHelpUrl().subscribe();
  }

  openModalAbout(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    this.dialog.open(IrisModalAboutComponent, dialogConfig);
  }
}
