/* eslint-disable @typescript-eslint/no-explicit-any */
import { NgSelectEvent } from '@iris/common/modules/fields/ng-select-field/ng-select-event-handler';

export const NG_SELECT_EVENTS_PROP = Symbol('ngSelect:events');

export function BindNgSelectEvent(event: NgSelectEvent) {
  return (target: any, key: string) => {
    const constructor = target.constructor;
    const events = constructor[NG_SELECT_EVENTS_PROP] ?
      constructor[NG_SELECT_EVENTS_PROP] :
      Object.defineProperty(constructor, NG_SELECT_EVENTS_PROP, { value: {} })[NG_SELECT_EVENTS_PROP];
    events[event] ??= [];
    events[event].push(key);
  };
}
