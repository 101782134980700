export enum IrisDMSFileQueryParam {
  File = 'file',
  Action = 'action',
  NoFile = 'no-file'
}

export enum IrisDMSFileQueryParamAction {
  Edit = 'edit',
  Select = 'select',
  OpenInfo = 'openInfo'
}

