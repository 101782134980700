import { StaticProvider } from '@angular/core';
import { SEARCH_ENGINE, templateFromComponentProvider } from '@iris/common/modules/fields/ng-select-field';
import {
  BIND_LABEL,
  LABEL_TEMPLATE,
  OPTION_TEMPLATE,
} from '@iris/common/modules/fields/ng-select-field/properties-tokens';
import { IrisUserSelect } from './field-user-select';
import { IrisUserSelectInputItemComponent } from './input-item';
import { IrisUserSelectDropdownItemComponent } from './dropdown-item';

export const USER_SELECT_BASE_PROVIDERS: StaticProvider[] = [
  {
    provide: LABEL_TEMPLATE,
    ...templateFromComponentProvider(IrisUserSelectInputItemComponent, 'template'),
  },
  {
    provide: OPTION_TEMPLATE,
    ...templateFromComponentProvider(IrisUserSelectDropdownItemComponent, 'template'),
  },
  {
    provide: BIND_LABEL,
    useValue: 'fullname',
  },
];

export const USER_SELECT_PROVIDERS: StaticProvider[] = [
  {
    provide: IrisUserSelect,
    useClass: IrisUserSelect,
  },
  {
    provide: SEARCH_ENGINE,
    useExisting: IrisUserSelect,
  },
  ...USER_SELECT_BASE_PROVIDERS,
];
