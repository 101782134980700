<div
  class="bg-dark text-white justify-content-center justify-content-between preview-toolbar d-flex"
  confirmByKeypress
  (confirmed)="nextFile()"
  (cancelled)="close()"
>
  <div class="col-auto">
    <div class="preview-file-name ms-1">{{fileName}}</div>
  </div>

  <div class="col-auto">
    <button id="buttonGoToParentFolderId"
      [hidden]="!showGoToFolder"
      [matTooltip]="'label.dms.GoToFolder' | translate"
      (click)="goToParentFolder()"
      class="btn btn-link text-white">
      <i class="fas fa-folder-open text-white fa-lg"></i>
    </button>
    <button
      (click)="downloadFile()"
      [matTooltip]="'label.Download' | translate"
      class="btn btn-link text-white"
    >
      <i class="fas fa-download"></i>
    </button>
    <a class="btn btn-link text-white" [href]="fileInfoHref" target="_blank">
      <i class="fas padded-right fa-lg"></i>
      <i class="fas fa-info fa-lg"></i>
    </a>
    <button class="btn btn-link text-white ms-2" [matTooltip]="'label.Close' | translate" (click)="closeWithoutResult()">
      <i class="fas fa-times fa-lg"></i>
    </button>
  </div>
</div>

<div class="modal-body-file-preview">
  <ng-container *ngIf="isImage || isPdf">
    <div *ngIf="isImage" class="image-preview">
      <pinch-zoom [disableZoomControl]="'never'">
        <img [src]="fileLink" alt="image preview" (load)="onImageLoaded($event)"/>
      </pinch-zoom>
    </div>

    <div *ngIf="isPdf"
      class="pdf-preview"
      [ngClass]="{ 'flex-full-column' : isPdfError }">
      <p *ngIf="isPdfError" class="alert alert-warning mb-0 rounded-0 flex-valign">
        <span class="flex-col-auto me-2">{{ 'message.PdfWarning' | translate }}</span>
        <button 
          (click)="downloadFile()"
          class="btn btn-secondary text-white"
        >{{ 'label.Download' | translate }}</button>
      </p>
      <ngx-extended-pdf-viewer
        *ngIf="!isPdfError"
        [ngStyle]="{ visibility: previewReady ? 'visible' : 'hidden' }"
        [src]="fileLink"
        [theme]="'dark'"
        [textLayer]="true"
        [handTool]="false"
        [showHandToolButton]="true"
        [showRotateButton]="false"
        [showScrollingButton]="false"
        [showPresentationModeButton]="false"
        [showOpenFileButton]="false"
        [showPrintButton]="false"
        [showDownloadButton]="false"
        [showRotateButton]="true"
        [minZoom]="0.25"
        [maxZoom]="4"
        [(sidebarVisible)]="sidebarVisible"
        (pdfLoaded)="onPdfLoaded($event)"
        (pdfLoadingFailed)="onPdfLoadingFailed($event)"
      ></ngx-extended-pdf-viewer>
    </div>

    <iris-simple-loader
      *ngIf="!previewReady"
      class="absolute-box bg-white preview-loader"
    ></iris-simple-loader>
  </ng-container>

  <div *ngIf="isDocument" class="frame-type-document">
    <iframe width="{{isExcelFile ? '100%' : 985}}" height="100%"
            [src]="fileLink | safeResourceUrl">
      <div>
        <a [href]="fileLink"> {{'error.file.preview' | translate}} </a>
      </div>
    </iframe>
  </div>

  <div *ngIf="!previewFileType" class="preview-file-error">
    <p><a [href]="fileLink"> {{'error.file.preview' | translate}} </a></p>
  </div>

  <div class="navigation-button-wrapper navigation-left" [class.pdf-nav]="isPdf" [hidden]="prevButtonDisabled">
    <button [disabled]="prevButtonDisabled" (click)="prevFile()"
            class="btn btn-link text-white navigation-button">
      <i class="fas fa-caret-left fa-lg"></i>
    </button>
  </div>

  <div class="navigation-button-wrapper navigation-right" [class.pdf-nav]="isPdf" [hidden]="nextButtonDisabled">
    <button [disabled]="nextButtonDisabled" (click)="nextFile()"
            class="btn btn-link text-white navigation-button">
      <i class="fas fa-caret-right fa-lg"></i>
    </button>
  </div>
</div>
