import { IrisQueryParams } from '@iris/api-query';
import { defaultChipRenderingOptions } from '@iris/modules/module-filters-v2/chips/rendering-options';
import { appendPureValueQueryParams } from '@iris/modules/module-filters-v2/query-params';
import { filterValueObjectIsEmpty, stringifyServerEntityFn } from '@iris/modules/module-filters-v2/utils';
import { IrisFieldsTranslationSet } from '@iris/common/models';
import { Observable } from 'rxjs';
import { IrisFilterPropertyMeta } from './filter-property-meta';

export function serverEntityFilterMeta<TId>(
  currentLanguage: string,
  propertyId: string,
  label: string,
  fetchEntityByIdFn: (id: TId, queryParams?: IrisQueryParams) => Observable<{
    name?: string;
    nameTranslated?: string;
    translations?: IrisFieldsTranslationSet;
  }>,
  fieldNames: string | string[] = propertyId,
): IrisFilterPropertyMeta {
  return {
    propertyId,
    label,
    appendFilterQueryParamsFn: appendPureValueQueryParams(fieldNames),
    chipRenderingOptions: defaultChipRenderingOptions<TId>(stringifyServerEntityFn(currentLanguage, fetchEntityByIdFn)),
    filterValueIsEmpty: filterValueObjectIsEmpty,
  };
}
