import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IrisProjectSelectEngine } from '../project-select.engine';

interface Context {
  totalCount: number;
  shownCount: number;
}

@Component({
  selector: 'iris-project-select-dropdown-footer',
  templateUrl: './dropdown-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisProjectSelectDropdownFooterComponent {
  @ViewChild('template', { static: true }) template: TemplateRef<unknown>;
  context$: Observable<Context>;

  constructor(
    _searchEngine: IrisProjectSelectEngine,
  ) {
    this.context$ = _searchEngine.result$
      .pipe(
        map(page => ({
          totalCount: page.count,
          shownCount: page.elements.find(x => x.code === 'ALL')?.projects?.length ?? 0,
        })),
      );
  }
}
