/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiQueryHelper } from '../../api-query.helper';
import { API_PARAM_DEFAULTS_METADATA_KEY, API_URL_TEMPLATE_PROPERTY, API_URL_TEMPLATE_METADATA_KEY } from './api-url.constant';
import { IRIS_API_CLIENT_CONFIG_TOKEN, IrisApiClientConfig } from './iris-api-client.config';
import { ApiQueryInjector } from '../../api-query.injector';
import { IRIS_MICROSERVICE_REDIRECT_CONFIG_TOKEN, IrisMicroserviceRedirectConfig } from '../../interceptors/iris-microservice-redirect.config';

export abstract class IrisApiClientCore {
  get urlTemplate(): string {
    return this[API_URL_TEMPLATE_PROPERTY] || this._urlTemplate;
  }

  get apiConfig(): IrisApiClientConfig {
    if (!ApiQueryInjector.injector) { return {} as IrisApiClientConfig; }
    return ApiQueryInjector.injector.get(IRIS_API_CLIENT_CONFIG_TOKEN);
  }

  get redirectConfig(): IrisMicroserviceRedirectConfig {
    if (!ApiQueryInjector.injector) { return {} as IrisMicroserviceRedirectConfig; }
    return ApiQueryInjector.injector.get(IRIS_MICROSERVICE_REDIRECT_CONFIG_TOKEN);
  }

  protected _urlTemplate: string;
  protected [API_URL_TEMPLATE_PROPERTY]: string;

  protected constructor(urlTemplate: string = null) {
    const classUrlTemplate = Reflect.getMetadata(API_URL_TEMPLATE_METADATA_KEY, this) || '';
    this._urlTemplate = urlTemplate || classUrlTemplate;
  }

  protected resolveUrl(url: string, params: { [key: string]: any } = null): string {
    const urlParams = Reflect.getMetadata(API_PARAM_DEFAULTS_METADATA_KEY, this) || {};
    Object.keys(this.apiConfig.urlParams || {}).forEach(key => urlParams[`iris@${key}`] = this.apiConfig.urlParams[key]);
    Object.keys(params || {}).forEach(key => urlParams[key] = params[key]);
    return ApiQueryHelper.resolveUrlPlaceholders(url, urlParams).replace(/\/+/g, '/');
  }

  protected url(params: { [key: string]: any } = null): string {
    return this.resolveUrl(this.urlTemplate, params);
  }

  protected apiUrl(forUrl?: string): string {
    return (
      forUrl && this.redirectConfig.redirectMicroservices?.length
      && ApiQueryHelper.microserviceRedirectUrl(forUrl, this.redirectConfig.redirectMicroservices, this.redirectConfig.redirectMicroservicesUrl)
    ) || this.apiConfig.apiUrl;
  }

  protected serverUrl(): string {
    return this.apiConfig.serverUrl;
  }

  protected fullUrl(params: { [key: string]: any } = null): string {
    return this.concatUrl(this.apiUrl(this.url(params)), this.url(params));
  }

  protected concatUrl(a: string, b: string): string {
    return ApiQueryHelper.concatUrl(a, b);
  }
}
