<div class="iris-profile-info">
  <div class="nav-item"
       [class.show]="menuDropdownTrigger.menuOpen"
       data-test="header-profile-menu-button">
    <button class="btn btn-link nav-link"
            #menuDropdownTrigger="matMenuTrigger"
            [matMenuTriggerFor]="profileMenu">
      <iris-user-ws class="user"
                    [userId]="me.id"
                    [showOnlyAvatar]="true"
                    [noHint]="true"></iris-user-ws>
    </button>

    <mat-menu #profileMenu="matMenu"
              class="iris-dropdown-menu with-icon pt-2 pb-2"
              data-test="header-profile-menu">
      <ul class="list-group">
        <li data-test="header-profile-menu-main-link">
          <a class="dropdown-item" [routerLink]="['profile']" id="profile">
            {{'label.MyProfile' | translate}}
          </a>
        </li>
        <li data-test="header-profile-menu-styleguide-link"
            *ngIf="me.isAdmin || me.isInternal">
          <a class="dropdown-item" target="_blank" href="/ui/ui2/styleguide">
            {{'label.Styleguide' | translate}}
          </a>
        </li>
        <li data-test="header-profile-menu-logoff-link">
          <a class="dropdown-item" (click)="logoff()" href="javascript:void(0)" id="logout">
            {{'label.Logout' | translate}}
          </a>
        </li>
      </ul>
    </mat-menu>
  </div>
</div>
