import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IrisModalBase } from '@iris/common/utils/iris-modal.base';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { IrisLanguageI } from '@iris/common/models/IrisLanguage';
import { IrisModuleFilterI } from '@iris/common/modules/module-filter/models/IrisModuleFilter';
import { SubscriptionMixin } from '@iris/common/mixins/subscription.mixin';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { Observable } from 'rxjs';

@Component({
  selector: 'iris-module-save-filter-modal',
  styleUrls: ['./module-save-filter-modal.component.scss'],
  templateUrl: './module-save-filter-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisModuleSaveFilterModalComponent extends SubscriptionMixin(IrisModalBase) implements OnInit {

  set userFilter(userFilter: Partial<IrisModuleFilterI>) {
    this._userFilter = Object.assign({ }, userFilter);
    this.translationsName = userFilter?.translations?.name || { };
    this.userFilterForm.patchValue(this._userFilter, { emitEvent: false });
  }

  get userFilter(): Partial<IrisModuleFilterI> {
    return this._userFilter;
  }

  @Output() filterSave: EventEmitter<unknown> = new EventEmitter();

  userFilterForm: FormGroup;
  translationsName = { };
  languages$: Observable<IrisLanguageI[]> = this.globalSandbox.languages$;
  _userFilter: Partial<IrisModuleFilterI> = <IrisModuleFilterI> { };

  private readonly defaultControlValues = {
    name: ['', Validators.required],
    isPublic: false,
    isPinnedByDefault: false,
  };

  constructor(
    dialogRef: MatDialogRef<IrisModuleSaveFilterModalComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly globalSandbox: IrisGlobalSandbox,
    @Inject(MAT_DIALOG_DATA) public data: {
      userFilter: Partial<IrisModuleFilterI>;
    },
  ) {
    super(dialogRef);
    this.userFilterForm = this.formBuilder.group(this.defaultControlValues);
    this.userFilter = data.userFilter ?? {};
  }

  ngOnInit(): void {
    this.subscribe(
      this.userFilterForm.get('isPublic').valueChanges.pipe(
        filter((isPublic) => !isPublic),
      ),
      () => this.userFilterForm.patchValue({ isPinnedByDefault: false }),
    );
  }

  onFilterSave(): void {
    const translations = {
      translations: {
        name: this.translationsName,
      },
    };

    if (this.userFilter.isPublic) {
      this.userFilter.isPinned ||= this.userFilter.isPinnedByDefault;
    }

    const resultFilter = Object.assign({ }, this.userFilter, this.userFilterForm.value, translations);
    this.closeWithResult(resultFilter);
  }
}
