import { ChangeDetectionStrategy, Component, Inject, InjectionToken, Input, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';

export type ChipValueToStringFn = (val: unknown) => Observable<string>;
export const CHIP_VALUE_TO_STRING_FN = new InjectionToken<ChipValueToStringFn>('IrisFilterDefaultChipItemViewComponent - Chip value to string fn');

@Component({
  selector: 'iris-filter-default-chip-item-view',
  templateUrl: './default-chip-item-view.component.html',
  styleUrls: ['./default-chip-item-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisFilterDefaultChipItemViewComponent {
  @Input() text$: Observable<string>;
  @Input() maxWidth: number;

  constructor(
    @Inject(CHIP_VALUE_TO_STRING_FN) @Optional() readonly chipValueToStringFn: ChipValueToStringFn,
  ) {
    this.chipValueToStringFn ??= val => of(String(val));
  }
}
