import { createAction, props } from '@ngrx/store';
import { IrisEmailMessageI } from '../../models/IrisEmail';
import { EmailWellKnownFolderName } from '../../models/IrisEmailNavigation';
import { IrisEmailsPaginationI } from '../../models/IrisEmailPagination';
import { IrisEmailUserSettingsI } from '../../models/IrisEmailSettings';

const prefixed = (type: string): string => `[EmailsGlobal] ${type}`;

export const SetSelectedEmail = createAction(
  prefixed('Set Selected Email'),
  props<{ emailId: string; resetSelectedEmails: boolean }>(),
);

export const EmailSelected = createAction(
  prefixed('Email Selected'),
  props<{ emailId: string }>(),
);

export const SelectEmailsMessages = createAction(
  prefixed('Select Emails Messages'),
  props<{ messagesIds: string[] }>(),
);

export const DeselectEmailsMessages = createAction(
  prefixed('Deselect Emails Messages'),
  props<{ messagesIds: string[] }>(),
);

export const ToggleMultipleMessageSelection = createAction(
  prefixed('Toggle Multiple Message Selection'),
  props<{ messagesIds: string[] }>(),
);

export const SetMessagesCheckAll = createAction(
  prefixed('Set Messages Check All'),
  props<{ checkAll: boolean }>(),
);

export const ResetMessagesSelection = createAction(
  prefixed('Reset message Selection'),
);

export const ResetMessages = createAction(
  prefixed('Reset Messages'),
);

export const SetSelectedMenuItem = createAction(
  prefixed('Set Selected Menu Item'),
  props<{ menuItemId: string }>(),
);

export const SetMessagesPagination = createAction(
  prefixed('Set Messages Pagination'),
  props<{ pagination: IrisEmailsPaginationI }>(),
);

export const SetTotalItemsCount = createAction(
  prefixed('Set Total Items Count'),
  props<{ count: number }>(),
);

export const ResetMessagesPagination = createAction(
  prefixed('Reset Messages Pagination'),
  props<{ pagination?: IrisEmailsPaginationI }>(),
);

export const SetOpenedDraftMessage = createAction(
  prefixed('Set Opnend Draft Message'),
  props<{ message: IrisEmailMessageI }>(),
);

export const GetEmailsUserSettingsStart = createAction(
  prefixed('Get Emails User Settings Start'),
);

export const GetEmailsUserSettingsSuccess = createAction(
  prefixed('Get Emails User Settings Success'),
  props<{ settings: IrisEmailUserSettingsI }>(),
);

export const GetEmailsUserSettingsError = createAction(
  prefixed('Get Emails User Settings Error'),
);

export const GetFolderByShortcutStart = createAction(
  prefixed('Get Folder By Shortcut Start'),
  props<{ folderShortcut: EmailWellKnownFolderName }>(),
);

export const GetFolderByShortcutSuccess = createAction(
  prefixed('Get Folder By Shortcut Success'),
  props<{ folderShortcut: EmailWellKnownFolderName; folderId: string }>(),
);

export const GetFolderByShortcutError = createAction(
  prefixed('Get Folder By Shortcut Error'),
);

export const GetUnreadCountStart = createAction(
  prefixed('Get Unread Count Start'),
);

export const GetUnreadCountSuccess = createAction(
  prefixed('Get Unread Count Success'),
  props<{ unreadCount: number }>(),
);

export const GetUnreadCountError = createAction(
  prefixed('Get Unread Count Error'),
);
