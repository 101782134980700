import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { convertToArray } from '@iris/common/utils/array.utils';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';

const TAB_TITLE_PARTS_DELIMITER = ' ';
const TAB_TITLE_FALLBACK = 'IRIS';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  private readonly title = inject(Title);
  private readonly irisEnv = inject(IrisEnvironmentService);
  private readonly translate = inject(TranslateService);

  private readonly tabTitleSubject = new BehaviorSubject<string>(null);
  readonly tabTitle$ = combineLatest([
    this.tabTitleSubject.asObservable(),
    this.translate.onLangChange.pipe(startWith(null)),
  ]).pipe(map(([val]) => val));

  private readonly pageTitleSubject = new BehaviorSubject<string[]>([]);
  readonly pageTitle$ = combineLatest([
    this.pageTitleSubject.asObservable(),
    this.translate.onLangChange.pipe(startWith(null)),
  ]).pipe(map(([val]) => val));

  constructor() {
    this.tabTitle$.pipe(
      tap(title => this.applyTabTitle(title)),
    ).subscribe();
  }

  setTabTitle(title: string): void {
    this.tabTitleSubject.next(title);
  }

  private applyTabTitle(title: string): void {
    const titleParts: string[] = [];

    if (this.irisEnv.env.instanceName) {
      titleParts.push(`[${this.irisEnv.env.instanceName}]`);
    }

    if (title?.trim()) {
      titleParts.push(this.translate.instant(title));
    }

    this.title.setTitle(titleParts.join(TAB_TITLE_PARTS_DELIMITER) || TAB_TITLE_FALLBACK);
  }

  setPageTitle(titleChain: string | string[]): void {
    this.pageTitleSubject.next(convertToArray(titleChain, true));
  }
}
