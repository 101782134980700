import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { ValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver';
import { VALIDATION_MESSAGE_RESOLVER_LIST } from './provider';

const VALIDATION_ERROR_CODE_PRIORITY = [Validators.required.name, Validators.min.name, Validators.max.name];

@Injectable()
export class ValidationMessageResolverService {
  constructor(
    @Inject(VALIDATION_MESSAGE_RESOLVER_LIST) private readonly resolvers: ValidationMessageResolver[],
    private readonly translateService: TranslateService,
  ) { }

  getErrorMessage(formControl: FormControl): string | null {
    if (formControl.valid) { return null; }

    const priorityErrorCode = VALIDATION_ERROR_CODE_PRIORITY.find(errorCode => formControl.hasError(errorCode));
    if (priorityErrorCode) {
      return this.getErrorMessageByCode(formControl, priorityErrorCode);
    }

    const errorCode = Object.keys(formControl.errors)?.[0];
    return this.getErrorMessageByCode(formControl, errorCode);
  }

  getErrorMessageByCode(formControl: FormControl, errorCode: string): string {
    const resolver = this.resolvers.find(t => t.errorCode === errorCode);
    return resolver?.getMessage(formControl.getError(errorCode)) ?? this.defaultValidationMessage();
  }

  private defaultValidationMessage(): string {
    return this.translateService.instant('label.validation.default');
  }
}
