import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IrisModalBase } from '@iris/common/utils/iris-modal.base';
import { IrisModalHeaderTitleDirective } from './iris-modal-header-title.directive';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'iris-modal-content',
  templateUrl: './iris-modal-content.component.html',
  styleUrls: ['./iris-modal-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisModalContentComponent extends IrisModalBase implements OnInit, OnDestroy {
  @ContentChild(IrisModalHeaderTitleDirective) headerTitleTmpl!: IrisModalHeaderTitleDirective;

  @HostBinding('attr.data-test') dataTestAttribute;

  private readonly dialogTopPosition: string = '0px';

  private readonly destroy$ = new Subject<void>();

  isExpanded = false;

  smallDialogSubscription: unknown;

  @Input() parent;
  @Input() title: string;
  @Input() isHideCross = false;
  @Input() isHideCloseBtn = false;
  @Input() headerCloseIcon = 'fal fa-times';
  @Input() enableDrag = false;
  @Input() enableResize = false;
  _cancelBtnText: string;
  @Input() set cancelBtnText(cancelBtnText: string) {
    this._cancelBtnText = cancelBtnText;
  }

  get cancelBtnText(): string {
    return this._cancelBtnText || 'label.Cancel';
  }

  private dialogTestId(): string {
    const titleString = this.title?.replace(/\s+/g, '-').toLowerCase() ?? 'confirm';
    const titleSuffix = this.translate.instant('label.Dialog').toLowerCase();
    return this.dataTestAttribute = `${titleString}-${titleSuffix}`;
  }

  constructor(
    public dialogRef: MatDialogRef<IrisModalContentComponent>,
    private readonly translate: TranslateService,
  ) {
    super(dialogRef);
  }

  onResize(): void {
    if (this.isExpanded) {
      this.dialogRef.removePanelClass('iris-modal-wide');
      this.dialogRef.addPanelClass('iris-modal-basis');
    } else {
      this.dialogRef.removePanelClass('iris-modal-basis');
      this.dialogRef.addPanelClass('iris-modal-wide');
    }
    this.isExpanded = !this.isExpanded;
  }

  onClose(event: MouseEvent): void {
    event.preventDefault();
    this.parent.closeWithoutResult();
  }

  ngOnInit(): void {
    this.dialogTestId();
    this.dialogRef.updatePosition({ top: this.dialogTopPosition });
    this.dialogRef.addPanelClass('iris-modal-basis');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

}
