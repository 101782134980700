import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';
import { IMessage } from '@stomp/stompjs';
import { Observable, Subscription, map, switchMap, of } from 'rxjs';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';

@Injectable({ providedIn: 'root' })
export class CommandWebsocketService {
  private userSubscription: Subscription;

  constructor(
    private readonly websocketService: WebsocketService,
    private readonly envService: IrisEnvironmentService,
    private readonly authFacade: AuthFacade,
  ) {}

  get instanceId(): string {
    return this.envService.env.instanceId;
  }

  get instanceIdUrlPart(): string {
    return !!this.instanceId ? `/${this.instanceId}` : '';
  }

  connectToUserWs(userId: number): Observable<IMessage> {
    return this.websocketService.watchNotificationsWs(`/instance-broker${this.instanceIdUrlPart}/${userId}/command`);
  }

  public subscribeToUser(userId: number): void {
    if (!!this.userSubscription) {
      this.unsubscribeFromUser();
    }
    this.userSubscription = this.connectToUserWs(userId)
      .pipe(
        map(({ body }) => JSON.parse(body)),
        switchMap(event => {
          if (event === 'RefreshToken') {
            return this.authFacade.actualToken(true);
          }
          return of(null);
        }),
      ).subscribe();
  }

  public unsubscribeFromUser(): void {
    if (!!this.userSubscription) {
      this.userSubscription.unsubscribe();
      this.userSubscription = null;
    }
  }
}
