import SockJS from 'sockjs-client';
import { inject, Injectable } from '@angular/core';
import { MicroservicesUtils } from '../../utils/url.utils';
import { WatchedRxStomp, watchedStompServiceFactory } from '@iris/common/services/websockets/watched-rx-stomp';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';

@Injectable()
export class NotificationsStompService extends WatchedRxStomp {
  constructor() {
    super();
  }
}

export function notificationsStompServiceFactory(): NotificationsStompService {
  const envService = inject(IrisEnvironmentService,);
  const authFacade = inject(AuthFacade);
  return watchedStompServiceFactory(
    NotificationsStompService,
    authFacade,
    (authToken: string) => () => new SockJS(`${MicroservicesUtils.getMicroserviceBaseUrl('notifications', envService.env)}/websocket?token=${authToken}`, null, { transports: ['websocket'] }),
  );
}
