import { CHIP_VALUE_TO_STRING_FN, IrisFilterDefaultChipItemViewComponent } from '@iris/modules/module-filters-v2/chips/default-chip-item-view.component';
import { isObservable, Observable, of } from 'rxjs';
import { IrisFilterChipRenderingOptions } from './chip-rendering-options';
import { StaticProvider } from '@angular/core';

export function defaultChipRenderingOptions<TValue>(
  valueStringifyFn: (value: TValue) => string | Observable<string>,
  maxWidth?: number,
  componentProviders?: StaticProvider[],
): IrisFilterChipRenderingOptions<IrisFilterDefaultChipItemViewComponent, TValue> {
  return {
    component: IrisFilterDefaultChipItemViewComponent,
    componentProviders: [
      {
        provide: CHIP_VALUE_TO_STRING_FN,
        useValue: val => {
          const text = valueStringifyFn(val);
          return isObservable(text) ? text : of(text);
        },
      },
      ...(componentProviders ?? []),
    ],
    initComponentFn: (component, value) => {
      component.text$ = component.chipValueToStringFn(value);
      component.maxWidth = maxWidth;
    },
  };
}
