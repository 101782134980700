import { AbstractControl, ValidatorFn } from '@angular/forms';

export const DIVISIBLE_BY_VALIDATOR_NAME = 'divisibleBy';

export function divisibleByValidator(step: number): ValidatorFn {
  return (control: AbstractControl): Record<string, unknown> | null => {
    if (control.value != null && control.value % step !== 0) {
      return { [DIVISIBLE_BY_VALIDATOR_NAME]: { step, value: control.value } };
    }
    return null;
  };
}
