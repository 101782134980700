<mat-form-field [appearance]="$any(enhancedControl.fieldAppearance)">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input matInput #input
         type="{{showPassword ? 'text' : 'password'}}"
         name="password_value"
         [formControl]="valueControl"
         [required]="isFieldRequired"
         [readonly]="readonly"
         [autocomplete]="autocomplete"
  >

  <ng-container matSuffix>
    <button mat-icon-button
            type="button"
            class="visibility-switch"
            (click)="showPassword = !showPassword">
      <i class="icon far"
         [ngClass]="{'fa-eye': !showPassword, 'fa-eye-slash': showPassword}"></i>
    </button>

    <ng-container *ngIf="enhancedControl.fieldSuffix">
      <ng-template [cdkPortalOutlet]="enhancedControl.fieldSuffix"></ng-template>
    </ng-container>
  </ng-container>

  <mat-error *ngIf="valueControl.hasError('required')">
    {{'label.validation.required'|translate}}
  </mat-error>
  <mat-hint align="start">{{hint}}</mat-hint>
  <mat-hint align="end">{{hintRight}}</mat-hint>
</mat-form-field>
