import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IrisUserSelectInputItemComponent } from './input-item.component';
import { IrisUserWSComponent } from '@iris/common/modules/user-common/modules/user-ws/components/user-ws.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IrisUserWSComponent,
  ],
  declarations: [IrisUserSelectInputItemComponent],
  exports: [IrisUserSelectInputItemComponent],
})
export class IrisUserSelectInputItemComponentModule { }
