<div class="iris-header-help">
  <div class="nav-item"
       [class.show]="menuDropdownTrigger.menuOpen"
       data-test="header-help-button">
      <button class="btn btn-link nav-link"
              #menuDropdownTrigger="matMenuTrigger"
              [matMenuTriggerFor]="helpMenu"
              [matTooltip]="'label.Help' | translate">
        <i class="fas fa-lg fa-fw fa-question-circle"></i>
      </button>
      <mat-menu #helpMenu="matMenu"
                class="iris-dropdown-menu with-icon pt-2 pb-2"
                data-test="header-help-menu">
        <ul class="list-group">
          <li data-test="header-help-main-link">
            <a class="dropdown-item"
               [href]="helpCenterUrl$ | async"
               target="_blank">{{'label.Help' | translate}}</a>
          </li>
          <li data-test="header-help-about-link">
            <button (click)="openModalAbout()"
                    class="dropdown-item">
              <span class="item-title">{{'label.AboutIRIS' | translate}}</span>
              <span class="item-hotkey">{{'label.Ctrl' | translate}} + i</span>
            </button>
          </li>
        </ul>
      </mat-menu>
  </div>
</div>
