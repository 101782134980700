import { userChipRenderingOptions } from '@iris/modules/module-filters-v2/chips/rendering-options';
import { appendPureValueQueryParams } from '@iris/modules/module-filters-v2/query-params';
import { IrisFilterPropertyMeta } from './filter-property-meta';
import { filterValueUniversalIsEmpty } from '@iris/modules/module-filters-v2/utils';

export function userFilterMeta(
  propertyId: string,
  label: string,
  fieldNames: string | string[] = propertyId,
): IrisFilterPropertyMeta {
  return {
    propertyId,
    label,
    appendFilterQueryParamsFn: appendPureValueQueryParams(fieldNames),
    chipRenderingOptions: userChipRenderingOptions(),
    filterValueIsEmpty: filterValueUniversalIsEmpty,
  };
}
