import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IrisFieldsModule } from '../fields/fields.module';
import { TranslateModule } from '@ngx-translate/core';
import { IrisCommonPipesModule } from '../pipes-common/common-pipes.module';
import { IrisExternalCommonLibsModule } from '../external-common-libs/external-common-libs.module';
import { IrisCommonDirectivesModule } from '../directives-common/common-directives.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    IrisFieldsModule,
    IrisCommonPipesModule,
    IrisCommonDirectivesModule,
    IrisExternalCommonLibsModule,
  ],
  exports: [
    IrisFieldsModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IrisCommonPipesModule,
    IrisCommonDirectivesModule,
    IrisExternalCommonLibsModule,
  ],
})
export class IrisCommonModule {
}
