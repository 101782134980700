import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { TranslateModule } from '@ngx-translate/core';
import { IrisTimeModule } from '@iris/common/modules/time';
import { IrisCommonDirectivesModule } from '@iris/common/modules/directives-common/common-directives.module';
import { IrisFieldDatetimeComponent } from './field-datetime.component';
import { MatDatetimepickerContentExtended, MatDatetimepickerExtended } from './mat-datetimepicker/datetimepicker';
import { MatDatetimepickerCalendarExtended } from './mat-datetimepicker/calendar';
import { MatDatetimepickerMultiYearViewExtended } from './mat-datetimepicker/multi-year-view';
import { MatDatetimepickerMonthViewExtended } from './mat-datetimepicker/month-view';
import { MatDatetimepickerCalendarBodyExtended } from './mat-datetimepicker/calendar-body';
import { DatetimePickerAdapter } from './datetime-picker.adapter';
import { DatetimeAdapterExtended } from './mat-datetimepicker/datetime-adapter';
import { InputAllowedCharactersDirective } from './allowed-characters.directive';
import { MatDatetimepickerYearViewExtended } from './mat-datetimepicker/year-view';
import { MatDatetimepickerYearBodyExtended } from './mat-datetimepicker/year-body';
import { MatDatetimepickerInputExtended } from './mat-datetimepicker/datetimepicker-input';
import { MatDatetimepickerToggleExtended } from './mat-datetimepicker/datetimepicker-toggle';
import { IrisRelativeDatetimeInputComponentModule } from './iris-relative-datetime-input/component.module';
import { LuxonDateAdapter, LuxonDateModule } from '@angular/material-luxon-adapter';
import { IrisInfoIconComponent } from '../../ui/modules/info-icon/info-icon.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
    IrisCommonDirectivesModule,
    IrisTimeModule,
    MatMenuModule,
    MatDatetimepickerModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatTooltipModule,
    LuxonDateModule,
    IrisRelativeDatetimeInputComponentModule,
    IrisInfoIconComponent,
  ],
  exports: [
    IrisFieldDatetimeComponent,
    MatDatetimepickerCalendarExtended,
    MatDatetimepickerExtended,
  ],
  declarations: [
    MatDatetimepickerExtended,
    IrisFieldDatetimeComponent,
    MatDatetimepickerContentExtended,
    MatDatetimepickerCalendarExtended,
    MatDatetimepickerYearViewExtended,
    MatDatetimepickerYearBodyExtended,
    MatDatetimepickerMultiYearViewExtended,
    MatDatetimepickerMonthViewExtended,
    MatDatetimepickerCalendarBodyExtended,
    MatDatetimepickerInputExtended,
    MatDatetimepickerToggleExtended,
    InputAllowedCharactersDirective,
  ],
  providers: [
    LuxonDateAdapter,
    {
      provide: DatetimeAdapterExtended,
      useClass: DatetimePickerAdapter,
    },
  ],
})
export class IrisFieldDatetimeModule { }
