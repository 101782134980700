import { isDataColumn } from '@iris/common/modules/dynamic-forms/df-list/utils/df-list.grid.utils';
import { getFieldNameForFilter, idExtractor } from '@iris/common/modules/dynamic-forms/df-prop/filters/utils';
import { IRIS_CURRENT_USER_ID, IrisUserInfoI } from '@iris/common/modules/user-common/models/IrisUserInfo';
import { userChipRenderingOptions } from '@iris/modules/module-filters-v2/chips/rendering-options';
import { DfPropUserData } from './data';
import { defaultAppendQueryParamsFn, IrisFilterAppendQueryParamsContext } from '@iris/modules/module-filters-v2/query-params';
import { convertToArray } from '@iris/common/utils/array.utils';
import { IrisFilterPropertyMeta } from '@iris/modules/module-filters-v2/filter-meta';
import { DfFilterInfo } from '@iris/common/modules/dynamic-forms/df-filter/models/df-filter-info';
import { DfPropUserSettings } from '@iris/common/modules/dynamic-forms/df-prop/properties/user/settings';
import { IrisFilterValues } from '@iris/modules/module-filters-v2/filter-values';
import { filterValueUniversalIsEmpty } from '@iris/modules/module-filters-v2/utils';

export const USER_FILTER_META_RESOLVER = {
  filter: buildDfPropUserSidebarFilterMeta,
  floatingFilter: commonUserFilterMeta,
};

export function buildDfPropUserSidebarFilterMeta(filterInfo: DfFilterInfo<DfPropUserSettings>): IrisFilterPropertyMeta {
  return {
    label: filterInfo.name,
    chipRenderingOptions: userChipRenderingOptions<IrisUserInfoI | IrisUserInfoI['id']>(idExtractor),
    ...commonUserFilterMeta(filterInfo),
  } as IrisFilterPropertyMeta;
}

function commonUserFilterMeta(filterInfo: DfFilterInfo<DfPropUserSettings>): IrisFilterPropertyMeta {
  return {
    propertyId: filterInfo.id,
    filterValueIsEmpty: val => filterValueUniversalIsEmpty(val),
    appendFilterQueryParamsFn: defaultAppendQueryParamsFn(
      getFieldNameForFilter<DfPropUserData>(filterInfo.dataPath, isDataColumn(filterInfo.dataPath) ? 'userId' : null),
      userFilterValueConverter,
    ),
  } as IrisFilterPropertyMeta;
}

function userFilterValueConverter (value: IrisFilterValues | IrisFilterValues[], context: IrisFilterAppendQueryParamsContext): number[] {
  const valueConverter = (value: IrisFilterValues): number => {
    const userId = getUserId(value);
    return userId === IRIS_CURRENT_USER_ID ? context.currentUser.id : userId;
  };
  return convertToArray(value, true).map((val) => valueConverter(val));
}

export function getUserId(value: unknown): number {
  if (value == null) { return null; }
  return typeof value === 'object' ? value['id'] : value;
}
