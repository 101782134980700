import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { IrisUserService } from '@iris/common/services/user.service';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { take, tap } from 'rxjs/operators';

const BASE_HREF = '/ui/ui2/';

export const isUserLoggedInGuard: CanMatchFn = (_route: Route, _segments: UrlSegment[]) => {
  const isLoggedIn = inject(AuthFacade).isLoggedIn();
  const userService = inject(IrisUserService);
  const router = inject(Router);

  if (isLoggedIn) {
    userService.getMeStartPageUrl().pipe(
      take(1),
      tap(pageUrl => {
        if (pageUrl.startsWith(BASE_HREF)) {
          void router.navigate(pageUrl.replace(BASE_HREF, '').split('/'));
        } else {
          window.location.replace(pageUrl);
        }
      }),
    ).subscribe();
  }

  return !isLoggedIn;
};
