import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IrisFilterDefaultChipItemViewComponent } from './default-chip-item-view.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [IrisFilterDefaultChipItemViewComponent],
  exports: [IrisFilterDefaultChipItemViewComponent],
})
export class IrisFilterDefaultChipItemViewComponentModule { }
