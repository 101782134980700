import { Injectable } from '@angular/core';
import { IrisGlobalSettingsI } from '@iris/common/models/IrisGlobalSettings';
import { IrisGlobalSettingsService, IrisModuleAliasType } from '@iris/common/services/settings/global-settings.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HelpUrlSettingsService {

  defaultHelpUrl = 'https://help.iris-4.com';

  private readonly helpUrlSubject = new BehaviorSubject<string>(null);
  helpUrl$ = this.helpUrlSubject.asObservable();

  constructor(
    private readonly globalSettingsService: IrisGlobalSettingsService,
  ) { }

  getHelpUrl(): Observable<void> {
    return this.globalSettingsService.getGlobalSettingsById$<{helpCenterUrl: string}>(IrisModuleAliasType.General).pipe(
      take(1),
      map(settings =>  {
        const url = settings.helpCenterUrlTranslated || this.defaultHelpUrl;
        this.helpUrlSubject.next(url);
      }),
    );
  }

  updateUrl(settings: IrisGlobalSettingsI<{helpCenterUrl: string}>): Observable<void> {
    return this.globalSettingsService.updateGlobalSettings<{helpCenterUrl: string}>(IrisModuleAliasType.General, settings).pipe(
      switchMap(() => this.getHelpUrl()),
    );
  }

  setHelpUrl(url: string): void {
    this.helpUrlSubject.next(url);
  }
}
