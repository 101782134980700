import { omit, isArray } from 'lodash';
import { Primitive } from '@iris/common/models/Primitive';

interface IsSubarrayOptionsI {
  discardEmpty?: boolean;
}

export function sortCompare(a: any, b: any, reverse = false): number {
  const res = reverse ? 1 : -1;
  if (a < b) { return res; }
  if (a > b) { return -res; }
  return 0;
}

export function sortCompareBy<T extends Record<string, any>>(a: T, b: T, compareField: string | ((t: T) => any), reverse = false): number {
  let compareA: any;
  let compareB: any;
  if (compareField instanceof Function) {
    compareA = compareField(a);
    compareB = compareField(b);
  } else {
    compareA = a[compareField];
    compareB = b[compareField];
  }
  return sortCompare(compareA, compareB, reverse);
}

export function sortLocaleCompare(a: string, b: string, reverse = false): number {
  if (a === null || a === undefined) { return 1; }
  const compareResult = a.localeCompare(b);
  return reverse ? -compareResult : compareResult;
}

export function sortLocaleCompareBy<T extends Record<string, any>>(a: T, b: T, compareField: string | ((t: T) => any), reverse = false): number {
  let compareA: string;
  let compareB: string;
  if (compareField instanceof Function) {
    compareA = compareField(a);
    compareB = compareField(b);
  } else {
    compareA = a[compareField];
    compareB = b[compareField];
  }
  return sortLocaleCompare(compareA, compareB, reverse);
}

export const sortCompareFn = (reverse = false) => (a: any, b: any) => sortCompare(a, b, reverse);
export const sortCompareByFn = <T extends Record<string, any>>(compareField: string | ((t: T) => any), reverse = false) => (a: T, b: T) => sortCompareBy(a, b, compareField, reverse);
export const sortLocaleCompareFn = (reverse = false) => (a: any, b: any) => sortLocaleCompare(a, b, reverse);
export const sortLocaleCompareByFn = <T extends Record<string, any>>(compareField: string | ((t: T) => any), reverse = false) => (a: T, b: T) => sortLocaleCompareBy(a, b, compareField, reverse);

export function trackByIndex(index) {
  return index;
}

export function trackByField(fieldName) {
  return function (index, item) {
    return item[fieldName];
  };
}

export function trackByFieldTyped<T extends Record<string, any>>(field: string | ((t: T) => any)) {
  return function (index, item) {
    if (field instanceof Function) {
      return field(item);
    }
    return item[field];
  };
}

export const findAndGetNeighbour = <T extends Record<string, any>>(array: T[], shift: number, predicate: (value: T, index: number, obj: T[]) => boolean) => {
  return array[array.findIndex(predicate) + shift];
};

export function replaceItemInArray<T extends Record<string, any>>(oldItems: T[], oldItemFinder: (oldItem: T) => boolean, newItemGetter: (oldItem?: T) => T, pushToTop = false, keepArray = false): T[] {
  const newItems = keepArray ? oldItems : [...oldItems];
  const itemIndex = oldItems.findIndex(oldItemFinder);
  const newItem = newItemGetter(itemIndex >= 0 ? newItems[itemIndex] : null);
  if (newItem) {
    if (itemIndex >= 0) {
      newItems.splice(itemIndex, 1, newItem);
    } else {
      if  (!pushToTop) {
        newItems.push(newItem);
      } else {
        newItems.unshift(newItem);
      }
    }
  }
  return newItems;
}

export const omitInEach = <T extends Record<string, any>>(array: T[], fieldOrFields: string | string[]) => {
  return array.map((item) => omit(item, isArray(fieldOrFields) ? fieldOrFields : [fieldOrFields]));
};

export const isEveryTruthy = (array: any[]): boolean => {
  return array.every(Boolean);
};

export const isSubarray = (
  biggerArray: Primitive[],
  maybeSubarray: Primitive[],
  options: IsSubarrayOptionsI = { discardEmpty: false },
): boolean => {
  if (options.discardEmpty && !maybeSubarray.length) {
    return false;
  }
  const parentValuesObj = {};
  biggerArray.forEach((value) => parentValuesObj[JSON.stringify(value)] = true);
  return maybeSubarray.every((item) => parentValuesObj[JSON.stringify(item)]);
};

export function arraysEqual<T>(a: T[], b: T[], comparer = (a, b) => a === b): boolean {
  return a?.length === b?.length && !a?.some(ia => !b?.some(ib => comparer(ia, ib)));
}

export function arraysEqualWithOrder<T>(a: T[], b: T[], comparer = (a, b) => a === b): boolean {
  return a?.length === b?.length && a?.every((ia, index) => comparer(ia, b[index]));
}

export function convertToArray<T>(value: T | T[], replaceNullWithEmptyArray: boolean): T[] {
  if (Array.isArray(value)) return value;

  if (replaceNullWithEmptyArray && value == null) return [];

  return [value];
}

export function appendIfNotFalsy<TItem>(arr: TItem[], item: TItem): TItem[] {
  return item ? [...arr, item] : [...arr];
}
