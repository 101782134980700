import { Injectable } from '@angular/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { MatDialog } from '@angular/material/dialog';
import { IrisModalAboutComponent } from '../../modules/about/iris-modal-about.component';

@Injectable({ providedIn: 'root' })
export class IrisHotkeysService {

  constructor(
    private readonly hotkeysService: HotkeysService,
    private readonly dialog: MatDialog,
  ) {
    this.hotkeysService.add([
      new Hotkey('ctrl+,', (_event: KeyboardEvent): boolean => {
        window.open('/ui/ui2/configuration', '_blank');
        return false;
      }),

      new Hotkey('ctrl+.', (_event: KeyboardEvent): boolean => {
        window.open('/ui/ui2/administration', '_blank');
        return false;
      }),

      new Hotkey('ctrl+i', (_event: KeyboardEvent): boolean => {
        this.dialog.open(IrisModalAboutComponent, { });
        return false;
      }),
    ]);
  }
}
