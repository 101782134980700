import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IrisUserAvatarModule } from '@iris/common/modules/user-common/modules/user-avatar/user-avatar.module';
import { IrisHeaderSearchResultForUserComponent } from './user.component';

@NgModule({
  imports: [
    CommonModule,
    IrisUserAvatarModule,
  ],
  declarations: [IrisHeaderSearchResultForUserComponent],
  exports: [IrisHeaderSearchResultForUserComponent],
})
export class IrisHeaderSearchResultForUserComponentModule { }
