import { Injectable } from '@angular/core';
import { IrisUserService } from '@iris/common/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class IrisLocaleService {

  constructor(
    private readonly userService: IrisUserService,
  ) { }

  getCurrentLocale(): string {
    return this.userService.me?.language;
  }
}
