import { NgModule } from '@angular/core';
import { IrisScreensaverComponent } from './screensaver.component';

@NgModule({
  declarations: [
    IrisScreensaverComponent,
  ],
  exports: [
    IrisScreensaverComponent,
  ],
})
export class IrisScreensaverModule { }
