import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IrisQueryParams } from '@iris/api-query';

export interface IrisDepartmentWithParentsI {
  id: string;
  name: string;
  companyId: number;
  translations: any;
  parentDepartmentInfo: any;
}

@Injectable({ providedIn: 'root' })
export class IrisDepartmentService {

  constructor(private readonly _httpClient: HttpClient) {
  }

  hasChildren(companyId: number, departmentId: string): Observable<boolean[]> {
    return this._httpClient.get<boolean[]>(`/system/companies/${companyId}/departments/${departmentId}/hasChildren`);
  }

  removeWithChildren(companyId: number, departmentId: string): Observable<IrisDepartmentI[]> {
    return this._httpClient.delete<IrisDepartmentI[]>(`/system/companies/${companyId}/departments/${departmentId}`);
  }

  saveDepartment(companyId: number, department: IrisDepartmentI): Observable<IrisDepartmentI> {
    return this._httpClient.post<IrisDepartmentI>(`/system/companies/${companyId}/departments/${department.id}`, department);
  }

  createDepartment(companyId: number, department: IrisDepartmentI): Observable<IrisDepartmentI> {
    return this._httpClient.post<IrisDepartmentI>(`/system/companies/${companyId}/departments`, department);
  }

  getDepartmentsByCompanyId(companyId: number): Observable<IrisDepartmentI[]> {
    return this._httpClient.get<IrisDepartmentI[]>(`/system/companies/${companyId}/departments/`);
  }

  getAllDepartments(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisDepartmentI[]> {
    return this._httpClient.get<IrisDepartmentI[]>('/system/departments', { params: params.toObject() });
  }

  getDepartmentsWithParents(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisDepartmentWithParentsI[]> {
    return this._httpClient.get<IrisDepartmentWithParentsI[]>('/system/companies/departments/with-parents', { params: params.toObject() });
  }

  getDepartmentById(departmentId: number): Observable<IrisDepartmentI> {
    return this._httpClient.get<IrisDepartmentI>(`/system/departments/${departmentId}`);
  }

}
