import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { isTouchDevice } from '@iris/common/utils/touch.utils';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule, NgxTippyProps } from 'ngx-tippy-wrapper';

@Component({
  selector: 'iris-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    NgxTippyModule,
    TranslateModule,
  ],
})
export class IrisInfoIconComponent implements AfterViewInit {
  @ViewChild('icon') icon: ElementRef;

  @Input() message = '';
  @Input() disabled = false;

  @HostBinding('class.d-none') get hidden(): boolean {
    return !this.message;
  }

  readonly tooltipProps: NgxTippyProps = {
    arrow: true,
    maxWidth: '500px',
    trigger: this.triggers,
    theme: 'light-border',
    appendTo: document.body,
  };

  get triggers(): string {
    return this.isTouch() ? 'click focus' : 'mouseenter focus';
  }

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  isTouch(): boolean {
    return isTouchDevice();
  }

  setMessage(propInfo: string): void {
    this.message = propInfo;
    this.changeDetector.markForCheck();
  }

  ngAfterViewInit(): void {
    if (this.disabled) {
      this.icon.nativeElement.setAttribute('disabled', '');
    };
  }
}
