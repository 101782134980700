import { Component, ChangeDetectionStrategy, TemplateRef, ViewChild } from '@angular/core';
import { FieldOptions, IrisProjectSelectEngine } from '@iris/common/modules/fields/project-select/project-select.engine';

@Component({
  selector: 'iris-project-select-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisProjectSelectDropdownItemComponent {

  @ViewChild('template', { static: true }) template: TemplateRef<unknown>;
  options: FieldOptions;

  constructor(projectSelect: IrisProjectSelectEngine) {
    this.options = projectSelect.options;
  }
}
