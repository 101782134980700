import { NgModule } from '@angular/core';
import { ToggleComponent } from './toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    MatSlideToggleModule,
    ReactiveFormsModule,
  ],
  declarations: [ToggleComponent],
  exports: [ToggleComponent],
})
export class ToggleModule { }
