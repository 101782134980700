import { IrisQueryParamsOrderBy } from '@iris/api-query';
import { Action, createReducer, on } from '@ngrx/store';
import { compact, difference, isUndefined, uniq, uniqBy } from 'lodash';
import { IrisEmailMessageI } from '../../models/IrisEmail';
import { IrisEmailUserSettingsI } from '../../models/IrisEmailSettings';
import { IrisEmailsPaginationI } from '../../models/IrisEmailPagination';
import {
  GetMessageAttachmentsSuccess,
  RemoveMessageAttachmentSuccess,
  UploadAttachmentsFromDmsSuccess,
  UploadMessageAttachmentsSuccess,
} from '../messages/emails-messages.actions';
import {
  SetSelectedEmail,
  SetSelectedMenuItem,
  SelectEmailsMessages,
  DeselectEmailsMessages,
  SetMessagesPagination,
  ToggleMultipleMessageSelection,
  SetMessagesCheckAll,
  ResetMessagesPagination,
  ResetMessagesSelection,
  SetOpenedDraftMessage,
  SetTotalItemsCount,
  GetEmailsUserSettingsSuccess,
  ResetMessages,
  GetFolderByShortcutSuccess,
  GetUnreadCountSuccess,
} from './emails-global.actions';
import { EmailWellKnownFolderName } from '../../models/IrisEmailNavigation';

export const defaultMessagesPagination: IrisEmailsPaginationI = {
  offset: 0,
  limit: 25,
  orderBy: [new IrisQueryParamsOrderBy({ name: 'date', value: 'desc' })],
  searchText: '',
};

export interface EmailsGlobalState {
  selectedEmailId: string;
  selectedEmailsIds: string[];
  deselectedEmailsIds: string[];
  checkAll: boolean;
  selectedMenuItemId: string;
  messagesPagination: IrisEmailsPaginationI;
  messagesTotalItemsCount: number;
  openedDraftMessage: IrisEmailMessageI;
  userSettings: IrisEmailUserSettingsI;
  folderShortcutMap: Map<EmailWellKnownFolderName, string>;
  unreadCount: number;
}

const initialState: EmailsGlobalState = {
  selectedEmailId: null,
  selectedEmailsIds: [],
  deselectedEmailsIds: [],
  checkAll: false,
  selectedMenuItemId: null,
  messagesPagination: defaultMessagesPagination,
  messagesTotalItemsCount: null,
  openedDraftMessage: null,
  userSettings: {},
  folderShortcutMap: new Map(),
  unreadCount: 0,
};

const emailsGlobalReducer = createReducer(
  initialState,
  on(SetSelectedMenuItem, (state, { menuItemId }) => ({
    ...state,
    selectedMenuItemId: menuItemId,
    openedDraftMessage: null,
  })),
  on(SetSelectedEmail, (state, { emailId, resetSelectedEmails }) => ({
    ...state,
    selectedEmailId: emailId,
    selectedEmailsIds: resetSelectedEmails ? [] : state.selectedEmailsIds,
    checkAll: false,
    openedDraftMessage: null,
  })),
  on(SelectEmailsMessages, (state, { messagesIds }) => {
    if (state.checkAll) {
      return {
        ...state,
        deselectedEmailsIds: difference(state.deselectedEmailsIds, messagesIds),
      };
    }
    return {
      ...state,
      selectedEmailsIds: messagesIds,
    };
  }),
  on(DeselectEmailsMessages, (state, { messagesIds }) => {
    if (state.checkAll) {
      const deselectedEmailsIds = uniq([...state.deselectedEmailsIds, ...messagesIds]);
      return {
        ...state,
        deselectedEmailsIds,
        checkAll: state.messagesTotalItemsCount !== deselectedEmailsIds.length,
      };
    }
    return {
      ...state,
      selectedEmailsIds: difference(state.selectedEmailsIds, messagesIds),
    };
  }),
  on(SetMessagesPagination, (state, { pagination }) => {
    return {
      ...state,
      messagesPagination: {
        offset: isUndefined(pagination.offset) ? state.messagesPagination.offset : pagination.offset,
        limit: isUndefined(pagination.limit) ? state.messagesPagination.limit : pagination.limit,
        orderBy: isUndefined(pagination.orderBy) ? state.messagesPagination.orderBy : pagination.orderBy,
        searchText: isUndefined(pagination.searchText) ? state.messagesPagination.searchText : pagination.searchText,
        filter: isUndefined(pagination.filter) ? state.messagesPagination.filter : pagination.filter,
      },
    };
  }),
  on(SetTotalItemsCount, (state, { count }) => {
    return {
      ...state,
      messagesTotalItemsCount: count,
    };
  }),
  on(ResetMessagesPagination, (state, { pagination }) => {
    return {
      ...state,
      messagesPagination: { ...defaultMessagesPagination, ...(pagination ?? {}) },
    };
  }),
  on(ToggleMultipleMessageSelection, (state, { messagesIds }) => {
    const selectedMessagesIds = state.selectedEmailsIds;
    const messagesToBeKept = difference(selectedMessagesIds, messagesIds);
    const messagesToAdd = difference(messagesIds, selectedMessagesIds);
    return {
      ...state,
      selectedEmailsIds: [...messagesToBeKept, ...messagesToAdd],
      checkAll: false,
    };
  }),
  on(SetMessagesCheckAll, (state, { checkAll }) => ({
    ...state,
    selectedEmailsIds: [],
    deselectedEmailsIds: [],
    checkAll,
  })),
  on(ResetMessagesSelection, state => ({
    ...state,
    selectedEmailId: null,
    selectedEmailsIds: [],
    deselectedEmailsIds: [],
    checkAll: false,
  })),
  on(SetOpenedDraftMessage, (state, { message }) => ({ ...state, openedDraftMessage: message })),
  on(
    UploadMessageAttachmentsSuccess,
    GetMessageAttachmentsSuccess,
    UploadAttachmentsFromDmsSuccess,
    (state, { messageId, attachments }) => {
      if (messageId !== state.openedDraftMessage?.id) { return state; }
      const updatedAttachments = compact(uniqBy([...(state.openedDraftMessage?.attachments ?? []), ...attachments], 'id'));
      const openedDraftMessage = {
        ...state.openedDraftMessage,
        attachments: updatedAttachments,
      };
      return {
        ...state,
        openedDraftMessage,
      };
    },
  ),
  on(RemoveMessageAttachmentSuccess, (state, { messageId, attachmentId }) => {
    if (messageId !== state.openedDraftMessage?.id) { return state; }
    const updatedAttachments = (state.openedDraftMessage?.attachments ?? []).filter(attachment => attachment.id !== attachmentId);
    const openedDraftMessage = {
      ...state.openedDraftMessage,
      attachments: updatedAttachments,
      hasAttachments: !!updatedAttachments.length,
    };
    return {
      ...state,
      openedDraftMessage,
    };
  }),
  on(
    GetEmailsUserSettingsSuccess,
    (state, { settings }) => ({ ...state, userSettings: { ...state.userSettings, ...settings } }),
  ),
  on(GetFolderByShortcutSuccess, (state, { folderShortcut, folderId }) => ({
    ...state,
    folderShortcutMap: state.folderShortcutMap.set(folderShortcut, folderId),
  })),
  on(ResetMessages, () => {
    return {
      ...initialState,
      messagesPagination: defaultMessagesPagination,
      folderShortcutMap: new Map(),
    };
  }),
  on(GetUnreadCountSuccess, (state, { unreadCount }) => ({
    ...state,
    unreadCount,
  })),
);

export const reducer = (state: EmailsGlobalState | undefined, action: Action): EmailsGlobalState => emailsGlobalReducer(state, action);

export const selectedMenuItemId = (state: EmailsGlobalState): string => state.selectedMenuItemId;
export const selectedMessageId = (state: EmailsGlobalState): string => state.selectedEmailId;
export const selectedMessagesIds = (state: EmailsGlobalState): string[] => state.selectedEmailsIds;
export const deselectedMessagesIds = (state: EmailsGlobalState): string[] => state.deselectedEmailsIds;
export const messagesPagination = (state: EmailsGlobalState): IrisEmailsPaginationI => state.messagesPagination;
export const messageTotalItemsCount = (state: EmailsGlobalState): number => state.messagesTotalItemsCount;
export const checkAll = (state: EmailsGlobalState): boolean => state.checkAll;
export const openedDraftMessage = (state: EmailsGlobalState): IrisEmailMessageI => state.openedDraftMessage;
export const hasMessagesNextPage = (state: EmailsGlobalState): boolean => {
  return state.messagesPagination.offset + state.messagesPagination.limit < state.messagesTotalItemsCount;
};
export const getUserSettings = (state: EmailsGlobalState): IrisEmailUserSettingsI => state.userSettings;
export const getFolderByShortcut = (state: EmailsGlobalState, folderShortcut: EmailWellKnownFolderName): string =>
  state.folderShortcutMap.get(folderShortcut);
export const unreadCount = (state: EmailsGlobalState): number => state.unreadCount;
