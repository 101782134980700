import { Component, ViewEncapsulation } from '@angular/core';
import { IrisMainMenuService } from '@iris/common/services/main-menu.service';
import { IrisMenuItemI } from '@iris/common/models/IrisMenuItem';
import cloneDeep from 'lodash/cloneDeep';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { getUi2SubPathOrNull } from '@iris/common/utils/url.utils';

@Component({
  selector: 'iris-header-settings',
  templateUrl: './header-settings.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class IrisHeaderSettingsComponent {
  menu: IrisMenuItemI[] = [];

  constructor(
    private readonly mainMenuService: IrisMainMenuService,
    private readonly translate: TranslateService,
  ) {
    this.mainMenuService.getConfigMenu().pipe(
      tap(configMenu => {
        const treeMenu: IrisMenuItemI[] = this.mainMenuService.prepareMenuFromData(cloneDeep(configMenu));
        this.menu = this.getFlatList(treeMenu, 0);
      }),
    ).subscribe();
  }

  getUi2SubPathOrNull = (url: string): string => getUi2SubPathOrNull(url);

  private getFlatList(menu: IrisMenuItemI[], level: number): IrisMenuItemI[] {
    if (!Array.isArray(menu) || !menu.length) {
      return [];
    }

    return menu.reduce((list, item) => {
      item.level = level;
      item.dataTestAttr = item.url != null ? item.title?.toLowerCase().split(' ').join('-') : null;

      if (item.module) {
        switch (item.module.toLowerCase()) {
          case 'systemconfig':
            item.hotkey = `${this.translate.instant('label.Ctrl')} + ,`;
            break;
          case 'admin':
            item.hotkey = `${this.translate.instant('label.Ctrl')} + .`;
            break;
        }
      }

      list.push(item);

      return list.concat(this.getFlatList(item.children, level + 1));
    }, []);
  }
}
