@if (!!tags.length) {
  <div class="fltr-tags">
    <div class="fltr-tags__cnt w-100" [ngClass]="cssClass">
      <mat-chip-listbox selectable="false" class="w-100">
        @for (tag of tags; track tag.label) {
          @if (!tag.meta.isSplittable) {
            <ng-template #tooltipContent><div [innerHtml]="tag.tooltip"></div></ng-template>
            <mat-chip-option
              [ngxTippy]="tag.tooltip ? tooltipContent : null"
              [tippyProps]="{ placement: 'bottom', theme: 'light-border' }">
              {{ tag.label }}
              <i class="far fa-times ms-2 clickable" (click)="removeTag(tag)"></i>
            </mat-chip-option>
          } @else {
            @if (tag.isBulk) {
              <iris-module-filter-tag-chip-bulk
                [tag]="tag"
                class="mx-2"
                (tagItemRemoved)="removeTagItem($event)"
                (tagGroupRemoved)="removeTag($event)"
                (openFilterMenuClicked)="openFilterMenu()"
              ></iris-module-filter-tag-chip-bulk>
            } @else {
              @for (tagItem of tag.items; track tagItem) {
                <iris-module-filter-tag-chip-single
                  [tag]="tag"
                  [value]="tagItem"
                  class="mx-2"
                  (tagItemRemoved)="removeTagItem($event)"
                ></iris-module-filter-tag-chip-single>
              }
            }
          }
        }
      </mat-chip-listbox>
    </div>
    <div class="fltr-tags__btn">
      <button class="btn btn-link fltr-tags__btn-clear text-nowrap" (click)="resetFilterToDefault()">
        {{ 'label.ResetFilter' | translate }}
      </button>
    </div>
  </div>
}
