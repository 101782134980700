import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IrisModalContentModule } from '../iris-modal-content/iris-modal-content.module';
import { IrisTimeModule } from '@iris/common/modules/time';
import { TranslateModule } from '@ngx-translate/core';
import { IrisModalAboutComponent } from './iris-modal-about.component';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports: [
    IrisModalContentModule,
    TranslateModule,
    IrisTimeModule,
    CommonModule,
    MatButtonModule,
    MatListModule,
    FormsModule,
    ClipboardModule,
  ],
  declarations: [
    IrisModalAboutComponent,
  ],
  exports: [
    IrisModalAboutComponent,
  ],
})
export class IrisAboutModule {
}
