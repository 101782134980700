import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { MicroservicesUtils } from '@iris/common/utils/url.utils';
import { IrisQueryParamsBuilder } from '@iris/api-query';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';

@Injectable({ providedIn: 'root' })
export class IrisNotificationsService {

  private readonly notificationsServiceBaseUrl = MicroservicesUtils.getMicroserviceBaseUrl('notifications', this.envService.env);

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly authFacade: AuthFacade,
    private readonly userService: IrisUserService,
    private readonly envService: IrisEnvironmentService,
  ) { }

  getUserNotifications(userId: number, limit: number, offset: number, unread: boolean): Observable<IrisNotificationI[]> {
    const queryParamsBuilder = new IrisQueryParamsBuilder()
      .filter('channel', ['POPUP'])
      .filter('userId', [userId])
      .orderBy('eventDate', 'desc');

    if (limit != undefined) {
      queryParamsBuilder.limit(limit);
    }

    if (offset != undefined) {
      queryParamsBuilder.offset(offset);
    }

    if (unread) {
      queryParamsBuilder.filter('read', [!unread]);
    }

    const path = `${ this.notificationsServiceBaseUrl }/messages`;
    return this._httpClient.get<IrisNotificationI[]>(path, {
      params: queryParamsBuilder.toObject(),
    });
  }

  setMessageAsRead(hash: string): Observable<IrisNotificationI> {
    const path = `${ this.notificationsServiceBaseUrl }/messages/${ hash }/read`;
    return this.authFacade.actualToken().pipe(
      switchMap((token) => {
        return this._httpClient.get<IrisNotificationI>(path, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
      }),
    );
  }

  setAllAsRead(): Observable<void> {
    const path = `${ this.notificationsServiceBaseUrl }/messages/popup/read/${ this.userService.me.id }`;
    return this.authFacade.actualToken().pipe(
      switchMap((token) => {
        return this._httpClient.post<void>(path, null, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
      }),
    );
  }
}
