import { IrisUserWSComponent } from '@iris/common/modules/user-common/modules/user-ws/components/user-ws.component';
import { IrisFilterChipRenderingOptions } from './chip-rendering-options';

export function userChipRenderingOptions<TEntity>(userIdGetter?: (entity: TEntity) => number): IrisFilterChipRenderingOptions<IrisUserWSComponent, TEntity> {
  return {
    component: IrisUserWSComponent,
    initComponentFn: (component, entity) => {
      component.userId = userIdGetter ? userIdGetter(entity) : (entity as unknown as number);
      component.avatarSize = 'sm';
      component.showOnlyAvatar = true;
    },
  } as IrisFilterChipRenderingOptions<IrisUserWSComponent, TEntity>;
}
