import { IrisMFFilterBase } from './IrisMFFilterBase';
import { IrisContainsPropertyOptions } from './IrisModuleFilterPropertyOptionsParams';
import { Observable, of } from 'rxjs';

export class IrisMFContains extends IrisMFFilterBase {

  private readonly useRawString: boolean;

  constructor(fieldName, options: IrisContainsPropertyOptions) {
    super(fieldName, options);

    this.useRawString = options.useRawString;
  }

  getFilterValue(val): string[] {

    if (Array.isArray(val)) {
      const filtered = val.filter((item) => item);
      return this.useRawString ? filtered : filtered.map((item) => `%${item}%`);
    }

    return this.useRawString ? [`${val}`] : [`%${val}%`];
  }

  getFilterParameters() {
    return { s: false, m: 'fts' };
  }

  getFilterDescription(filter): Observable<string> {
    return of(`${this.label}: '${filter[this.formFieldName]}'`);
  }

}
