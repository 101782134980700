<iris-modal-content
  [title]="'label.Translations' | translate"
  [parent]="this"
  [cancelBtnText]="'label.Close'">

  <ng-container id="iris-modal-body">
    <div *ngFor="let language of languages">
      <iris-field-text
        [label]="'label.' + language.name | translate"
        [(ngModel)]="translations[language.name]"></iris-field-text>
    </div>
  </ng-container>

  <ng-container id="iris-modal-footer-buttons">
    <button
      type="button"
      class="btn btn-success"
      data-test="modal-footer-confirm-button"
      (click)="accept()">
      <i class="fas fa-check"></i>
      <span>{{'label.Save' | translate}}</span>
    </button>
  </ng-container>

</iris-modal-content>
