import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { LoadUnits, UnitsActionsTypes, UnitsActions, LoadUnitsComplete } from '../actions/units-actions';
import { map, switchMap } from 'rxjs/operators';
import { IrisUtilsService } from '@iris/common/services/utils/iris-utils.service';

@Injectable()
export class UnitsEffects {
  
  loadUnits$: Observable<UnitsActions> = createEffect(() => this.actions$.pipe(
    ofType<LoadUnits>(UnitsActionsTypes.LoadUnits),
    switchMap(() => this.irisUtilsService.getUnitsList().pipe(map(units => new LoadUnitsComplete(units)))),
  ));

  constructor(private readonly actions$: Actions,
              private readonly irisUtilsService: IrisUtilsService) {
  }
}
