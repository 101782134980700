import { ActionReducerMap, createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import * as emailsGlobalReducer from './global/emails-global.reducer';
import * as emailsNavigationReducer from './navigation/emails-navigation.reducer';
import * as emailsMessagesReducer from './messages/emails-messages.reducer';
import { IrisEmailMessageI } from '../models/IrisEmail';
import { EmailWellKnownFolderName, IrisEmailNavigationI } from '../models/IrisEmailNavigation';

export interface EmailsCommonState {
  emailsGlobal: emailsGlobalReducer.EmailsGlobalState;
  emailsNavigation: emailsNavigationReducer.EmailsNavigationState;
  emailsMessages: emailsMessagesReducer.EmailsMessagestState;
}

export const emailsCommonReducers: ActionReducerMap<EmailsCommonState> = {
  emailsGlobal: emailsGlobalReducer.reducer,
  emailsNavigation: emailsNavigationReducer.reducer,
  emailsMessages: emailsMessagesReducer.reducer,
};

export const getDynamicState = createFeatureSelector<EmailsCommonState>('emailsCommonModule');

// global
export const getGlobalState = createSelector(
  getDynamicState,
  state => state.emailsGlobal,
);

export const getSelectedMenuItemId = createSelector(
  getGlobalState,
  emailsGlobalReducer.selectedMenuItemId,
);

export const getSelectedMessageId = createSelector(
  getGlobalState,
  emailsGlobalReducer.selectedMessageId,
);

export const getSelectedMessagesIds = createSelector(
  getGlobalState,
  emailsGlobalReducer.selectedMessagesIds,
);

export const getDeselectedMessagesIds = createSelector(
  getGlobalState,
  emailsGlobalReducer.deselectedMessagesIds,
);

export const getMessagesPagination = createSelector(
  getGlobalState,
  emailsGlobalReducer.messagesPagination,
);

export const getMessageTotalItemsCount = createSelector(
  getGlobalState,
  emailsGlobalReducer.messageTotalItemsCount,
);

export const getCheckAll = createSelector(
  getGlobalState,
  emailsGlobalReducer.checkAll,
);

export const getSelectedMessagesCount = createSelector(
  getCheckAll,
  getMessageTotalItemsCount,
  getSelectedMessagesIds,
  getDeselectedMessagesIds,
  (checkAll, messagesCount, selectedMessagesIds, deselectedMessagesIds) => {
    return checkAll ? messagesCount - deselectedMessagesIds.length : selectedMessagesIds.length;
  },
);

export const getOpenedDraftMessage = createSelector(
  getGlobalState,
  emailsGlobalReducer.openedDraftMessage,
);

export const hasMessagesNextPage = createSelector(
  getGlobalState,
  emailsGlobalReducer.hasMessagesNextPage,
);

export const getUserSettings = createSelector(
  getGlobalState,
  emailsGlobalReducer.getUserSettings,
);

export const unreadCount = createSelector(
  getGlobalState,
  emailsGlobalReducer.unreadCount,
);

// navigation
export const getNavigationState = createSelector(
  getDynamicState,
  state => state.emailsNavigation,
);

export const getSelectedMenuItem = createSelector(
  getNavigationState,
  getSelectedMenuItemId,
  (state, folderId) => emailsNavigationReducer.getNavigationFolderById(state, folderId),
);

export const getNavigationFolders = createSelector(
  getNavigationState,
  emailsNavigationReducer.getNavigationFolders,
);

export const buildSortedVisibleFlatNavigationTree = createSelector(
  getNavigationState,
  emailsNavigationReducer.buildSortedVisibleFlatNavigationTree,
);

export const getNavigationChildrenFolders = (parentFolderId: string): MemoizedSelector<EmailsCommonState, IrisEmailNavigationI[]> => createSelector(
  getNavigationState,
  state => emailsNavigationReducer.getNavigationChildrenFolders(state, parentFolderId),
);

export const getNavigationFolder = (folderId: string): MemoizedSelector<EmailsCommonState, IrisEmailNavigationI> => createSelector(
  getNavigationState,
  state => emailsNavigationReducer.getNavigationFolderById(state, folderId),
);

export const getNavigationFolderByTitle = (title: string): MemoizedSelector<EmailsCommonState, IrisEmailNavigationI> => createSelector(
  getNavigationState,
  state => emailsNavigationReducer.getNavigationFolderByTitle(state, title),
);

export const isNavigationFolderSelected = (title: string): MemoizedSelector<EmailsCommonState, boolean> => createSelector(
  getNavigationState,
  getSelectedMenuItem,
  (state, selectedMenuItem) => emailsNavigationReducer.getNavigationFolderByTitle(state, title)?.id === selectedMenuItem.id,
);

export const getFolderByShortcut = (folderShortcut: EmailWellKnownFolderName): MemoizedSelector<EmailsCommonState, string> => createSelector(
  getNavigationState,
  state => emailsNavigationReducer.getFolderByShortcut(state, folderShortcut),
);

// messages
export const getMessagesState = createSelector(
  getDynamicState,
  state => state.emailsMessages,
);

export const getMessages = createSelector(
  getMessagesState,
  emailsMessagesReducer.getMessages,
);

export const getMessagesFlatList = createSelector(
  getMessagesState,
  emailsMessagesReducer.getMessagesFlatList,
);

export const getMessageById = (messageId: string): MemoizedSelector<EmailsCommonState, IrisEmailMessageI> => createSelector(
  getMessagesState,
  state => emailsMessagesReducer.getMessageById(state, messageId),
);

export const getMessagesByIds = (messagesIds: string[]): MemoizedSelector<EmailsCommonState, IrisEmailMessageI[]> => createSelector(
  getMessagesState,
  state => emailsMessagesReducer.getMessagesByIds(state, messagesIds),
);

export const getSelectedMessage = createSelector(
  getSelectedMessageId,
  getMessagesState,
  (messageId, state) => emailsMessagesReducer.getMessageById(state, messageId),
);

