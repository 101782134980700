import { DateTime, Duration } from 'luxon';
import {
  AbstractDatetime,
  AbstractDuration,
  AbstractDurationObject, DatetimeDurationUnit,
  DatetimeUnitOptional,
} from '@iris/common/modules/date';

export function parseLuxon(rawDate: string | number | Date | AbstractDatetime| DatetimeUnitOptional): DateTime {
  if (DateTime.isDateTime(rawDate)) {
    return rawDate;
  }
  if (AbstractDatetime.isAbstractDate(rawDate)) {
    return (rawDate as AbstractDatetime).nativeDateTime;
  }
  if (typeof rawDate === 'string') {
    let date = DateTime.fromISO(rawDate, { setZone: true });
    if (!date.isValid) {
      date = DateTime.fromSQL(rawDate);
    }
    if (!date.isValid) {
      date = DateTime.fromFormat(rawDate, 'yyyy-MM-dd HH:mm:ss');
    }
    return date;
  }

  if (rawDate instanceof Date) {
    return DateTime.fromJSDate(rawDate);
  }

  if (typeof rawDate === 'object') {
    return DateTime.fromObject(rawDate);
  }

  if (typeof rawDate === 'number') {
    return DateTime.fromMillis(rawDate);
  }

  throw new Error(`[Luxon] Not supported type: ${rawDate}`);
}

export function parseLuxonDuration(rawData: number | AbstractDurationObject | Duration ): Duration {
  if (AbstractDuration.isAbstractDuration(rawData)) {
    return (rawData as AbstractDuration).nativeDuration;
  }

  return Duration.fromDurationLike(rawData);
}

export function normalizeUnit(unit?: DatetimeDurationUnit | DatetimeDurationUnit[]): undefined | DatetimeDurationUnit | DatetimeDurationUnit[]  {
  if (unit == undefined) {
    return undefined;
  }
  if (Array.isArray(unit)) {
    return unit.map( normalizeUnit ) as DatetimeDurationUnit[];
  }

  const normalized = {
    year: 'years',
    years: 'years',
    quarter: 'quarters',
    quarters: 'quarters',
    month: 'months',
    months: 'months',
    week: 'weeks',
    weeks: 'weeks',
    day: 'days',
    days: 'days',
    hour: 'hours',
    hours: 'hours',
    minute: 'minutes',
    minutes: 'minutes',
    second: 'seconds',
    seconds: 'seconds',
    millisecond: 'milliseconds',
    milliseconds: 'milliseconds',
  }[unit ? unit.toLowerCase() : unit];
  if (!normalized) throw new Error(`InvalidUnitError(${unit})`);
  return normalized as DatetimeDurationUnit;
}
