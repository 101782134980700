import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IrisDateAgoPipe } from './date-ago.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [IrisDateAgoPipe],
  exports: [IrisDateAgoPipe],
})
export class IrisDateAgoPipeModule {
}
