import { Injectable, Type } from '@angular/core';
import { Observable, EMPTY, of, ReplaySubject } from 'rxjs';
import { IrisAlert, IrisAlertSettings, IrisAlertType } from '../model/AlertModel';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { v4 as uuid } from 'uuid';
import { catchError, switchMap, tap } from 'rxjs/operators';

export type ConfirmAlertOptions = {
  message?: string;
  confirmBtnText?: string;
  confirmBtnIconClass?: string;
  cancelBtnText?: string;
  isHideCloseBtn?: boolean;
  doNotEmitOnDismiss?: boolean;
  isDangerButton?: boolean;
  disableConfirmBtn?: boolean;
};

@Injectable({ providedIn: 'root' })
export class IrisAlertService {

  private readonly DEFAULT_ALERT_SETTINGS: IrisAlertSettings = {
    dismissible: true,
    dismissTimeout: 10000,
  };
  private static readonly subject = new ReplaySubject<IrisAlert>(1);

  constructor(
    private readonly dialog: MatDialog,
  ) { }

  dialogRef: MatDialogRef<ConfirmModalComponent, unknown>;

  public getAlert(): Observable<IrisAlert> {
    return IrisAlertService.subject.asObservable();
  }

  public success(message: string, rendererFramework?: Type<unknown>): void {
    this.alert(IrisAlertType.Success, message, null, rendererFramework);
  }

  public error(message: string, params?: IrisAlertSettings): void {
    this.alert(IrisAlertType.Error, message);
  }

  public info(message: string, params?: IrisAlertSettings): void {
    this.alert(IrisAlertType.Info, message);
  }

  public warning(message: string, params?: IrisAlertSettings): void {
    this.alert(IrisAlertType.Warning, message);
  }

  public log(message: string, params?: IrisAlertSettings): void {
    if (params) {
      this.alert(IrisAlertType.Log, message, params);
    } else {
      this.alert(IrisAlertType.Log, message);
    }
  }

  public openConfirm(
    data: {
      cancelBtnText?: string;
      confirmBtnText?: string;
      message?: string;
      isHideCloseBtn?: boolean;
      confirmBtnIconClass?: string;
      isDangerButton?: boolean;
      disableConfirmBtn?: boolean;
    },
    options = {},
  ): MatDialogRef<ConfirmModalComponent> {
    const defaultConfirmConfig: MatDialogConfig = Object.assign( { data }, {
      disableClose: true,
      role: 'alertdialog',
      panelClass: 'confirm-dialog',
    }, options) as MatDialogConfig;

    return this.dialog.open(ConfirmModalComponent, defaultConfirmConfig);
  }

  public confirm(message?: string, confirmBtnText?: string, cancelBtnText?: string, isHideCloseBtn?: boolean, confirmBtnIconClass?: string, isDangerButton?: boolean, disableConfirmBtn?: boolean): Promise<boolean> {

    return new Promise((resolve, reject) => {
      this.openConfirm({
        message,
        confirmBtnText,
        cancelBtnText,
        isHideCloseBtn,
        confirmBtnIconClass,
        isDangerButton,
        disableConfirmBtn,
      }).afterClosed().pipe(
        tap(e => e ? resolve(null) : reject()),
        catchError((err) => {
          reject(err);
          return err;
        }),
      ).subscribe();
    });
  }

  confirm$(options: ConfirmAlertOptions): Observable<boolean | never> {
    return this.openConfirm( {
      message: options.message,
      confirmBtnText: options.confirmBtnText,
      cancelBtnText: options.cancelBtnText,
      isHideCloseBtn: options.isHideCloseBtn,
      confirmBtnIconClass: options.confirmBtnIconClass,
      isDangerButton: options.isDangerButton,
      disableConfirmBtn: options.disableConfirmBtn,
    }).afterClosed().pipe(
      switchMap(result => {
        return options.doNotEmitOnDismiss && !result
          ? EMPTY
          : of(!!result);
      }),
    );
  }

  alert(type: IrisAlertType, message: string, params?: IrisAlertSettings, rendererFramework?: Type<unknown>): void {
    const settings = Object.assign({ }, this.DEFAULT_ALERT_SETTINGS, params);
    IrisAlertService.subject.next({ id: uuid(), type, message, settings, rendererFramework });
  }
}
