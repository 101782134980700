<ng-template #printLayout>
  <div>
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>
<div
  class="app-container"
  [ngClass]="{
    'fullscreen': fullscreenMode,
    'iris-main-menu-opened': sidenav.openedChange | async
  }"
  *ngIf="!printMode; else printLayout"
>
  <ng-container *ngIf="(notification$ | async) as notification">
    <global-announcement-banner
      *ngIf="notification.active"
      [message]="notification.message"
    ></global-announcement-banner>
  </ng-container>

  <iris-header></iris-header>


  <mat-sidenav-container class="app-wrap"
                         [autosize]="true"
                         [hasBackdrop]="navMenu.hasBackdrop"
                         (backdropClick)="navMenu.close()">
    <mat-sidenav class="app-navigation-menu-container narrow d-block" #sidenav
                 [mode]="sidenavMode"
                 [opened]="isPinned"
                 [autoFocus]="false"
                 (keydown.escape)="navMenu.close()"
                 [ngClass]="{
                   'pinned': sidenavMode === 'side',
                   'over': sidenavMode === 'over'
                 }" disableClose>
      <global-navigation-menu
        #navMenu
        [(sidenavMode)]="sidenavMode"
        (sidenavModeChange)="onSidenavModeChange($event)"
      ></global-navigation-menu>
    </mat-sidenav>

    <mat-sidenav-content class="app-navigation-menu-sidenav-content">
      <div class="app-body-container">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <iris-alert></iris-alert>
</div>
