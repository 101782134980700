import { DfProp, DfPropForm } from '@iris/common/modules/dynamic-forms/df-prop/shared/models';
import { words } from 'lodash';

export const DF_PATH_DELIMITER = '.';
export const DATA_PATH_PREFIX = 'data.';

export function appendDataPrefix(path: string): string {
  return `${DATA_PATH_PREFIX}${path}`;
}

export function removeDataPrefix(path: string): string {
  return path?.replace(/^data\./, '');
}

export function splitPropertyPath(path: string): string[] {
  return words(path, /([a-zA-Z0-9_-]+)/g);
}

export function joinPropertyPath(...paths: string[]): string {
  return paths.reduce((res, path) => `${res}${DF_PATH_DELIMITER}${path}`, '')
    .replace(/^\.+/, '')
    .replace(/\.+$/, '')
    .replace(/\.+/, '.');
}

export function trimPropertyPathLeft(path: string, left: string): string {
  if (path === left) { return ''; }
  const leftPart = `${left}${DF_PATH_DELIMITER}`;
  if (!path.startsWith(leftPart)) { return path; }
  return path.substring(leftPart.length);
}

export function findPropertyByPath<T extends (DfProp | DfPropForm) = (DfProp | DfPropForm)>(properties: T[], path: string): T {
  let result: T = null;

  properties.every(property => {
    if (property.path === path) {
      result = property;
    }
    else if (property.type === 'FORM') {
      result = findPropertyByPath((property as DfPropForm).properties, path) as T;
    }

    return result == null;
  });

  return result;
}

export function lookupPropertyByPath(properties: (DfProp | DfPropForm)[], path: string): DfProp {
  let res: DfProp = null;
  let pathParts = path?.split('.') ?? [];
  let pathProperties = properties;
  while (pathParts.length && (!res || res.type === 'FORM')) {
    res = pathProperties?.find(t => t.alias === pathParts[0]);
    pathProperties = res?.type === 'FORM' ? (res as DfPropForm).properties : [];
    pathParts = pathParts.slice(1);
  }
  return res?.type !== 'FORM' ? res : null;
}

export function isMultipleContextByPath(properties: (DfProp | DfPropForm)[], path: string): boolean {
  let cursor: DfProp = null;
  let pathProperties = properties;
  let pathParts = path?.split('.') ?? [];
  let res = false;
  while (!res && pathParts.length && (!cursor || cursor.type === 'FORM')) {
    cursor = pathProperties?.find(t => t.alias === pathParts[0]);
    pathProperties = cursor?.type === 'FORM' ? (cursor as DfPropForm).properties : [];
    pathParts = pathParts.slice(1);
    res ||= cursor?.type === 'FORM' && (cursor as DfPropForm).settings.isMultiple;
  }
  return res;
}

export function resolveJsonPathArray(obj: unknown, path: string): unknown[] {
  return resolveJsonPathArrayCore(obj, path.split('.'));
}

export function resolveJsonPathArrayCore(obj: unknown, paths: string[]): unknown[] {
  if (paths.length === 1) {
    return Array.isArray(obj)
      ? obj.reduce((res, item) => [...res, item?.[paths[0]]], [] as unknown[])
      : [obj?.[paths[0]]];
  }
  return Array.isArray(obj)
    ? obj.reduce((res, item) => [...res, ...resolveJsonPathArrayCore(item?.[paths[0]], paths.slice(1))], [] as unknown[])
    : resolveJsonPathArrayCore(obj?.[paths[0]], paths.slice(1));
}

export function propertyParentPath(path: string): string {
  const lastDelimiterIndex = path.lastIndexOf('.');
  if (lastDelimiterIndex < 0) { return ''; }
  return path.substring(0, lastDelimiterIndex);
}
