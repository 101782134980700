import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IrisMainMenuService } from '@iris/common/services/main-menu.service';
import { IrisLogoService } from '@iris/common/services/logo.service';

@Component({
  selector: 'iris-header-dropdown-menu',
  templateUrl: './header-dropdown-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisHeaderDropdownMenuComponent {
  
  imageUrl$ = this.logoService.imageUrl$;

  constructor(
    private readonly menuService: IrisMainMenuService,
    private readonly logoService: IrisLogoService,
  ) {}

  public toggleSidenav(): void {
    this.menuService.sidenav.toggle();
  }
}
