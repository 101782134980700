import { Injectable } from '@angular/core';
import { IrisPage } from '@iris/common/models/page';
import { IrisNgSelectFieldSearchEngine } from '@iris/common/modules/fields/ng-select-field';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { handleInfiniteScroll$, handleSearch$, handleTypeahead$, SearchContext, searchProcessor } from '@iris/common/modules/fields/ng-select-field/search-processing';
import { BindNgSelectEvent } from '@iris/common/modules/fields/ng-select-field/decorators';
import { finalize } from 'rxjs/operators';

@Injectable()
export abstract class IrisSelectEngineInfinityScroll<TItem, TValue, TSearchContext extends SearchContext<TItem> = SearchContext<TItem>> implements IrisNgSelectFieldSearchEngine<TItem, TValue> {
  abstract getMissingItemLabelFn(val: TValue): string | Observable<string>;
  protected abstract searchItems$(term: string, offset: number, ctx?: TSearchContext): Observable<IrisPage<TItem>>;
  protected handleSearchOptions$(ctx: TSearchContext): Observable<TSearchContext> { return of(ctx); }

  readonly typeahead = new BehaviorSubject('');

  protected readonly loadingSubject = new Subject<boolean>();
  readonly loading$ = this.loadingSubject.asObservable();

  @BindNgSelectEvent('scrollToEnd')
  protected readonly scrollToEndSubject = new Subject<void>();

  readonly result$ = searchProcessor<TItem, TSearchContext>(
    null,
    ctx => handleTypeahead$(ctx, this.typeahead),
    ctx => this.handleSearchOptions$(ctx),
    ctx => handleSearch$(ctx, term => {
      this.loadingSubject.next(true);
      return this.searchItems$(term, 0, ctx).pipe(
        finalize(() => this.loadingSubject.next(false)),
      );
    }),
    ctx => handleInfiniteScroll$(ctx, this.scrollToEndSubject, (term, offset) => {
      this.loadingSubject.next(true);
      return this.searchItems$(term, offset, ctx).pipe(
        finalize(() => this.loadingSubject.next(false)),
      );
    }),
  );
}
