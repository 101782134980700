import 'reflect-metadata';
import { API_URL_TEMPLATE_PROPERTY, API_URL_RELATIVE_SYMBOL, API_URL_TEMPLATE_METADATA_KEY } from './api-url.constant';
import { ApiQueryHelper } from '../../api-query.helper';

function ApiUrlClass(urlTemplate: string): ClassDecorator {
  return (target: any) => {
    Reflect.defineMetadata(API_URL_TEMPLATE_METADATA_KEY, urlTemplate, target.prototype);
    return target;
  };
}

function resolveClassUrlTemplate(context: any): string {
  let classUrlTemplate = null;
  while (!classUrlTemplate && context) {
    classUrlTemplate = Reflect.getMetadata(API_URL_TEMPLATE_METADATA_KEY, context);
    context = context.__proto__;
  }
  return classUrlTemplate || '';
}

function ApiUrlMethod(methodUrlTemplate: string): MethodDecorator {
  methodUrlTemplate = methodUrlTemplate || '';
  return (target: any, propertyKey: string, descriptor: any) => {
    const originalMethod = descriptor.value;
    descriptor.value = function(...args: any[]) {
      const classUrlTemplate = resolveClassUrlTemplate(this);
      const localUrlTemplate = methodUrlTemplate.startsWith(API_URL_RELATIVE_SYMBOL)
        ? ApiQueryHelper.concatUrl(classUrlTemplate, methodUrlTemplate.substr(API_URL_RELATIVE_SYMBOL.length))
        : (methodUrlTemplate || classUrlTemplate);
      Object.defineProperty(this, API_URL_TEMPLATE_PROPERTY, {
        value: localUrlTemplate,
        writable: false,
        configurable: true
      });
      const res = originalMethod.apply(this, args);
      Reflect.deleteProperty(this, API_URL_TEMPLATE_PROPERTY);
      return res;
    };
    return descriptor;
  };
}

export function ApiUrl(urlTemplate: string) {
  return function(...args: any[]) {
    switch (args.length) {
      case 1:
        return ApiUrlClass(urlTemplate).apply(this, args);
      case 3:
        if (typeof args[2] !== 'number') {
          return ApiUrlMethod(urlTemplate).apply(this, args);
        }
        break;
    }
    throw Error('Unsupported target for ApiUrl decorator');
  };
}
