import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { isNull, isNil, isUndefined } from 'lodash';
import { IrisQueryParams, IrisQueryParamsBuilder, ApiUrl, IrisApiClient } from '@iris/api-query';
import { IrisUserInfoI } from '@iris/common/modules/user-common/models/IrisUserInfo';
import { IrisUserGroupI } from '@iris/common/modules/user-common/models/IrisUserGroup';
import { IrisPage } from '@iris/common/models/page';
import { UserPermissions } from '@iris/common/models/IrisPermission';
import { Page } from '@iris/modules/marketplace/common/models/page.model';
import { AbstractDatetime } from '../modules/date';
import { filter, map } from 'rxjs/operators';
import { IrisUserSettingsI } from '@iris/common/modules/grid-configuration/models/grid-configuration.settings';

export interface UserFilterParams {
  [key: string]: any[];
}

export interface MeSettings {
  recentProjects: number[];
  recentProjectsIds: number[];
}

@Injectable({
  providedIn: 'root',
})
@ApiUrl('/security/users')
export class IrisUserService extends IrisApiClient<IdentUserI> {
  private _meSettings: MeSettings;

  private readonly meSubject = new BehaviorSubject<IdentUserI>(null);
  readonly me$ = this.meSubject.asObservable().pipe(filter(Boolean));

  constructor(
    protected readonly httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  get me(): IdentUserI {
    return this.meSubject.value;
  }

  get meSettings(): MeSettings {
    return this._meSettings;
  }

  setMe(user: IdentUserI): void {
    this.meSubject.next(user);
  }

  setMeSettings(userSettings: IrisUserSettingsI<MeSettings>): void {
    this._meSettings = userSettings.settings;
  }

  public getUsers(): Observable<IdentUserI[]> {
    return this.httpClient.get<IdentUserI[]>(this.url());
  }

  generateUser(): IdentUserI {
    return {
      id: undefined,
      createdBy: undefined,
      updatedBy: undefined,
      createdOn: undefined,
      updatedOn: undefined,
      firstname: undefined,
      lastname: undefined,
      email: undefined,
      username: undefined,
      companyId: undefined,
      isInternal: undefined,
      enabled: undefined,
      isAdmin: undefined,
      accountType: undefined,
      ssoActive: undefined,
      phone: undefined,
      mobileNumber: undefined,
      city: undefined,
      address: undefined,
      zip: undefined,
      departmentId: undefined,
      startPageId: undefined,
      language: this.me.language,
      favProjectId: undefined,
      timeZone: AbstractDatetime.guessTimeZone(),
      dateTimeFormatCode: this.me.dateTimeFormatCode,
      hasNoPassword: undefined,
      hasPincode: undefined,
      roles: undefined,
      systemInvitationSent: undefined,
      systemInvitationSentOn: undefined,
      verifiedOn: undefined,
      avatarFileId: undefined,
      supervisorId: undefined,
      company: undefined,
      fullName: undefined,
      personnelNumber: undefined,
      traceId: undefined,
      settings: undefined,
      syncObjId: undefined,
    };
  }

  createUser(user: IdentUserI): Observable<IdentUserI> {
    const params = new IrisQueryParamsBuilder();
    params.urlParam('companyId', user.companyId);
    return this.httpClient.post<IdentUserI>(this.url(), user, { params: params.toObject() });
  }

  query(queryParams?: IrisQueryParams): Observable<IdentUserI[]> {
    return super.queryCore({}, queryParams);
  }

  @ApiUrl('~/me')
  getCurrentUser(): Observable<IdentUserI> {
    return this.httpClient.get<IdentUserI>(this.url());
  }

  @ApiUrl('/security/menu/v2/me/start-page')
  getMeStartPageUrl(): Observable<string> {
    return this.httpClient.get<{ url: string }>(this.url()).pipe(
      map(pageUrl => pageUrl.url),
    );
  }

  @ApiUrl('~/{userId}')
  saveUser(user: IdentUserI): Observable<IdentUserI> {
    const params = new IrisQueryParamsBuilder();
    params.urlParam('companyId', user.companyId);
    return this.httpClient.post<IdentUserI>(this.url({ userId: user.id }), user, { params: params.toObject() });
  }

  @ApiUrl('~/{userId}')
  deleteUser(userId: number): Observable<IdentUserI> {
    return this.httpClient.delete<IdentUserI>(this.url({ userId }));
  }

  @ApiUrl('~/{userId}/send-system-invitation')
  sendSystemInvitation(userId: number): Observable<void> {
    return this.httpClient.post<void>(this.url({ userId }), userId);
  }

  @ApiUrl('~/{userId}/user-groups')
  getUserGroups(userId: number, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisUserGroupI[]> {
    return this.httpClient.get<IrisUserGroupI[]>(this.url({ userId }), { params: params.toObject() });
  }

  @ApiUrl('~/page')
  getUsersPage(params: IrisQueryParams = new IrisQueryParams()): Observable<Page<IdentUserI[]>> {
    return this.httpClient.get<Page<IdentUserI[]>>(this.url(), { params: params.toObject() });
  }

  @ApiUrl('~/{userId}/pwd-generate')
  generatePassword(userId): Observable<{ password: string }> {
    if (isNil(userId)) { userId = -1; }
    return this.httpClient.post<{ password: string }>(this.url({ userId }), userId);
  }

  @ApiUrl('/security/resources/{entityType}')
  getUserEntities(entityType: string, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisPage<UserPermissions>> {
    return this.httpClient.get<IrisPage<UserPermissions>>(this.url({ entityType }), { params: params.toObject() });
  }

  @ApiUrl('~/{id}')
  getById(id: number, params: IrisQueryParams = new IrisQueryParams()): Observable<IdentUserI> {
    return this.httpClient.get<IdentUserI>(this.url({ id }), { params: params.toObject() });
  }

  @ApiUrl('~/info')
  fetchUsersInfo(params?: IrisQueryParams): Observable<IrisUserInfoI[]> {
    if (isNull(params) || isUndefined(params)) {
      return this.httpClient.get<IrisUserInfoI[]>(this.url());
    } else {
      return this.httpClient.get<IrisUserInfoI[]>(this.url(), { params: params.toObject() });
    }
  }

  @ApiUrl('~/info')
  getUserInfoById(userId: number): Observable<IrisUserInfoI[]> {
    const params = new IrisQueryParamsBuilder().filter('id', [userId]).toStructure().toObject();
    return this.httpClient.get<IrisUserInfoI[]>(this.url(), { params });
  }

  @ApiUrl('~/{userId}')
  saveCurrentUser(user): Observable<IdentUserI> {
    return this.httpClient.post<IdentUserI>(this.url({ userId: this.meSubject.value.id }), user);
  }

  @ApiUrl('~/{userId}/pwd')
  changeUserPassword(userId: number, passwordModel: any): Observable<any> {
    return this.httpClient.post(this.url({ userId }), passwordModel);
  }

  @ApiUrl('~/me/set-pin-code')
  setPinForCurrentUser(pinCode: string, password: string): Observable<any> {
    return this.httpClient.post(this.url(), { pinCode, password });
  }

  @ApiUrl('/dms2/{iris@instanceId}/{fileId}/content')
  generateAvatarLinkByFileId(fileId: string): string {
    return this.url({ fileId });
  }

  @ApiUrl('~/{dashboardId}/set-dashboard')
  setDefaultDashboard(dashboardId: number): Observable<unknown> {
    return this.httpClient.post(this.url({ dashboardId }), dashboardId);
  }

  @ApiUrl('~/me/check-pin-code')
  checkPinCode(pinCode: string): Observable<{success: boolean}> {
    return this.httpClient.post<{success: boolean}>(this.url(), { pinCode });
  }

  @ApiUrl('~/me/check-password')
  checkPassword(password: string): Observable<{success: boolean}> {
    return this.httpClient.post<{success: boolean}>(this.url(), { password });
  }

  @ApiUrl('/users/elastic')
  elasticQuery(queryParams: IrisQueryParams = new IrisQueryParams()): Observable<IdentUserI[]> {
    return this.httpClient.get<IdentUserI[]>(this.url(), { params: queryParams.toObject() });
  }
}
