/* eslint-disable @typescript-eslint/no-explicit-any */
import { ServerBaseEntity } from './ServerBaseEntity';
import { copyValues } from '../utils/object.utils';

export class IrisIdentUser extends ServerBaseEntity<IdentUserI> implements IdentUserI {
  email: string = undefined;
  username: string = undefined;
  firstname: string = undefined;
  lastname: string = undefined;
  enabled: boolean = undefined;
  hasNoPassword: boolean = undefined;
  hasPincode: boolean = undefined;
  isInternal?: boolean = undefined;
  internal?: boolean = undefined;
  isAdmin: boolean = undefined;
  roles: any[] = undefined;
  systemInvitationSent: boolean = undefined;
  systemInvitationSentOn: string = undefined;
  verifiedOn: string = undefined;
  departmentId: number = undefined;
  avatarFileId: string = undefined;
  dateTimeFormatCode: string = undefined;
  supervisorId: number = undefined;
  favProjectId: number = undefined;
  companyId: number = undefined;
  company: any = undefined;
  fullName: string = undefined;
  language: string = undefined;
  timeZone: string = undefined;
  personnelNumber: string = undefined;
  phone: string = undefined;
  mobileNumber: string = undefined;
  address: string = undefined;
  zip: string = undefined;
  city: any = undefined;
  startPageId: number;
  traceId: string = undefined;
  settings: any = undefined;
  ssoActive: boolean = undefined;
  accountType?: string = undefined;
  syncObjId?: string = undefined;
  org?: Record<string, string> = {
    be: undefined,
    di: undefined,
    fco: undefined,
    gr: undefined,
    ld: undefined,
    ub: undefined,
    vss: undefined,
  };

  constructor(params?: IdentUserI) {
    super(params);
    if (params) {
      copyValues(this, params);
      if (!params.org) {
        this.org = {
          be: undefined,
          di: undefined,
          fco: undefined,
          gr: undefined,
          ld: undefined,
          ub: undefined,
          vss: undefined,
        };
      }
    }
  }
}
