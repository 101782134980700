import { IrisRange } from '@iris/modules/module-filters-v2/models';
import { defaultAppendQueryParamsFn, IrisFilterAppendQueryParamsFn } from './append-filter-query-params-fn';
import { adjustNumericValue } from '@iris/modules/module-filters-v2/utils';

export function appendNumericRangeQueryParamsFn(fieldNames: string | string[]): IrisFilterAppendQueryParamsFn {
  return defaultAppendQueryParamsFn<IrisRange<number>>(
    fieldNames,
    range => [adjustNumericValue(range.from), adjustNumericValue(range.to)],
    t => t.method('btw').strict(false),
  );
}
