import { IrisFilterPropertyMeta } from '@iris/modules/module-filters-v2/filter-meta';
import { IrisFieldTextComponent } from '@iris/common/modules/fields/field-text/field-text.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { of } from 'rxjs';
import { filterValueUniversalIsEmpty } from '@iris/modules/module-filters-v2/utils';

export function numberFilterMeta(propertyId: string, label: string, filterName: string): IrisFilterPropertyMeta<number> {
  return {
    propertyId,
    label,
    appendFilterQueryParamsFn: (builder, value) => {
      return builder.filter(filterName, [value]);
    },
    filterRenderingOptions: {
      component: IrisFieldTextComponent,
      getFilterValueFn: (componentInstance: IrisFieldTextComponent) =>
        componentInstance.valueControl.valueChanges.pipe(debounceTime(300), distinctUntilChanged()),
      initComponentFn: (componentInstance: IrisFieldTextComponent) => {
        componentInstance.inputType = 'number';
        componentInstance.emitEventOnSetValue = false;
        componentInstance.showClear = true;
        componentInstance.enhancedControl.stylingMode = 'unformed';

        return of(null);
      },
      setValueFn: (componentInstance: IrisFieldTextComponent, value): void => componentInstance.writeValue(value ?? null),
    },
    filterValueIsEmpty: filterValueUniversalIsEmpty,
  };
}
