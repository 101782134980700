import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IrisPermissionI, IrisPermissionSubjectTemplateI } from '../models/IrisPermission';
import { ApiUrl, IrisApiClient, IrisQueryParams, IrisQueryParamsBuilder } from '@iris/api-query';

@Injectable({ providedIn: 'root' })
@ApiUrl('/security/subjects')
export class IrisSubjectPermissions extends IrisApiClient<any> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  @ApiUrl('~/{scope}/{id}/permissions')
  public getPermissions(id: number, scope: string): Observable<IrisPermissionSubjectTemplateI[]> {
    return this.httpClient.get<IrisPermissionSubjectTemplateI[]>(this.url({ id, scope }));
  }

  @ApiUrl('~/{scope}/permissions')
  public getAllPermissions(scope: string): Observable<any[]> {
    return this.httpClient.get<any[]>(this.url({ scope }));
  }

  @ApiUrl('~/permissions/users/{userId}/permissions/{subjectType}')
  public getAllPermissionsByUserAndSubjectType(userId: number, subjectType: string): Observable<IrisPermissionI[]> {
    return this.httpClient.get<IrisPermissionI[]>(this.url({ userId, subjectType }));
  }

  @ApiUrl('/security/menu/v2/permissions')
  public getAccessPermissions(moduleName: string, subPath='ui2'): Observable<{ hasPermission: boolean }> {
    return this.httpClient.get<{ hasPermission: boolean }>(this.url(), { params: { moduleName: `/ui/${subPath}/${moduleName}` } });
  }

  @ApiUrl('~/permissions')
  public getPermissionsBySubjectTypes(subjectTypes: string[] = [], params: IrisQueryParams = new IrisQueryParams()): Observable<Record<string, unknown>>  {
    const paramBuilder = new IrisQueryParamsBuilder(params);
    if (subjectTypes.length) { paramBuilder.urlParam('subject-types', subjectTypes); }
    return this.httpClient.get<Record<string, unknown>>(this.url(), { params: paramBuilder.toObject() });
  }
}
