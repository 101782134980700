import { Observable, of } from 'rxjs';
import { emptyIrisPage, IrisPage } from '@iris/common/models/page';
import { catchError, debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

const INPUT_DEBOUNCE = 300;

export type SearchContext<TItem> = Partial<{ term: string; search: IrisPage<TItem> }>;

export function searchProcessor<TItem, TContext extends SearchContext<TItem> = SearchContext<TItem>>(context: TContext, ...steps: ((ctx: TContext) => Observable<TContext>)[]): Observable<IrisPage<TItem>> {
  return steps.reduce<Observable<TContext>>((res, step) => res.pipe(
    switchMap(ctx => step(ctx).pipe(
      catchError(() => of({ ...ctx, search: emptyIrisPage<TItem>() })),
    )),
  ), of(context)).pipe(
    map(step => step.search),
    shareReplay(({ bufferSize: 1, refCount: true })),
  );
}

export function handleTypeahead$<TItem, TContext extends SearchContext<TItem>>(
  ctx: TContext,
  typeahead$: Observable<string>,
): Observable<TContext> {
  return typeahead$.pipe(
    debounceTime(INPUT_DEBOUNCE),
    startWith(''),
    map(term => term ?? ''),
    distinctUntilChanged(),
    map(term => ({ ...ctx ?? {}, term } as TContext)),
  );
}

export function handleSearch$<TItem, TContext extends SearchContext<TItem>>(
  ctx: TContext,
  searchFn$: (term: string) => Observable<IrisPage<TItem>>,
): Observable<TContext> {
  return searchFn$(ctx?.term).pipe(
    map(search => ({ ...ctx ?? {}, search } as TContext)),
  );
}

export function handleInfiniteScroll$<TItem, TContext extends SearchContext<TItem>>(
  ctx: TContext,
  scrollToEnd$: Observable<unknown>,
  searchFn$: (term: string, offset?: number) => Observable<IrisPage<TItem>>,
): Observable<TContext> {
  return scrollToEnd$.pipe(
    switchMap(() => searchFn$(ctx.term, ctx.search?.elements?.length).pipe(
      filter(search => !!search.elements.length),
      map(search => {
        ctx.search = {
          count: search.count,
          elements: search.elements.length ? [...ctx.search.elements, ...search.elements] : ctx.search.elements,
        };
        return ctx;
      }),
    )),
    startWith(ctx),
    shareReplay(({ bufferSize: 1, refCount: true })),
  );
}
