import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isObject } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class IrisUserSettingsService {
  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  getUserSettingsById<T = any>(alias: string, userId: number | string = 'default'): Observable<T> {
    return this.httpClient.get<any>(`/user-settings/${alias}/${userId}`).pipe(
      map(result => {
        if (result.userId !== userId && userId !== 'default') {
          result.id = null;
        }
        return result;
      }));
  }

  saveUserSettings<T = any>(alias: string, userId: number, settings: any): Observable<T> {
    return this.httpClient.post<T>(`/user-settings/${alias}/${userId}`, settings);
  }

  clearUserSettings<T = unknown>(alias: string, userId: number): Observable<T> {
    return this.httpClient.delete<T>(`/user-settings/${alias}/${userId}`);
  }

  getEmailSignature(userId: number): Observable<string> {
    return this.getUserSettingsById('user-email-signature', userId).pipe(
      filter(userSettings => !!userSettings),
      map(({ settings }) => isObject(settings) ? '' : settings),
    );
  }
}
