import { BaseStructureI } from '../base-structure';

const FILTER_KEYWORDS = ['or', 'f', 'v', 's', 'm', 'n', 'st'];

export class IrisQueryParamsFilter implements BaseStructureI {
  public or?: Array<Array<IrisQueryParamsFilter>>;

  public field?: string;
  public value?: any[];
  public strict?: boolean;
  public method?: string;
  public not?: boolean;
  public searchTranslations?: boolean;
  public customParams?: {
    paramName: string;
    value: any;
  }[];

  constructor(initialFilter?: Record<string, any>) {
    if (!initialFilter) {
      return;
    }
    this.fromObject(initialFilter);
  }

  fromObject(params: Record<string, any>): IrisQueryParamsFilter {
    if (!params) {
      return this;
    }

    if (params['or'] && Array.isArray(params['or'])) {
      this.or = params['or'].map((orFilters: Record<string, any>[]) => orFilters.map(orFilter => new IrisQueryParamsFilter(orFilter)));
      return this;
    }

    if (!params['f']) {
      return this;
    } else {
      this.field = params['f'] as string;
    }
    if (!params['v'] || !Array.isArray(params['v']) || !params['v'].length) {
      return this;
    } else {
      this.value = params['v'] as any[];
    }
    if (typeof params['s'] === 'boolean') {
      this.strict = params['s'] as boolean;
    }
    if (params['m']) {
      this.method = params['m'] as string;
    }
    if (typeof params['n'] === 'boolean') {
      this.not = params['n'] as boolean;
    }
    if (typeof params['st'] === 'boolean') {
      this.searchTranslations = params['st'] as boolean;
    }

    Object.keys(params).filter(key => !FILTER_KEYWORDS.includes(key)).forEach(key => {
      this.customParams = [...(this.customParams || []), { paramName: key, value: params[key] }];
    });

    return this;
  }

  toObject() {
    const res = {};

    if (this.or && this.or.length) {
      res['or'] = this.or.map(orFilters => orFilters.map(orFilter => orFilter.toObject()));
      return res;
    }

    res['f'] = this.field;
    res['v'] = this.value ?? [];

    if (typeof this.strict === 'boolean') {
      res['s'] = this.strict;
    }
    if (this.method) {
      res['m'] = this.method;
    }
    if (typeof this.not === 'boolean') {
      res['n'] = this.not;
    }
    if (typeof this.searchTranslations === 'boolean') {
      res['st'] = this.searchTranslations;
    }
    if (this.customParams?.length) {
      this.customParams.forEach(p => {
        const paramName = p.paramName?.trim();
        if (!paramName || FILTER_KEYWORDS.includes(paramName)) { return; }
        res[paramName] = p.value;
      });
    }

    return res;
  }
}
