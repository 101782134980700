import { BaseStructureI } from '../base-structure';
import { IrisQueryParamsFilter } from './iris-query-params-filter';
import { IrisQueryParamsOrder } from './iris-query-params-order';

export class IrisQueryParams implements BaseStructureI {
  filter?: IrisQueryParamsFilter[];
  orderBy?: IrisQueryParamsOrder[];
  limit?: number;
  offset?: number;
  onlyFields?: string[];
  excludeFields?: string[];
  urlParams?: { name: string, value: any }[];

  constructor(initialParams?: Record<string, any>) {
    if (!initialParams) {
      return;
    }
    this.fromObject(initialParams);
  }

  clearFilter() {
    this.filter = null;
  }

  clearOrderBy() {
    this.orderBy = null;
  }

  clearLimit() {
    this.limit = null;
  }

  clearOffset() {
    this.offset = null;
  }

  clearOnlyFields() {
    this.onlyFields = null;
  }

  clearExcludeFields() {
    this.excludeFields = null;
  }

  clearUrlParams() {
    this.urlParams = null;
  }

  clear() {
    this.clearFilter();
    this.clearOrderBy();
    this.clearLimit();
    this.clearOffset();
    this.clearOnlyFields();
    this.clearExcludeFields();
    this.clearUrlParams();
  }

  findFilter(field: string): IrisQueryParamsFilter {
    if (!this.filter) {
      return null;
    }
    return this.filter.find(t => t.field === field);
  }

  findOrder(field: string): IrisQueryParamsOrder {
    if (!this.orderBy) {
      return null;
    }
    return this.orderBy.find(t => t.field === field);
  }

  fromObject(params: Record<string, any>): IrisQueryParams {
    if (!params) {
      return this;
    }

    if (params['filter']) {
      if (typeof params['filter'] === 'string') {
        params['filter'] = JSON.parse(params['filter']);
      }
      if (Array.isArray(params['filter'])) {
        this.filter = params['filter'].map(t => new IrisQueryParamsFilter(t)).filter(t => !!t);
      }
    }
    if (params['order-by']) {
      if (typeof params['order-by'] === 'string') {
        params['order-by'] = JSON.parse(params['order-by']);
      }
      if (Array.isArray(params['order-by'])) {
        this.orderBy = params['order-by'].map(t => new IrisQueryParamsOrder(t)).filter(t => !!t);
      }
    }
    if (params['limit']) {
      this.limit = params['limit'];
    }
    if (params['offset'] || params['offset'] === 0) {
      this.offset = params['offset'];
    }
    if (params['only-fields']) {
      if (typeof params['only-fields'] === 'string') {
        params['only-fields'] = JSON.parse(params['only-fields']);
      }
      if (Array.isArray(params['only-fields'])) {
        this.onlyFields = params['only-fields'];
      }
    }
    if (params['exclude-fields']) {
      if (typeof params['exclude-fields'] === 'string') {
        params['exclude-fields'] = JSON.parse(params['exclude-fields']);
      }
      if (Array.isArray(params['exclude-fields'])) {
        this.excludeFields = params['exclude-fields'];
      }
    }

    const keywords = ['filter', 'order-by', 'limit', 'offset', 'only-fields', 'exclude-fields'];
    for (const key in params) {
      if (keywords.indexOf(key) >= 0) {
        continue;
      }
      this.urlParams.push({name: key, value: JSON.parse(params[key])});
    }
    return this;
  }

  toObject() {
    const res = {};
    if (this.filter && this.filter.length) {
      res['filter'] = JSON.stringify(this.filter.map(t => t.toObject()));
    }
    if (this.orderBy && this.orderBy.length) {
      res['order-by'] = JSON.stringify(this.orderBy.map(t => t.toObject()));
    }
    if (this.limit) {
      res['limit'] = this.limit;
    }
    if (this.offset || this.offset === 0) {
      res['offset'] = this.offset;
    }
    if (this.onlyFields && this.onlyFields.length) {
      res['only-fields'] = JSON.stringify(this.onlyFields);
    }
    if (this.excludeFields && this.excludeFields.length) {
      res['exclude-fields'] = JSON.stringify(this.excludeFields);
    }
    if (this.urlParams && this.urlParams.length) {
      this.urlParams.forEach(p => {
        res[p.name] = typeof p.value === 'object' ? JSON.stringify(p.value) : p.value;
      });
    }
    return res;
  }
}
