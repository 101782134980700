import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Primitive } from '../../../models/Primitive';
import { IrisFieldBaseComponent } from '../field-base/field-base.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface ButtonGroupSelectorOptionI {
  value: Primitive;
  title: string;
  icon?: string;
  tooltip?: string;
}

export enum ButtonGroupView {
  Default = 'default',
  Tab = 'tab'
}

const itemActivityClassScheme = {
  [ButtonGroupView.Default]: {
    selected: 'btn-success',
    unselected: 'btn-default',
  },
  [ButtonGroupView.Tab]: {
    selected: 'selected',
    unselected: '',
  },
};

@Component({
  selector: 'iris-field-button-group-selector',
  templateUrl: './button-group-selector.component.html',
  styleUrls: ['./button-group-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IrisFieldButtonGroupSelectorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
})
export class IrisFieldButtonGroupSelectorComponent
  extends IrisFieldBaseComponent implements ControlValueAccessor {

  @Input() options: ButtonGroupSelectorOptionI[] = [];
  @Input() view: ButtonGroupView = ButtonGroupView.Default;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super([]);
  }

  private _isView(view: ButtonGroupView): boolean {
    return this.view === view;
  }

  get groupClass(): string {
    return this._isView(ButtonGroupView.Tab) ? 'view-tab' : '';
  }

  isSelected(item: ButtonGroupSelectorOptionI): boolean {
    return this.valueControl.value === item.value;
  }

  onSelect(value: Primitive): void {
    this.valueControl.setValue(value);
    this.changeDetectorRef.markForCheck();
  }

  getOptionClass(item: ButtonGroupSelectorOptionI): string {
    return itemActivityClassScheme[this.view][this.isSelected(item) ? 'selected' : 'unselected'];
  }
}
