import { Injectable } from '@angular/core';
import { IrisCountryI } from '@iris/common/models/IrisCountry';
import { IrisPage } from '@iris/common/models/page';
import { IrisNgSelectFieldSearchEngine } from '@iris/common/modules/fields/ng-select-field';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { Observable, ReplaySubject } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { IrisCountryGroup } from './models/country-group';
import { TranslateService } from '@ngx-translate/core';
import orderBy from 'lodash/orderBy';
import flatten from 'lodash/flatten';

const defaultCountries = ['Germany', 'Austria', 'Poland', 'Czech Republic', 'Hungary'];

@Injectable()
export class IrisCountrySelectEngine implements IrisNgSelectFieldSearchEngine<IrisCountryGroup, number | string> {
  readonly result$: Observable<IrisPage<IrisCountryGroup>>;
  readonly getMissingItemLabelFn: (countryId: number | string) => Observable<string>;

  constructor(
    globalSandbox: IrisGlobalSandbox,
    private readonly translate: TranslateService,
  ) {
    this.result$ = globalSandbox.countries$
      .pipe(
        map(elements =>  {
          const countries = JSON.parse(JSON.stringify(elements));
          return {
            count: countries?.length ?? 0,
            elements: [
              ...this.defaultCountriesGroup(countries),
              ...this.allCountriesGroup(countries),
            ],
          };
        }),
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false,
        }),
      );

    this.getMissingItemLabelFn = countryId => this.result$
      .pipe(
        map(page => {
          const countries = flatten(page.elements.map(group => group.countries));
          return countries.find(country => country.id == countryId)?.name ?? '';
        }),
      );
  }

  private defaultCountriesGroup(countries: IrisCountryI[]): IrisCountryGroup[] {
    if (!countries?.length) { return []; }
    const resultCountries = [];
    defaultCountries.forEach(dC => {
      const foundCountry = countries.find(country => dC === country.name);
      if (foundCountry) {
        resultCountries.push(foundCountry);
      }
    });
    return [{
      groupName: this.translate.instant('label.SuggestedCountries'),
      countries: resultCountries.map(this.extractTranslatedName),
    }];
  }

  private allCountriesGroup(countries: IrisCountryI[]): IrisCountryGroup[] {
    return countries?.length ? [{
      groupName: this.translate.instant('label.AllCountries'),
      countries: orderBy(countries.map(this.extractTranslatedName), 'name'),
    }] : [];
  }

  private extractTranslatedName(country: IrisCountryI): IrisCountryI {
    if (country.translated?.['name']) {
      country.name = country.translated['name'];
    }
    return country;
  }
}
