import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IrisFieldsModule } from '@iris/common/modules/fields/fields.module';
import { AuthRoutingModule } from './auth.routes';
import { LoginComponent } from './login/login.component';
import { IrisLoaderService } from '@iris/common/services/loader.service';
import { AuthComponent } from './auth.component';
import { RestorePasswordComponent } from './restore-password/restore-password.component';
import { LanguageSelectModule } from './language-select/language-select.module';
import { IrisCommonPipesModule } from '@iris/common/modules/pipes-common/common-pipes.module';
import { ToggleModule } from '@iris/common/modules/ui/controls/toggle/toggle.module';
import { SsoLoginComponent } from '@iris/modules/auth/sso/sso.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    IrisFieldsModule,
    AuthRoutingModule,
    IrisCommonPipesModule,
    ToggleModule,
    LanguageSelectModule,
  ],
  providers: [
    IrisLoaderService,
  ],
  declarations: [
    LoginComponent,
    AuthComponent,
    SsoLoginComponent,
    RestorePasswordComponent,
  ],
  exports: [TranslateModule],
})
export class AuthModule { }
