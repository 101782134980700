import { NgModule } from '@angular/core';
import { IrisFillLoaderComponentModule } from './fill-loader.component';
import { IrisIfLoadedDirective } from './if-loaded.directive';
import { IrisInlineLoaderComponentModule } from './inline-loader.component';
import { IrisSkeletonLoaderComponentModule } from './skeleton-loader.component';
import { IrisOverlayLoaderDirective } from './overlay-loader.directive';
import { IrisOverlayLoaderComponentModule } from './overlay-loader.component';

@NgModule({
  declarations: [
    IrisIfLoadedDirective,
    IrisOverlayLoaderDirective,
  ],
  exports: [
    IrisIfLoadedDirective,
    IrisOverlayLoaderDirective,
  ],
  imports: [
    IrisInlineLoaderComponentModule,
    IrisFillLoaderComponentModule,
    IrisSkeletonLoaderComponentModule,
    IrisOverlayLoaderComponentModule,
  ],
})
export class IrisLoaderModule { }
