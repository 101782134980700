import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IrisProtocolService {

  constructor(private readonly _httpClient: HttpClient) {
  }

  public unlock(protocolId: number, userId: number): Observable<any> {
    return this._httpClient.get(
      `/shift/protocol/${protocolId}/unlock`,
      { params: { userId: userId.toString() } });
  }

  public rejectUnlock(protocolId: number, userId: number): Observable<any> {
    return this._httpClient.get(
      `/shift/protocol/${protocolId}/reject-unlock`,
      { params: { userId: userId.toString() } });
  }

}
