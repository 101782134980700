import { ModuleWithProviders, NgModule } from '@angular/core';
import { IrisTimePipe } from './pipes/time.pipe';
import { IrisUserTimePipe } from './pipes/user-time.pipe';
import { IrisTimeService } from './services/time.service';

export const IrisTimeModuleType = 'IrisTimeModule';

@NgModule({
  imports: [IrisTimePipe, IrisUserTimePipe],
  exports: [IrisTimePipe, IrisUserTimePipe],
})
export class IrisTimeModule {
  static forRoot(): ModuleWithProviders<IrisTimeModule> {
    return {
      ngModule: IrisTimeModule,
      providers: [
        IrisTimeService,
      ],
    };
  }
}
