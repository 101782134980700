import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { IrisModuleFilterBodyComponent } from './components/module-filter-body/module-filter-body.component';
import { IrisModuleFilterButtonsComponent } from './components/module-filter-buttons/module-filter-buttons.component';
import { StoreModule } from '@ngrx/store';
import { moduleFilterReducers } from './store/module.filter.reducer';
import { IrisFieldsModule } from '../fields/fields.module';
import { IrisModuleFilterTagsComponent } from './components/module-filter-tags/module-filter-tags.component';
import { EffectsModule } from '@ngrx/effects';
import { ModuleFilterEffects } from './store/module-filter.effects';
import { IrisModuleSaveFilterModalComponent } from './components/module-save-filter-modal/module-save-filter-modal.component';
import { IrisCommonModule } from '../common/common.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { IrisModuleFilterOverlayService } from './services/module-filter-overlay.service';
import { IrisModalContentModule } from '@iris/common/modules/iris-modal-content/iris-modal-content.module';
import { MatMenuModule } from '@angular/material/menu';
import { IrisCommonPipesModule } from '@iris/common/modules/pipes-common/common-pipes.module';
import { IrisChipDropdownModule } from '@iris/common/modules/chip-dropdown/chip-dropdown.module';
import { MatChipsModule } from '@angular/material/chips';
import { IrisModuleFilterTagChipSingleComponent } from '@iris/common/modules/module-filter/components/tags-chips/module-filter-tag-chip-single/module-filter-tag-chip-single.component';
import { IrisModuleFilterTagChipBulkComponent } from '@iris/common/modules/module-filter/components/tags-chips/module-filter-tag-chip-bulk/module-filter-tag-chip-bulk.component';
import { IrisModuleFilterSandbox } from '@iris/common/modules/module-filter/store/module-filter.sandbox';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

@NgModule({
  imports: [
    IrisCommonModule,
    MatSidenavModule,
    IrisFieldsModule,
    MatMenuModule,
    IrisModalContentModule,
    StoreModule.forFeature('ModuleFilterModule', moduleFilterReducers),
    EffectsModule.forFeature([ModuleFilterEffects]),
    OverlayModule,
    IrisCommonPipesModule,
    IrisChipDropdownModule,
    MatChipsModule,
    NgxTippyModule,
  ],
  declarations: [
    IrisModuleFilterBodyComponent,
    IrisModuleFilterButtonsComponent,
    IrisModuleFilterTagsComponent,
    IrisModuleSaveFilterModalComponent,
    IrisModuleFilterTagChipSingleComponent,
    IrisModuleFilterTagChipBulkComponent,
  ],
  providers: [
    IrisModuleFilterOverlayService,
    IrisModuleFilterSandbox,
  ],
  exports: [
    IrisModuleFilterButtonsComponent,
    IrisModuleFilterBodyComponent,
    IrisModuleFilterTagsComponent,
  ],
})
export class IrisModuleFilterModule {
}
