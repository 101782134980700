import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationError, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

const CHUNK_ERROR_PATTERN = /Loading chunk .+ failed/;

@Injectable({
  providedIn: 'root',
})
export class ChunkLoadErrorHandlerService implements OnDestroy {
  private routeEventSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
  ) { }

  activate(): void {
    this.routeEventSubscription?.unsubscribe();
    this.routeEventSubscription = this.router.events.pipe(
      filter(e => e instanceof NavigationError),
      tap((navigationError: NavigationError) => {
        try {
          if (CHUNK_ERROR_PATTERN.test(navigationError?.error?.message)) {
            this.location.replaceState(navigationError.url);
            window.location.reload();
          }
        } catch (ex) {
          console.error('ROUTE NavigationError not handled', ex, navigationError);
        }
      }),
    ).subscribe();
  }

  deactivate(): void {
    this.routeEventSubscription?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.deactivate();
  }
}
