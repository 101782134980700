import { NgModule } from '@angular/core';
import { IrisNgSelectFieldModule } from '@iris/common/modules/fields/ng-select-field';
import { NgSelectModule } from '@natlex/ng-select';
import { IrisCountrySelectDirective } from './country-select.directive';
import { IrisCountrySelectDropdownGroupItemComponentModule } from './dropdown-group-item';

@NgModule({
  imports: [
    NgSelectModule,
    IrisNgSelectFieldModule,
    IrisCountrySelectDropdownGroupItemComponentModule,
  ],
  declarations: [
    IrisCountrySelectDirective,
  ],
  exports: [
    IrisCountrySelectDirective,
    IrisNgSelectFieldModule,
    NgSelectModule,
  ],
})
export class IrisCountrySelectModule {
}
