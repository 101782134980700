<div class="d-flex align-items-center local-container-paddings-{{secondaryData?.length > 1 ? 'small' : 'big'}}">
  <div class="local-primary-icon local-spaced-right flex-shrink-0">
    <ng-container [ngTemplateOutlet]="primaryIconContentTemplate">
    </ng-container>
  </div>

  <ng-template #itemTemplate
               let-item="item"
               let-dataTestAttr="dataTestAttr">
    <ng-container [ngSwitch]="true">
      <a *ngSwitchCase="!!item.routerLink"
         class="text-ellipsis"
         [attr.data-test]="dataTestAttr"
         [routerLink]="item.routerLink"
         [queryParams]="item.routerLinkQueryParams">{{ item.text }}</a>

      <a *ngSwitchCase="!!item.href"
         class="text-ellipsis"
         [attr.data-test]="dataTestAttr"
         [href]="item.href">{{ item.text }}</a>

      <span *ngSwitchDefault
            [attr.data-test]="dataTestAttr"
            class="text-ellipsis text-secondary-dark">{{ item.text }}</span>
    </ng-container>
  </ng-template>

  <div class="flex-fill me-auto overflow-hidden search-card">
    <div class="d-flex flex-column local-text-near-primary-icon justify-content-center">
      <ng-template #secondaryDataTemplate
                   let-secondaryDataItem=secondaryDataItem>
        <div class="d-flex text-medium text-secondary pt-1">
          <div class="d-inline spaced-right">
            <span *ngIf="secondaryDataItem.label"
                  class="local-colon-after">{{ secondaryDataItem.label }}</span>

            <i *ngIf="secondaryDataItem.labelIconClass"
               [class]="secondaryDataItem.labelIconClass"></i>
          </div>

          <div class="d-inline text-truncate local-values-container" [ngClass]="{ 'dotted-left': secondaryDataItem.alignRight }">
            <ng-container *ngFor="let item of secondaryDataItem.items">
              <ng-container [ngTemplateOutlet]="itemTemplate"
                            [ngTemplateOutletContext]="{ item: item, dataTestAttr: 'secondary' }">
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-template>

      <div *ngIf="titleData"
           class="text-ellipsis local-title mb-auto"
           [class.mb-auto]="secondaryData?.length">
        <ng-container [ngTemplateOutlet]="itemTemplate"
                      [ngTemplateOutletContext]="{ item: titleData, dataTestAttr: 'title' }">
        </ng-container>
      </div>

      <div *ngIf="secondaryData?.length">
        <ng-container [ngTemplateOutlet]="secondaryDataTemplate"
                      [ngTemplateOutletContext]="{ secondaryDataItem: secondaryData[0] }">
        </ng-container>
      </div>
    </div>

    <ng-container *ngFor="let secondaryDataItem of secondaryData?.slice(1)">
      <ng-container [ngTemplateOutlet]="secondaryDataTemplate"
                    [ngTemplateOutletContext]="{ secondaryDataItem: secondaryDataItem }">
      </ng-container>
    </ng-container>
  </div>

  <div *ngIf="secondaryIconContentTemplate"
       class="local-secondary-icon align-self-center local-spaced-left flex-shrink-0">
    <ng-container [ngTemplateOutlet]="secondaryIconContentTemplate">
    </ng-container>
  </div>

  <a *ngIf="cardLink?.routerLink"
     data-test="card-link"
     (click)="toggleDropdown()"
     class="stretched-link local-hidden"
     [routerLink]="cardLink.routerLink"
     [queryParams]="cardLink.routerLinkQueryParams">
  </a>

  <a *ngIf="cardLink?.href"
     data-test="card-link"
     (click)="toggleDropdown()"
     class="stretched-link local-hidden"
     [href]="cardLink.href">
  </a>

</div>
