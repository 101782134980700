import { Pipe, PipeTransform } from '@angular/core';
import { AbstractDatetime } from '../../date';
import { IrisTimeSandbox } from '../time.sandbox';

@Pipe({
  name: 'irisTime',
  standalone: true,
})
export class IrisTimePipe implements PipeTransform {
  constructor(
    private readonly timeSandbox: IrisTimeSandbox,
  ) { }

  transform(value: unknown, format?: string, timeZone?: string): string {
    const defaultTimeFormat = 'dd.MM.yyyy';
    const userDateTimeFormat: IrisTimeFormatI = this.timeSandbox.currentUserDateTimeFormat;
    const dateTimeFormat = format || userDateTimeFormat?.luxonFormatString || defaultTimeFormat;

    if (!value) {
      return '';
    }

    const date = AbstractDatetime.parse(value).setTimezone(timeZone || this.timeSandbox.currentUserTimeZone);

    return date.isValid() ? date.format(dateTimeFormat) : '';
  }
}
