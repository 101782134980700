import { Pipe, PipeTransform } from '@angular/core';
import { IrisLocaleService } from '../../../locale/services/locale.service';
import { transformTranslate } from './translate.utils';

@Pipe({
  name: 'irisTranslate',
  standalone: true,
})
export class IrisTranslatePipe implements PipeTransform {

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private readonly localeService: IrisLocaleService = {} as any) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any, translations, defaultValue?, currLocale?): any {
    return transformTranslate(value, translations, defaultValue, currLocale, this.localeService);
  }
}
