import { createAction, props } from '@ngrx/store';
import { IrisModuleFilterI } from '../models/IrisModuleFilter';
import { FilterValidatorFnI } from '@iris/common/modules/module-filter/models/interfaces/filter-validator-fn.interface';

export enum ModuleFilterActionTypes {
  SetFilter = '[ModuleFilter] Set filter',
  ApplyFilter = '[ModuleFilter] Update filter',
  ApplyFloatingFilter = '[ModuleFilter] Apply floating filter',
  ApplyDefaultFilter = '[ModuleFilter] Apply Default Filter',
  SetFilterVisibility = '[ModuleFilter] Set filter visibility',
  SetMenuVisibility = '[ModuleFilter] Set menu visivility',
  SetPropertiesMeta = '[ModuleFilter] Set properties meta',
  SetModuleName = '[ModuleFilter] Set module name',
  ResetModuleFilter = '[ModuleFilter] Reset module filter',
  FetchUserFilters = '[ModuleFilter] Fetch module filter',
  FetchUserFiltersComplete = '[ModuleFilter] Fetch module filter complete',
  FetchUserFiltersError = '[ModuleFilter] Fetch module filter error',
  SaveModuleFilter = '[ModuleFilter] Save module filter',
  SaveModuleFilterComplete = '[ModuleFilter] Save module filter complete',
  SaveModuleFilterError = '[ModuleFilter] Save module filter error',
  RemoveModuleFilter = '[ModuleFilter] Remove module filter',
  RemoveModuleFilterComplete = '[ModuleFilter] Remove module filter complete',
  RemoveModuleFilterError = '[ModuleFilter] Remove module filter error',
  InitRecentlyFilters = '[ModuleFilter] Init recently filters',
  RefreshRecentlyFilters = '[ModuleFilter] Refresh recently filters',
  PinFilter = '[ModuleFilter] Pin filter',
  PinFilterComplete = '[ModuleFilter] Pin filter complete',
  PinFilterError = '[ModuleFilter] Pin filter error',
  SetFilterValidatorFn = '[ModuleFilter] Set filter validator fn'
}

export const setFilter = createAction(
  ModuleFilterActionTypes.SetFilter,
  props<{ filter: any }>(),
);

export const setFilterVisibility = createAction(
  ModuleFilterActionTypes.SetFilterVisibility,
  props<{ visibility?: boolean }>(),
);

export const setMenuVisibility = createAction(
  ModuleFilterActionTypes.SetMenuVisibility,
  props<{ visibility: boolean }>(),
);

export const applyFilter = createAction(
  ModuleFilterActionTypes.ApplyFilter,
  props<{ filter: any}>(),
);

export const applyFloatingFilter = createAction(
  ModuleFilterActionTypes.ApplyFloatingFilter,
  props<{ floatingFilter: any}>(),
);

export const applyDefaultFilter = createAction(
  ModuleFilterActionTypes.ApplyDefaultFilter,
);

export const resetFilter = createAction(
  ModuleFilterActionTypes.ResetModuleFilter,
);

export const setPropertiesMeta = createAction(
  ModuleFilterActionTypes.SetPropertiesMeta,
  props<{ propertiesMeta: any[] }>(),
);

export const setModuleName = createAction(
  ModuleFilterActionTypes.SetModuleName,
  props<{ moduleName: string }>(),
);

export const fetchUserFilters = createAction(
  ModuleFilterActionTypes.FetchUserFilters,
  props<{ moduleName: string }>(),
);

export const fetchUserFiltersComplete = createAction(
  ModuleFilterActionTypes.FetchUserFiltersComplete,
  props<{ filters: IrisModuleFilterI[] }>(),
);

export const fetchUserFiltersError = createAction(
  ModuleFilterActionTypes.FetchUserFiltersError,
  props<{ err: any }>(),
);

export const saveFilter = createAction(
  ModuleFilterActionTypes.SaveModuleFilter,
  props<{ filter: IrisModuleFilterI }>(),
);

export const saveFilterComplete = createAction(
  ModuleFilterActionTypes.SaveModuleFilterComplete,
  props<{ filter: IrisModuleFilterI }>(),
);

export const saveFilterError = createAction(
  ModuleFilterActionTypes.SaveModuleFilterError,
  props<{ err: any }>(),
);

export const removeFilter = createAction(
  ModuleFilterActionTypes.RemoveModuleFilter,
  props<{ filterId: number }>(),
);

export const removeFilterComplete = createAction(
  ModuleFilterActionTypes.RemoveModuleFilterComplete,
  props<{ filter: any }>(),
);

export const removeFilterError = createAction(
  ModuleFilterActionTypes.RemoveModuleFilterError,
  props<{ err: any }>(),
);

export const initRecentlyFilters = createAction(
  ModuleFilterActionTypes.InitRecentlyFilters,
  props<{ filters: any[] }>(),
);

export const refreshRecentlyFilters = createAction(
  ModuleFilterActionTypes.RefreshRecentlyFilters,
  props<{ filter: IrisModuleFilterI }>(),
);

export const pinFilter = createAction(
  ModuleFilterActionTypes.PinFilter,
  props<{ filterId: number; isPin: boolean }>(),
);

export const pinFilterComplete = createAction(
  ModuleFilterActionTypes.PinFilterComplete,
  props<{ pinnedFilter: IrisModuleFilterI }>(),
);

export const pinFilterError = createAction(
  ModuleFilterActionTypes.PinFilterError,
  props<{ err: any }>(),
);

export const setFilterValidatorFn = createAction(
  ModuleFilterActionTypes.SetFilterValidatorFn,
  props<{ fn: FilterValidatorFnI }>(),
);
