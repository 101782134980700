import { NgModule } from '@angular/core';
import {
  MsalBroadcastService, MsalGuard, MsalModule, MsalRedirectComponent, MsalService, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { IrisAuthService } from '@iris/modules/auth/utils/auth.service';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './msal.config';
import { IrisMsalService } from './msal.service';

@NgModule({
  imports: [
    MsalModule,
  ],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [IrisAuthService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [IrisAuthService],
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [IrisAuthService],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    IrisMsalService,
  ],
  bootstrap: [MsalRedirectComponent],
})
export class IrisMsalModule { }
