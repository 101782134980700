import { createAction, props } from '@ngrx/store';
import { IrisProjectTypeI } from '@iris/common/models/irisProjectType';
import { IrisDepartmentWithParentsI } from '@iris/common/services/department.service';
import { IrisProjectI } from '@iris/common/models/IrisProject';

export const loadProjectTypes = createAction(
  '[GlobalProjects] Load project types',
);
export const loadProjectTypesComplete = createAction(
  '[GlobalProjects] Load project types complete',
  props<{ projectTypes: IrisProjectTypeI[] }>(),
);
export const loadDepartmentPaths = createAction(
  '[GlobalProjects] Load department paths',
  props<{ departmentId: string }>(),
);
export const loadDepartmentPathsComplete = createAction(
  '[GlobalProjects] Load department paths complete',
  props<{ departments: IrisDepartmentWithParentsI[] }>(),
);
export const changeCurrentProjectId = createAction(
  '[GlobalProjects] Change current project id',
  props<{ projectId: number }>(),
);
export const setRecentProjectsIds = createAction(
  '[GlobalProjects] Set recent projectsIds',
  props<{ projectsIds: number[] }>(),
);
export const setRecentProjects = createAction(
  '[GlobalProjects] Set recent projects',
  props<{ projects: IrisProjectI[] }>(),
);
export const setCurrentProject = createAction(
  '[GlobalProjects] Set current project',
  props<{ project: IrisProjectI }>(),
);
