import { NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { IrisUserService } from '@iris/common/services/user.service';

@NgModule({
  imports: [],
})
export class IrisSentryIntegrationModule {
  constructor(
    envService: IrisEnvironmentService,
    userService: IrisUserService,
  ) {
    const env = envService.env;

    if (env.sentryActivated) {
      Sentry.init({
        dsn: env.sentryFrontendDsn,
        beforeSend: (event: Sentry.Event, _hint: Sentry.EventHint) => {
          /**
           * Non-Error is a fallback that can be obtained from various errors (usually from HttpErrorResponse).
           * In some cases, Sentry can't extract a message from the error that has occurred.
           * Here we're trying to get a real error message from a serialized event object.
           * https://github.com/getsentry/sentry-javascript/issues/2292
           */
          const isNonErrorException = event?.exception?.values?.[0]?.value?.startsWith('Non-Error exception captured');
          if (isNonErrorException) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const serializedEvent: Record<string, any> = event.extra?.__serialized__;

            if (!serializedEvent) {
              return event;
            }

            const realErrMessage = serializedEvent?.error?.message ?? serializedEvent?.message;

            event.exception.values[0].value = realErrMessage;
            event.message = realErrMessage;
          }

          return event;
        },
      });

      Sentry.setUser({ id: userService.me?.id, email: userService.me?.email });

      Sentry.setTag('instance_id', env.instanceId);
      Sentry.setTag('release_version', env.version);
      Sentry.setTag('instance', env.instanceName);
    }
  }
}
