import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IrisLoaderService } from '@iris/common/services/loader.service';
import { Actions, createEffect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { LoaderStartActionI, LoaderStopActionI } from '../actions/loader-actions';
import isNil from 'lodash/isNil';

@Injectable()
export class IrisLoaderEffects {
  
  checkStartLoader$: Observable<LoaderStartActionI> = createEffect(() => this.actions$.pipe(
    tap((action: LoaderStartActionI) => {
      if (!isNil(action.loaderStartContainerSelector)) {
        this.loader.start(action.loaderStartContainerSelector);
      }
    }),
  ), {
    dispatch: false,
  });
  
  checkStopLoader$: Observable<LoaderStopActionI> = createEffect(() => this.actions$.pipe(
    tap((action: LoaderStopActionI) => {
      if (!isNil(action.loaderStopContainerSelector)) {
        if (action.loaderStopContainerSelector === '') {
          this.loader.stop();
        } else {
          this.loader.stop(action.loaderStopContainerSelector);
        }
      }
    }),
  ), {
    dispatch: false,
  });

  constructor(private readonly actions$: Actions,
              private readonly loader: IrisLoaderService) {
  }
}
