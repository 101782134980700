import { AfterViewInit, Component, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Component({
  templateUrl: './screensaver.component.html',
  styleUrls: [
    './styles/bat.scss',
    './styles/dracula.scss',
    './styles/pumpkin.scss',
    './styles/sparks.scss',
    './styles/witches.scss',
    './styles/ghost.scss',
    './screensaver.component.scss',
  ],
})
export class IrisScreensaverComponent implements AfterViewInit {
  @HostBinding('class.loaded') loaded = false;

  @Output() cancelEmitter = new EventEmitter<void>();

  @HostListener('document:keydown.escape', ['$event'])
  cancel($event?: KeyboardEvent | UIEvent): void {
    if ($event) { $event.stopPropagation(); }
    this.cancelEmitter.emit();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loaded = true;
    }, 500);
  }
}
