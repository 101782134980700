export const ROOT_EMAILS_PATH = 'emails';
export const NAVIGATION_MENU_PATH_NAME = 'folderOrTagId';
export const MESSAGE_PATH_NAME = 'messageId';
export const NEW_MESSAGE_PATH_NAME = 'new';
export const DRAFT_MESSAGE_PATH_NAME = 'draft';
export const FORWARDED_MESSAGE_QUERY_NAME = 'forwardedMessageId';
export const REPLIED_MESSAGE_QUERY_NAME = 'repliedMessageId';
export const REPLY_ALL_QUERY_NAME = 'replyAll';

export enum IrisEmailsTabIndex {
  Mailbox = 0,
  Documents = 1,
}

export enum IrisEmailsTabType {
  Mailbox = 'Mailbox',
  Documents = 'Documents',
}

export enum IrisEmailFormState {
  New = 'New',
  Draft = 'Draft',
  Forward = 'Forward',
  Reply = 'Reply',
  ReplyAll = 'ReplyAll',
}
