import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ChangeDetectorRef } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { TagItemRemovedActionI } from '@iris/common/modules/module-filter/components/tags-chips/tag-item-remove-action.model';
import { ModuleFilterTagDataI } from '../../module-filter-tags/module-filter-tags.component';
import { Primitive } from '@iris/common/models/Primitive';

@Component({
  selector: 'iris-module-filter-tag-chip-single',
  templateUrl: './module-filter-tag-chip-single.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisModuleFilterTagChipSingleComponent implements OnChanges {
  @Input() tag: ModuleFilterTagDataI;
  @Input() value: Primitive;
  @Output() tagItemRemoved: EventEmitter<TagItemRemovedActionI> = new EventEmitter<TagItemRemovedActionI>();

  label = '';

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.label = `${this.tag.meta.label}: `;
    const memoizedLabel = this.tag?.meta?.getMemoizedLabel(this.value);

    if (memoizedLabel) {
      this.label = memoizedLabel;
      return this.changeDetectorRef.markForCheck();
    }

    this.tag.meta.singleModeItemLabel$(this.value)
      .pipe(
        take(1),
        tap(label => {
          this.label = label;
          this.tag.meta.memoizeLabel(this.value, label);
          this.changeDetectorRef.markForCheck();
        }),
      ).subscribe();
  }

  removeItem(): void {
    this.tagItemRemoved.emit({
      field: this.tag.meta.formFieldName,
      updatedItems: this.tag.items.filter((item) => item !== this.value),
      removedItem: this.value,
    });
  }
}
