import { Injectable } from '@angular/core';
import { IrisDMSFileI } from '@iris/common/modules/dms/models/IrisDMSFile';
import {
  FilesPreviewModalComponent,
} from '@iris/common/modules/preview-file/components/files-preview-modal/files-preview-modal.component';
import { IrisFilesService } from '@iris/common/modules/dms/services/files.service';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { take, tap } from 'rxjs';
import { hasQueryParam, removeQueryParams } from '@iris/common/utils/router.utils';
import { IrisDMSFileQueryParam, IrisDMSFileQueryParamAction } from '../../dms/models/IrisDMSFileQueryParam';
import { ActivatedRoute, Router } from '@angular/router';

export interface PreviewInputParamsI {
  fileInfo: IrisDataRepoFileI | IrisDMSFileI;
  filesList?: IrisDataRepoFileI[] | IrisDMSFileI[];
  versionId?: string;
  openDms?: boolean;
  showGoToFolder?: boolean;
  saveRecentFile?: boolean;
  previewDataString?: string;
}

@Injectable({ providedIn: 'root' })
export class IrisPreviewFileService {
  private previewOpened = false;
  constructor(
    private readonly filesService: IrisFilesService,
    private readonly alertService: IrisAlertService,
    private readonly translateService: TranslateService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) { }

  public openPreviewFileModal({
    fileInfo,
    filesList = [],
    versionId = null,
    openDms = false,
    showGoToFolder = true,
    saveRecentFile = false,
    previewDataString,
  }: PreviewInputParamsI): MatDialogRef<FilesPreviewModalComponent, unknown> {
    return this.openPreviewModal(fileInfo, filesList, versionId, openDms, showGoToFolder, saveRecentFile, previewDataString);
  }

  public openPreviewInNewTab(file: IrisDataRepoFileI | IrisDMSFileI): void {
    const url = this.filesService.getFilePreviewUrl(file.id);
    window.open(url, '_blank');
  }

  private openPreviewModal(
    fileInfo: IrisDataRepoFileI | IrisDMSFileI,
    filesList: IrisDataRepoFileI[] | IrisDMSFileI[] = [],
    versionId?: string,
    openDms?: boolean,
    showGoToFolder = true,
    saveRecentFile = false,
    previewDataString = undefined,
  ): MatDialogRef<FilesPreviewModalComponent, unknown> {
    if (this.previewOpened) { return; }
    if (!this.filesService.isPreviewReady(fileInfo)) {
      this.alertService.log(this.translateService.instant('text.PreviewNotSupported'));
      return;
    }

    this.previewOpened = true;

    const fileLink = versionId
      ? this.filesService.getVersionPreviewUrl(fileInfo.id, versionId)
      : this.filesService.getFilePreviewUrl(fileInfo.id, false, true);

    const modal = this.dialog.open(
      FilesPreviewModalComponent, {
        data: {
          file: {
            ...Object.assign(
              {
                attachedInd: undefined,
                searchHits: undefined,
                previewType: undefined,
                icon: undefined,
                downloadUrl: undefined,
              },
              fileInfo,
              {
                createdBy: fileInfo.createdBy?.toString(),
                contentLanguage: fileInfo.contentLanguage?.length ? fileInfo.contentLanguage[0] : fileInfo.contentLanguage as string,
              },
            ),
            fileLink: previewDataString ?? fileLink,
          },
          filesList,
          openDms: openDms ?? false,
          showGoToFolder,
          saveRecentFile: saveRecentFile ?? false,
        },
        panelClass: 'modal-fullscreen',
        maxWidth: '100%',
      },
    );

    modal.afterClosed().pipe(
      take(1),
      tap(() => {
        this.previewOpened = false;
        const hasSelectParam = hasQueryParam(this.activatedRoute, IrisDMSFileQueryParamAction.Select);
        if (!hasSelectParam && openDms) {
          removeQueryParams(this.router, [IrisDMSFileQueryParam.File]);
        }
      }),
    ).subscribe();

    return modal;
  }
}
