import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { IrisModuleFilterService } from '../services/module-filter.service';
import { IrisLocalStorageService } from '../../../services/utils/iris-local-storage.service';
import * as fromModuleFilter from './module.filter.reducer';
import {
  fetchUserFilters,
  fetchUserFiltersComplete,
  fetchUserFiltersError,
  initRecentlyFilters,
  refreshRecentlyFilters,
  removeFilter,
  removeFilterComplete,
  removeFilterError,
  saveFilter,
  saveFilterComplete,
  saveFilterError,
  pinFilter,
  pinFilterComplete,
  pinFilterError
} from '@iris/common/modules/module-filter/store/module-filter.actions';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ModuleFilterEffects {
  fetchUserFilter$ = createEffect(() => (
    this.actions$.pipe(
      ofType(fetchUserFilters),
      switchMap(({ moduleName }) => {
        return moduleName ? of(moduleName) : this.store$.pipe(select(fromModuleFilter.getModuleName));
      }),
      switchMap((moduleName) => {
        return this.moduleFilterService
          .getUserFilters(moduleName)
          .pipe(
            tap(() => {
              this.store$.dispatch(initRecentlyFilters({
                filters: this.moduleFilterService.getRecentFiltersFromStore(moduleName),
              }));
            }),
            map((filters) => fetchUserFiltersComplete({ filters })),
            catchError((error: Error) => of(fetchUserFiltersError({ err: error.message }))),
          );
      }),
    )
  ));

  saveFilter$ = createEffect(() => (
    this.actions$.pipe(
      ofType(saveFilter),
      switchMap(({ filter }) => {
        return this.moduleFilterService
          .saveFilter(filter)
          .pipe(
            map((savedFilter) => saveFilterComplete({ filter: savedFilter })),
            catchError((error: Error) => of(saveFilterError({ err: error.message }))),
          );
      }),
    )
  ));

  saveFilterComplete$ = createEffect(() => (
    this.actions$.pipe(
      ofType(saveFilterComplete),
      tap(() => {
        this.alertify.success(this.translate.instant('label.SavedSuccessfully'));
      }),
      tap(({ filter }) => this.store$.dispatch(refreshRecentlyFilters({ filter }))),
    )
  ), { dispatch: false });

  removeFilter$ = createEffect(() => (
    this.actions$.pipe(
      ofType(removeFilter),
      switchMap(({ filterId }) => {
        return this.moduleFilterService
          .delete(filterId)
          .pipe(
            map((filter) => removeFilterComplete({ filter })),
            catchError((error: Error) => of(removeFilterError({ err: error.message }))),
          );
      }),
    )
  ));

  removeFilterComplete$ = createEffect(() => (
    this.actions$.pipe(
      ofType(removeFilterComplete),
      tap(() => {
        this.alertify.success(this.translate.instant('message.DeleteItemSuccessful'));
      }),
      tap(({ filter }) => this.store$.dispatch(refreshRecentlyFilters({ filter }))),
    )
  ), { dispatch: false });

  saveFilterToLocalStore$ = createEffect(() => (
    this.actions$.pipe(
      ofType(refreshRecentlyFilters),
      withLatestFrom(
        this.store$.pipe(select(fromModuleFilter.getModuleFilterRecentlyFilters)),
        this.store$.pipe(select(fromModuleFilter.getModuleName)),
      ),
      tap(([action, recentlyFilters, moduleName]) => {
        const data = this.localStorageService.getItem(IrisModuleFilterService.RECENTLY_FILTER_KEY) || {};
        data[moduleName] = recentlyFilters.map((filter) => ({ id: filter.id }));
        this.localStorageService.setItem(IrisModuleFilterService.RECENTLY_FILTER_KEY, data);
      }),
      map(([action]) => action),
    )
  ), { dispatch: false });

  pinFilter$ = createEffect(() => (
    this.actions$.pipe(
      ofType(pinFilter),
      switchMap(({ filterId, isPin }) => {
        return combineLatest([this.moduleFilterService.togglePinned(filterId), of(isPin)]).pipe(
          switchMap(([filter, isPin]) => filter.isPinned !== isPin ?
            this.moduleFilterService.togglePinned(filterId) : of(filter)) ,
          tap(filter => this.alertify.success(this.translate.instant(
            filter.isPinned ? 'label.mf.FilterPinned' : 'label.mf.FilterUnpinned'))),
          tap(filter => this.store$.dispatch(refreshRecentlyFilters({ filter }))),
          map((pinnedFilter) => pinFilterComplete({ pinnedFilter })),
          catchError((error: Error) => of(pinFilterError({ err: error.message }))),
        );
      }),
    )
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly moduleFilterService: IrisModuleFilterService,
    private readonly localStorageService: IrisLocalStorageService,
    private readonly alertify: IrisAlertService,
    private readonly translate: TranslateService,
  ) { }

}
