import { IrisTranslatePipe } from '@iris/common/modules/pipes-common/pipes';
import { isEmpty, isObject } from 'lodash';

export function filterValueCommonIsEmpty(val: unknown): boolean {
  return typeof val !== 'number' && typeof val !== 'boolean' && isEmpty(val);
}

export function filterValueObjectIsEmpty(val: unknown): boolean {
  if (filterValueCommonIsEmpty(val)) { return true; }
  return typeof val === 'object' && Object.values(val).every(v => filterValueCommonIsEmpty(v));
}

export function filterValueArrayIsEmpty(val: unknown): boolean {
  if (filterValueCommonIsEmpty(val)) { return true; }
  return Array.isArray(val) && ![...val].length;
}

export function filterValueUniversalIsEmpty(val: unknown): boolean {
  if (filterValueCommonIsEmpty(val)) { return true; }
  if (typeof val === 'string' && String(val) === '') { return true; }
  if (Array.isArray(val) && ![...val].length) { return true; }
  return typeof val === 'object' && Object.values(val).every(v => filterValueUniversalIsEmpty(v));
}

/**
 * nameTranslated property can be outdated (in case of saved filters)
 * It should be updated for a filter sidebar
 */
export function filterValueUpdateNameTranslated<T>(filterFormValue: T, translatePipe: IrisTranslatePipe): T {
  const updateNameTranslatedProp = (entity) => {
    if (entity.nameTranslated && !isEmpty(entity.translations)) {
      entity.nameTranslated = translatePipe.transform(entity.name, entity.translations)?.name
        || entity.nameTranslated
        || entity.name;
    }
  };

  if (isObject(filterFormValue) && !isEmpty(filterFormValue)) {
    Object.values(filterFormValue).forEach((filterVal) => {
      if (Array.isArray(filterVal) && filterVal.length > 0 && isObject(filterVal[0])) {
        filterVal.forEach((filterEntity) => {
          updateNameTranslatedProp(filterEntity);
        });
      } else if (isObject(filterVal)) {
        updateNameTranslatedProp(filterVal);
      }
    });
  }

  return filterFormValue;
}
