import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IrisTimeFormatI } from '../models/IrisTimeFormat';

@Injectable({
  providedIn: 'root',
})
export class IrisTimeService {
  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  getTimeFormats(): Observable<IrisTimeFormatI[]> {
    return this.httpClient.get<IrisTimeFormatI[]>(`/security/datetimeformat`);
  }
}
