<iris-modal-content
  [title]="'label.Imprint' | translate"
  [parent]="this"
  isHideCloseBtn="true">
  <ng-container id="iris-modal-body">
    <div class="host-frame-container">
      <iframe
        width="100%"
        height="100%"
        class="host-frame"
        [title]="'label.Imprint' | translate"
        [src]="path | safeResourceUrl"></iframe>
    </div>
  </ng-container>
  <ng-container id="iris-modal-footer-buttons">
    <button
      class="btn btn-default"
      type="button"
      (click)="closeWithoutResult()"
      data-test="imprint-footer-button">
      <i class="fal fa-times"></i>
      <span>{{ 'label.Close' | translate }}</span>
    </button>
  </ng-container>
</iris-modal-content>
