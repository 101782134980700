/* eslint-disable */
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatDatetimepickerCalendarBodyComponent } from '@mat-datetimepicker/core';

export class MatDatetimepickerCalendarCell {
  constructor(public value: number,
              public displayValue: string,
              public ariaLabel: string,
              public enabled: boolean,
              public weekNumber?: number) { }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[mat-datetimepicker-year-body-extended]',
  templateUrl: 'year-body.html',
  host: {
    'class': 'mat-datetimepicker-calendar-body',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatDatetimepickerYearBodyExtended extends MatDatetimepickerCalendarBodyComponent {
  /** The label for the table. (e.g. 'Jan 2017'). */
  @Input() label: string;

  /** The cells to display in the table. */
  @Input() rows: MatDatetimepickerCalendarCell[][];

  /** The value in the table that corresponds to today. */
  @Input() todayValue: number;

  /** The value in the table that is currently selected. */
  @Input() selectedValue: number;

  /** The minimum number of free cells needed to fit the label in the first row. */
  @Input() labelMinRequiredCells: number;

  /** The number of columns in the table. */
  @Input() numCols = 7;

  /** Whether to allow selection of disabled cells. */
  @Input() allowDisabledSelection = false;

  /** The cell number of the active cell in the table. */
  @Input() activeCell = 0;

  /** Emits when a new value is selected. */
  @Output() selectedValueChange = new EventEmitter<number>();

  _cellClicked(cell: MatDatetimepickerCalendarCell): void {
    if (!this.allowDisabledSelection && !cell.enabled) {
      return;
    }
    this.selectedValueChange.emit(cell.value);
  }
}
