import { Directive, Inject, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NgSelectComponent } from '@natlex/ng-select';
import { IrisUserInfoI } from '@iris/common/modules/user-common/models/IrisUserInfo';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { IrisUserSelect } from './field-user-select';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import { USER_SELECT_PROVIDERS } from './field-user-select.providers';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: 'ng-select[irisUserSelect]',
  exportAs: 'irisUserSelect',
  providers: USER_SELECT_PROVIDERS,
})
export class IrisUserSelectDirective implements OnInit, OnDestroy, OnChanges {
  @Input() showAvatar: boolean;
  @Input() showAvatarForSelected: boolean;
  @Input() showCompany: boolean;
  @Input() showCompanyForSelected: boolean;
  @Input() showEmail: boolean;
  @Input() showEmailForSelected: boolean;
  @Input() showDepartment: boolean;
  @Input() sortByFullName: boolean;
  @Input() excludeUsers: number[];
  @Input() typeToSearchText = 'label.StartTypingNameOrSurname';
  @Input() placeholder: string;
  @Input() searchable: boolean;
  @Input() multiple: boolean;
  @Input() panelWidth: string;
  @Input() bindValue = 'id';
  @Input() searchByApiUser: boolean;
  @Input() searchBySSOUser: boolean;

  public items$: Observable<IrisUserInfoI[]>;
  public selectedItems: IrisUserInfoI[] = [];
  public loading = false;

  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(
    protected readonly ngSelect: NgSelectComponent,
    protected readonly userSelect: IrisUserSelect,
    protected readonly translate: TranslateService,
    @Inject(DOCUMENT) protected readonly document: Document,
  ) {
    this._toggleClass('user-select-field');
    this._toggleClass('user-select-field-searchable', this.searchable !== undefined ? this.searchable : this.userSelect.options.searchable);
    this._toggleClass('ng-select-selected-as-chip');

    this.ngSelect.hideSelected = true;
    this.ngSelect.bindValue = this.bindValue;

    this.items$ = userSelect.result$
      .pipe(
        map(page => page.elements),
      );

    userSelect.loading$
      .pipe(
        tap(loading => {
          this.ngSelect.loading = loading;
          this.ngSelect.refreshItems(); // needs for showing loader icon
        }),
        takeUntil(this.unsubscribe$),
      ).subscribe();

    this.ngSelect.openEvent
      .pipe(
        tap(() => this._setPanelWidth()),
        takeUntil(this.unsubscribe$),
      ).subscribe();

    this.ngSelect.blurEvent
      .pipe(
        tap(() => this.togglePlaceholder(true)),
        takeUntil(this.unsubscribe$),
      ).subscribe();

    this.ngSelect.focusEvent
      .pipe(
        tap(() => {
          if(this.ngSelect.multiple) {
            this.setExcludedUsers();
          }
          this.togglePlaceholder();
        }),
        takeUntil(this.unsubscribe$),
      ).subscribe();

    this.ngSelect.changeEvent
      .pipe(
        tap(() => {
          if(this.ngSelect.multiple) {
            this.setExcludedUsers();
            return;
          }
          this.togglePlaceholder(!!(this.ngSelect.selectedItems && this.ngSelect.selectedItems.length));
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.userSelect.setSearchByApiUser(this.searchByApiUser);
    this.userSelect.setSearchBySSOUser(this.searchBySSOUser);
  }

  togglePlaceholder(remove = false): void {
    if(this.ngSelect.searchable === false) {
      return;
    }

    remove = !remove && !this.ngSelect.multiple && this.ngSelect.selectedItems && this.ngSelect.selectedItems.length ? true : remove;

    const searchInput:HTMLInputElement = this.ngSelect.searchInput.nativeElement;

    if (searchInput) {
      if (!this.ngSelect.multiple) {
        searchInput.setAttribute('multiple','false');
      }
      if (remove) {
        searchInput.setAttribute('placeholder', this.placeholder ?? '');
      } else {
        searchInput.setAttribute('placeholder', this.translate.instant(this.typeToSearchText) || this.placeholder || '');
      }
    }
  }

  private _setPanelWidth(): void {
    if(!this.panelWidth) {
      return;
    }

    const dropdownId: string = this.ngSelect.dropdownId;

    setTimeout(() => {
      const panel:HTMLElement = this.document.querySelector(`#${dropdownId}`);
      if(panel) {
        panel.style.width = this.panelWidth;
      }
    }, 50);
  }

  private _toggleClass(className: string, remove = false): void {
    const classes: string[] = !this.ngSelect.classes ? [] : this.ngSelect.classes.split(' ');
    const element: HTMLElement = this.ngSelect.element;

    if(!element.classList.contains(className)) {
      element.classList.add(className);
    } else if(remove) {
      element.classList.remove(className);
    }

    if(!classes.includes(className)) {
      classes.push(className);
      this.ngSelect.classes = classes.join(' ');
    } else if(remove) {
      this.ngSelect.classes = classes.filter((cls) => cls != className).join(' ');
    }
  }

  ngOnChanges(changes: SimpleChanges<this>): void {
    if (changes.showEmail) {
      this.userSelect.options.showEmail = coerceBooleanProperty(this.showEmail);
    }

    if (changes.showEmailForSelected) {
      this.userSelect.options.showEmailForSelected = coerceBooleanProperty(this.showEmailForSelected);
    }

    if (changes.showAvatar) {
      this.userSelect.options.showAvatar = coerceBooleanProperty(this.showAvatar);
    }

    if (changes.showAvatarForSelected) {
      this.userSelect.options.showAvatarForSelected = coerceBooleanProperty(this.showAvatarForSelected);
    }

    if (changes.showCompany) {
      this.userSelect.options.showCompany = coerceBooleanProperty(this.showCompany);
    }

    if (changes.showCompanyForSelected) {
      this.userSelect.options.showCompanyForSelected = coerceBooleanProperty(this.showCompanyForSelected);
    }

    if (changes.showDepartment) {
      this.userSelect.options.showDepartment = coerceBooleanProperty(this.showDepartment);
    }

    if (changes.searchable) {
      this.userSelect.options.searchable = this.ngSelect.searchable = coerceBooleanProperty(this.searchable);

      this._toggleClass('user-select-field-searchable', this.userSelect.options.searchable);
    }

    if (changes.multiple) {
      this.userSelect.options.multiple = this.ngSelect.multiple = coerceBooleanProperty(this.multiple);
    }

    if(changes.excludeUsers) {
      this.setExcludedUsers();
    }

    if(changes.typeToSearchText) {
      this.userSelect.options.typeToSearchText = this.typeToSearchText;
    }

    if(changes.sortByFullName) {
      this.userSelect.setSortByName(coerceBooleanProperty(this.sortByFullName));
    }

    if (changes.bindValue) {
      this.ngSelect.bindValue = this.bindValue;
    }

    if (changes.searchByApiUser) {
      this.userSelect.setSearchByApiUser(this.searchByApiUser);
    }

    if (changes.searchBySSOUser) {
      this.userSelect.setSearchBySSOUser(this.searchBySSOUser);
    }

    if (changes.placeholder) {
      this.userSelect.options.placeholder = this.placeholder;
      this.togglePlaceholder(true);
    }
  }

  private setExcludedUsers(): void {
    const excludedItems = [...(this.excludeUsers || []), ...this.ngSelect.selectedValues.map((value: IrisUserInfoI) => value.id)];
    this.userSelect.setExcludedItems(excludedItems);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
