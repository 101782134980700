<ng-template #template let-item="item">
  <iris-user-ws class="user-as-list-option"
                [noHint]="true"
                [userId]="item.id"
                [showAvatar]="options.showAvatar"
                [showCompany]="options.showCompany"
                [showDepartment]="options.showDepartment"
                [showEmail]="options.showEmail"
  ></iris-user-ws>
</ng-template>
