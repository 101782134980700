/* eslint-disable @typescript-eslint/no-explicit-any */
import { copyValues } from '@iris/common/utils/object.utils';
import { ServerBaseEntity } from '@iris/common/models/ServerBaseEntity';

export interface IrisCountryI extends ServerBaseEntityI {
  name: string;
  active: boolean;
  currency: any;
  flag: string;
  isoAlpha2: string;
  isoAlpha3: string;
  isoNumeric: number;
  translations?: any;
  originalName: string;
  translated: {
    name: string;
  };
}

export class IrisCountry extends ServerBaseEntity<IrisCountryI> implements IrisCountryI {
  name: string = undefined;
  active: boolean = undefined;
  currency: any = undefined;
  flag: string = undefined;
  isoAlpha2: string = undefined;
  isoAlpha3: string = undefined;
  isoNumeric: number = undefined;
  originalName: string = undefined;
  translated = undefined;

  constructor(params?: IrisCountryI) {
    super(params);
    if (params) {
      copyValues(this, params);
    }
  }
}
