import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { uncapitalize } from '../utils/string.utils';

type GuardDataKeyField = `routeDataKey`;
type GuardRouteDataFnField<TGuardDataKey extends string> = `${Uncapitalize<TGuardDataKey>}Data`;

type GuardRouteDataFactoryWithKeyReturnType<TData, TGuardDataKey extends string, TDataField extends GuardDataKeyField, TDataFnField extends GuardRouteDataFnField<TGuardDataKey>>
  = { [K in TDataFnField | TDataField]: K extends TDataFnField ? (data: TData) => { [D in TGuardDataKey]: TData } : TGuardDataKey };

type GuardRouteDataFactoryReturnType<TData> = {
  withKey: <TGuardDataKey extends string, TDataField extends GuardDataKeyField, TDataFnField extends GuardRouteDataFnField<TGuardDataKey>>(dataKey: TGuardDataKey)
    => GuardRouteDataFactoryWithKeyReturnType<TData, TGuardDataKey, TDataField, TDataFnField>;
};

export function guardRouteDataFactory<TData>(): GuardRouteDataFactoryReturnType<TData> {
  return {
    withKey: <TGuardDataKey extends string, TDataField extends GuardDataKeyField, TDataFnField extends GuardRouteDataFnField<TGuardDataKey>>(dataKey: TGuardDataKey)
      : GuardRouteDataFactoryWithKeyReturnType<TData, TGuardDataKey, TDataField, TDataFnField> => {
      return {
        routeDataKey: dataKey,
        [`${uncapitalize(dataKey)}Data`]: (data: TData) => ({ [dataKey]: data } as { [D in TGuardDataKey]: TData }),
      } as GuardRouteDataFactoryWithKeyReturnType<TData, TGuardDataKey, TDataField, TDataFnField>;
    },
  };
}

export function showForbiddenPage(router: Router, location: Location, visitedUrl: string): void {
  void router.navigate(['/', 'forbidden'], { skipLocationChange: true });
  location.replaceState(visitedUrl);
}
