import { Action } from '@ngrx/store';
import { IrisUserGroupI } from '@iris/common/modules/user-common/models/IrisUserGroup';

export enum UserGroupsActionTypes {
  UpdateUserGroup = '[UserGroup] Update'
}

export class UpdateUserGroup implements Action {
  readonly type = UserGroupsActionTypes.UpdateUserGroup;

  constructor(public userGroup: IrisUserGroupI) {

  }
}

export type UserGroupsAction = UpdateUserGroup;
