export interface IrisFileIconI {
  iconType: string;
  fillType: IrisFileIconFillType;
}

export enum IrisFileIconFillType {
  Lite = 'square',
  Solid = 'solid'
}

export enum FontAwesomeFileIconClass {
  File = 'fa-file-o',
  Audio = 'fa-file-audio-o',
  Video = 'fa-file-video-o',
  Image =  'fa-file-image-o',
  Zip = 'fa-file-zip-o',
  Word = 'fa-file-word-o',
  Excel = 'fa-file-excel-o',
  Pdf = 'fa-file-pdf-o',
  Jpg = 'fa-file-jpg-o',
  Gif = 'fa-file-gif-o',
  Png = 'fa-file-png-o',
  Powerpoint = 'fa-file-powerpoint-o',
  Message = 'fa-file-message-o',
  Svg = 'fa-file-svg-o',
  Txt = 'fa-file-txt-o',
}

export enum IrisFileIconClass {
  File = 'file',
  Audio = 'audio',
  Video = 'video',
  Image = 'image',
  Zip = 'zip',
  Word = 'doc',
  Excel = 'xls',
  Pdf = 'pdf',
  Jpg = 'jpg',
  Gif = 'gif',
  Png = 'png',
  Powerpoint = 'ppt',
  Message = 'msg',
  Svg = 'svg',
  Txt = 'txt',
  IncomingEmail = 'mail-in',
  OutgoingEmail = 'mail-out',
  SentEmail = 'mail-sent',
}

export class IrisFileIcon implements IrisFileIconI {
  iconType: IrisFileIconClass;
  fillType: IrisFileIconFillType = IrisFileIconFillType.Lite;

  private readonly iconsWithoutFillType = [
    IrisFileIconClass.IncomingEmail,
    IrisFileIconClass.OutgoingEmail,
    IrisFileIconClass.SentEmail,
  ];

  static fromType(type: IrisFileIconClass, fillType?: IrisFileIconFillType): IrisFileIcon {
    return new IrisFileIcon(type, fillType);
  }
 
  static fromFontAwesome(type: FontAwesomeFileIconClass, fillType?: IrisFileIconFillType): IrisFileIcon {
    const icon = new IrisFileIcon(null, fillType);
    icon.iconType = icon.fontAwesomeToIris(type);
    return icon;
  }

  constructor(iconType?: IrisFileIconClass, fillType?: IrisFileIconFillType) {
    this.iconType = iconType ?? IrisFileIconClass.File;
    this.fillType = fillType ?? IrisFileIconFillType.Lite;
  }

  get className(): string {
    if (this.iconsWithoutFillType.includes(this.iconType)) { return this.iconType; }
    return `${this.iconType}-${this.fillType}`;
  }

  private fontAwesomeToIris(type: FontAwesomeFileIconClass): IrisFileIconClass {
    switch (type) {
      case FontAwesomeFileIconClass.Audio:
        return IrisFileIconClass.Audio;
      case FontAwesomeFileIconClass.Video:
        return IrisFileIconClass.Video;
      case FontAwesomeFileIconClass.Image:
        return IrisFileIconClass.Image;
      case FontAwesomeFileIconClass.Zip:
        return IrisFileIconClass.Zip;
      case FontAwesomeFileIconClass.Word:
        return IrisFileIconClass.Word;
      case FontAwesomeFileIconClass.Excel:
        return IrisFileIconClass.Excel;
      case FontAwesomeFileIconClass.Pdf:
        return IrisFileIconClass.Pdf;
      case FontAwesomeFileIconClass.Jpg:
        return IrisFileIconClass.Jpg;
      case FontAwesomeFileIconClass.Gif:
        return IrisFileIconClass.Gif;
      case FontAwesomeFileIconClass.Png:
        return IrisFileIconClass.Png;
      case FontAwesomeFileIconClass.Powerpoint:
        return IrisFileIconClass.Powerpoint;
      case FontAwesomeFileIconClass.Message:
        return IrisFileIconClass.Message;
      case FontAwesomeFileIconClass.Txt:
        return IrisFileIconClass.Txt;
      case FontAwesomeFileIconClass.Svg:
        return IrisFileIconClass.Svg;
      case FontAwesomeFileIconClass.File:
      default:
        return IrisFileIconClass.File;
    }
  }
}
