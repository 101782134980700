import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { FieldOptions, IrisUserSelect } from '../field-user-select';

@Component({
  selector: 'iris-user-select-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisUserSelectDropdownItemComponent {
  @ViewChild('template', { static: true }) template: TemplateRef<unknown>;
  options: FieldOptions;

  constructor(
    userSelect: IrisUserSelect) {
    this.options = userSelect.options;
  }
}
