<tr *ngFor="let row of rows; let rowIndex = index" role="row">
  <td (click)="_cellClicked(item)"
      *ngFor="let item of row; let colIndex = index"
      [attr.aria-disabled]="!item.enabled || null"
      [attr.aria-label]="item.ariaLabel"
      [class.mat-datetimepicker-calendar-body-active]="_isActiveCell(rowIndex, colIndex)"
      [class.mat-datetimepicker-calendar-body-disabled]="!item.enabled"
      class="mat-datetimepicker-calendar-body-cell"
      role="button">
    <div [attr.aria-selected]="selectedValue === item.value"
         [class.mat-datetimepicker-calendar-body-selected]="selectedValue === item.value"
         [class.mat-datetimepicker-calendar-body-today]="todayValue === item.value"
         class="mat-datetimepicker-calendar-body-cell-content">
      {{ item.displayValue }}
    </div>
  </td>
</tr>
