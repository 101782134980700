/* eslint-disable */
import { Inject, Optional } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DateTime } from 'luxon';

export abstract class DatetimeAdapterExtended extends LuxonDateAdapter {

  constructor(
    protected _delegate: LuxonDateAdapter,
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string
  ) {
    super(dateLocale);
  }

  abstract getHour(date: DateTime): number;

  abstract getMinute(date: DateTime): number;

  abstract getFirstDateOfMonth(date: DateTime): DateTime;

  abstract isInNextMonth(startDate: DateTime, endDate: DateTime): boolean;

  abstract getHourNames(): string[];

  abstract getMinuteNames(): string[];

  abstract addCalendarHours(date: DateTime, months: number): DateTime;

  abstract addCalendarMinutes(date: DateTime, months: number): DateTime;

  abstract createDatetime(year: number, month: number, date: number, hour: number, minute: number): DateTime;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  getValidDateOrNull(obj: any): DateTime | null {
    return (this.isDateInstance(obj) && this.isValid(obj)) ? obj : null;
  }

  compareDatetime(first: DateTime, second: DateTime): number {
    return this.compareDate(first, second) ||
      this.getHour(first) - this.getHour(second) ||
      this.getMinute(first) - this.getMinute(second) ||
      this.getSeconds(first) - this.getSeconds(second);
  }

  sameDatetime(first: DateTime | null, second: DateTime | null): boolean {
    if (first && second) {
      const firstValid = this.isValid(first);
      const secondValid = this.isValid(second);
      if (firstValid && secondValid) {
        return !this.compareDatetime(first, second);
      }
      return firstValid === secondValid;
    }
    return first === second;
  }

  sameYear(first: DateTime, second: DateTime): boolean {
    return first && second && this.getYear(first) === this.getYear(second);
  }

  sameDay(first: DateTime, second: DateTime): boolean {
    return first && second && this.getDate(first) === this.getDate(second) && this.sameMonthAndYear(first, second);
  }

  sameHour(first: DateTime, second: DateTime): boolean {
    return first && second && this.getHour(first) === this.getHour(second) && this.sameDay(first, second);
  }

  sameMinute(first: DateTime, second: DateTime): boolean {
    return first && second && this.getMinute(first) === this.getMinute(second) && this.sameHour(first, second);
  }

  sameMonthAndYear(first: DateTime | null, second: DateTime | null): boolean {
    if (first && second) {
      const firstValid = this.isValid(first);
      const secondValid = this.isValid(second);
      if (firstValid && secondValid) {
        return !(this.getYear(first) - this.getYear(second) ||
          this.getMonth(first) - this.getMonth(second));
      }
      return firstValid === secondValid;
    }
    return first === second;
  }

  format(date: DateTime, displayFormat: string): string {
    return date.toFormat(displayFormat);
  }

  clampDate(date: DateTime, min?: DateTime | null, max?: DateTime | null): DateTime {
    if (min && this.compareDatetime(date, min) < 0) {
      return min;
    }
    if (max && this.compareDatetime(date, max) > 0) {
      return max;
    }
    return date;
  }

  abstract getNumberOfWeek(date: DateTime): number;
  abstract setDay(date: DateTime, day: number): DateTime;
  abstract setMonth(date: DateTime, month: number): DateTime;
  abstract setYear(date: DateTime, year: number): DateTime;
  abstract setHours(date: DateTime, hours: number): DateTime;
  abstract setMinutes(date: DateTime, minutes: number): DateTime;
  abstract setSeconds(date: DateTime, seconds: number): DateTime;
  abstract getSeconds(date: DateTime): number;
  abstract setTimezone(date: DateTime, timezone: string, keepLocalTime?: boolean): DateTime;
  abstract initDate(date: DateTime, type: string, timezone: string): DateTime;
  abstract toNoon(date: DateTime): DateTime;
  abstract toUTC(date: DateTime): DateTime;
  abstract toUTCString(date: DateTime): string;
  abstract toUTCDate(date: DateTime): Date;
  abstract isValidString(date:  string, format: string): boolean;
  abstract isDatetime(date: unknown): boolean;
  abstract isDate(date:  any): boolean;
  abstract convert(value: DateTime, type: string): string | Date | DateTime;
}
