import { Injectable, TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IrisMFFilterBase } from '../models/IrisMFFilterBase';
import { IrisQueryParams } from '@iris/api-query';
import { IrisQueryParamsFilter } from '@iris/api-query';
import { HttpClient } from '@angular/common/http';
import { IrisQueryParamsBuilder } from '@iris/api-query';
import { ApiUrl, IrisBaseCRUDService } from '@iris/api-query';
import { IrisLocalStorageService } from '../../../services/utils/iris-local-storage.service';
import { IrisModuleFilterI } from '../models/IrisModuleFilter';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';

@Injectable({
  providedIn: 'root',
})
@ApiUrl('/filters')
export class IrisModuleFilterService extends IrisBaseCRUDService<IrisModuleFilterI> {

  private formsTemplate: TemplateRef<any>;
  private readonly formsTemplateSubj: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
  static readonly RECENTLY_FILTER_KEY = 'recentlyFilters';

  constructor(
    httpClient: HttpClient,
    private readonly localStorageService: IrisLocalStorageService,
  ) {
    super(httpClient);
  }

  refreshFormTemplate() {
    if (this.formsTemplate) {
      this.formsTemplateSubj.next(this.formsTemplate);
    }
  }

  setFormTemplateRef(templateRef) {
    this.formsTemplate = templateRef;
    this.formsTemplateSubj.next(templateRef);
  }

  getFormTemplateRef(): Observable<TemplateRef<any>> {
    return this.formsTemplateSubj.asObservable();
  }

  extractFilter(filterMeta: IrisMFFilterBase[], filter: Record<string, any>): IrisQueryParams {
    const res = [];

    if (!!filterMeta) {
      filterMeta.filter(({ type }) => type == 'filter').forEach(p => {
        if (!p.isInFilter(filter)) { return; }
        res.push(p.getFilter(filter));
      });
    }

    const params: IrisQueryParams = new IrisQueryParams();
    params.filter = res.map(r => new IrisQueryParamsFilter().fromObject(r));
    return params;
  }

  extractParams(filterMeta, filter) {
    const res = {};
    if (!isNil(filterMeta)) {
      const urlFilterMeta = filterMeta.filter(p => p.type == 'url');
      if (!isNil(urlFilterMeta) && urlFilterMeta.length) {
        urlFilterMeta.forEach(f => {
          if (!f.isInFilter(filter)) {
            return;
          }
          const filterValue = f.getFilterValue(filter);
          if (!isNil(filterValue)) {
            res[f.fieldName] = [...filterValue];
          }
        });
      }
    }
    return res;
  }

  getUserFilters(moduleName: string): Observable<IrisModuleFilterI[]> {
    const params = new IrisQueryParamsBuilder().filter(
      'moduleName', [moduleName],
    ).toObject();
    return this.httpClient.get<IrisModuleFilterI[]>(this.url(), { params });
  }

  saveFilter(filter: any): Observable<IrisModuleFilterI> {
    if (isNil(filter.id)) {
      return this.add(filter);
    } else {
      return this.update(filter.id, filter);
    }
  }

  getRecentFiltersFromStore(moduleName): { id: number }[] {
    const localFilters = this.localStorageService.getItem(IrisModuleFilterService.RECENTLY_FILTER_KEY) || {};
    return localFilters[moduleName];
  }

  mergeFilters(filter1, filter2): any {
    if (isEmpty(filter2)) { return filter1; }
    if (isEmpty(filter1)) { return filter2; }

    const result = {};
    const keys1 = Object.keys(filter1);
    const keys2 = Object.keys(filter2);

    // remove stuck non-existing filters
    keys1.forEach(key => {
      if (!filter1[key] && filter1[key] !== 0) {
        delete filter1[key];
      }
    });

    keys2.forEach(key => {
      if (!filter2[key] && filter2[key] !== 0) {
        delete filter2[key];
      }
    });

    // merge from filter1 object
    keys1.forEach(key1 => {
      if (keys2.includes(key1)) {
        // merge when filter property is available in both
        result[key1] = [
          ...(Array.isArray(filter1[key1]) ? filter1[key1] : [filter1[key1]]),
          ...(Array.isArray(filter2[key1]) ? filter2[key1] : [filter2[key1]]),
        ];
        result[key1] = result[key1].filter((v) => v !== undefined);
        result[key1] = uniqWith(result[key1], isEqual);
      } else {
        result[key1] = filter1[key1];
      }
    });

    // add remaining from filter2
    keys2.forEach(key2 => {
      if (!keys1.includes(key2)) {
        result[key2] = filter2[key2];
      }
    });

    return result;
  }

  @ApiUrl('~/{filterId}/toggle-pinned')
  public togglePinned(filterId: number): Observable<IrisModuleFilterI> {
    return this.httpClient.post<IrisModuleFilterI>(this.url({ filterId }), null);
  }
}
