export interface IrisPage<TEntity> {
  count: number;
  elements: TEntity[];
}

export function emptyIrisPage<TEntity>(): IrisPage<TEntity> {
  return { count: 0, elements: [] };
}

export function unboundedIrisPage<TEntity>(elements: TEntity[]): IrisPage<TEntity> {
  return { count: undefined, elements: elements ?? [] };
}

export function fullIrisPage<TEntity>(elements: TEntity[]): IrisPage<TEntity> {
  return { count: elements?.length ?? 0, elements: elements ?? [] };
}

export function irisPage<TEntity>(count: number, elements: TEntity[]): IrisPage<TEntity> {
  return { count, elements: elements ?? [] };
}

export const IRIS_PAGE_ONLY_FIELDS: (keyof IrisPage<unknown>)[] = ['count', 'elements'];
