import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { IrisQueryParams, IrisQueryParamsBuilder } from '@iris/api-query';
import { IrisFilterPropertyMeta } from '@iris/modules/module-filters-v2/filter-meta';
import { IrisFilterValues } from '@iris/modules/module-filters-v2/filter-values';
import { MODULE_FILTER_META } from '@iris/modules/module-filters-v2/tokens';
import { fromPairs } from 'lodash';
import { IrisFilterPropertyValueWithMeta } from './filter-property-value-with-meta';
import {
  filter,
  map,
  share,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  isObservable,
  MonoTypeOperatorFunction,
  Observable,
  of,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import { TIMEZONE } from '@iris/common/tokens';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisUserInfoI } from '@iris/common/modules/user-common/models/IrisUserInfo';

@Injectable()
export class IrisActiveFilterService<TMeta extends IrisFilterPropertyMeta = IrisFilterPropertyMeta> implements OnDestroy {
  private readonly _rawFilterValuesSubject = new BehaviorSubject<IrisFilterValues>({});
  private readonly _filterMetasSubject = new ReplaySubject<TMeta[]>(1);
  private readonly _configuring = new BehaviorSubject<boolean>(false);

  readonly filterValues$: Observable<IrisFilterValues>;
  readonly filterMetas$ = this._filterMetasSubject.asObservable();
  readonly filterValuesWithMeta$: Observable<IrisFilterPropertyValueWithMeta[]>;
  readonly filterQueryParams$: Observable<IrisQueryParams>;

  private readonly _filterMetaSubscription: Subscription;

  constructor(
    private readonly userService: IrisUserService,
    @Inject(TIMEZONE) timezone$: Observable<string>,
    @Optional() @Inject(MODULE_FILTER_META) filterMetas: TMeta[] | Observable<TMeta[]>,
  ) {
    if (isObservable(filterMetas)) {
      this._filterMetaSubscription = filterMetas.pipe(
        tap(i => this.setMeta(i)),
      ).subscribe();
    } else if (filterMetas) {
      this.setMeta(filterMetas);
    }

    this.filterValuesWithMeta$ = mergeFilterValuesWithMeta(this._rawFilterValuesSubject, this.filterMetas$)
      .pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false,
        }),
        this._waitWhileConfiguring(),
      );

    this.filterValues$ = extractFilterValues(this.filterValuesWithMeta$)
      .pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false,
        }),
        this._waitWhileConfiguring(),
      );

    this.filterQueryParams$ = createQueryParamsForFilter(this.filterValuesWithMeta$, timezone$, userService.me)
      .pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false,
        }),
        this._waitWhileConfiguring(),
      );
  }

  ngOnDestroy(): void {
    this._rawFilterValuesSubject.complete();
    this._filterMetasSubject.complete();
    this._configuring.complete();
    this._filterMetaSubscription?.unsubscribe();
  }

  setMeta(filterMeta: TMeta[]): void {
    this._filterMetasSubject.next(filterMeta);
  }

  updateValues(values: IrisFilterValues): void {
    this._rawFilterValuesSubject.next(values ?? {});
  }

  resetValues(): void {
    this.updateValues({});
  }

  updateValue(propertyId: string, value: unknown): Observable<void> {
    return this.filterValues$.pipe(
      take(1),
      tap(values => {
        values[propertyId] = value;
        this.updateValues(values);
      }),
      map(() => null),
    );
  }

  deleteValue(propertyId: string): Observable<void> {
    return this.filterValues$.pipe(
      take(1),
      tap(values => {
        delete values[propertyId];
        this.updateValues(values);
      }),
      map(() => null),
    );
  }

  startConfiguring(): void {
    this._configuring.next(true);
  }

  stopConfiguring(): void {
    this._configuring.next(false);
  }

  private _waitWhileConfiguring<T>(): MonoTypeOperatorFunction<T> {
    return source => source
      .pipe(
        switchMap(() => this._configuring
          .pipe(
            filter(configuring => !configuring),
            take(1),
            withLatestFrom(source),
            switchMap(([_configuring, value]) => of(value)),
          ),
        ),
      );
  }
}

function mergeFilterValuesWithMeta<TMeta extends IrisFilterPropertyMeta = IrisFilterPropertyMeta>(
  activeValues$: Observable<IrisFilterValues>,
  filterMeta$: Observable<TMeta[]>,
): Observable<IrisFilterPropertyValueWithMeta[]> {
  return combineLatest([
    activeValues$,
    filterMeta$,
  ]).pipe(
    map(([activeData, filterMeta]) => {
      const activeFieldsIds = Object.keys(activeData);
      const metaForActiveFields = filterMeta.filter(i => activeFieldsIds.includes(i.propertyId));

      const valuesWithMeta = metaForActiveFields
        .map(meta => (<IrisFilterPropertyValueWithMeta>{
          meta,
          value: activeData[meta.propertyId],
        }))
        .filter(({ meta, value }) => !meta.filterValueIsEmpty(value))
      ;

      return valuesWithMeta;
    }),
  );
}

function createQueryParamsForFilter(filterValuesWithMeta: Observable<IrisFilterPropertyValueWithMeta[]>, timezone$: Observable<string>, currentUser: IrisUserInfoI): Observable<IrisQueryParams> {
  return combineLatest([
    timezone$,
    filterValuesWithMeta,
  ])
    .pipe(
      map(([timezone, valuesWithMeta]) => {
        const paramsBuilder = new IrisQueryParamsBuilder();
        valuesWithMeta.forEach(i => i.meta.appendFilterQueryParamsFn(paramsBuilder, i.value, { timezone, currentUser }));
        return paramsBuilder.toStructure();
      }),
    );
}

function extractFilterValues(filterValuesWithMeta: Observable<IrisFilterPropertyValueWithMeta[]>): Observable<IrisFilterValues> {
  return filterValuesWithMeta.pipe(
    map(valuesWithMeta => {
      const pairs = valuesWithMeta.map(({ meta, value }) => [meta.propertyId, value]);
      return fromPairs(pairs);
    }),
  );
}
