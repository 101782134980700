import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Primitive } from '@iris/common/models/Primitive';

export interface IrisChipDropdownOption {
  value: Primitive;
  label: string;
}

@Component({
  selector: 'iris-chip-dropdown',
  templateUrl: './chip-dropdown.component.html',
  styleUrls: ['./chip-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IrisChipDropdownComponent {
  @HostBinding('class.iris-chip-dropdown') irisChipDropdownClass = true;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  @Input() chipTitle: string;
  @Input() headerTemplate: TemplateRef<any>;

  @Output() itemRemoved: EventEmitter<Primitive> = new EventEmitter<Primitive>();

  @Input() options: IrisChipDropdownOption[] = [];

  removeItem(valueToRemove: Primitive) {
    this.options = this.options.filter((item) => item.value !== valueToRemove);
    this.itemRemoved.emit(valueToRemove);
  }
}
