import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { LoadEnabledProjectResourceRoleComplete, LoadEnabledProjectResourceRoles, LoadEnabledResourceRoles, LoadEnabledResourceRolesComplete } from '@iris/common/redux/actions/resource-roles.actions';
import { IrisResourceRolesService } from '@iris/common/services/resource-roles.service';
import { IrisResourceRoleI } from '@iris/common/models/IrisResourceRole';
import { IrisQueryParamsBuilder } from '@iris/api-query';

@Injectable()
export class IrisResourceRolesEffects {
  loadEnabledResourceRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadEnabledResourceRoles),
      switchMap(action => {
        return this.resourceRolesService.getEnabled(action.resourceType).pipe(
          map((resourceRoles: IrisResourceRoleI[]) => LoadEnabledResourceRolesComplete({
            resourceRoles,
            resourceType: action.resourceType,
          })),
          catchError(() => EMPTY),
        );
      }),
      catchError(() => EMPTY),
    ),
  );

  LoadEnabledProjectResourceRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadEnabledProjectResourceRoles),
      switchMap(action => {
        const params = new IrisQueryParamsBuilder()
          .urlParam('allowedPattern', `Project/${action.projectId}`)
          .filter('resourceType', ['Project'])
          .filter('isEnabled', [true])
          .toStructure();
        return this.resourceRolesService.query(params).pipe(
          map((projectRoles: IrisResourceRoleI[]) => LoadEnabledProjectResourceRoleComplete({
            projectRoles,
            projectId: action.projectId,
          })),
          catchError(() => EMPTY),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly resourceRolesService: IrisResourceRolesService,
  ) {}
}
