import { AbstractControl } from '@angular/forms';

export const URL_VALIDATOR_NAME = 'url';

export function urlValidator(control: AbstractControl): Record<string, unknown> | null {
  if (!control.value) { return null; }

  try {
    new URL(control.value);
  } catch {
    return { [URL_VALIDATOR_NAME]: true };
  }

  return null;
}
