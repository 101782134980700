import { ComponentFactoryResolver, Directive, Input, Type, ViewContainerRef } from '@angular/core';

interface IrisAlertRendererInfoI {
  rendererFramework: Type<any>;
  message: string;
}

@Directive({
  selector: '[alertRenderer]',
})
export class IrisAlertRendererDirective {
  @Input('alertRenderer') set rendererInfo(rendererInfo: IrisAlertRendererInfoI) {
    this.createComponent(rendererInfo);
  }

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  createComponent(rendererInfo: IrisAlertRendererInfoI): void {
    const { rendererFramework, message } = rendererInfo;
    if (!!rendererFramework) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(rendererFramework);
      this.viewContainerRef.clear();
      const componentRef = this.viewContainerRef.createComponent(componentFactory);
      componentRef.instance.message = message;
    }
  }
}
