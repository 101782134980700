import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[confirmByKeypress]',
})
export class ConfirmByKeypressDirective {
  @Input() suppressConfirm = false;
  @Input() suppressCancel = false;
  @Input() suppressIfModalOrDropdownOpened = true;
  @Input() allowConfirmIfChildFocused = false;
  @Input() allowCancelIfChildFocused = false;

  @Output() confirmed = new EventEmitter<void>();
  @Output() cancelled = new EventEmitter<void>();

  private readonly mutationObserver: MutationObserver;
  private readonly configMutationObserver: MutationObserverInit = { childList: true, subtree: true, attributes: true };
  private isDropdownOpened = false;

  constructor(
    private readonly elementRef: ElementRef,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.mutationObserver = new MutationObserver(() => {
      setTimeout(() => {
        const dropdown = this.document.querySelector('.mat-autocomplete-panel');
        this.isDropdownOpened = !!dropdown;
      });
    });

    this.mutationObserver.observe(this.hostElement, this.configMutationObserver);
  }

  @HostListener('document:keydown.escape', ['$event.target'])
  cancel(target: HTMLElement): void {
    if (this.suppressCancel) { return; }
    if (this._isTargetChild(target) && !this.allowCancelIfChildFocused) { return; }
    if (this.suppressIfModalOrDropdownOpened && this.isModalOrDropdownOpened) { return; }

    this.cancelled.emit();
  }

  @HostListener('document:keydown.enter', ['$event.target'])
  confirm(target: HTMLElement): void {
    if (this.suppressConfirm) { return; }
    if (this._isTargetChild(target) && !this.allowConfirmIfChildFocused) { return; }
    if (this.suppressIfModalOrDropdownOpened && this.isModalOrDropdownOpened) { return; }

    this.confirmed.emit();
  }

  private get hostElement(): HTMLElement {
    return this.elementRef.nativeElement as HTMLElement;
  }

  private get isModalOrDropdownOpened(): boolean {
    const openedModals = Array.from(this.document.querySelectorAll('.modal'));

    if (!!openedModals.length) {
      const currentModal = openedModals.find(modal => modal.contains(this.hostElement));
      return !currentModal || !!currentModal.nextElementSibling || this.isDropdownOpened;
    }

    return this.isDropdownOpened;
  }

  private _isTargetChild(target: HTMLElement): boolean {
    return this.hostElement.contains(target);
  }
}
