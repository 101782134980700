import { IrisBasePropertyOptions } from './IrisModuleFilterPropertyOptionsParams';
import { Observable, of } from 'rxjs';
import { isFunction } from 'lodash';
import isNil from 'lodash/isNil';

export class IrisModuleFilterPropertyBase {
  fieldName: string;
  type: string;
  options: any;
  formFieldName: string;
  label: string;
  stringifyFn?: any;
  filter: any = null;
  memoizedLabels: {[key: string]: any} = {};

  constructor(fieldName: string, type: string, options: IrisBasePropertyOptions = {}) {
    this.fieldName = fieldName;
    this.type = type;
    this.options = options;
    this.label = this.options.label;
    this.formFieldName = this.options.formFieldName || this.fieldName;
  }

  setFilter(filter: any) {
    this.filter = filter;
  }

  get fieldValue(): any {
    return this.filter[this.formFieldName];
  }

  get fieldItems(): any[] {
    const value = this.fieldValue;
    return Array.isArray(value) ? value : [value];
  }

  get isSplittable(): boolean {
    return !!this.options?.splittable;
  }

  get allowMemoization(): boolean {
    return this.options?.splittable.bulkMode?.allowMemoization;
  }

  memoizeLabel(item: any, label: string): void {
    if (!this.allowMemoization) { return; }
    this.memoizedLabels[item] = label;
  }

  getMemoizedLabel(item: any): string {
    if (!this.allowMemoization) { return null; }
    return this.memoizedLabels?.[item];
  }

  singleModeItemLabel$(item): Observable<string> {
    const itemLabel$ = this.options?.splittable.singleMode?.itemLabel$;
    if (itemLabel$) {
      return isFunction(itemLabel$) ? itemLabel$(item) : itemLabel$;
    }
    return this.filter ? this.getFilterDescription(this.filter) : of(`${this.label}: "${item}"`);
  }

  get bulkModeGroupLabel(): string {
    return `${this.fieldItems.length} ${this.options?.splittable?.bulkMode?.groupLabel}`;
  }

  bulkModeItemLabel$(item): Observable<string> {
    const itemLabel$ = this.options?.splittable?.bulkMode?.itemLabel$;
    if (itemLabel$) {
      return isFunction(itemLabel$) ? itemLabel$(item) : itemLabel$;
    }
    return of(item);
  }

  isInFilter(filter): boolean {
    return !isNil(filter[this.formFieldName]);
  }

  getFilterDescription(filter = this.filter, filterScope = {}): Observable<string> | null {
    return of(`${this.label}: ${filter[this.formFieldName]}`);
  }

  getFilterTooltip(filter = this.filter, filterScope = {}): Observable<string> | null {
    return null;
  }
}
