import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

// The matTooltip directive sets the style 'touch-action: none' to the
// target that prevents scroll on mobile devices.
// This custom config overrides 'touch-action' to 'auto' thus allows
// these elements to be scrollable
@Injectable()
export class CustomGestureConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    const options = {
      touchAction: 'auto',
    };
    return new Hammer(element, options);
  }
}
