import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { FieldOptions, IrisUserSelect } from '../field-user-select';
import { getUserId } from '@iris/common/modules/dynamic-forms/df-prop/properties/user/filter-meta';

@Component({
  selector: 'iris-user-select-input-item',
  templateUrl: './input-item.component.html',
  styleUrls: ['./input-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisUserSelectInputItemComponent {
  @ViewChild('template', { static: true }) template: TemplateRef<unknown>;
  options: FieldOptions;

  constructor(userSelect: IrisUserSelect) {
    this.options = userSelect.options;
  }

  /*
  * When initializing selected users externally, we can sometimes have an array of user IDs.
  * But when we select through the user selector, our array will already hold a user object.
  * To display it correctly in the UI, we only need the user ID, and then under the hood,
  * the iris-user-ws component will already load all the other necessary user data
  * */
  getUserId = getUserId;
}
