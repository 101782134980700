import { Directive, HostListener, ElementRef, Input, Inject } from '@angular/core';
import { WindowRef } from '@iris/common/utils/window.ref';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[inputAllowedCharacters]',
})
export class InputAllowedCharactersDirective {
  @Input() set inputAllowedCharacters(value: string) {
    this.mask = value ;
  }
  mask: string;
  @Input() selectTextOnFocus = false;

  constructor(private readonly el: ElementRef,
              private readonly window: WindowRef,
              @Inject(DOCUMENT) private readonly document: Document) {
  }

  @HostListener('keypress', ['$event'])
  onKeypress(event: KeyboardEvent): boolean {
    return this.validate(event.key);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent):boolean {
    return this.validate(event.clipboardData.getData('text/plain'));
  }

  @HostListener('focus', ['$event.target'])
  onFocus(target: HTMLInputElement): void {
    if(this.selectTextOnFocus) {
      target.select();
    }
  }

  validate(value: string): boolean {
    return new RegExp(this.mask).test(value);
  }
}
