import { ChangeDetectionStrategy, Component, ContentChild, Input, Output, TemplateRef, EventEmitter } from '@angular/core';
import { IrisHeaderSearchResultCardPrimaryIconDirective } from '../primary-icon.directive';
import { IrisHeaderSearchResultCardSecondaryIconDirective } from '../secondary-icon.directive';

export interface DataItem extends Link {
  text: string;
}

export interface SecondaryDataItem {
  items: DataItem[];
  label?: string;
  labelIconClass?: string;
  alignRight?: boolean;
}

export interface Link {
  href?: string;
  routerLink?: string | unknown[];
  routerLinkQueryParams?: Record<string, unknown>;
}

@Component({
  selector: 'iris-header-search-result-card-template',
  templateUrl: './card-template.component.html',
  styleUrls: ['./card-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisHeaderSearchResultCardTemplateComponent {
  @Input() titleData: DataItem;
  @Input() secondaryData: SecondaryDataItem[];
  @Input() cardLink: Link;

  @Output() closeClick = new EventEmitter<boolean>();

  toggleDropdown(): void {
    this.closeClick.emit();
  }

  @ContentChild(IrisHeaderSearchResultCardPrimaryIconDirective, { read: TemplateRef })
  primaryIconContentTemplate: TemplateRef<unknown>;

  @ContentChild(IrisHeaderSearchResultCardSecondaryIconDirective, { read: TemplateRef })
  secondaryIconContentTemplate: TemplateRef<unknown>;
}
