import SockJS from 'sockjs-client';
import { inject, Injectable } from '@angular/core';
import { WatchedRxStomp, watchedStompServiceFactory } from '@iris/common/services/websockets/watched-rx-stomp';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';

@Injectable()
export class BackendStompService extends WatchedRxStomp {
  constructor() {
    super();
  }
}

export function backendStompServiceFactory(): BackendStompService {
  const envService = inject(IrisEnvironmentService);
  const authFacade = inject(AuthFacade);
  return watchedStompServiceFactory(
    BackendStompService,
    authFacade,
    (authToken: string) => () => new SockJS(`${envService.env.apiUrl}/websocket?token=${authToken}`, null, { transports: ['websocket'] }),
  );
}
