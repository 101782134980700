import { ValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { DIVISIBLE_BY_VALIDATOR_NAME } from '@iris/common/modules/fields/validation/validators/divisible-by.validator';

@Injectable()
export class DivisibleByValidationMessageResolver implements ValidationMessageResolver {
  readonly errorCode = DIVISIBLE_BY_VALIDATOR_NAME;

  constructor(
    private readonly translateService: TranslateService,
  ) { }

  getMessage(error?: { step: number }): string {
    return this.translateService.instant('label.validation.divisibleBy', { step: error?.step });
  }
}
