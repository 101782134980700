import { StaticProvider } from '@angular/core';
import { SEARCH_ENGINE, templateFromComponentProvider } from '@iris/common/modules/fields/ng-select-field';
import { IrisNgSelectFieldShownElementsCountComponent } from '@iris/common/modules/fields/ng-select-field/common/components';
import { BIND_LABEL, BIND_VALUE, DROPDOWN_FOOTER_TEMPLATE } from '@iris/common/modules/fields/ng-select-field/properties-tokens';
import { IrisCompanySelectEngine } from './company-select.engine';

export const COMPANY_SELECT_PROVIDERS: StaticProvider[] = [
  {
    provide: IrisCompanySelectEngine,
    useClass: IrisCompanySelectEngine,
  },
  {
    provide: SEARCH_ENGINE,
    useExisting: IrisCompanySelectEngine,
  },
  {
    provide: DROPDOWN_FOOTER_TEMPLATE,
    ...templateFromComponentProvider(IrisNgSelectFieldShownElementsCountComponent, 'template'),
  },
  {
    provide: BIND_LABEL,
    useValue: 'nameTranslated',
  },
  {
    provide: BIND_VALUE,
    useValue: 'id',
  },
];
