import { Injectable } from '@angular/core';
import { ApiUrl, IrisBaseCRUDService, IrisQueryParamsBuilder } from '@iris/api-query';
import { IrisDeviceMonitoringI, IrisDeviceI } from '../../models/IrisDevice';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IrisDeviceCostTypeI } from '@iris/common/models/IrisDeviceCostType';
import { IrisQueryParams } from '@iris/api-query';
import { TranslateService } from '@ngx-translate/core';
import { IrisDeviceNaviConfigI } from '@iris/common/models/IrisDeviceNaviConfig';
import { EntityPageResponse } from '@iris/common/models/IrisServerPage';
import { IrisFilterPropertyMeta, serverEntityFilterMeta, textFilterMeta } from '@iris/modules/module-filters-v2/filter-meta';
import { numberFilterMeta } from '@iris/modules/module-filters-v2/custom-filter-meta/custom-number-meta';
import { IrisDeviceTypeService } from './device-type.service';
import { appendPureValueQueryParams } from '@iris/modules/module-filters-v2/query-params';
import { defaultChipRenderingOptions } from '@iris/modules/module-filters-v2/chips/rendering-options';
import { filterValueArrayIsEmpty, filterValueCommonIsEmpty } from '@iris/modules/module-filters-v2/utils';
import { IrisTagI } from '@iris/common/models/IrisTag';
import { DeviceStatusI } from '@iris/common/models/IrisDataSeriesValue';
import { IrisTranslatePipe } from '@iris/common/modules/pipes-common/pipes/translate/translate.pipe';
import { RreferenceDataseriesI } from '@iris/common/models/IrisDataSeries';

interface DevicesWithAvailabilityAndDistance {
  availabilityInMinutes: number;
  device: IrisDeviceI;
  relativeKmDistance: number;
}

interface DeviceCumulativeOperatingHoursI {
  datetime: Date;
  hour: number;
}

interface DeviceEngineStatusI {
  datetime: Date;
  engineNumber: number;
  running: boolean;
}

interface DeviceFuelUsageI {
  datetime: Date;
  fuelConsumed: number;
  fuelUnits: string;
}

interface DeviceLocationI {
  datetime: Date;
  latitude: number;
  longitude: number;
}

export interface DeviceAEMPDataI {
  cumulativeOperatingHours: DeviceCumulativeOperatingHoursI;
  engineStatus: DeviceEngineStatusI;
  fuelUsed: DeviceFuelUsageI;
  location: DeviceLocationI;
}

@Injectable({
  providedIn: 'root',
})
@ApiUrl('/devices')
export class IrisDevicesService extends IrisBaseCRUDService<IrisDeviceI> {
  deviceShieldTypes = ['Gripper', 'Single Shield', 'Hydro Shield', 'Earth Pressure Shield',
    'DoubleShield', 'Stabilizer', 'UNDEFINED'].map(d => { return { id: d, name: d }; });

  private readonly _filterMeta: IrisFilterPropertyMeta[];

  constructor(
    httpClient: HttpClient,
    private readonly translate: TranslateService,
    private readonly deviceTypeService: IrisDeviceTypeService,
    private readonly irisTranslatePipe: IrisTranslatePipe,
  ) {
    super(httpClient);

    this._filterMeta = [
      numberFilterMeta('id', 'label.Id', 'id'),
      textFilterMeta('name', 'label.Name', 'name', false, true),
      textFilterMeta('machineId', 'label.MachineId', 'machineId', false, true),
      serverEntityFilterMeta<number>(this.translate.currentLang, 'deviceTypeIds', 'label.Type', (id, params) => deviceTypeService.get(id, params), 'deviceTypeId'),
      textFilterMeta('registrationNumber', 'label.RegistrationNumber', 'registrationNumber', false, true),
      {
        propertyId: 'tags',
        label: 'label.Tags',
        appendFilterQueryParamsFn: (builder: IrisQueryParamsBuilder, tags: IrisTagI[]) => {
          return builder.urlParam('tags', tags.map(i => i.id));
        },
        chipRenderingOptions: defaultChipRenderingOptions<IrisTagI>(tag => {
          return this.irisTranslatePipe.transform(tag.name, tag.translations)?.name || tag.nameTranslated || tag.name;
        }),
        filterValueIsEmpty: filterValueArrayIsEmpty,
      },
      {
        propertyId: 'isActive',
        label: 'label.Active',
        appendFilterQueryParamsFn: appendPureValueQueryParams('isActive'),
        chipRenderingOptions: defaultChipRenderingOptions<boolean>(value => translate.instant(value ? 'label.Active' : 'label.NotActive')),
        filterValueIsEmpty: filterValueCommonIsEmpty,
      },
      textFilterMeta('model', 'label.Model', 'model', false, true),
      textFilterMeta('serialNumber', 'label.SerialNumber', 'serialNumber', false, true),
    ];
  }

  public getFilterMeta(): IrisFilterPropertyMeta[] {
    return this._filterMeta;
  }

  public getAllDevices(): Observable<IrisDeviceI[]> {
    return this.getAll();
  }

  public getDevices(params: IrisQueryParams): Observable<IrisDeviceI[]> {
    return this.httpClient.get<IrisDeviceI[]>(this.url(), { params: params.toObject() });
  }

  public availableShieldTypes(): { id: string; name: string }[] {
    return this.deviceShieldTypes;
  }

  public updateDevice(device: IrisDeviceI): Observable<IrisDeviceI> {
    return this.update(device.id, device);
  }

  public addDevice(device: IrisDeviceI): Observable<IrisDeviceI> {
    return this.add(device);
  }

  public getDevice(deviceId: number | string): Observable<IrisDeviceI> {
    return this.getById(+deviceId);
  }

  @ApiUrl('~/v2')
  public getDevicesV2(params = new IrisQueryParams()): Observable<EntityPageResponse<IrisDeviceI>> {
    return this.httpClient.get<EntityPageResponse<IrisDeviceI>>(this.url(), { params: params.toObject() });
  }

  @ApiUrl('~/cost/types')
  public getCostTypes(params?: IrisQueryParams): Observable<IrisDeviceCostTypeI[]> {
    return this.httpClient.get<IrisDeviceCostTypeI[]>(this.url(), { params: params?.toObject() });
  }

  @ApiUrl('/devices/search')
  public searchDevices(params: IrisQueryParams): Observable<IrisDeviceI[]> {
    return this.httpClient.get<IrisDeviceI[]>(this.url(), { params: params.toObject() });
  }

  @ApiUrl('/tunneling/devices/{id}/navigation-configuration')
  public getDeviceNaviConfig(id: number): Observable<IrisDeviceNaviConfigI> {
    return this.httpClient.get<IrisDeviceNaviConfigI>(this.url({ id }));
  }

  public getDeviceUrl(deviceId: number): string {
    return `/ui/ui2/devices/${deviceId}/view`;
  }

  @ApiUrl('/device-data/devices/{id}/status')
  public getStatus$(id: number, params: IrisQueryParams): Observable<DeviceStatusI>  {
    return this.httpClient.get<DeviceStatusI>(this.url({ id }), { params: params.toObject() });
  }

  @ApiUrl('/device-data/devices/{id}/monitoring')
  public getMonitoring(id: number): Observable<IrisDeviceMonitoringI>  {
    return this.httpClient.get<IrisDeviceMonitoringI>(this.url({ id }));
  }

  @ApiUrl('~/ordered-by-availability-and-distance')
  public getDevicesWithAvailability(params = new IrisQueryParams()): Observable<EntityPageResponse<DevicesWithAvailabilityAndDistance>> {
    return this.httpClient.get<EntityPageResponse<DevicesWithAvailabilityAndDistance>>(this.url(), { params: params.toObject() });
  }

  @ApiUrl('/tunneling/devices/{id}/reference-dataseries')
  public getReferenceSeries(id: number, params = new IrisQueryParamsBuilder()): Observable<RreferenceDataseriesI> {
    return this.httpClient.get<RreferenceDataseriesI>(this.url({ id }), { params: params.toObject() });
  }

  @ApiUrl('/machines-data/{iris@instanceId}/aemp/{deviceId}')
  public getDeviceAEMPData(deviceId: number): Observable<DeviceAEMPDataI> {
    return this.httpClient.get<DeviceAEMPDataI>(this.url({ deviceId }));
  }
}
