import { TemplateRef } from '@angular/core';

export interface ColumnsI {
  id: ColumnsIds;
  value: string;
  cellTemplate?: TemplateRef<unknown>;
}

export enum ColumnsIds {
  Name = 'name',
  ProjectLeader = 'responsibleUserId',
  From = 'from',
  To = 'to',
  WarrantyStart = 'warrantyStart',
  WarrantyEnd = 'warrantyEnd',
  Progress = 'progress',
  Country = 'country',
  StrathekNumber = 'strathekNumber',
  Color = 'color',
  OrderValue = 'orderValue',
  ProjectId = 'id',
  UB = 'division',
  DI = 'subDivision',
  BE = 'businessUnit',
  NR = 'group',
  LD = 'ld',
  FCO = 'fco',
  KOST = 'kost',
}
