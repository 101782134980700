import { NgModule } from '@angular/core';
import { IrisNgSelectFieldModule } from '@iris/common/modules/fields/ng-select-field';
import { NgSelectModule } from '@natlex/ng-select';
import { IrisTimezoneSelectDirective } from './timezone-select.directive';

@NgModule({
  imports: [
    NgSelectModule,
    IrisNgSelectFieldModule,
  ],
  declarations: [
    IrisTimezoneSelectDirective,
  ],
  exports: [
    IrisTimezoneSelectDirective,
    IrisNgSelectFieldModule,
    NgSelectModule,
  ],
})
export class IrisTimezoneSelectModule {
}
