<mat-chip-option
  class="clickable"
  [matMenuTriggerFor]="menu"
>
  {{ chipTitle }}
  <i class="far fa-angle-down ms-2"></i>
</mat-chip-option>

<mat-menu #menu="matMenu"
          xPosition="after"
          class="iris-chip-dropdown-menu"
>
  <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>

  <mat-divider *ngIf="headerTemplate"></mat-divider>

  <div *ngFor="let option of options" mat-menu-item clickStopPropagation>
    <div class="flex-grow-1">{{ option.label }}</div>
    <i class="far fa-times" clickStopPropagation (click)="removeItem(option.value);"></i>
  </div>

</mat-menu>


