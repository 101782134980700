import { interval as observableInterval, timer as observableTimer } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { IrisProtocolService } from '../../../../../common/services/protocol-service.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IrisModalBase } from '@iris/common/utils/iris-modal.base';

@Component({
  selector: 'iris-notifications-unlock-modal',
  styleUrls: ['./notifications-unlock-modal.component.scss'],
  templateUrl: './notifications-unlock-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisNotificationsUnlockModalComponent extends IrisModalBase implements OnInit, OnDestroy {

  user: any = {};
  protocolId: any;
  countDown = 6 * 60;
  private countdownObservable$;

  constructor(private readonly protocolService: IrisProtocolService,
    readonly dialogRef: MatDialogRef<IrisNotificationsUnlockModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      user: any;
      protocolId: any;
    },
  ) {
    super(dialogRef);
    this.user = data.user ?? {};
    this.protocolId = data.protocolId;
  }

  ngOnInit(): void {

    const unlockAfterTimerObservable$ = observableTimer(6 * 60 * 1000).pipe(
      tap(() => this.unlock()));

    this.countdownObservable$ = observableInterval(1000).pipe(
      map(() => this.countDown--),
      takeUntil(unlockAfterTimerObservable$) ).subscribe();

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.countdownObservable$) {
      this.countdownObservable$.unsubscribe();
    }
  }

  public unlock(): void {
    this.protocolService.unlock(this.protocolId, this.user.id).pipe(
      tap(() => this.dialogRef.close()),
    ).subscribe();
  }

  public reject(): void {
    this.protocolService.rejectUnlock(this.protocolId, this.user.id).pipe(
      tap(() => this.dialogRef.close()),
    ).subscribe();
  }

}
