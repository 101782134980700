import { Pipe, PipeTransform } from '@angular/core';
import { IrisFilesService } from '@iris/common/modules/dms/services/files.service';

@Pipe({
  name: 'dmsFilePreview',
})
export class IrisDmsFilePreviewPipe implements PipeTransform {

  constructor(private readonly fileService: IrisFilesService) {}

  transform(fileId: string): string {
    if (!fileId) { return null; }

    return this.fileService.getFilePreviewUrl(fileId);
  }
}
