import { API_URL_ENCODED_PARAMETER_SYMBOL } from './features/api-client/api-url.constant';

export class ApiQueryHelper {
  static IRIS_ACCESS_TOKEN_HEADER = 'Authorization';

  static resolveUrlPlaceholders(url: string, params: Record<string, unknown>, aliasMappings?: Record<string, string>): string {
    const placeholders = [...new Set(url.match(/{([^}]+)}/g))];
    placeholders.forEach(pl => {
      let alias = pl.substring(1, pl.length - 1);
      const toBeEncoded = alias.startsWith(API_URL_ENCODED_PARAMETER_SYMBOL);
      if (toBeEncoded) { alias = alias.substring(1, alias.length); }
      const aliasMapping = (aliasMappings || {})[alias] || alias;
      const replaceValue = params[aliasMapping] ?? '';
      url = url.replace(pl, toBeEncoded ? encodeURIComponent(replaceValue as string | number | boolean) : String(replaceValue));
    });
    url = url.replace(/[/]+$/g, '');
    return url;
  }

  static concatUrl(a: string, b: string): string {
    return `${a.replace(new RegExp('/+$'), '')}/${b.replace(new RegExp('^/+'), '')}`;
  }

  static microserviceRedirectUrl(url: string, redirectMicroservices: string[], redirectMicroservicesUrl: {name: string; redirectUrl: string}[]): string {
    const match = redirectMicroservicesUrl?.find(t => (url.startsWith(`${t.name}/`) || url.startsWith(`/${t.name}/`)) && redirectMicroservices?.includes(t.name));
    return match?.redirectUrl;
  }
}
