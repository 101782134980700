import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IrisProjectSelectEngine } from '@iris/common/modules/fields/project-select/project-select.engine';

@Component({
  selector: 'iris-project-select-dropdown-header',
  templateUrl: './dropdown-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisProjectSelectDropdownHeaderComponent {
  @ViewChild('template', { static: true }) template: TemplateRef<unknown>;

  constructor(
    readonly engine: IrisProjectSelectEngine,
  ) {
  }

  onToggle(event: MatSlideToggleChange): void {
    this.engine.setIncludeCompletedProjectsInSearch(event.checked);
  }
}
