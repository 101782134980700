import { NgModule } from '@angular/core';
import { IrisCommonModule } from '../../../common/common.module';
import { IrisUserAvatarComponent } from '@iris/common/modules/user-common/modules/user-avatar/components/user-avatar.component';

@NgModule({
  imports: [
    IrisCommonModule,
  ],
  declarations: [IrisUserAvatarComponent],
  exports: [IrisUserAvatarComponent],
})
export class IrisUserAvatarModule {
}
