import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IrisSelectProjectModalComponent } from './select-project-modal/select-project-modal.component';
import { IrisProjectSelectModule, IrisUserSelectModule } from '@iris/common/modules/fields';
import { IrisModalContentModule } from '@iris/common/modules/iris-modal-content/iris-modal-content.module';
import { TranslateModule } from '@ngx-translate/core';
import { IrisCommonDirectivesModule } from '@iris/common/modules/directives-common/common-directives.module';
import { ReactiveFormsModule } from '@angular/forms';
import { IrisSelectUserModalComponent } from './select-user-modal/select-user-modal.component';

@NgModule({
  declarations: [
    IrisSelectProjectModalComponent,
    IrisSelectUserModalComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,

    IrisProjectSelectModule,
    IrisModalContentModule,
    IrisCommonDirectivesModule,
    IrisUserSelectModule,
  ],
})
export class IrisEmailsModalsModule { }
