import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IrisModalContentModule } from '@iris/common/modules/iris-modal-content/iris-modal-content.module';
import { IrisCommonPipesModule } from '@iris/common/modules/pipes-common/common-pipes.module';
import { IrisModalBase } from '@iris/common/utils/iris-modal.base';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'imprint-info-modal',
  standalone: true,
  imports: [
    IrisModalContentModule,
    TranslateModule,
    IrisCommonPipesModule,
  ],
  templateUrl: './imprint-info-modal.component.html',
  styleUrl: './imprint-info-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImprintInfoModalComponent extends IrisModalBase implements OnDestroy {
  path: string;
  userLocale = 'en';

  constructor(
    private readonly translate: TranslateService,
    public readonly dialogRef: MatDialogRef<ImprintInfoModalComponent>,
  ) {
    super(dialogRef);
    if (this.translate.currentLang === 'de') {
      this.userLocale = 'de';
    }
    this.path = 'assets/legal/imprint-' + this.userLocale + '.html';
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
