import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'iris-fill-loader',
  templateUrl: './fill-loader.component.html',
  styleUrls: ['./fill-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisFillLoaderComponent {
  @Input() text: string;
}
