<iris-chip-dropdown
  [chipTitle]="tag.meta.bulkModeGroupLabel"
  [headerTemplate]="chipMenuHeader"
  [options]="dropdownOptions"
  (itemRemoved)="removeItem($event)"
>
</iris-chip-dropdown>

<ng-template #chipMenuHeader>
  <div
    *ngFor="let headerItem of chipMenuHeaderItems"
    mat-menu-item
    clickStopPropagation
    class="d-flex align-items-center"
    (click)="headerItem.onclick()"
  >
    <span class="flex-grow-1">{{ headerItem.label | translate }}</span>
    <i class="far" [class]="headerItem.iconClass"></i>
  </div>
</ng-template>
