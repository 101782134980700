import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUrl } from '../features/api-client/api-url.decorator';
import { IrisApiClient } from '../features/api-client/iris-api-client';
import { IrisQueryParams } from '../features/query-params/iris-query-params';

export abstract class IrisBaseCRUDService<T> extends IrisApiClient<T> {

  protected constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getAll(params?: IrisQueryParams): Observable<T[]> {
    return this.queryCore({}, params);
  }

  @ApiUrl('~/{id}')
  public getById(id: number, params: IrisQueryParams = new IrisQueryParams()): Observable<T> {
    return this.getCore({ id }, params);
  }

  public add(entity: T): Observable<T> {
    return this.saveCore(entity);
  }

  @ApiUrl('~/{id}')
  public update(id: number, entity: T): Observable<T> {
    return this.saveCore(entity, { id });
  }

  @ApiUrl('~/{id}')
  public delete(id: number): Observable<T> {
    return this.deleteCore({ id });
  }
}
