import { Injectable } from '@angular/core';
import { ApiUrl, IrisApiClient, IrisQueryParams } from '@iris/api-query';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface GlobalNotificationI {
  /**
   * readonly because they're not used
   */
  readonly instanceId?: string;
  readonly title?: string;
  readonly titleTranslations?: Record<string, unknown>;

  id?: number;
  message: string;
  messageTranslations?: Record<string, unknown>;
  active: boolean;
}

@Injectable({ providedIn: 'root' })
@ApiUrl('/notifications/{iris@instanceId}/global')
export class IrisGlobalNotificationService extends IrisApiClient<GlobalNotificationI> {

  notificationSubject = new BehaviorSubject<GlobalNotificationI>(null);
  notification$ = this.notificationSubject.asObservable();

  constructor(
    protected readonly httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  query(queryParams?: IrisQueryParams): Observable<GlobalNotificationI[]> {
    return super.queryCore(queryParams);
  }

  post(notification: GlobalNotificationI): Observable<GlobalNotificationI> {
    return super.saveCore(notification).pipe(
      tap(() => { this.notificationSubject.next(notification); }),
    );
  }

  @ApiUrl(`~/active`)
  getActive(): Observable<GlobalNotificationI> {
    return this.httpClient.get<GlobalNotificationI>(this.url()).pipe(
      tap((notification) => { this.notificationSubject.next(notification); }),
    );
  }

}
