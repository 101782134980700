import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IrisInlineLoaderComponent } from './inline-loader.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    IrisInlineLoaderComponent,
  ],
  exports: [
    IrisInlineLoaderComponent,
  ],
})
export class IrisInlineLoaderComponentModule { }
