import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { IrisLanguageI } from '../../../../models/IrisLanguage';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IrisModalBase } from '@iris/common/utils/iris-modal.base';

export interface IrisFieldTextTranslationsModalDataI {
  languages: IrisLanguageI[];
  translatedFieldName: string;
  translations: unknown;
}
@Component({
  selector: 'iris-field-text-translations-modal',
  styleUrls: ['./field-text-translations-modal.component.scss'],
  templateUrl: './field-text-translations-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisFieldTextTranslationsModalComponent extends IrisModalBase {

  languages: IrisLanguageI[] = [];
  translatedFieldName: string;
  set translations(translations: unknown) {
    if (this.translatedFieldName) {
      const translationsModalObject = {};
      for (const languageKey of Object.keys(translations)) {
        translationsModalObject[languageKey] = translations[languageKey][this.translatedFieldName];
      }
      this._translations = Object.assign({}, translationsModalObject);
    } else {
      this._translations = Object.assign({}, translations);
    }
  }

  get translations(): unknown {
    return this._translations;
  }

  _translations: unknown = {};

  @Output() onSetTranslations: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor(
    public readonly dialogRef: MatDialogRef<IrisFieldTextTranslationsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IrisFieldTextTranslationsModalDataI,
  ) {
    super(dialogRef);
    this.languages = data.languages ?? [];
    this.translatedFieldName = data.translatedFieldName ?? '';
    this.translations = data.translations ?? {};
  }

  isNullOrEmpty(str: string): boolean {
    return !str || !str.trim();
  }

  accept(): void {
    // delete empty properties
    Object.keys(this.translations).forEach((key) => {
      if (this.isNullOrEmpty(this.translations[key])) {
        delete this.translations[key];
      }
    });

    if (this.translatedFieldName) {
      const translationsResult = {};
      for (const languageKey of Object.keys(this.translations)) {
        translationsResult[languageKey] = { [this.translatedFieldName]: this.translations[languageKey] };
      }
      this.onSetTranslations.emit(translationsResult);
    } else {
      this.onSetTranslations.emit(this.translations);
    }

    this.closeWithoutResult();
  }

}
