<div class="iris-header-settings" *ngIf="menu?.length">
  <div class="nav-item"
       [class.show]="menuDropdownTrigger.menuOpen"
       data-test="header-settings-button">
    <button class="btn btn-link nav-link"
            #menuDropdownTrigger="matMenuTrigger"
            [matMenuTriggerFor]="settingsMenu"
            [matTooltip]="'label.Settings' | translate">
      <i class="fas fa-lg fa-fw fa-cog"></i>
    </button>
    <mat-menu class="iris-dropdown-menu with-icon pt-2 pb-2"
              data-test="header-settings-menu"
              #settingsMenu="matMenu">
      <ul class="list-group">
        <li *ngFor="let item of menu; let first = first;"
            [ngClass]="{ 'dropdown-header': !item.url, 'mt-2': !first && !item.url }"
            class="level-{{ item.level }} mb-0"
            [attr.data-test]="item.dataTestAttr ? ('header-settings-' + item.dataTestAttr) : null">
          <span *ngIf="!item.url; else itemLink">{{ item.title }}</span>

          <ng-template #itemLink>
            <a class="dropdown-item"
              *ngIf="!getUi2SubPathOrNull(item.url); else routerLink"
              [href]="item.url">
              <span class="item-title text-ellipsis">{{ item.title }}</span>
              <span class="item-hotkey" *ngIf="item.hotkey">{{ item.hotkey }}</span>
            </a>
          </ng-template>

          <ng-template #routerLink>
            <a class="dropdown-item" [routerLink]="[getUi2SubPathOrNull(item.url)]">
              <span class="item-title text-ellipsis">{{ item.title }}</span>
              <span class="item-hotkey" *ngIf="item.hotkey">{{ item.hotkey }}</span>
            </a>
          </ng-template>
        </li>
      </ul>
    </mat-menu>
  </div>
</div>
