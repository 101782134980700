import { Injectable } from '@angular/core';
import { IrisQueryParams, IrisQueryParamsBuilder } from '@iris/api-query';
import { TranslateService } from '@ngx-translate/core';
import { IrisProjectI } from '@iris/common/models';
import { emptyIrisPage, IrisPage } from '@iris/common/models/page';
import { IrisNgSelectFieldSearchEngine } from '@iris/common/modules/fields/ng-select-field';
import { IrisProjectGroup } from './models/project-group';
import { IrisProjectsService } from '@iris/common/services/projects.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, startWith } from 'rxjs/operators';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { BindNgSelectEvent } from '@iris/common/modules/fields/ng-select-field/decorators';
import { handleInfiniteScroll$, handleSearch$, handleTypeahead$, SearchContext, searchProcessor } from '@iris/common/modules/fields/ng-select-field/search-processing';

export interface FieldOptions {
  showCostCenter: boolean;
  hideRecentProjects: boolean;
}

interface SearchOptions {
  includeCompletedProjectsInSearch: boolean;
  searchOnlyInProjects: number[] | null;
  excludeProjects: number[] | null;
}

type ProjectSearchContext<TItem = IrisProjectGroup> = SearchContext<TItem> & { searchOptions?: SearchOptions };

const PAGE_SIZE = 10;
const ONLY_FIELDS: (keyof IrisProjectI | keyof IrisPage<unknown>)[] = ['id', 'name', 'nameTranslated', 'costCenter', 'count', 'elements'];

@Injectable()
export class IrisProjectSelectEngine implements IrisNgSelectFieldSearchEngine<IrisProjectGroup, number> {
  readonly typeahead = new BehaviorSubject('');

  private readonly loadingSubject = new Subject<boolean>();
  readonly loading$ = this.loadingSubject.asObservable();

  @BindNgSelectEvent('scrollToEnd')
  private readonly scrollToEndSubject = new Subject<void>();

  @BindNgSelectEvent('openEvent')
  private readonly openEventSubject = new Subject<void>();

  readonly options: FieldOptions = {
    showCostCenter: true,
    hideRecentProjects: false,
  };

  private readonly includeCompletedProjectsInSearchSubject = new BehaviorSubject<boolean>(false);
  readonly includeCompletedProjectsInSearch$ = this.includeCompletedProjectsInSearchSubject.asObservable();
  readonly setIncludeCompletedProjectsInSearch = (value: boolean): void => this.includeCompletedProjectsInSearchSubject.next(value);

  private readonly searchOnlyInProjectsSubject = new BehaviorSubject<IrisProjectI['id'][]>(null);
  readonly searchOnlyInProjects = (projectsIds: IrisProjectI['id'][]): void => this.searchOnlyInProjectsSubject.next(projectsIds);

  private readonly excludeProjectsSubject = new BehaviorSubject<IrisProjectI['id'][]>(null);
  readonly excludeProjects = (projectsIds: number[] | null): void => this.excludeProjectsSubject.next(projectsIds);

  readonly needScrollToTop = (prev: IrisProjectGroup[], curr: IrisProjectGroup[]): boolean => {
    const prevAll = prev?.find(x => x.code === 'ALL');
    const currAll = curr?.find(x => x.code === 'ALL');
    return prevAll?.projects?.length > currAll?.projects?.length;
  };

  readonly result$ = searchProcessor<IrisProjectGroup, ProjectSearchContext>(
    null,
    ctx => handleTypeahead$(ctx, this.typeahead),
    ctx => this.handleSearchOptions$(ctx),
    ctx => this.handleSearch$(ctx),
  );

  constructor(
    private readonly translate: TranslateService,
    private readonly projectsService: IrisProjectsService,
    private readonly globalSandbox: IrisGlobalSandbox,
  ) { }

  private handleSearchOptions$(ctx: ProjectSearchContext): Observable<ProjectSearchContext> {
    return combineLatest([
      this.includeCompletedProjectsInSearchSubject,
      this.searchOnlyInProjectsSubject,
      this.excludeProjectsSubject,
    ]).pipe(
      map(([includeCompletedProjectsInSearch, searchOnlyInProjects, excludeProjects]) => ({
        ...ctx ?? {},
        searchOptions: {
          includeCompletedProjectsInSearch,
          searchOnlyInProjects,
          excludeProjects,
        },
      })),
    );
  }

  private handleSearch$(ctx: ProjectSearchContext): Observable<ProjectSearchContext> {
    return combineLatest([
      searchProcessor<IrisProjectI, ProjectSearchContext<IrisProjectI>>(
        { term: ctx.term, searchOptions: ctx.searchOptions },
        ctxProject => this.openEventSubject.pipe(startWith(null), map(() => ctxProject)),
        ctxProject => handleSearch$(ctxProject, term => this.searchRecentProjects$(term, ctxProject.searchOptions)),
      ),
      searchProcessor<IrisProjectI, ProjectSearchContext<IrisProjectI>>(
        { term: ctx.term, searchOptions: ctx.searchOptions },
        ctxProject => handleSearch$(ctxProject, term => this.searchProjects$(term, ctxProject.searchOptions)),
        ctxProject => handleInfiniteScroll$(ctxProject, this.scrollToEndSubject, (term, offset) => this.searchProjects$(term, ctxProject.searchOptions, offset)),
      ),
    ]).pipe(
      map(([recentProjects, projects]) => ({
        ...ctx ?? {},
        search: {
          count: projects?.count ?? 0,
          elements: [
            ...this.projectsGroup(recentProjects.elements, true),
            ...this.projectsGroup(projects.elements),
          ],
        },
      })),
    );
  }

  private projectsGroup(projects: IrisProjectI[], isRecent = false): IrisProjectGroup[] {
    return projects?.length ? [{
      code: isRecent ? 'RECENT' : 'ALL',
      name: this.translate.instant(isRecent ? 'label.RecentlyViewed' : 'label.AllProjects'),
      projects: projects.map(project => ({
        ...project,
        nameTranslated: project.nameTranslated ?? project.name,
      })),
    }] : [];
  }

  private searchRecentProjects$(term: string, searchOptions: SearchOptions): Observable<IrisPage<IrisProjectI>> {
    if (this.options.hideRecentProjects) { return of(emptyIrisPage<IrisProjectI>()); }

    return this.globalSandbox.recentProjects$.pipe(
      map(projects => ({
        count: projects.length,
        elements: projects
          .filter(project => !searchOptions.searchOnlyInProjects || searchOptions.searchOnlyInProjects.includes(project.id))
          .filter(project => !searchOptions.excludeProjects || !searchOptions.excludeProjects.includes(project.id))
          .filter(project => !term || project.name.toLowerCase().includes(term.toLowerCase())),
      })),
      catchError(() => of(emptyIrisPage<IrisProjectI>())),
    );
  }

  private searchProjects$(term: string, searchOptions: SearchOptions, offset = 0): Observable<IrisPage<IrisProjectI>> {
    this.loadingSubject.next(true);
    return this.projectsService.getProjectsPage(this.buildSearchProjectsQueryParams(term, searchOptions, offset)).pipe(
      catchError(() => of(emptyIrisPage<IrisProjectI>())),
      finalize(() => this.loadingSubject.next(false)),
    );
  }

  private buildSearchProjectsQueryParams(term: string, options: SearchOptions, offset = 0): IrisQueryParams {
    const termIsNotEmpty = term != null && term !== '';
    return new IrisQueryParamsBuilder()
      .plugin(b => termIsNotEmpty
        ? b.filterOr([
          or => or.filter('name', [`%${term}%`], t => t.strict(false)),
          or => or.filter('costCenter', [`%${term}%`], t => t.strict(false)),
        ])
        : b)
      .plugin(b => options.searchOnlyInProjects != null
        ? b.filter('id', options.searchOnlyInProjects, t => t.strict(false))
        : b)
      .plugin(b => options.excludeProjects != null
        ? b.filter('id', options.excludeProjects, t => t.strict(false).not())
        : b)
      .urlParam('kind', options.includeCompletedProjectsInSearch ? 'ALL' : 'ONGOING')
      .limit(PAGE_SIZE)
      .offset(offset)
      .orderBy('name')
      .onlyFields(ONLY_FIELDS)
      .toStructure();
  }

  getMissingItemLabelFn(projectId: IrisProjectI['id']): Observable<string> {
    if (!projectId) { return of(null); }

    const params = new IrisQueryParamsBuilder()
      .onlyFields(['name', 'translations', this.translate.currentLang])
      .toStructure();

    return this.projectsService.getById(projectId, params).pipe(
      map(project => project.translations?.[this.translate.currentLang]?.['name'] ?? project.name),
      catchError(() => of(null)),
    );
  }
}
