import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { IrisApiQueryInitializerService } from '@iris/api-query';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { forkJoin, lastValueFrom, of } from 'rxjs';
import { IrisTimeSandbox } from './common/modules/time';
import { IrisSecurityService } from '@iris/common/modules/security/services/security.service';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { TranslateService } from '@ngx-translate/core';
import { IrisLocaleService } from '@iris/common/modules/locale/services/locale.service';
import { IrisLocalStorageService } from '@iris/common/services/utils/iris-local-storage.service';
import { inject } from '@angular/core';
import { IrisUserService, MeSettings } from '@iris/common/services/user.service';
import { IrisUserSettingsService } from '@iris/common/services/user-settings.service';
import { IrisUserSettingsI } from '@iris/common/modules/grid-configuration/models/grid-configuration.settings';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { extractPayloadFromToken } from '@iris/modules/auth/utils/extract-payload-from-token';
import { SWService } from '@iris/common/services/service-worker.service';
import { CommandWebsocketService } from '@iris/common/services/websockets/command-websocket.service';

const MICROSERVICE_REDIRECTS: {name: string; redirectUrl: string}[] = [
  { name: 'bimm', redirectUrl: 'http://localhost:37080/restful' },
  { name: 'marketplace', redirectUrl: 'http://localhost:5002/restful' },
  { name: 'dms2', redirectUrl: 'http://localhost:8092/restful' },
];

export function AppInitializer(): () => Promise<unknown> {
  const env = inject(IrisEnvironmentService).env;
  const apiQueryInitializer = inject(IrisApiQueryInitializerService);
  const globalSandbox = inject(IrisGlobalSandbox);
  const timeSandbox = inject(IrisTimeSandbox);
  const securityService = inject(IrisSecurityService);
  const authFacade = inject(AuthFacade);
  const translate = inject(TranslateService);
  const localeService = inject(IrisLocaleService);
  const localStorageService = inject(IrisLocalStorageService);
  const userService = inject(IrisUserService);
  const userSettingsService = inject(IrisUserSettingsService);
  const sWService = inject(SWService);
  const commandWebsocketService = inject(CommandWebsocketService);

  const extractUserFromToken = (authToken: string): IdentUserI => {
    const payload = extractPayloadFromToken<{ account_details: string }>(authToken);
    return JSON.parse(payload.account_details);
  };

  const appInitializer$ = authFacade.actualToken().pipe(
    switchMap((authToken) => authFacade.shareToken(authToken)),
    switchMap((authToken) => {
      // prevent initialize before user login
      if (!authFacade.isLoggedIn()) {
        return of(null);
      }

      const currentUser = extractUserFromToken(authToken);
      userService.setMe(currentUser);

      commandWebsocketService.subscribeToUser(currentUser.id);

      const userSettings$ = userSettingsService.getUserSettingsById<IrisUserSettingsI<MeSettings>>('general', currentUser.id);

      const appInitializerEnv = {
        env,
        apiQueryConfig: {
          serverUrl: env.serverUrl,
          apiUrl: env.apiUrl,
          actualToken: () => authFacade.actualToken(),
          urlParams: {
            instanceId: env.instanceId,
            formsUrlPart: env.formsEnabled ? `/forms/${env.instanceId}/` : '',
          },
        },
      };

      return forkJoin([
        timeSandbox.timeFormats$.pipe(take(1)),
        securityService.getRights().pipe(take(1)),
        userSettings$.pipe(tap(settings => userService.setMeSettings(settings))),
      ]).pipe(
        tap(() => {
          const projectsIds: number[] = userService.meSettings.recentProjects || [];
          globalSandbox.setRecentProjectsIds(<number[]> projectsIds);

          if (projectsIds[0]) {
            globalSandbox.changeCurrentProjectId(projectsIds[0]);
          }

          apiQueryInitializer.init(
            appInitializerEnv.apiQueryConfig,
            {
              redirectMicroservices: appInitializerEnv.env.redirectMicroservices,
              redirectMicroservicesUrl: MICROSERVICE_REDIRECTS,
            },
          );
        }),
        map(() => env),
      );
    }),
  );

  return () => lastValueFrom(appInitializer$.pipe(
    tap(() => {
      const navigatorLanguage =  navigator.language.replace(/^([^\-]+).*/, '$1');
      let userLang = localeService.getCurrentLocale() || localStorageService.getItem('defaultAuthLanguage') || navigatorLanguage || 'de';
      if (!authFacade.isLoggedIn() && !userLang.match(/en|de/is)) {
        userLang = 'de';
      }
      translate.setDefaultLang('en');
      translate.use(userLang);
      localStorageService.setItem('defaultAuthLanguage', userLang);
    }),
  ));
}
