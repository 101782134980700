import { inject, Injectable } from '@angular/core';
import { IrisQueryParamsBuilder } from '@iris/api-query';
import { IrisPage } from '@iris/common/models/page';
import { IrisCompaniesService } from '@iris/common/services/companies.service';
import { IrisCompanyI } from '@iris/common/models/IrisCompany';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IrisSelectEngineInfinityScroll } from '@iris/common/modules/fields/ng-select-field/select-engine.infinity-scroll';

const PAGE_SIZE = 10;

@Injectable()
export class IrisCompanySelectEngine extends IrisSelectEngineInfinityScroll<IrisCompanyI, number> {
  private readonly companiesService = inject(IrisCompaniesService);

  searchItems$(term: string, offset = 0): Observable<IrisPage<IrisCompanyI>> {
    const termIsNotEmpty = term != null && term !== '';
    const builder = new IrisQueryParamsBuilder()
      .plugin(b => termIsNotEmpty
        ? b.filter('name', [`%${term}%`], t => t.strict(false))
        : b)
      .limit(PAGE_SIZE)
      .offset(offset)
      .orderBy('name');

    return this.companiesService.getAll(builder.toStructure()).pipe(
      map(elements => {
        elements.forEach(element => element.nameTranslated = element.nameTranslated || element.name);

        return <IrisPage<IrisCompanyI>> { elements };
      }),
    );
  }

  getMissingItemLabelFn(val: number | Record<string, unknown>): Observable<string> {
    if (val == null) { return of(null); }

    const params = new IrisQueryParamsBuilder()
      .onlyFields(['name', 'nameTranslated'])
      .toStructure();
    const companyId = typeof val === 'object' ? Number(val['id']) : val;

    return this.companiesService.getById(companyId, params).pipe(
      map(company => company.nameTranslated || company.name),
      catchError(() => of(null)),
    );
  }
}
