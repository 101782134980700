import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IrisLoaderService } from '@iris/common/services/loader.service';
import { IrisLocalStorageService } from '@iris/common/services/utils/iris-local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { IrisMsalService } from '@iris/modules/msal/msal.service';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { IrisAuthService } from '@iris/modules/auth/utils/auth.service';
import { LocationStrategy } from '@angular/common';

const LAST_HREF_KEY = 'lastHref';
const LOADER_PLACE = '.login-form';

type LoginState = 'login' | 'sso' | 'forgot';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  email: string;
  pass: string;
  error: string;
  message: string;
  currentYear = (new Date()).getFullYear();
  state: LoginState = 'sso';
  headings: { [key in LoginState]: string } = {
    'login': 'message.login',
    'sso': 'message.SelectPreferredLoginMethod',
    'forgot': 'text.login.ForgotYourPasswordNew',
  };

  constructor(
    private readonly router: Router,
    private readonly authService: IrisAuthService,
    private readonly authFacade: AuthFacade,
    private readonly translateService: TranslateService,
    private readonly localStorage: IrisLocalStorageService,
    private readonly loader: IrisLoaderService,
    private readonly route: ActivatedRoute,
    private readonly irisMsalService: IrisMsalService,
    private readonly locationStrategy: LocationStrategy,
  ) { }

  ngOnInit(): void {
    if (this.authFacade.isLoggedIn()) {
      void this.router.navigate(['']);
    }

    this.route.queryParamMap.pipe(
      take(1),
      tap((queryParams) => {
        if (queryParams.get('originalUrl')) {
          this.authFacade.lastHref = decodeURIComponent(queryParams.get('originalUrl'));
        }
        if (queryParams.get('ssoError')) {
          this.error = this.translateService.instant('error.UserAuthFailed');
          void this.router.navigate([], {
            queryParams: { 'ssoError': null },
            queryParamsHandling: 'merge',
          });
        }
      }),
    ).subscribe();
  }

  checkAccountAndRedirect(): void {
    this.localStorage.setItem(LAST_HREF_KEY, this.authFacade.lastHref);
    this.irisMsalService.handleAzureAccount().pipe(
      take(1),
      switchMap(res => res ? this.irisMsalService.acquireTokenRedirect() : EMPTY),
    ).subscribe();
  }

  submitForm(): void {
    this.clearAlerts();
    this.loader.start(LOADER_PLACE);

    this.authFacade.loginUsername(this.email, this.pass).pipe(
      take(1),
      tap(() => {
        location.assign(this.authFacade.lastHref || this.locationStrategy.getBaseHref());
      }),
      catchError((err) => {
        this.loader.stop(LOADER_PLACE);
        return this.error = this.translateService.instant(err.message || err);
      }),
    ).subscribe();
  }

  setState(state: LoginState): void {
    this.clearAlerts();
    this.state = state;
  }

  sendResetLink(): void {
    if (!this.email) { return; }
    this.clearAlerts();
    this.loader.start(LOADER_PLACE);
    this.authService.sendResetLink(this.email).pipe(
      tap(() => {
        this.message = this.translateService.instant('message.LinkWasSent');
        this.state = 'login';
      }),
      catchError(err => this.error = this.translateService.instant(err?.error?.messages[0] || err.message)),
      finalize(() => this.loader.stop(LOADER_PLACE)),
    ).subscribe();
  }

  private clearAlerts(): void {
    this.error = null;
    this.message = null;
  }
}
