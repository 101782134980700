import { Injectable } from '@angular/core';
import { ApiUrl, IrisApiClientById, IrisQueryParams } from '@iris/api-query';
import { IrisContact } from '../models/IrisContact';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IrisPage } from '@iris/common/models/page';

@Injectable({
  providedIn: 'root',
})
@ApiUrl('/contacts/{id}')
export class IrisContactsService extends IrisApiClientById<IrisContact> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  @ApiUrl('~/page')
  getContactsPage(params: IrisQueryParams): Observable<IrisPage<IrisContact>> {
    return this.httpClient.get<IrisPage<IrisContact>>(this.url(), { params: params.toObject() });
  }

  getContacts(params: IrisQueryParams): Observable<IrisContact[]> {
    return this.httpClient.get<IrisContact[]>(this.url(), { params: params.toObject() });
  }
}
