import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatetimeAdapterExtended } from './mat-datetimepicker/datetime-adapter';
import { AbstractDatetime } from '../../date';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MatLuxonDateAdapterOptions } from '@angular/material-luxon-adapter';
import { DateTime } from 'luxon';

function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

@Injectable()
export class DatetimePickerAdapter extends DatetimeAdapterExtended {

  private _localeData: {
    firstDayOfWeek: number;
    longMonths: string[];
    shortMonths: string[];
    dates: string[];
    hours: string[];
    minutes: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
    narrowDaysOfWeek: string[];
  };

  private readonly _useUtc: boolean = false;

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
    @Optional() @Inject(MAT_LUXON_DATE_ADAPTER_OPTIONS) matLuxonAdapterOptions: MatLuxonDateAdapterOptions,
    _delegate: LuxonDateAdapter,
  ) {
    super(_delegate, matDateLocale);
    this.setLocale(matDateLocale || AbstractDatetime.locale);
    this._useUtc = matLuxonAdapterOptions.useUtc;
  }

  setLocale(locale: string): void {
    super.setLocale(locale);

    this._localeData = {
      firstDayOfWeek: AbstractDatetime.firstDayOfWeek(locale),
      longMonths: AbstractDatetime.months(locale),
      shortMonths: AbstractDatetime.monthsShort(),
      dates: range(31, (i) => super.createDate(2017, 0, i + 1).toFormat('D')),
      hours: range(24, (i) => this.createDatetime(2017, 1, 1, i, 0).toFormat('H')),
      minutes: range(60, (i) => this.createDatetime(2017, 1, 1, 1, i).toFormat('m')),
      longDaysOfWeek: AbstractDatetime.weekdays(),
      shortDaysOfWeek: AbstractDatetime.weekdaysShort(),
      narrowDaysOfWeek: AbstractDatetime.weekdaysMin(),
    };
  }

  getHour(date: DateTime): number {
    return date.hour;
  }

  getMinute(date: DateTime): number {
    return date.minute;
  }

  isInNextMonth(startDate: DateTime, endDate: DateTime): boolean {
    const nextMonth = this.getDateInNextMonth(startDate);
    return super.sameMonthAndYear(nextMonth, endDate);
  }

  createDatetime(year: number, month: number, date: number, hour: number, minute: number): DateTime {
    // Check for invalid month and date (except upper bound on date which we have to check after
    // creating the Date).
    if (month < 1 || month > 12) {
      throw Error(`Invalid month index '${month}'. Month index has to be between 1 and 12.`);
    }

    if (date < 1) {
      throw Error(`Invalid date '${date}'. Date has to be greater than 0.`);
    }

    if (hour < 0 || hour > 23) {
      throw Error(`Invalid hour '${hour}'. Hour has to be between 0 and 23.`);
    }

    if (minute < 0 || minute > 59) {
      throw Error(`Invalid minute '${minute}'. Minute has to be between 0 and 59.`);
    }

    const datetime = { year, month, day: date, hour, minute };
    let result = DateTime.fromObject(datetime);
    if (this._useUtc) {
      result = result.toUTC();
    }

    // If the result isn't valid, the date must have been out of bounds for this month.
    if (!result.isValid) {
      throw Error(`Invalid date '${date}' for month with index '${month}'.`);
    }

    return result;
  }

  getFirstDateOfMonth(date: DateTime): DateTime {
    return date.startOf('month');
  }

  getHourNames(): string[] {
    return this._localeData.hours;
  }

  getMinuteNames(): string[] {
    return this._localeData.minutes;
  }

  addCalendarHours(date: DateTime, hours: number): DateTime {
    return date.plus({ hours });
  }

  addCalendarMinutes(date: DateTime, minutes: number): DateTime {
    return date.minus({ minutes });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  deserialize(value: any): DateTime | null {
    return super.deserialize(value);
  }

  private getDateInNextMonth(date: DateTime): DateTime {
    return date.plus({ months: 1 }).set({ day: 1 });
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  getNumberOfWeek(dt: DateTime): number {
    return dt.weekNumber;
  }

  setDay(date: DateTime, day: number): DateTime {
    return date.set({ day });
  }

  setMonth(date: DateTime, month: number): DateTime {
    return date.set({ month });
  }

  setYear(date: DateTime, year: number): DateTime {
    return date.set({ year });
  }

  setHours(date: DateTime, hours: number): DateTime {
    return date.set({ hour: hours });
  }

  setMinutes(date: DateTime, minutes: number): DateTime {
    return date.set({ minute: minutes });
  }

  setSeconds(date: DateTime, seconds: number): DateTime {
    return date.set({ second: seconds });
  }

  getSeconds(date: DateTime): number {
    return date.second;
  }

  setTimezone(date: DateTime, timezone: string, keepLocalTime = false): DateTime {
    timezone = timezone || AbstractDatetime.guessTimeZone();
    if (timezone === date.zoneName) { return date; }
    return date.set({ millisecond: 0 }).setZone(timezone, { keepLocalTime });
  }

  initDate(date: DateTime, type: string, timezone: string): DateTime {
    if(!this.getValidDateOrNull(date)) {
      return date;
    }
    if (type == 'date') { return this.toNoon(date); }

    return this.setTimezone(date, timezone);
  }

  toNoon(date: DateTime): DateTime {
    return date.startOf('day').set({ hour: 12 });
  }

  toUTC(date: DateTime): DateTime {
    if(!this.getValidDateOrNull(date)) {
      return date;
    }

    return date.toUTC();
  }

  toUTCString(date: DateTime): string {
    if(!this.getValidDateOrNull(date)) {
      return '';
    }

    return this.toUTC(date).toISO();
  }

  toUTCDate(date: DateTime): Date {
    if(!this.getValidDateOrNull(date)) {
      return null;
    }

    return this.toUTC(date).toJSDate();
  }

  isValidString(date: string, format: string): boolean {
    return DateTime.fromFormat(date, format).isValid;
  }

  isDate(date: unknown): boolean {
    return date instanceof Date;
  }

  isDatetime(date: unknown): boolean {
    return date instanceof DateTime;
  }

  convert(value: DateTime, type: string): string | Date | DateTime {
    if(!this.getValidDateOrNull(value)) {
      return value;
    }

    switch(type) {
      case 'string':
        return this.toUTCString(value);

      case 'DateTime':
        return this.toUTC(value);

      case 'date':
        return this.toUTCDate(value);
    }

    return value;
  }
}
