import { IrisQueryParamsBuilder } from '@iris/api-query';
import { IrisFilterPropertyMeta } from '@iris/modules/module-filters-v2/filter-meta';
import { IrisFilterAppendQueryParamsContext } from '@iris/modules/module-filters-v2/query-params';

export function buildQueryParams(metas: IrisFilterPropertyMeta[], filterValue: Record<string, unknown>, context: IrisFilterAppendQueryParamsContext = null, paramsBuilder = new IrisQueryParamsBuilder()): IrisQueryParamsBuilder {
  metas
    .filter(meta => Object.keys(filterValue || {}).includes(meta.propertyId) && !meta.filterValueIsEmpty(filterValue[meta.propertyId]))
    .forEach(meta => meta.appendFilterQueryParamsFn(paramsBuilder, filterValue[meta.propertyId], context));
  return paramsBuilder;
}
