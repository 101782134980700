import { Action } from '@ngrx/store';
import { IrisUserInfoI } from '@iris/common/modules/user-common/models/IrisUserInfo';

export enum UsersActionTypes {
  LoadUserInfo = '[UserInfo] Load UserInfo',
  LoadUserInfoComplete = '[UserInfo] Load UserInfo Complete',
  LoadUserByEmail = '[UserInfo] Load User By Email',
  LoadUserByEmailComplete = '[UserInfo] Load User By Email Complete',
}

export class LoadUserInfo implements Action {
  readonly type = UsersActionTypes.LoadUserInfo;

  constructor(public payload: number) {
  }
}

export class LoadUserInfoComplete implements Action {
  readonly type = UsersActionTypes.LoadUserInfoComplete;

  constructor(public payload: IrisUserInfoI[]) {
  }
}

export class LoadUserByEmail implements Action {
  readonly type = UsersActionTypes.LoadUserByEmail;

  constructor(public payload: string) {}
}

export class LoadUserByEmailComplete implements Action {
  readonly type = UsersActionTypes.LoadUserByEmailComplete;

  constructor(public payload: IdentUserI[]) {}
}

export type UsersActions =
  | LoadUserInfo
  | LoadUserInfoComplete
  | LoadUserByEmail
  | LoadUserByEmailComplete;
