import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { EmailWellKnownFolderName, IrisEmailNavigation, IrisEmailNavigationI } from '../../models/IrisEmailNavigation';
import {
  CollapseNavigationFolderStart,
  GetChildrenFoldersSuccess,
  GetCustomFoldersError,
  GetCustomFoldersStart,
  GetCustomFoldersSuccess,
  GetCustomFolderSuccess,
} from './emails-navigation.actions';
import { chain } from 'lodash';

export const FOLDERS_ORDER = {
  [EmailWellKnownFolderName.Inbox]: 0,
  [EmailWellKnownFolderName.Drafts]: 1,
  [EmailWellKnownFolderName.SentItems]: 2,
  [EmailWellKnownFolderName.DeletedItems]: 3,
  [EmailWellKnownFolderName.JunkEmail]: 4,
  [EmailWellKnownFolderName.Archive]: 5,
  [EmailWellKnownFolderName.Outbox]: 6,
};

export interface EmailsNavigationState extends EntityState<IrisEmailNavigationI> {
  contentLoading: boolean;
  rootFolderId: string;
  total: number;
}

export const adapter: EntityAdapter<IrisEmailNavigationI> = createEntityAdapter<IrisEmailNavigationI>();

export const initialState: EmailsNavigationState = adapter.getInitialState({
  contentLoading: false,
  rootFolderId: null,
  total: 0,
});

export const emailsNavigationReducer = createReducer(
  initialState,
  on(GetCustomFoldersStart, () => ({ ...initialState, contentLoading: true })),
  on(GetCustomFoldersSuccess, (state, { folders, total }) => {
    const foldersToAdd = (folders || []).map(folder => new IrisEmailNavigation(folder));
    return {
      ...adapter.setAll(foldersToAdd, state),
      contentLoading: false,
      total,
      rootFolderId: foldersToAdd[0]?.parentFolderId,
    };
  }),
  on(GetChildrenFoldersSuccess, (state, { folders }) => {
    const foldersToAdd = (folders || []).map(folder => new IrisEmailNavigation(folder));
    const updatedState = adapter.upsertMany(foldersToAdd, state);
    return {
      ...updatedState,
      total: updatedState.ids.length,
    };
  }),
  on(GetCustomFolderSuccess, (state, { folder }) => {
    return adapter.upsertOne(folder, state);
  }),
  on(GetCustomFoldersError, state => ({ ...state, contentLoading: false })),
  on(CollapseNavigationFolderStart, (state, { folderId }) => {
    const parentFolder = state.entities[folderId];
    const children = getFlatFoldersTree(state, parentFolder);
    return adapter.removeMany(children.map(child => child.id), state);
  }),
);

export function reducer(state: EmailsNavigationState | undefined, action: Action): EmailsNavigationState {
  return emailsNavigationReducer(state, action);
}

const { selectAll, selectEntities } = adapter.getSelectors();

export const getNavigationFolders = (state: EmailsNavigationState): IrisEmailNavigationI[] => selectAll(state);
export const getNavigationChildrenFolders = (state: EmailsNavigationState, parentFolderId: string): IrisEmailNavigationI[] => selectAll(state).filter(folder => folder.parentFolderId === parentFolderId);
export const getNavigationFolderById = (state: EmailsNavigationState, folderId: string): IrisEmailNavigationI => selectEntities(state)[folderId];
export const getNavigationFolderByTitle = (state: EmailsNavigationState, title: string): IrisEmailNavigationI => selectAll(state)
  .find(folder => folder.title.toLowerCase() === title.toLowerCase());
export const getFolderByShortcut = (state: EmailsNavigationState, folderShortcut: EmailWellKnownFolderName): string => {
  return selectAll(state).find(folder => folder.wellKnownFolderName === folderShortcut)?.id;
};

export const buildSortedVisibleFlatNavigationTree = (state: EmailsNavigationState): IrisEmailNavigationI[] => {
  if (!state.ids.length) {
    return [];
  }
  const inboxId = getFolderByShortcut(state, EmailWellKnownFolderName.Inbox);
  const inboxFolder = state.entities[inboxId];
  const rootFolders = getNavigationChildrenFolders(state, inboxFolder.parentFolderId);
  return chain(rootFolders)
    .sortBy(folder => {
      return FOLDERS_ORDER[folder.wellKnownFolderName];
    })
    .map(folder => [new IrisEmailNavigation(folder), ...getFlatFoldersTree(state, folder)])
    .flatten()
    .value();
};

export const getFlatFoldersTree = (state: EmailsNavigationState, parentFolder: IrisEmailNavigationI): IrisEmailNavigationI[] => {
  if (!parentFolder.childFolderCount) { return []; }
  const children = getNavigationChildrenFolders(state, parentFolder.id);
  return chain(children).map(child => [new IrisEmailNavigation(child), ...getFlatFoldersTree(state, child)]).flatten().value();
};
