import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { IrisSubjectPermissions } from '@iris/common/services/subject-permissions.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { showForbiddenPage } from '@iris/common/guards/utils';

export const canAccessModuleGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const location = inject(Location);

  return inject(IrisSubjectPermissions).getAccessPermissions(route.routeConfig.path).pipe(
    map(result => result.hasPermission),
    map(hasPermission => {
      if (!hasPermission) {
        showForbiddenPage(router, location, state.url);
      }

      return hasPermission;
    }),
    catchError(() => of(false)),
  );
};
