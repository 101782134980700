import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IrisUserSelectDropdownItemComponent } from './dropdown-item.component';
import { IrisUserWSComponent } from '@iris/common/modules/user-common/modules/user-ws/components/user-ws.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IrisUserWSComponent,
  ],
  declarations: [IrisUserSelectDropdownItemComponent],
  exports: [IrisUserSelectDropdownItemComponent],
})
export class IrisUserSelectDropdownItemComponentModule { }
