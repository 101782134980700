<iris-modal-content
  [title]="getTitle()"
  [parent]="this"
  [cancelBtnText]="'label.Close'"
>
  <ng-container id="iris-modal-body" *ngIf="status$ | async as status">
    <div class="summary">
      <dl class="flex-grid flex-col-8 stick">
        <dt class="flex-col-4 stick">{{'label.InstanceId' | translate}}</dt>
        <dd>{{status.instanceId}}</dd>
        <dt class="flex-col-4 stick">{{'label.ApplicationStartDate' | translate}}</dt>
        <dd>{{status.startDate | irisTime:'yyyy-MM-dd HH:mm:ss':'Europe/Moscow'}}</dd>
        <dt class="flex-col-4 stick">{{'label.ReleaseVersion' | translate}}</dt>
        <dd>{{status.releaseVersion}} (build: {{status.buildNumber}})</dd>
        <dt class="flex-col-4 stick">{{'label.GitCommit' | translate}}</dt>
        <dd>{{status.gitCommit}}</dd>
        <dt class="flex-col-4 stick">{{'label.FrontendReleaseVersion' | translate}}</dt>
        <dd>{{env.version}}</dd>
        <dt class="flex-col-4 stick">{{'label.DeploymentDate' | translate}}</dt>
        <dd>{{status.buildDate | irisTime:'yyyy-MM-dd HH:mm:ss'}}</dd>
        <dt class="flex-col-4 stick">{{'label.InstanceType' | translate}}</dt>
        <dd>{{(status.isProd ? 'label.ProdInstance' : 'label.TestInstance') | translate}}</dd>
      </dl>
    </div>

    <div *ngIf="requestErrors.length" class="wrapper">
      <h1 class="h1">
        {{'label.about.LastErrors' | translate}}
      </h1>
      <button
        class="btn btn-default"
        tabindex="-1"
        (click)="clearErrors()">
        <i class="fa fa-trash-alt fa-fw"></i>
        {{'label.Clear' | translate}}
      </button>
    </div>

    <div
      class="request-errors"
      *ngIf="requestErrors.length">
       <mat-list class="list">
          <mat-list-item
            class="list-item"
            *ngFor="let item of requestErrors; let last = last">
              <div class="flex-col-auto py-3">
                <div class="flex-row">
                  <div class="flex-col-2 text-right">Request ID:</div>
                  <div class="flex-col-auto ps-2">
                    <ng-container *ngIf="item.requestId; else emptyLine">
                      <button
                        class="btn btn-xs btn-flat icon"
                        (click)="onCopy(item.requestId, 'text.ErrorRequestId')">
                          <i class="far fa-copy"></i>
                      </button>
                      <span>{{item.requestId}}</span>
                    </ng-container>
                    <ng-template #emptyLine>&mdash;</ng-template>
                  </div>
                </div>
                <div class="flex-row">
                  <div class="flex-col-2 text-right">Request URL:</div>
                  <div class="flex-col-auto ps-2">{{item.method}} {{item.status}} {{item.url}}</div>
                </div>
                <div class="flex-row">
                  <div class="flex-col-2 text-right">Date:</div>
                  <div class="flex-col-auto ps-2">{{item.date | irisTime}}</div>
                </div>
                <div class="flex-row">
                  <div class="flex-col-2 text-right">URL:</div>
                  <div class="flex-col-auto ps-2"><a href="{{item.pathname}}">{{item.pathname}}</a></div>
                </div>
              </div>
              <button *ngIf="item.stack"
                class="btn btn-flat"
                (click)="onCopy(item.stack, 'text.ErrorStackCopied')">
                  <i class="fal fa-debug"></i>
              </button>
              <button
                class="btn btn-flat"
                (click)="onCopy(item, 'text.ErrorCopied')">
                  <i class="far fa-copy"></i>
              </button>
              <mat-divider *ngIf="!last"></mat-divider>
          </mat-list-item>
      </mat-list>
    </div>
  </ng-container>

</iris-modal-content>
