import { NUMERIC_RANGE_CHIP_RENDERING_OPTIONS } from '@iris/modules/module-filters-v2/chips/rendering-options';
import { appendNumericRangeQueryParamsFn } from '@iris/modules/module-filters-v2/query-params';
import { IrisFilterPropertyMeta } from './filter-property-meta';
import { filterValueObjectIsEmpty } from '@iris/modules/module-filters-v2/utils';

export function numericRangeFilterMeta(
  propertyId: string,
  label: string,
  fieldNames: string | string[] = propertyId,
): IrisFilterPropertyMeta {
  return {
    propertyId,
    label,
    appendFilterQueryParamsFn: appendNumericRangeQueryParamsFn(fieldNames),
    chipRenderingOptions: NUMERIC_RANGE_CHIP_RENDERING_OPTIONS,
    filterValueIsEmpty: filterValueObjectIsEmpty,
  };
}
