<div class="login-form">
  <h3>{{'message.PwdRequirements' | translate}}</h3>
  <div *ngIf="error" class="alert error">
    {{error}}
  </div>
  <div *ngIf="message" class="alert alert-success">
    {{message}}
  </div>
  <form (ngSubmit)="resetPassword()" autocomplete="off">
    <iris-field-password
      [(ngModel)]="newPassword"
      name="newPassword"
      [label]="'label.Password' | translate"
    ></iris-field-password>
    <iris-field-password
      [(ngModel)]="confirmPassword"
      name="confirmPassword"
      [label]="'label.ConfirmNewPassword' | translate"
    ></iris-field-password>
    <button type="submit" class="btn btn-success btn-login">{{'label.RestorePassword' | translate}}</button>
  </form>
  <a class="btn btn-link" [routerLink]="['/login']">{{'label.BackToLoginPage' | translate}}</a>
</div>