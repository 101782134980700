import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { IrisChipDropdownComponent } from '@iris/common/modules/chip-dropdown/components/chip-dropdown.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { IrisCommonDirectivesModule } from '@iris/common/modules/directives-common/common-directives.module';

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    MatMenuModule,
    MatDividerModule,
    IrisCommonDirectivesModule,
  ],
  declarations: [
    IrisChipDropdownComponent,
  ],
  providers: [],
  exports: [
    IrisChipDropdownComponent,
  ],
})
export class IrisChipDropdownModule {
}
