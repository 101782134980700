import { NgSelectComponent } from '@natlex/ng-select';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export type NgSelectEvent = keyof Pick<NgSelectComponent, 'blurEvent' | 'focusEvent' | 'changeEvent' | 'openEvent' | 'closeEvent' | 'searchEvent' | 'clearEvent' | 'addEvent' | 'removeEvent' | 'scroll' | 'scrollToEnd'>;

export interface NgSelectEventHandler<TEventData = unknown> {
  readonly eventType: NgSelectEvent;
  handle(ngSelect: NgSelectComponent, event: TEventData): void | Observable<void>;
}

export const NG_SELECT_EVENT_HANDLER = new InjectionToken<NgSelectEventHandler>('Ng-select-field event handler');
