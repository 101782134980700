import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { IrisGlobalNavigationMenuModuleComponent } from './components/global-navigation-menu/global-navigation-menu.component';
import { IrisCommonModule } from '../common/common.module';
import { ToggleModule } from '@iris/common/modules/ui/controls/toggle/toggle.module';
import { IrisEmailsSandboxModule } from '../emails/sandbox/emails-sandbox.module';
import { IrisEmailsNavigationSandbox } from '../emails/sandbox/navigation/emails-navigation.sandbox';

@NgModule({
  imports: [
    RouterModule,
    IrisCommonModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatTreeModule,
    MatListModule,
    ToggleModule,
    IrisEmailsSandboxModule,
  ],
  declarations: [
    IrisGlobalNavigationMenuModuleComponent,
  ],
  exports: [
    IrisGlobalNavigationMenuModuleComponent,
  ],
  providers: [
    IrisEmailsNavigationSandbox,
    
  ],
})
export class IrisGlobalNavigationMenuModule {
  static readonly type = 'NavigationMenuModule';
}
