/* eslint-disable */
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { DOWN_ARROW, END, ENTER, HOME, LEFT_ARROW, PAGE_DOWN, PAGE_UP, RIGHT_ARROW, UP_ARROW } from '@angular/cdk/keycodes';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { MAT_DATETIME_FORMATS, MatDatetimeFormats, MatDatetimepickerFilterType } from '@mat-datetimepicker/core';
import { MatClockView } from '@mat-datetimepicker/core/datetimepicker/clock';
import { MatDatetimepickerType } from './datetimepicker';
import { slideCalendar } from './datetimepicker-animations';
import { createMissingDateImplError } from './datetimepicker-errors';
import { getActiveOffset, isSameMultiYearView, yearsPerPage, yearsPerRow } from './multi-year-view';
import { DatetimeAdapterExtended } from './datetime-adapter';
import { DateTime } from 'luxon';

export type MatCalendarView = 'month' | 'year' | 'multi-year';

@Component({
  selector: 'mat-datetimepicker-calendar-extended',
  templateUrl: 'calendar.html',
  styleUrls: ['calendar.scss', 'calendar-body.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.mat-datetimepicker-calendar]': 'true',
    '[attr.aria-label]': 'ariaLabel',
    'role': 'dialog',
    'tabindex': '0',
    '(keydown)': '_handleCalendarBodyKeydown($event)'
  },
  animations: [slideCalendar],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MatDatetimepickerCalendarExtended implements AfterContentInit, OnDestroy {
  @Output() _userSelection = new EventEmitter<void>();
  /** Active multi year view when click on year. */
  @Input() multiYearSelector: boolean = false;
  /** Whether the calendar should be started in month or year view. */
  @Input() startView: MatCalendarView = 'month';
  @Input() twelvehour: boolean = false;
  @Input() timeInterval: number = 1;
  /** A function used to filter which dates are selectable. */
  @Input() dateFilter: (date: DateTime, type: MatDatetimepickerFilterType) => boolean;
  @Input() ariaLabel = 'Use arrow keys to navigate';
  @Input() ariaNextMonthLabel = 'Next month';
  @Input() ariaPrevMonthLabel = 'Previous month';
  @Input() ariaNextYearLabel = 'Next year';
  @Input() ariaPrevYearLabel = 'Previous year';
  @Input() ariaNextMultiYearLabel = 'Next year range';
  @Input() ariaPrevMultiYearLabel = 'Previous year range';
  @Input() showWeekNumbers: boolean;
  @Input() timezone: string;
  /** Prevent user to select same date time */
  @Input() preventSameDateTimeSelection = false;
  /** Emits when the currently selected date changes. */
  @Output() selectedChange: EventEmitter<DateTime> = new EventEmitter<DateTime>();
  /** Emits when the view has been changed. **/
  @Output() viewChanged: EventEmitter<MatCalendarView> = new EventEmitter<MatCalendarView>();
  _AMPM: string;
  _clockView: MatClockView = 'hour';
  _calendarState: string;
  private _intlChanges: Subscription;
  private _clampedActiveDate: DateTime;
  monthNames: string[];

  _activeHours: string;
  _activeMinutes: string;
  _activeSeconds: string;

  constructor(private _elementRef: ElementRef,
              private _intl: MatDatepickerIntl,
              private _ngZone: NgZone,
              @Optional() private _adapter: DatetimeAdapterExtended,
              @Optional() @Inject(MAT_DATETIME_FORMATS) private _dateFormats: MatDatetimeFormats,
              changeDetectorRef: ChangeDetectorRef) {
    if (!this._adapter) {
      throw createMissingDateImplError('DatetimeAdapterExtended');
    }

    if (!this._dateFormats) {
      throw createMissingDateImplError('MAT_DATETIME_FORMATS');
    }

    this._intlChanges = _intl.changes.subscribe(() => changeDetectorRef.markForCheck());

    this.monthNames = this._adapter.getMonthNames('long');
  }

  private _type: MatDatetimepickerType = 'date';

  @Input()
  get type(): MatDatetimepickerType {
    return this._type;
  }

  set type(value: MatDatetimepickerType) {
    this._type = value || 'date';
    if (this.type === 'year') {
      this.multiYearSelector = true;
    }

    this.showSeconds = this.type == 'long-datetime';
  }

  public showSeconds = false;

  private _startAt: DateTime | null;

  /** A date representing the period (month or year) to start the calendar in. */
  @Input()
  get startAt(): DateTime | null {
    return this._startAt;
  }

  set startAt(value: DateTime | null) {
    this._startAt = this._adapter.getValidDateOrNull(value);
  }

  private _selected: DateTime | null;

  /** The currently selected date. */
  @Input()
  get selected(): DateTime | null {
    return this._selected;
  }

  set selected(value: DateTime | null) {
    this._selected = this._adapter.getValidDateOrNull(value);
  }

  private _minDate: DateTime | null;

  /** The minimum selectable date. */
  @Input()
  get minDate(): DateTime | null {
    return this._minDate;
  }

  set minDate(value: DateTime | null) {
    this._minDate = this._adapter.getValidDateOrNull(value);
  }

  private _maxDate: DateTime | null;

  /** The maximum selectable date. */
  @Input()
  get maxDate(): DateTime | null {
    return this._maxDate;
  }

  set maxDate(value: DateTime | null) {
    this._maxDate = this._adapter.getValidDateOrNull(value);
  }

  /**
   * The current active date. This determines which time period is shown and which date is
   * highlighted when using keyboard navigation.
   */
  get _activeDate(): DateTime {
    return this._clampedActiveDate;
  }

  set _activeDate(value: DateTime) {
    const oldActiveDate = this._clampedActiveDate;
    this._clampedActiveDate = this._adapter.clampDate(value, this.minDate, this.maxDate);
    if (oldActiveDate && this._clampedActiveDate && this.currentView === 'month' &&
      !this._adapter.sameMonthAndYear(oldActiveDate, this._clampedActiveDate)) {
      if (this._adapter.isInNextMonth(oldActiveDate, this._clampedActiveDate)) {
        this.calendarState('right');
      } else {
        this.calendarState('left');
      }
    }
  }

  /** Whether the calendar is in month view. */
  _currentView: MatCalendarView;

  get currentView(): MatCalendarView {
    return this._currentView;
  }

  set currentView(view: MatCalendarView) {
    this._currentView = view;
    this.viewChanged.emit(view);
  }

  /** The label for the current calendar view. */
  get _yearLabel(): string {
    return this._adapter.getYearName(this._activeDate);
  }

  get _monthYearLabel(): string {

    if (this.currentView === 'multi-year') {
      // The offset from the active year to the 'slot' for the starting year is the
      // *actual* first rendered year in the multi-year view, and the last year is
      // just yearsPerPage - 1 away.
      const activeYear = this._adapter.getYear(this._activeDate);
      const minYearOfPage = activeYear - getActiveOffset(
        this._adapter, this._activeDate, this.minDate, this.maxDate);
      const maxYearOfPage = minYearOfPage + yearsPerPage - 1;
      const minYearName =
        this._adapter.getYearName(this._adapter.createDate(minYearOfPage, 0, 1));
      const maxYearName =
        this._adapter.getYearName(this._adapter.createDate(maxYearOfPage, 0, 1));
      return this._intl.formatYearRange(minYearName, maxYearName);
    }

    return this.currentView === 'month' ? this._adapter.getMonthNames('long')[this._adapter.getMonth(this._activeDate)] :
      this._adapter.getYearName(this._activeDate);
  }

  get _currentMonth(): number {
    return this._activeDate.month;
  }

  get _dateLabel(): string {
    switch (this.type) {
      case 'month':
        return this._adapter.getMonthNames('long')[this._currentMonth];
      default:
        return this._adapter.format(this._activeDate, this._dateFormats.display.popupHeaderDateLabel);
    }
  }

  get _hoursLabel(): string {
    let hour = this._adapter.getHour(this._activeDate);

    if (!!this.twelvehour) {
      if (hour === 0) {
        hour = 24;
      }
      hour = hour > 12 ? (hour - 12) : hour;
    }

    return this._2digit(hour);
  }

  get _minutesLabel(): string {
    return this._2digit(this._adapter.getMinute(this._activeDate));
  }

  get _secondsLabel(): string {
    return this._2digit(this._adapter.getSeconds(this._activeDate));
  }

  get _ariaLabelNext(): string {
    switch (this._currentView) {
      case 'month':
        return this.ariaNextMonthLabel;
      case 'year':
        return this.ariaNextYearLabel;
      case 'multi-year':
        return this.ariaNextMultiYearLabel;
      default:
        return '';
    }
  }

  get _ariaLabelPrev(): string {
    switch (this._currentView) {
      case 'month':
        return this.ariaPrevMonthLabel;
      case 'year':
        return this.ariaPrevYearLabel;
      case 'multi-year':
        return this.ariaPrevMultiYearLabel;
      default:
        return '';
    }
  }

  /** Date filter for the month and year views. */
  _dateFilterForViews = (date: DateTime) => {
    return !!date &&
      (!this.dateFilter || this.dateFilter(date, MatDatetimepickerFilterType.DATE)) &&
      (!this.minDate || this._adapter.compareDate(date, this.minDate) >= 0) &&
      (!this.maxDate || this._adapter.compareDate(date, this.maxDate) <= 0);
  }

  _userSelected(): void {
    this._userSelection.emit();
  }

  ngAfterContentInit(): void {
    this._activeDate = this.startAt || this._adapter.setTimezone(this._adapter.today(), this.timezone, true);
    this._selectAMPM(this._activeDate);
    this._focusActiveCell();
    if (this.type === 'year') {
      this.currentView = 'multi-year';
    } else if (this.type === 'month') {
      this.currentView = 'year';
    } else {
      this.currentView = this.startView || 'month';
    }

    this._activeHours = this._hoursLabel;
    this._activeMinutes = this._minutesLabel;
    this._activeSeconds = this._secondsLabel;
  }

  ngOnDestroy(): void {
    this._intlChanges.unsubscribe();
  }

  /** Handles date selection in the month view. */
  _dateSelected(date: DateTime): void {
    if (this.type === 'date') {
      if (!this._adapter.sameDate(date, this.selected) || !this.preventSameDateTimeSelection) {
        this._activeDate = date;
        this._apply();
      }
    } else {
      this._activeDate = date;
      this.currentView = 'month';
    }
  }

  /** Handles month selection in the year view. */
  _monthSelected(month: DateTime): void {
    if (this.type === 'month') {
      if (!this._adapter.sameMonthAndYear(month, this.selected) || !this.preventSameDateTimeSelection) {
        this.selectedChange.emit(this._adapter.getFirstDateOfMonth(month));
      }
    } else {
      this._activeDate = month;
      this.currentView = 'month';
      this._clockView = 'hour';
    }
  }

  /** Handles year selection in the multi year view. */
  _yearSelected(year: DateTime): void {
    if (this.type === 'year') {
      if (!this._adapter.sameYear(year, this.selected)  || !this.preventSameDateTimeSelection) {
        let normalizedDate = this._adapter.setMonth(year, 1);
        normalizedDate = this._adapter.setDay(normalizedDate, 1);
        normalizedDate = this._adapter.setHours(normalizedDate, 0);
        normalizedDate = this._adapter.setMinutes(normalizedDate, 0);

        this.selectedChange.emit(normalizedDate);
      }
    } else {
      this._activeDate = year;
      this.currentView = 'year';
    }
  }

  _timeSelected(date: DateTime): void {
    if (this._clockView !== 'minute') {
      this._activeDate = this._updateDate(date);
      this._clockView = 'minute';
    } else {
      if (!this._adapter.sameDatetime(date, this.selected) || !this.preventSameDateTimeSelection) {
        this.selectedChange.emit(date);
      }
    }
  }

  _updateDate(date: DateTime): DateTime {
    if (!!this.twelvehour) {
      const HOUR = this._adapter.getHour(date);
      if (HOUR === 12) {
        if (this._AMPM === 'AM') {
          return this._adapter.addCalendarHours(date, -12);
        }
      } else if (this._AMPM === 'PM') {
        return this._adapter.addCalendarHours(date, 12);
      }
    }
    return date;
  }

  _selectAMPM(date: DateTime): void {
    if (this._adapter.getHour(date) > 11) {
      this._AMPM = 'PM';
    } else {
      this._AMPM = 'AM';
    }
  }

  _ampmClicked(source: string): void {
    if (source === this._AMPM) {
      return;
    }
    this._AMPM = source;
    if (this._AMPM === 'AM') {
      this._activeDate = this._adapter.addCalendarHours(this._activeDate, -12);
    } else {
      this._activeDate = this._adapter.addCalendarHours(this._activeDate, 12);
    }

  }

  _yearClicked(): void {
    if (this.type === 'year' || this.multiYearSelector) {
      this.currentView = 'multi-year';
      return;
    }
    this.currentView = 'year';
  }

  _dateClicked(): void {
    if (this.type !== 'month') {
      this.currentView = 'month';
    }
  }

  _toggleMonth(month: number): void {
    this._activeDate = this._adapter.setMonth(this._activeDate, month);
  }

  _updateHours(hours: string): void {
    if(hours == '') {
      return;
    }

    this._activeHours = this._addValue(hours, 0, 23);
    const date = this._adapter.setHours(this._activeDate, parseInt(this._activeHours, 10));

    const clamped = this._adapter.clampDate(date, this.minDate, this.maxDate);
    if (date === clamped) {
      this._activeDate = clamped;
    }
  }

  _updateMinutes(minutes: string): void {
    if(minutes == '') {
      return;
    }

    this._activeMinutes = this._addValue(minutes, 0, 59);
    const date = this._adapter.setMinutes(this._activeDate, parseInt(this._activeMinutes, 10));

    const clamped = this._adapter.clampDate(date, this.minDate, this.maxDate);
    if (date === clamped) {
      this._activeDate = clamped;
    }
  }

  _updateSeconds(seconds: string): void {
    if(seconds == '') {
      return;
    }

    this._activeSeconds = this._addValue(seconds, 0, 59);
    const date = this._adapter.setSeconds(this._activeDate, parseInt(this._activeSeconds, 10));

    const clamped = this._adapter.clampDate(date, this.minDate, this.maxDate);
    if (date === clamped) {
      this._activeDate = clamped;
    }
  }

  _scrollHours($event: WheelEvent): void {
    this._addHours(this._wheelOffset($event));
  }

  _scrollMinutes($event: WheelEvent): void {
    this._addMinutes(this._wheelOffset($event));
  }

  _scrollSeconds($event: WheelEvent): void {
    this._addSeconds(this._wheelOffset($event));
  }

  private _wheelOffset($event: WheelEvent): number {
    return $event.deltaY > 2 ? 1 : $event.deltaY < -2 ? -1 : 0
  }

  private _addValue(value: string, addValue: number, maxValue: number): string {
    let number = parseInt(value, 10);

    number = (number + addValue) > maxValue ? 0 : (number + addValue);

    return this._2digit(number < 0 ? maxValue : number);
  }

  _addHours(hours: number): void {
    this._activeHours = this._addValue(this._activeHours, hours, 23);
    this._updateHours(this._activeHours);
  }

  _addMinutes(minutes: number): void {
    this._activeMinutes = this._addValue(this._activeMinutes, minutes, 59);
    this._updateMinutes(this._activeMinutes);
  }

  _addSeconds(seconds: number): void {
    this._activeSeconds = this._addValue(this._activeSeconds, seconds, 59);
    this._updateSeconds(this._activeSeconds);
  }

  _today(): void {
    this._activeDate = this._adapter.setTimezone(this._adapter.today(), this.timezone);

    if(this.type.endsWith('time')) {
      this._activeMinutes = this._minutesLabel;
      this._activeHours = this._hoursLabel;
      this._activeSeconds = this._secondsLabel;
    }
    this._apply();
  }

  _apply(): void {
    switch(this.type) {
      case 'date':
        this._activeDate = this._adapter.toNoon(this._activeDate);
        break;
      case 'datetime':
        this._activeDate = this._adapter.setSeconds(this._activeDate,  0);
        break;
      case 'long-datetime':
        this._activeDate = this._adapter.setSeconds(this._activeDate,  parseInt(this._activeSeconds, 10));
        break;
    }

    this.selectedChange.emit(this._activeDate);
  }

  /** Handles user clicks on the previous button. */
  _previousClicked(): void {
    this._activeDate = this.currentView === 'month' ?
      this._adapter.addCalendarMonths(this._activeDate, -1) :
      this._adapter.addCalendarYears(
        this._activeDate, this.currentView === 'year' ? -1 : -yearsPerPage
      );
  }

  /** Handles user clicks on the next button. */
  _nextClicked(): void {
    this._activeDate = this.currentView === 'month' ?
      this._adapter.addCalendarMonths(this._activeDate, 1) :
      this._adapter.addCalendarYears(
        this._activeDate, this.currentView === 'year' ? 1 : yearsPerPage
      );
  }

  /** Whether the previous period button is enabled. */
  _previousEnabled(): boolean {
    if (!this.minDate) {
      return true;
    }
    return !this.minDate || !this._isSameView(this._activeDate, this.minDate);
  }

  /** Whether the next period button is enabled. */
  _nextEnabled(): boolean {
    return !this.maxDate || !this._isSameView(this._activeDate, this.maxDate);
  }

  /** Handles keydown events on the calendar body. */
  _handleCalendarBodyKeydown(event: KeyboardEvent): void {
    if (this.currentView === 'month') {
      this._handleCalendarBodyKeydownInMonthView(event);
    } else if (this.currentView === 'year') {
      this._handleCalendarBodyKeydownInYearView(event);
    } else if (this.currentView === 'multi-year') {
      this._handleCalendarBodyKeydownInMultiYearView(event);
    } else {
      this._handleCalendarBodyKeydownInClockView(event);
    }
  }

  _focusActiveCell(): void {
    this._ngZone.runOutsideAngular(() => {
      this._ngZone.onStable.asObservable().pipe(first()).subscribe(() => {
        this._elementRef.nativeElement.focus();
      });
    });
  }

  _calendarStateDone(): void {
    this._calendarState = '';
  }

  _shouldEnableMonth(month: number): boolean {
    if(!this.minDate && !this.maxDate) {
      return true;
    }

    let shouldEnable: boolean;
    const activeYear = this._adapter.getYear(this._activeDate);

    if(this.minDate) {
      const minYear = this._adapter.getYear(this.minDate);
      const minMonth = this._adapter.getMonth(this.minDate);

      shouldEnable = activeYear > minYear || (activeYear == minYear && month > minMonth);
    }

    if(shouldEnable !== false && this.maxDate) {
      const maxYear = this._adapter.getYear(this.maxDate);
      const maxMonth = this._adapter.getMonth(this.maxDate);

      shouldEnable = activeYear < maxYear || (activeYear == maxYear && month < maxMonth);
    }

    return shouldEnable;
  }

  /** Whether the two dates represent the same view in the current view mode (month or year). */
  private _isSameView(date1: DateTime, date2: DateTime): boolean {
    if (this.currentView === 'month') {
      return this._adapter.getYear(date1) === this._adapter.getYear(date2) &&
        this._adapter.getMonth(date1) === this._adapter.getMonth(date2);
    }
    if (this.currentView === 'year') {
      return this._adapter.getYear(date1) === this._adapter.getYear(date2);
    }
    // Otherwise we are in 'multi-year' view.
    return isSameMultiYearView(
      this._adapter, date1, date2, this.minDate, this.maxDate);
  }

  /** Handles keydown events on the calendar body when calendar is in month view. */
  private _handleCalendarBodyKeydownInMonthView(event: KeyboardEvent): void {
    // eslint-disable-next-line deprecation/deprecation
    switch (event.keyCode) {
      case LEFT_ARROW:
        this._activeDate = this._adapter.addCalendarDays(this._activeDate, -1);
        break;
      case RIGHT_ARROW:
        this._activeDate = this._adapter.addCalendarDays(this._activeDate, 1);
        break;
      case UP_ARROW:
        this._activeDate = this._adapter.addCalendarDays(this._activeDate, -7);
        break;
      case DOWN_ARROW:
        this._activeDate = this._adapter.addCalendarDays(this._activeDate, 7);
        break;
      case HOME:
        this._activeDate = this._adapter.addCalendarDays(this._activeDate,
          1 - this._adapter.getDate(this._activeDate));
        break;
      case END:
        this._activeDate = this._adapter.addCalendarDays(this._activeDate,
          (this._adapter.getNumDaysInMonth(this._activeDate) -
            this._adapter.getDate(this._activeDate)));
        break;
      case PAGE_UP:
        this._activeDate = event.altKey ?
          this._adapter.addCalendarYears(this._activeDate, -1) :
          this._adapter.addCalendarMonths(this._activeDate, -1);
        break;
      case PAGE_DOWN:
        this._activeDate = event.altKey ?
          this._adapter.addCalendarYears(this._activeDate, 1) :
          this._adapter.addCalendarMonths(this._activeDate, 1);
        break;
      case ENTER:
        if (this._dateFilterForViews(this._activeDate)) {
          this._dateSelected(this._activeDate);
          // Prevent unexpected default actions such as form submission.
          event.preventDefault();
        }
        return;
      default:
        // Don't prevent default or focus active cell on keys that we don't explicitly handle.
        return;
    }

    // Prevent unexpected default actions such as form submission.
    event.preventDefault();
  }

  /** Handles keydown events on the calendar body when calendar is in year view. */
  private _handleCalendarBodyKeydownInYearView(event: KeyboardEvent): void {
    // eslint-disable-next-line deprecation/deprecation
    switch (event.keyCode) {
      case LEFT_ARROW:
        this._activeDate = this._adapter.addCalendarMonths(this._activeDate, -1);
        break;
      case RIGHT_ARROW:
        this._activeDate = this._adapter.addCalendarMonths(this._activeDate, 1);
        break;
      case UP_ARROW:
        this._activeDate = this._prevMonthInSameCol(this._activeDate);
        break;
      case DOWN_ARROW:
        this._activeDate = this._nextMonthInSameCol(this._activeDate);
        break;
      case HOME:
        this._activeDate = this._adapter.addCalendarMonths(this._activeDate,
          -this._adapter.getMonth(this._activeDate));
        break;
      case END:
        this._activeDate = this._adapter.addCalendarMonths(this._activeDate,
          11 - this._adapter.getMonth(this._activeDate));
        break;
      case PAGE_UP:
        this._activeDate =
          this._adapter.addCalendarYears(this._activeDate, event.altKey ? -10 : -1);
        break;
      case PAGE_DOWN:
        this._activeDate =
          this._adapter.addCalendarYears(this._activeDate, event.altKey ? 10 : 1);
        break;
      case ENTER:
        this._monthSelected(this._activeDate);
        break;
      default:
        // Don't prevent default or focus active cell on keys that we don't explicitly handle.
        return;
    }

    // Prevent unexpected default actions such as form submission.
    event.preventDefault();
  }

  /** Handles keydown events on the calendar body when calendar is in multi-year view. */
  private _handleCalendarBodyKeydownInMultiYearView(event: KeyboardEvent): void {
    // eslint-disable-next-line deprecation/deprecation
    switch (event.keyCode) {
      case LEFT_ARROW:
        this._activeDate = this._adapter.addCalendarYears(this._activeDate, -1);
        break;
      case RIGHT_ARROW:
        this._activeDate = this._adapter.addCalendarYears(this._activeDate, 1);
        break;
      case UP_ARROW:
        this._activeDate = this._adapter.addCalendarYears(this._activeDate, -yearsPerRow);
        break;
      case DOWN_ARROW:
        this._activeDate = this._adapter.addCalendarYears(this._activeDate, yearsPerRow);
        break;
      case HOME:
        this._activeDate = this._adapter.addCalendarYears(this._activeDate,
          -getActiveOffset(this._adapter, this._activeDate, this.minDate, this.maxDate));
        break;
      case END:
        this._activeDate = this._adapter.addCalendarYears(this._activeDate,
          yearsPerPage - getActiveOffset(
          this._adapter, this._activeDate, this.minDate, this.maxDate) - 1);
        break;
      case PAGE_UP:
        this._activeDate =
          this._adapter.addCalendarYears(
            this._activeDate, event.altKey ? -yearsPerPage * 10 : -yearsPerPage);
        break;
      case PAGE_DOWN:
        this._activeDate =
          this._adapter.addCalendarYears(
            this._activeDate, event.altKey ? yearsPerPage * 10 : yearsPerPage);
        break;
      case ENTER:
        this._yearSelected(this._activeDate);
        break;
      default:
        // Don't prevent default or focus active cell on keys that we don't explicitly handle.
        return;
    }
  }

  /** Handles keydown events on the calendar body when calendar is in month view. */
  private _handleCalendarBodyKeydownInClockView(event: KeyboardEvent): void {
    // eslint-disable-next-line deprecation/deprecation
    switch (event.keyCode) {
      case UP_ARROW:
        this._activeDate = this._clockView === 'hour' ?
          this._adapter.addCalendarHours(this._activeDate, 1) :
          this._adapter.addCalendarMinutes(this._activeDate, 1);
        break;
      case DOWN_ARROW:
        this._activeDate = this._clockView === 'hour' ?
          this._adapter.addCalendarHours(this._activeDate, -1) :
          this._adapter.addCalendarMinutes(this._activeDate, -1);
        break;
      case ENTER:
        this._timeSelected(this._activeDate);
        return;
      default:
        // Don't prevent default or focus active cell on keys that we don't explicitly handle.
        return;
    }

    // Prevent unexpected default actions such as form submission.
    event.preventDefault();
  }

  /**
   * Determine the date for the month that comes before the given month in the same column in the
   * calendar table.
   */
  private _prevMonthInSameCol(date: DateTime): DateTime {
    // Determine how many months to jump forward given that there are 2 empty slots at the beginning
    // of each year.
    const increment = this._adapter.getMonth(date) <= 4 ? -5 :
      (this._adapter.getMonth(date) >= 7 ? -7 : -12);
    return this._adapter.addCalendarMonths(date, increment);
  }

  /**
   * Determine the date for the month that comes after the given month in the same column in the
   * calendar table.
   */
  private _nextMonthInSameCol(date: DateTime): DateTime {
    // Determine how many months to jump forward given that there are 2 empty slots at the beginning
    // of each year.
    const increment = this._adapter.getMonth(date) <= 4 ? 7 :
      (this._adapter.getMonth(date) >= 7 ? 5 : 12);
    return this._adapter.addCalendarMonths(date, increment);
  }

  private calendarState(direction: string): void {
    this._calendarState = direction;
  }

  private _2digit(n: number): string {
    return ('00' + n).slice(-2);
  }
}
