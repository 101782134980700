import { createAction, props } from '@ngrx/store';
import { IrisEmailFolderResponseI } from '../../models/IrisEmailNavigation';

const prefixed = (type: string): string => `[EmailsNavigation] ${type}`;

export const GetCustomFoldersStart = createAction(
  prefixed('Get Custom Folders Start'),
);

export const RefreshCustomFoldersStart = createAction(
  prefixed('Refresh Custom Folders Start'),
);

export const GetCustomFoldersSuccess = createAction(
  prefixed('Get Custom Folders Success'),
  props<{ folders: IrisEmailFolderResponseI[]; total: number }>(),
);

export const GetCustomFoldersError = createAction(
  prefixed('Get Custom Folders Error'),
);

export const GetCustomFolderStart = createAction(
  prefixed('Get Custom Folder Start'),
  props<{ folderId: string }>(),
);

export const GetCustomFolderSuccess = createAction(
  prefixed('Get Custom Folder Success'),
  props<{ folder: IrisEmailFolderResponseI }>(),
);

export const GetCustomFolderError = createAction(
  prefixed('Get Custom Folder Error'),
);

export const GetChildrenFoldersStart = createAction(
  prefixed('Get Children Folders Start'),
  props<{ parentFolderId: string }>(),
);

export const GetChildrenFoldersSuccess = createAction(
  prefixed('Get Children Folders Success'),
  props<{ folders: IrisEmailFolderResponseI[] }>(),
);

export const GetChildrenFoldersError = createAction(
  prefixed('Get Children Folders Error'),
);

export const CollapseNavigationFolderStart = createAction(
  prefixed('Collapse NavigationFolder Start'),
  props<{ folderId: string }>(),
);
