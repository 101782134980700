import { IrisMFFilterBase } from './IrisMFFilterBase';
import { IrisBasePropertyOptions } from './IrisModuleFilterPropertyOptionsParams';
import { Observable, of } from 'rxjs';

export class IrisMFBetween extends IrisMFFilterBase {
  constructor(fieldName, options: IrisBasePropertyOptions) {
    super(fieldName, options);
  }

  isInFilter(filter) {
    return filter[this.formFieldName] && (filter[this.formFieldName].from || filter[this.formFieldName].to || Object.keys(filter[this.formFieldName]).map(key => filter[key]).includes(0));
  }

  getFilterValue(val) {
    return [val.from, val.to];
  }

  getFilterValueMongo(val) {
    const res = {};
    if (val.from || val.from === 0) {
      res['$gte'] = val.from;
    }
    if (val.to || val.to === 0) {
      res['$lte'] = val.to;
    }
    return res;
  }

  getFilterParameters() {
    return { m: 'btw', s: false };
  }

  getFilterDescription(filter, showEqual = false): Observable<string> {
    const fromStr = this.options.stringifyFn ? this.options.stringifyFn(filter[this.formFieldName].from) : filter[this.formFieldName].from;
    const toStr = this.options.stringifyFn ? this.options.stringifyFn(filter[this.formFieldName].to) : filter[this.formFieldName].to;
    if (fromStr == toStr && !showEqual) {
      return of(`${this.label}: ${fromStr}`);
    }
    if (!fromStr) {
      return of(`${this.label}: <= ${toStr}`);
    }
    if (!toStr) {
      return of(`${this.label}: >= ${fromStr}`);
    }
    return of(`${this.label}: ${fromStr} - ${toStr}`);
  }
}
