import { Directive } from '@angular/core';
import { DATETIME_FORMAT_SELECT_PROVIDERS } from './datetime-format-select.providers';

@Directive({
  selector: 'ng-select[iris-entity=datetime-format]',
  providers: DATETIME_FORMAT_SELECT_PROVIDERS,
  standalone: true,
})
export class IrisDatetimeSelectDirective {
}
