import { IrisModuleFilterPropertyBase } from './IrisModuleFilterPropertyBase';
import { IrisBasePropertyOptions } from './IrisModuleFilterPropertyOptionsParams';

export class IrisMFFilterBase extends IrisModuleFilterPropertyBase {
  constructor(fieldName, options: IrisBasePropertyOptions) {
    super(fieldName, 'filter', options);
  }

  getFilter(filter) {
    const filterItem = (fieldName) => Object.assign({
      f: fieldName,
      v: this.getFilterValue(filter[this.formFieldName]),
    }, this.getFilterParameters());

    if (this.options.orFields && Array.isArray(this.options.orFields) && this.options.orFields.length) {
      return {
        or: [this.fieldName, ...this.options.orFields].map(fieldName => [filterItem(fieldName)]),
      };
    }

    return filterItem(this.fieldName);
  }

  getFilterValue(val) {
    if (Array.isArray(val) && val.length) {
      return val;
    }
    return [val];
  }

  getFilterParameters() {
    return {};
  }

  getFilterMongo(filter) {
    return {
      [this.fieldName]: this.getFilterValueMongo(filter[this.formFieldName]),
    };
  }

  getFilterValueMongo(val) {
    return val;
  }
}
