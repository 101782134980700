import { Type } from '@angular/core';

export class IrisAlert {
  id: string;
  type: IrisAlertType;
  message: string;
  rendererFramework: Type<any>;
  settings: IrisAlertSettings;
}

export interface IrisAlertSettings {
  dismissible: boolean;
  dismissTimeout: number;
}

export enum IrisAlertType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
  Log = 'log'
}
