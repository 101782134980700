import { IrisRange } from '@iris/modules/module-filters-v2/models';

export function adjustNumericValue(val: unknown): number {
  return val === 0 || +val ? +val : null;
}

function stringifyNumericRangeValue(val: unknown): string {
  return adjustNumericValue(val) === null ? '...' : String(val);
}

export function stringifyNumericRange(range: IrisRange<number>): string {
  return `${stringifyNumericRangeValue(range.from)} - ${stringifyNumericRangeValue(range.to)}`;
}
