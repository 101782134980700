import { TEXT_CHIP_RENDERING_OPTIONS } from '@iris/modules/module-filters-v2/chips/rendering-options';
import { appendTextQueryParamsFn } from '@iris/modules/module-filters-v2/query-params';
import { IrisFilterPropertyMeta } from './filter-property-meta';
import { filterValueUniversalIsEmpty } from '@iris/modules/module-filters-v2/utils';
import { IrisFieldTextComponent } from '@iris/common/modules/fields/field-text/field-text.component';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export function textFilterMeta(
  propertyId: string,
  label: string,
  fieldNames: string | string[] = propertyId,
  isStrict = false,
  hasTextSearchPattern = false,
  method = '',
): IrisFilterPropertyMeta<string> {
  return {
    propertyId,
    label,
    appendFilterQueryParamsFn: appendTextQueryParamsFn(fieldNames, isStrict, hasTextSearchPattern, method),
    chipRenderingOptions: TEXT_CHIP_RENDERING_OPTIONS,
    filterRenderingOptions: {
      component: IrisFieldTextComponent,
      getFilterValueFn: (componentInstance: IrisFieldTextComponent) => componentInstance.valueControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
      ),
      initComponentFn: (componentInstance: IrisFieldTextComponent) => {
        componentInstance.showClear = true;
        componentInstance.emitEventOnSetValue = false;
        componentInstance.enhancedControl.stylingMode = 'unformed';

        return of(null);
      },
      setValueFn: (componentInstance: IrisFieldTextComponent, value: string) => componentInstance.writeValue(value ?? null),
    },
    filterValueIsEmpty: filterValueUniversalIsEmpty,
  };
}
