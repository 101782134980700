import { AbstractControl, NgControl } from '@angular/forms';

export abstract class ControlRootComponent {
  get outerControl(): AbstractControl {
    return this.ngControl?.control;
  }

  protected constructor(
    protected readonly ngControl: NgControl,
  ) { }
}
