import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IrisUserInfoI } from '@iris/common/modules/user-common/models/IrisUserInfo';

@Component({
  selector: 'iris-header-search-result-for-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisHeaderSearchResultForUserComponent {
  @Input() userInfo: IrisUserInfoI;
}
