<div class="app-header">
  <nav class="navbar navbar-custom navbar-expand-md">
    <div class="container-fluid">
      <div class="navbar-header">
        <iris-header-dropdown-menu></iris-header-dropdown-menu>
      </div>
      <div class="navbar-ttl" [ngClass]="{'is-search-opened': searchIsOpened}">
        <ng-select #projectSelector
                   class="ng-select-inline ng-select-header project-selector"
                   data-test="header-current-project"
                   iris-entity="project"
                   appendTo="body"
                   [placeholder]="projectSelectorPlaceholder"
                   [formControl]="projectControl"
        ></ng-select>
        <span class="module-name">
          <header-title [titleChain]="pageTitleService.pageTitle$ | async"></header-title>
        </span>
      </div>
      <div class="collapse navbar-collapse justify-content-end">
        <div class="nav navbar-nav navbar-notifications"
             [ngClass]="(searchIsOpened ? 'mobile-hidden' : '' )">
          <iris-header-clock></iris-header-clock>
          <iris-header-notifications></iris-header-notifications>
          <iris-header-help></iris-header-help>
          <iris-header-settings></iris-header-settings>
        </div>
        <iris-header-search (searchClick)="toggleSearchExpansion($event)"></iris-header-search>
        <div class="nav navbar-nav nav-menu-profile">
          <iris-header-profile-info></iris-header-profile-info>
        </div>
      </div>
    </div>
  </nav>
</div>
