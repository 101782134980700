import { inject, Injectable } from '@angular/core';
import { PageTitleService } from '@iris/common/services/page-name/page-title.service';
import { ActivatedRouteSnapshot, PRIMARY_OUTLET, RouterStateSnapshot, TitleStrategy } from '@angular/router';

export const SKIP_EMPTY_TITLE = '__skip__empty__title__';

@Injectable()
export class PageTitleStrategyService extends TitleStrategy {
  private readonly pageTitle = inject(PageTitleService);

  constructor() { super(); }

  buildTitleChain(snapshot: RouterStateSnapshot): string[] {
    const res: string[] = [];
    let route: ActivatedRouteSnapshot|undefined = snapshot.root;
    while (route !== undefined) {
      let routeTitle = this.getResolvedTitleForRoute(route);
      routeTitle = routeTitle != null ? String(routeTitle).trim() : undefined;
      if (routeTitle && routeTitle !== res[res.length - 1]) {
        res.push(routeTitle);
      }
      route = route.children.find(child => child.outlet === PRIMARY_OUTLET);
    }
    return res;
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const titleChain = this.buildTitleChain(routerState);
    const lastTitle = titleChain.length ? titleChain[titleChain.length - 1] : undefined;
    if (lastTitle !== SKIP_EMPTY_TITLE) {
      this.pageTitle.setTabTitle(lastTitle);
      this.pageTitle.setPageTitle(titleChain.filter(x => x !== SKIP_EMPTY_TITLE));
    }
  }
}
