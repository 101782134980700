import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, Input, OnChanges } from '@angular/core';
import { IrisProjectSelectEngine } from './project-select.engine';
import { PROJECT_SELECT_PROVIDERS } from './project-select.providers';

@Directive({
  selector: 'ng-select[iris-entity=project]',
  providers: PROJECT_SELECT_PROVIDERS,
})
export class IrisProjectSelectDirective implements OnChanges {
  @Input('limitedProjectIds') searchOnlyInProjects: number[];
  @Input() showCostCenter: boolean;
  @Input() excludeProjectIds: number[];
  @Input() hideRecentProjects: boolean;

  constructor(
    private readonly engine: IrisProjectSelectEngine,
  ) {
  }

  ngOnChanges(changes: SimpleChanges<this>): void {
    if (changes.searchOnlyInProjects) {
      this.engine.searchOnlyInProjects(changes.searchOnlyInProjects.currentValue);
    }

    if (changes.showCostCenter) {
      this.engine.options.showCostCenter = coerceBooleanProperty(this.showCostCenter);
    }

    if (changes.hideRecentProjects) {
      this.engine.options.hideRecentProjects = coerceBooleanProperty(this.hideRecentProjects);
    }

    if (changes.excludeProjectIds) {
      this.engine.excludeProjects(changes.excludeProjectIds.currentValue);
    }
  }
}
