<form [formGroup]="formGroup">
  <mat-toolbar class="iris-relative-datetime-header">
    <mat-button-toggle-group [formControlName]="'valueType'">
      <mat-button-toggle [value]="DatetimePlaceholderValueType.Absolute"
                         [checked]="formGroup.value.valueType !== DatetimePlaceholderValueType.Relative">
        {{ 'label.Absolute' | translate }}
      </mat-button-toggle>
      <mat-button-toggle [value]="DatetimePlaceholderValueType.Relative">
        {{ 'label.Relative' | translate }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </mat-toolbar>

  <section class="iris-relative-datetime-body"
           *ngIf="formGroup.value.valueType === DatetimePlaceholderValueType.Relative">
    <div class="iris-relative-datetime-input">
      <ng-select
        class="iris-form-field"
        [appendTo]="'body'"
        [formControlName]="'type'"
        [clearable]="false"
        [searchable]="false"
        [placeholder]="'label.df.PlaceholderType' | translate"
        required
        data-test="type">
        <ng-option [value]="DatetimePlaceholderRelType.Now" data-test="type-option-now">
          {{ (showTimeOptions ? 'label.Now' : 'label.Today') | translate }}
        </ng-option>

        <ng-option [value]="DatetimePlaceholderRelType.Add" data-test="type-option-add">
          {{ 'label.Add' | translate }}
        </ng-option>

        <ng-option [value]="DatetimePlaceholderRelType.StartOf" data-test="type-option-start-of">
          {{ 'label.df.StartOf' | translate }}
        </ng-option>

        <ng-option [value]="DatetimePlaceholderRelType.EndOf" data-test="type-option-end-of">
          {{ 'label.df.EndOf' | translate }}
        </ng-option>
      </ng-select>

      <ng-container *ngIf="showRelativeExtraOptions">
        <ng-select
          class="iris-form-field"
          [appendTo]="'body'"
          [formControlName]="'part'"
          [clearable]="false"
          [searchable]="false"
          [placeholder]="'label.df.PlaceholderPart' | translate"
          required
          data-test="part"
        >
          <ng-option [value]="DatetimePlaceholderRelPart.Hour" *ngIf="showTimeOptions" data-test="part-option-hour">
            {{ 'label.Hour' | translate }}
          </ng-option>

          <ng-option [value]="DatetimePlaceholderRelPart.Day" data-test="part-option-day">
            {{ 'label.Day' | translate }}
          </ng-option>

          <ng-option [value]="DatetimePlaceholderRelPart.Week" data-test="part-option-week">
            {{ 'label.Week' | translate }}
          </ng-option>

          <ng-option [value]="DatetimePlaceholderRelPart.Month" data-test="part-option-month">
            {{ 'label.Month' | translate }}
          </ng-option>

          <ng-option [value]="DatetimePlaceholderRelPart.Year" data-test="part-option-year">
            {{ 'label.Year' | translate }}
          </ng-option>
        </ng-select>

        <iris-number-field
          [formControlName]="'shift'"
          [required]="shiftIsRequired"
          [label]="'+/-'"
          data-test="shift"
        >
        </iris-number-field>

      </ng-container>
    </div>
  </section>

  <mat-toolbar class="iris-relative-datetime-footer"
               *ngIf="formGroup.value.valueType === DatetimePlaceholderValueType.Relative">
    <button mat-button color="accent" (click)="apply()" [disabled]="formGroup.invalid">
      {{ 'label.Apply' | translate }}
    </button>
  </mat-toolbar>
</form>
