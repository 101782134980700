<ng-template #template let-item="item">
  <iris-user-ws class="user-as-chip"
                [ngClass]="{ 'with-avatar': options.showAvatarForSelected }"
                [noHint]="true"
                [userId]="getUserId(item)"
                [avatarSize]="'sm'"
                [showAvatar]="options.showAvatarForSelected"
                [showCompany]="options.showCompanyForSelected"
                [showEmail]="options.showEmailForSelected"></iris-user-ws>
</ng-template>
