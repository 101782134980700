import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrl, IrisBaseCRUDService } from '@iris/api-query';
import { IrisLanguageI } from '../../models/IrisLanguage';
import { map, tap } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { IrisQueryParams } from '@iris/api-query';
import { TranslateService } from '@ngx-translate/core';
import { IrisGlobalSettingsService, IrisModuleAliasType } from '@iris/common/services/settings/global-settings.service';

@Injectable({ providedIn: 'root' })
@ApiUrl('/system/i18n/languages')
export class IrisLanguageService extends IrisBaseCRUDService<IrisLanguageI> {
  private languages: IrisLanguageI[] = [];

  constructor(
    httpClient: HttpClient,
    private readonly translateService: TranslateService,
    private readonly globalSettingsService: IrisGlobalSettingsService,
  ) {
    super(httpClient);
  }

  getAll(params?: IrisQueryParams): Observable<IrisLanguageI[]> {
    return super.getAll(params).pipe(
      map((languages: IrisLanguageI[]) => {
        return languages.map(lang => {
          return {
            ...lang,
            title: this.translateService.instant(`label.${lang.name}`),
          };
        });
      }),
      tap(languages => {
        this.languages = languages;
      }),
    );
  }

  getLanguagesNames(languages: string[]): string[] {
    if (!this.languages) {
      return languages;
    }

    return languages.map((languageName) => {
      const existingLanguage = this.languages.find(_lang => _lang.name === languageName);
      return existingLanguage ? this.translateService.instant(existingLanguage.title) : languageName;
    });
  }

  getSystemLanguages(): Observable<IrisLanguageI[]> {
    return forkJoin([
      this.globalSettingsService.getGlobalSettingsById$<{ preferredLanguages: string[] }>(IrisModuleAliasType.Translations),
      this.getAll(),
    ]).pipe(
      map(([translationsSettings, allLanguages]) => {
        return allLanguages
          .filter(l =>  translationsSettings.value.preferredLanguages.includes(l.name))
          .sort((a, b) => a.name.localeCompare(b.name));
      }),
    );
  }
}
