import { IrisQueryParamsBuilder } from '@iris/api-query';
import { AbstractDatetime, DatetimeUnit } from '@iris/common/modules/date';
import { DatetimePlaceholder, mapPlaceholderToAbstractDatetime } from '@iris/common/modules/date/datetime-placeholder';
import { IrisRange } from '@iris/modules/module-filters-v2/models';
import { defaultAppendQueryParamsFn, IrisFilterAppendQueryParamsContext, IrisFilterAppendQueryParamsFn } from './append-filter-query-params-fn';

function fixDate(dateValue: Date, timeZone: string, fn: string, accuracy: DatetimeUnit): Date {
  return dateValue && (AbstractDatetime.parse(dateValue).setTimezone(timeZone)[fn](accuracy) as AbstractDatetime).toJSDate();
}

export function appendDateRangeQueryParamsFn(fieldName: string, timeZone: string, accuracy: DatetimeUnit): IrisFilterAppendQueryParamsFn {
  return (builder: IrisQueryParamsBuilder, val: IrisRange<Date>) => {
    if (!val?.from && !val?.to) { return builder; }
    const from = val.from || null;
    const to = val.to || null;

    return builder.filter(fieldName, [
      fixDate(from, timeZone, 'startOf', accuracy),
      fixDate(to, timeZone, 'endOf', accuracy),
    ], t => t.method('btw').strict(false));
  };
}

export function appendDatetimePlaceholderRangeQueryParamsFn(fieldName: string, accuracy: DatetimeUnit): IrisFilterAppendQueryParamsFn {
  return defaultAppendQueryParamsFn<IrisRange<DatetimePlaceholder>>(
    fieldName,
    (range, { timezone } = {} as IrisFilterAppendQueryParamsContext) => {
      return [
        range?.from ? mapPlaceholderToAbstractDatetime(range.from, timezone)?.startOf(accuracy).setUtcTimezone() : null,
        range?.to ? mapPlaceholderToAbstractDatetime(range.to, timezone)?.endOf(accuracy).setUtcTimezone() : null,
      ];
    },
    t => t.method('btw').strict(false),
  );
}
