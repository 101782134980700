<div class="screensaver-container ag-sparks">
  <div class="layout layout__background">
    <div class="layout layout__sky">

      <div class="slogan">
        <div class="work-on-progress"></div>
        <!--<div class="halloween-text">Happy Halloween!</div>-->
      </div>

      <div class="layout layout__text">
        <div class="letter-container">
          <div class="letter-s">S</div>
        </div>
        <div class="letter-container up">
          <div class="animation-shake-little">T</div>
          <div class="cafe">
            <div class="crane-1 animation-shake-little"></div>

            <div class="path"></div>

            <div class="tanker">
              <div class="worker-4"></div>
            </div>

            <div class="ghost">
              <div class="spooky">
                <div class="body">
                  <div class="eyes"></div>
                  <div class="mouth"></div>
                  <div class="feet">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="shadow"></div>
            </div>

            <div class="pumpkin-container">
              <div class="pumpkin">
                <div class="texture"></div>
                <div class="root"></div>
                <div class="eye-left"></div>
                <div class="eye-right"></div>
                <div class="mouth-left"></div>
                <div class="mouth-right"></div>
                <div class="teeth"></div>
              </div>
            </div>

            <div class="dracula">
              <div class="head">
                <div class="sides"></div>
                <div class="teeth"></div>
                <div class="eyes"></div>
                <div class="eyelids"></div>
              </div>
              <div class="ears"></div>
              <div class="collar"></div>
              <div class="arms"></div>
              <div class="text">Happy Halloween!</div>
            </div>
          </div>
        </div>
        <div class="letter-container">
          <div>R</div>
          <div class="worker-1"></div>

          <div class="bat-container">
            <div class="bat-wrapper">
              <div class="bat">
                <div class="bat-leg-1"></div>
                <div class="bat-leg-2"></div>
              </div>
            </div>
            <div class="bat-shadow"></div>
          </div>
        </div>
        <div class="letter-container up animation-up-down">
          <span class="rotate">V</span>
          <div class="helicopter">
            <div class="rudder"></div>
          </div>
        </div>
        <div class="letter-container up">
          <div class="animation-down-up">B</div>
          <div class="crane-2"></div>
        </div>
        <div class="letter-container">
          <div class="rotate animation-shake-letter">V</div>
          <div class="quadcopter animation-circle delayed">
            <div class="signal"></div>
            <div class="camera"></div>
          </div>
          <div class="tower">
            <div class="worker-3 animation-shake"></div>
          </div>
        </div>
        <div class="letter-container up animation-left-right letter-g-container">
          <div>G</div>
          <div class="elevator"></div>
          <div class="worker-2"></div>
        </div>
      </div>
    </div>

    <div class="layout layout__buildings">
      <div class="car-1 animation-shake-little" style="animation-delay: 0.5s;"></div>
    </div>

    <div class="layout layout__road">

    </div>

    <div class="witch witch-1"></div>
    <div class="witch witch-2"></div>
    <div class="witch witch-3"></div>
  </div>

  <div class="ag-spark"></div>
  <div class="ag-lightning"></div>

  <button class="btn-cancel btn btn-link" (click)="cancel($event)">
    <i class="fal fa-times"></i>
  </button>
</div>
