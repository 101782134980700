import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IrisModalBase } from '@iris/common/utils/iris-modal.base';
@Component({
  selector: 'confirm-modal',
  styleUrls: ['./confirm-modal.component.scss'],
  templateUrl: './confirm-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent extends IrisModalBase {
  public isHideCloseBtn: boolean;
  constructor(
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      cancelBtnText: string;
      confirmBtnText: string;
      message: string;
      isHideCloseBtn: boolean;
      confirmBtnIconClass: string;
      isDangerButton: boolean;
      disableConfirmBtn: boolean;
    },
  ) {
    super(dialogRef);
    this.isHideCloseBtn = !!data.isHideCloseBtn;
  }

  confirm(): void {
    this.closeWithResult(true);
  }

}
