import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  Inject,
  Optional,
  Host,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { IrisFieldBaseComponent } from '../field-base/field-base.component';
import { EnhancedControlDirective } from '../base-control/enhanced-control.component';

@Component({
  selector: 'iris-field-checkbox',
  templateUrl: './field-checkbox.component.html',
  styleUrls: ['./field-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IrisFieldCheckboxComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [{
    directive: EnhancedControlDirective,
    inputs: ['stylingMode'],
  }],
})
export class IrisFieldCheckboxComponent extends IrisFieldBaseComponent implements ControlValueAccessor {
  @Input() indeterminate = false;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: (Function | Validator)[],
    @Host() public readonly enhancedControl: EnhancedControlDirective,
  ) {
    super(validators);
    enhancedControl.stylingMode = 'customized';
  }

  focus(): void {
    this.input.nativeElement.focus();
  }

  get checkboxColor(): ThemePalette {
    return !this.valueControl.value ? 'primary' : 'accent';
  }

  isFieldInvalid(): boolean {
    return this.valueControl.touched && this.valueControl.invalid;
  }

  isFieldValid(): boolean {
    return this.valueControl.touched && this.valueControl.valid;
  }

  isValidationError(): boolean {
    return this.isFieldInvalid() && this.isRequired;
  }

  isHintShown(): boolean {
    return this.hint && (this.valueControl.pristine || this.valueControl.valid);
  }
}
