import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component( { selector: 'iris-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisSkeletonLoaderComponent {
  @Input() height = '25px';
  @Input() width = null;
}
