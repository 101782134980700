import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * @deprecated Use IrisIfLoaded/IrisOverlayLoader instead
 */
@Injectable({ providedIn: 'root' })
export class IrisLoaderService {

  private readonly defaultPlace = 'body';
  private currentPlace = '';
  private readonly body: HTMLElement;

  private readonly bgLoaderCls = 'bg-loading';

  private readonly renderer: Renderer2;

  constructor(private readonly rendererFactory: RendererFactory2,
              @Inject(DOCUMENT) private readonly document: Document) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.body = this.document.body;
  }

  get isLoading(): boolean {
    return this.body.classList.contains('loading');
  }

  start(place: string = this.defaultPlace, text = ''): boolean {
    const tmpl = this.renderer.createElement('div');
    this.renderer.addClass(tmpl, 'loader');

    this.currentPlace = place;

    const innerDiv = this.renderer.createElement('div');
    this.renderer.addClass(innerDiv, 'animate');
    this.renderer.appendChild(tmpl, innerDiv);

    if (text != '') {
      const divWithText = this.renderer.createElement('div');
      this.renderer.addClass(divWithText, 'animate-text');

      const spanForText = this.renderer.createElement('span');
      this.renderer.setValue(spanForText, text);
      this.renderer.appendChild(divWithText, spanForText);

      this.renderer.appendChild(tmpl, divWithText);
    }

    const targetPlace = this.document.querySelector(place);

    if (targetPlace) {
      const loader = this.foundElement(targetPlace.children);

      if (!loader) {
        const container = this.renderer.createElement('div');
        this.renderer.addClass(container, 'loader');
        this.renderer.appendChild(container, tmpl);
        this.renderer.appendChild(targetPlace, container);
      }

      if (!this.isLoading) {
        this.renderer.addClass(this.document.body, 'loading');
      }
      return true;
    }
    return false;
  }

  isRunning(): boolean {
    if (!this.currentPlace) {
      return false;
    }

    const place = this.document.querySelector(this.currentPlace);

    return !!(this.isLoading && place && this.foundElement(place.children));
  }

  stop(curPlace: string = this.currentPlace): boolean {
    if (curPlace != this.currentPlace) {
      return false;
    }

    if (this.isLoading) {
      this.renderer.removeClass(this.body, 'loading');
    }

    if (curPlace !== '') {
      const place = this.document.querySelector(curPlace);
      if (place) {
        const loader = this.foundElement(place.children);
        if (loader) {
          loader.remove();
        }
      }
    }
    return true;
  }

  foundElement(elements: HTMLCollection): HTMLElement | undefined {
    return Array.from(elements).find((child: HTMLElement) => child.classList.contains('loader')) as HTMLElement;
  }

  bgStart(place: string): void {
    const el = this.document.querySelector(place);

    if (el && !el.classList.contains(this.bgLoaderCls)) {
      this.renderer.addClass(el, this.bgLoaderCls);
    }
  }

  bgStop(place: string): void {
    const el = this.document.querySelector(place);

    if (el && el.classList.contains(this.bgLoaderCls)) {
      this.renderer.removeClass(el, this.bgLoaderCls);
    }
  }
}
