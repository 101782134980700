import { ChangeDetectionStrategy, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { IrisNgSelectFieldSearchEngine, SEARCH_ENGINE } from '@iris/common/modules/fields/ng-select-field/search-engine';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface Context {
  totalCount: number;
  shownCount: number;
}

@Component({
  selector: 'iris-ng-select-field-shown-elements-count',
  templateUrl: './shown-elements-count.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisNgSelectFieldShownElementsCountComponent {
  @ViewChild('template', { static: true }) template: TemplateRef<unknown>;
  context$: Observable<Context>;

  constructor(
    @Inject(SEARCH_ENGINE) _searchEngine: IrisNgSelectFieldSearchEngine<unknown, unknown>,
  ) {
    this.context$ = _searchEngine.result$
      .pipe(
        map(page => ({
          totalCount: page.count,
          shownCount: page.elements.length,
        })),
      );
  }
}
