import { Directive, Input, OnChanges } from '@angular/core';
import { DOCUMENT_TEMPLATE_SELECT_PROVIDERS } from '@iris/common/modules/fields/document-template-select/document-template-select.providers';
import { IrisDocumentTemplateSelectEngine } from '@iris/common/modules/fields/document-template-select/document-template-select.engine';
import { IrisQueryParams } from '@iris/api-query';

@Directive({
  selector: 'ng-select[iris-entity=document-template]',
  providers: DOCUMENT_TEMPLATE_SELECT_PROVIDERS,
})
export class IrisDocumentTemplateSelectDirective implements OnChanges {
  @Input() queryParams: IrisQueryParams;

  constructor(
    private readonly engine: IrisDocumentTemplateSelectEngine,
  ) { }

  ngOnChanges(changes: SimpleChanges<this>): void {
    if (changes.queryParams) {
      this.engine.setExtraQueryParams(changes.queryParams.currentValue);
    }
  }
}
