import { Injectable, OnDestroy } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { IrisModuleFilterBodyComponent } from '../components/module-filter-body/module-filter-body.component';
import { select, Store } from '@ngrx/store';
import * as fromGlobal from '@iris/common/redux/reducers';
import * as fromModuleFilter from '../store/module.filter.reducer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class IrisModuleFilterOverlayService implements OnDestroy {
  private overlayRef: OverlayRef;
  private readonly filterPanelPortal: ComponentPortal<IrisModuleFilterBodyComponent>;
  private readonly unsubscribe$: Subject<void> = new Subject();

  constructor(private readonly overlay: Overlay, private readonly store: Store<fromGlobal.State>) {
    this.filterPanelPortal = new ComponentPortal(IrisModuleFilterBodyComponent);

    this.store.pipe(select(fromModuleFilter.getModuleVisibility))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(visible => visible ? this.openFilter() : this.closeFilter());
  }

  private getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    return new OverlayConfig({
      positionStrategy,
      panelClass: 'filter-custom-panel',
    });
  }

  openFilter(): void {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    const component = this.overlayRef.attach(this.filterPanelPortal);
    this.overlayRef.hostElement.classList.add('filter-overlay-container');
    component.instance.setOverlayElem(this.overlayRef.hostElement.parentElement);
  }

  closeFilter(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
