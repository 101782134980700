<table class="mat-datetimepicker-calendar-table">
  <thead class="mat-datetimepicker-calendar-table-header">
  <tr>
    <th class="iris-datetime-week-number" *ngIf="showWeekNumbers"></th>
    <th *ngFor="let day of _weekdays" [attr.aria-label]="day.long">{{day.narrow}}</th>
  </tr>
  </thead>
  <tbody (@slideCalendar.done)="_calendarStateDone()"
         (selectedValueChange)="_dateSelected($event)"
         [@slideCalendar]="_calendarState"
         [activeCell]="_adapter.getDate(activeDate) - 1"
         [rows]="_weeks"
         [selectedValue]="_selectedDate"
         [todayValue]="_todayDate"
         [showWeekNumbers]="showWeekNumbers"
         mat-datetimepicker-calendar-body-extended
         role="grid"></tbody>
</table>
