import { StaticProvider } from '@angular/core';
import { SEARCH_ENGINE, templateFromComponentProvider } from '@iris/common/modules/fields/ng-select-field';
import { BIND_LABEL, BIND_VALUE, GROUP_BY, OPTGROUP_TEMPLATE } from '@iris/common/modules/fields/ng-select-field/properties-tokens';
import { IrisCountrySelectEngine } from './country-select.engine';
import { IrisCountrySelectDropdownGroupItemComponent } from './dropdown-group-item';

export const COUNTRY_SELECT_PROVIDERS: StaticProvider[] = [
  {
    provide: IrisCountrySelectEngine,
    useClass: IrisCountrySelectEngine,
  },
  {
    provide: SEARCH_ENGINE,
    useExisting: IrisCountrySelectEngine,
  },
  {
    provide: BIND_LABEL,
    useValue: 'name',
  },
  {
    provide: BIND_VALUE,
    useValue: 'id',
  },
  {
    provide: GROUP_BY,
    useValue: 'countries',
  },
  {
    provide: OPTGROUP_TEMPLATE,
    ...templateFromComponentProvider(IrisCountrySelectDropdownGroupItemComponent, 'template'),
  },
];
