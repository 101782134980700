import { IrisQueryParamsOrderDirection } from './iris-query-params-order-direction.enum';
import { BaseStructureI } from '../base-structure';

export class IrisQueryParamsOrder implements BaseStructureI {
  field: string;
  direction: IrisQueryParamsOrderDirection;

  constructor(initialOrder?: Record<string, any>) {
    if (!initialOrder) {
      return;
    }
    this.fromObject(initialOrder);
  }

  fromObject(params: Record<string, any>) {
    if (!params) {
      return;
    }
    if (!params['name']) {
      return null;
    } else {
      this.field = params['name'] as string;
    }
    this.direction = params['value'] ? params['value'] as IrisQueryParamsOrderDirection : 'asc';
  }

  toObject() {
    const res = {};
    if (!this.field) {
      return null;
    } else {
      res['name'] = this.field;
    }
    res['value'] = (this.direction || 'asc').toString();
    return res;
  }
}
