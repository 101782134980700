<iris-relative-datetime-input #irisRelativeDatetimeExtension="irisRelativeDatetimeExtension"
                              [class.cdk-visually-hidden]="!datetimepicker.showRelativeFilters"
                              [type]="datetimepicker.type"
                              [formControl]="datetimepicker.relativeDatetimeControl"></iris-relative-datetime-input>

<mat-datetimepicker-calendar-extended [class.cdk-visually-hidden]="datetimepicker.showRelativeFilters && irisRelativeDatetimeExtension.isEnabled"
                             (_userSelection)="datetimepicker.close()"
                             (selectedChange)="onSelectionChange($event)"
                             (viewChanged)="datetimepicker._viewChanged($event)"
                             [ariaNextMonthLabel]="datetimepicker.ariaNextMonthLabel"
                             [ariaNextYearLabel]="datetimepicker.ariaNextYearLabel"
                             [ariaPrevMonthLabel]="datetimepicker.ariaPrevMonthLabel"
                             [ariaPrevYearLabel]="datetimepicker.ariaPrevYearLabel"
                             [preventSameDateTimeSelection]="datetimepicker.preventSameDateTimeSelection"
                             [attr.mode]="datetimepicker.mode"
                             [dateFilter]="datetimepicker._dateFilter"
                             [id]="datetimepicker.id"
                             [maxDate]="datetimepicker._maxDate"
                             [minDate]="datetimepicker._minDate"
                             [multiYearSelector]="datetimepicker.multiYearSelector"
                             [selected]="datetimepicker._selected"
                             [startAt]="datetimepicker.startAt"
                             [startView]="datetimepicker.startView"
                             [timeInterval]="datetimepicker.timeInterval"
                             [twelvehour]="datetimepicker.twelvehour"
                             [type]="datetimepicker.type"
                             [showWeekNumbers]="datetimepicker.showWeekNumbers"
                             [timezone]="datetimepicker.timezone"
                             cdkTrapFocus
                             class="mat-typography">
</mat-datetimepicker-calendar-extended>
