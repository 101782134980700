/* eslint-disable @typescript-eslint/no-explicit-any */
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  applyDefaultFilter,
  applyFilter,
  applyFloatingFilter,
  fetchUserFilters,
  fetchUserFiltersComplete,
  initRecentlyFilters,
  pinFilterComplete,
  refreshRecentlyFilters,
  removeFilterComplete,
  resetFilter,
  saveFilterComplete,
  setFilterValidatorFn,
  setFilterVisibility,
  setMenuVisibility,
  setModuleName,
  setPropertiesMeta,
} from './module-filter.actions';
import { IrisModuleFilterI } from '../models/IrisModuleFilter';
import { setFilter } from './module-filter.actions';
import reject from 'lodash/reject';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import { FilterValidatorFnI } from '@iris/common/modules/module-filter/models/interfaces/filter-validator-fn.interface';
import { IrisModuleFilterPropertyBase } from '@iris/common/modules/module-filter/models/IrisModuleFilterPropertyBase';

export interface ModuleFilterState {
  filterVisible: boolean;
  menuVisible: boolean;
  filter: Record<string, any>;
  filterDirty: any;
  floatingFilter: Record<string, any>;
  floatingFilterDirty: any;
  propertiesMeta: IrisModuleFilterPropertyBase;
  userFilters: IrisModuleFilterI[];
  recentlyFilters: IrisModuleFilterI[];
  moduleName: string;
  fnValidator: FilterValidatorFnI;
}

const initialState: ModuleFilterState = {
  filterVisible: false,
  menuVisible: false,
  filter: null,
  filterDirty: {},
  floatingFilter: null,
  floatingFilterDirty: {},
  propertiesMeta: null,
  userFilters: [],
  recentlyFilters: [],
  moduleName: null,
  fnValidator: () => true,
};

const MAX_RECENTLY_FILTERS = 5;

export const moduleFilterReducers = createReducer<any>(
  initialState,
  on(setFilter, (state, { filter }) => {
    const filterRecord = { ...filter };
    return {
      ...state,
      filter: filterRecord,
      filterDirty: filterRecord,
    };
  }),
  on(setFilterVisibility, (state, { visibility }) => ({
    ...state,
    filterVisible: visibility === null ? !state. filterVisible : visibility }),
  ),
  on(setMenuVisibility, (state, { visibility }) => ({
    ...state,
    menuVisible: visibility === null ? !state.menuVisible : visibility }),
  ),
  on(applyFilter, (state, { filter }) => {
    const filterRecord = { ...filter };
    return {
      ...state,
      filter: filterRecord,
      filterDirty: filterRecord,
      filterVisible: false,
      menuVisible: false,
    };
  }),
  on(applyDefaultFilter, (state) => {
    return {
      ...state,
      filterVisible: false,
      menuVisible: false,
    };
  }),
  on(applyFloatingFilter, (state, { floatingFilter }) => {
    const floatingFilterRecord = { ...floatingFilter };
    return {
      ...state,
      floatingFilter: floatingFilterRecord,
      floatingFilterDirty: floatingFilterRecord,
    };
  }),
  on(setPropertiesMeta, (state, { propertiesMeta }) => ({ ...state, propertiesMeta })),
  on(setModuleName, (state, { moduleName }) => ({ ...state, moduleName })),
  on(resetFilter, (state) => ({
    ...state,
    filter: {},
    filterDirty: {},
    floatingFilter: {},
    floatingFilterDirty: {},
    filterVisible: false,
    menuVisible: false,
  })),
  on(fetchUserFilters, (state) => ({ ...state, userFilters: [] })),
  on(fetchUserFiltersComplete, (state, { filters }) => ({ ...state, userFilters: filters })),
  on(removeFilterComplete, (state, { filter }) => ({
    ...state,
    userFilters: reject(state.userFilters, ['id', filter.id]),
  })),
  on(saveFilterComplete, (state, { filter }) => ({
    ...state,
    userFilters: [
      ...reject(state.userFilters, ['id', filter.id]),
      filter,
    ],
  })),
  on(initRecentlyFilters, (state, { filters }) => {
    const recentlyFilters = filters
      ? state.userFilters
        .filter((filter) => filters.some((f) => f.id == filter.id))
        .map(cloneDeep)
      : [];

    return {
      ...state,
      recentlyFilters,
    };
  }),
  on(refreshRecentlyFilters, (state, { filter }) => {
    const recentlyFilters = [
      filter,
      ... reject(state.recentlyFilters, ['id', filter.id]),
    ]
      .filter((item: any) => map(state.userFilters, 'id').includes(item.id))
      .slice(0, MAX_RECENTLY_FILTERS);
    return {
      ...state,
      recentlyFilters,
    };
  }),
  on(setFilterValidatorFn, (state, { fn }) => ({
    ...state,
    fnValidator: fn,
  })),
  on(pinFilterComplete, (state, { pinnedFilter }) => ({
    ...state,
    userFilters: state.userFilters.map(filter => filter.id === pinnedFilter.id? pinnedFilter : filter),
  })),
);

export const getModuleFilterState = createFeatureSelector<ModuleFilterState>('ModuleFilterModule');

export const getModuleFilter = createSelector(
  getModuleFilterState,
  (state) => state.filter,
);

export const getModuleFloatingFilter = createSelector(
  getModuleFilterState,
  (state) => state.floatingFilter,
);

export const getModuleVisibility = createSelector(
  getModuleFilterState,
  (state) => state.filterVisible,
);

export const getMenuVisibility = createSelector(
  getModuleFilterState,
  (state) => state.menuVisible,
);

export const getPropertiesMeta = createSelector(
  getModuleFilterState,
  (state) => state.propertiesMeta,
);

export const getModuleFilterUserFilters = createSelector(
  getModuleFilterState,
  (state) => state.userFilters,
);

export const getModuleFilterRecentlyFilters = createSelector(
  getModuleFilterState,
  (state) => state.recentlyFilters,
);

export const getModuleFilterDirty = createSelector(
  getModuleFilterState,
  (state) => state.filterDirty,
);

export const getModuleFloatingFilterDirty = createSelector(
  getModuleFilterState,
  (state) => state.floatingFilterDirty,
);

export const getModuleName = createSelector(
  getModuleFilterState,
  (state) => state.moduleName,
);

export const getPinnedUserFilters = createSelector(
  getModuleFilterState,
  (state) => state.userFilters.filter((filter) => filter.isPinned === true),
);

export const getModuleFilterValidatorFn = createSelector(
  getModuleFilterState,
  (state) => state.fnValidator,
);
