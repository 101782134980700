/* eslint-disable */
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MAT_DATETIME_FORMATS, MatDatetimeFormats } from '@mat-datetimepicker/core';
import { MatDatetimepickerType } from './datetimepicker';
import { createMissingDateImplError } from './datetimepicker-errors';
import { MatDatetimepickerCalendarCell } from './calendar-body';
import { slideCalendar } from './datetimepicker-animations';
import { DatetimeAdapterExtended } from './datetime-adapter';
import { DateTime } from 'luxon';

/**
 * An internal component used to display a single year in the datepicker.
 * @docs-private
 */
@Component({
  selector: 'mat-datetimepicker-year-view-extended',
  templateUrl: 'year-view.html',
  animations: [slideCalendar],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatDatetimepickerYearViewExtended implements AfterContentInit {

  @Output() _userSelection = new EventEmitter<void>();

  @Input() type: MatDatetimepickerType = 'date';
  /** A function used to filter which dates are selectable. */
  @Input() dateFilter: (date: DateTime) => boolean;
  @Input() timezone: string;
  /** Emits when a new month is selected. */
  @Output() selectedChange = new EventEmitter<DateTime>();
  /** Grid of calendar cells representing the months of the year. */
  _months: MatDatetimepickerCalendarCell[][];
  /** The label for this year (e.g. '2017'). */
  _yearLabel: string;
  /** The month in this year that today falls on. Null if today is in a different year. */
  _todayMonth: number;
  /**
   * The month in this year that the selected Date falls on.
   * Null if the selected Date is in a different year.
   */
  _selectedMonth: number;
  _calendarState: string;

  constructor(@Optional() public _adapter: DatetimeAdapterExtended,
              @Optional() @Inject(MAT_DATETIME_FORMATS) private _dateFormats: MatDatetimeFormats) {
    if (!this._adapter) {
      throw createMissingDateImplError('DatetimeAdapterExtended');
    }

    if (!this._dateFormats) {
      throw createMissingDateImplError('MAT_DATETIME_FORMATS');
    }
  }

  private _activeDate = this._today();

  /** The date to display in this year view (everything other than the year is ignored). */
  @Input()
  get activeDate(): DateTime {
    return this._activeDate;
  }

  set activeDate(value: DateTime) {
    let oldActiveDate = this._activeDate;
    this._activeDate = value || this._today();
    if (oldActiveDate && this._activeDate &&
      !this._adapter.sameYear(oldActiveDate, this._activeDate)) {
      this._init();
    }
  }

  private _today(): DateTime {
    return this._adapter.setTimezone(this._adapter.today(), this.timezone);
  }

  private _selected: DateTime;

  /** The currently selected date. */
  @Input()
  get selected(): DateTime {
    return this._selected;
  }

  set selected(value: DateTime) {
    this._selected = value;
    this._selectedMonth = this._getMonthInCurrentYear(this.selected);
  }

  ngAfterContentInit() {
    this._init();
  }

  /** Handles when a new month is selected. */
  _monthSelected(month: number) {
    const normalizedDate = this._getFirstDayOfMonth(month);

    let date = this._adapter.setMonth(this.activeDate, month + 1);
    date = this._adapter.setDay(date, Math.min(this._adapter.getDate(this.activeDate), this._adapter.getNumDaysInMonth(normalizedDate)));

    this.selectedChange.emit(date);
    if (this.type === 'month') {
      this._userSelection.emit();
    }
  }

  _calendarStateDone() {
    this._calendarState = '';
  }

  /** Initializes this month view. */
  private _init() {
    this._selectedMonth = this._getMonthInCurrentYear(this.selected);
    this._todayMonth = this._getMonthInCurrentYear(this._today());
    this._yearLabel = this._adapter.getYearName(this.activeDate);

    let monthNames = this._adapter.getMonthNames('long');
    // First row of months only contains 5 elements so we can fit the year label on the same row.
    //this._months = [[0, 1, 2, 3, 4], [5, 6, 7, 8, 9, 10, 11]].map(row => row.map(
    this._months = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [9, 10, 11]
    ].map(row => row.map(
      month => this._createCellForMonth(month, monthNames[month])));
  }

  /**
   * Gets the month in this year that the given Date falls on.
   * Returns null if the given Date is in another year.
   */
  private _getMonthInCurrentYear(date: DateTime) {
    return this._adapter.sameYear(date, this.activeDate) ?
      this._adapter.getMonth(date) : null;
  }

  /** Creates an MdCalendarCell for the given month. */
  private _createCellForMonth(month: number, monthName: string) {
    let firstOfMonth = this._getFirstDayOfMonth(month + 1);
    let ariaLabel = this._adapter.format(firstOfMonth, this._dateFormats.display.monthYearA11yLabel);
    return new MatDatetimepickerCalendarCell(
      month, monthName.toLocaleUpperCase(), ariaLabel, this._isMonthEnabled(month));
  }

  private _getFirstDayOfMonth(month: number): DateTime {
    return this._adapter.setDay(this._adapter.setMonth(this.activeDate, month), 1);
  }

  /** Whether the given month is enabled. */
  private _isMonthEnabled(month: number) {
    if (!this.dateFilter) {
      return true;
    }

    let firstOfMonth = this._getFirstDayOfMonth(month + 1);

    // If any date in the month is enabled count the month as enabled.
    for (let date = firstOfMonth; this._adapter.getMonth(date) === month;
         date = this._adapter.addCalendarDays(date, 1)) {
      if (this.dateFilter(date)) {
        return true;
      }
    }

    return false;
  }
}
