import { NgModule } from '@angular/core';
import { IrisCommonModule } from '../common/common.module';
import { IrisModalContentModule } from '@iris/common/modules/iris-modal-content/iris-modal-content.module';
import { FilesPreviewModalComponent } from './components/files-preview-modal/files-preview-modal.component';
import { IrisFilePreviewThumbnailComponent } from './components/file-preview-thumbnail/file-preview-thumbnail.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { IrisSimpleLoaderModule } from '@iris/common/modules/iris-simple-loader/simple-loader.module';
import { PinchZoomModule } from '@iris/common/modules/preview-file/components/pinch-zoom/pinch-zoom.module';

@NgModule({
  imports: [
    IrisCommonModule,
    IrisModalContentModule,
    NgxExtendedPdfViewerModule,
    IrisSimpleLoaderModule,
    PinchZoomModule,
  ],
  declarations: [
    FilesPreviewModalComponent,
    IrisFilePreviewThumbnailComponent,
  ],
  exports: [
    FilesPreviewModalComponent,
    IrisFilePreviewThumbnailComponent,
  ],
})
export class IrisPreviewFileModule {
}
