export enum EmailWellKnownFolderName {
  Archive = 'archive',
  DeletedItems = 'deleteditems',
  Drafts = 'drafts',
  Inbox = 'inbox',
  JunkEmail = 'junkemail',
  Outbox = 'outbox',
  SentItems = 'sentitems',
}

export enum EmailWellKnownFolderIcon {
  Archive = 'fa-archive',
  DeletedItems = 'fa-trash-alt',
  Drafts = 'fa-edit',
  Inbox = 'fa-inbox',
  JunkEmail = 'fa-folder-times',
  Outbox = 'fa-inbox-out',
  SentItems = 'fa-paper-plane',
  Default = 'fa-folder',
}
export interface IrisEmailNavigationI {
  id: string;
  title: string;
  childFolderCount?: number;
  isHidden?: boolean;
  parentFolderId?: string;
  totalItemCount?: number;
  unreadItemCount?: number;
  children?: IrisEmailNavigationI[];
  root?: boolean;
  color?: string;
  sortIndex?: number;
  wellKnownFolderName?: EmailWellKnownFolderName;
  get icon(): string;
}

export interface IrisEmailFolderResponseI extends IrisEmailNavigationI {
  displayName: string;
}

export enum NavigationRootFolder {
  Folders = 'folders',
}

export class IrisEmailNavigation implements IrisEmailNavigationI {
  id: string = undefined;
  title: string = undefined;
  childFolderCount?: number = undefined;
  isHidden?: boolean = undefined;
  parentFolderId?: string = undefined;
  totalItemCount?: number = undefined;
  unreadItemCount?: number = undefined;
  children?: IrisEmailNavigationI[] = [];
  root?: boolean = undefined;
  color?: string = undefined;
  sortIndex?: number = undefined;
  wellKnownFolderName?: EmailWellKnownFolderName = undefined;

  constructor(params?: Partial<IrisEmailFolderResponseI>) {
    const newParams = { ...params };

    if (params) {
      Object.keys(this).forEach((key) => {
        if (newParams[key] !== undefined) {
          this[key] = newParams[key];
        }
      });

      this.title = newParams.title ?? newParams.displayName;
      this.children = this.children ?? [];
    }
  }

  get icon(): string {
    switch(this.wellKnownFolderName) {
      case EmailWellKnownFolderName.Archive:
        return EmailWellKnownFolderIcon.Archive;
      case EmailWellKnownFolderName.DeletedItems:
        return EmailWellKnownFolderIcon.DeletedItems;
      case EmailWellKnownFolderName.Drafts:
        return EmailWellKnownFolderIcon.Drafts;
      case EmailWellKnownFolderName.Inbox:
        return EmailWellKnownFolderIcon.Inbox;
      case EmailWellKnownFolderName.JunkEmail:
        return EmailWellKnownFolderIcon.JunkEmail;
      case EmailWellKnownFolderName.Outbox:
        return EmailWellKnownFolderIcon.Outbox;
      case EmailWellKnownFolderName.SentItems:
        return EmailWellKnownFolderIcon.SentItems;
      default:
        return EmailWellKnownFolderIcon.Default;
    }
  }
}
