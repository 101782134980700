import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { convertToArray } from '@iris/common/utils/array.utils';

const CHAIN_DELIMITER = ' - ';

@Component({
  selector: 'header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderTitleComponent {
  private readonly translate = inject(TranslateService);

  @Input() set titleChain(val: string[]) {
    val = convertToArray(val, true);
    const title = val.reduce((res, curr) => `${res}${res.length ? CHAIN_DELIMITER : ''}${this.translate.instant(curr)}`, '');
    this.titleSubject.next(title);
  }

  private readonly titleSubject = new BehaviorSubject<string>('');
  readonly title$ = this.titleSubject.asObservable();
}
