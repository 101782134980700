import { Component, ChangeDetectionStrategy, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'iris-country-select-dropdown-group-item',
  templateUrl: './dropdown-group-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisCountrySelectDropdownGroupItemComponent {
  @ViewChild('template', { static: true }) template: TemplateRef<unknown>;
}
