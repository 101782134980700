import { IrisQueryParamsBuilder } from '@iris/api-query';
import { IrisProjectI } from '@iris/common/models';
import { IrisProjectsService } from '@iris/common/services/projects.service';
import { IrisFilterDefaultChipItemViewComponent } from '@iris/modules/module-filters-v2/chips/default-chip-item-view.component';
import { isObject } from 'lodash';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { IrisFilterChipRenderingOptions } from './chip-rendering-options';

type Project = IrisProjectI | IrisProjectI['id'];

export const PROJECT_CHIP_RENDERING_OPTIONS: IrisFilterChipRenderingOptions<IrisFilterDefaultChipItemViewComponent, Project> = {
  component: IrisFilterDefaultChipItemViewComponent,
  initComponentFn: (component, project, injector) => {
    const service = injector.get(IrisProjectsService);
    const id = isObject(project) ? project.id : project;
    const params = new IrisQueryParamsBuilder().onlyFields(['name']).toStructure();

    component.text$ = service.getById(id, params)
      .pipe(
        map(entity => entity.name),
        publishReplay(1),
        refCount(),
      );
  },
};
