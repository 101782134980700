import { IrisMFFilterBase } from './IrisMFFilterBase';
import { IrisChecklistPropertyOptions } from './IrisModuleFilterPropertyOptionsParams';
import { Observable, of } from 'rxjs';

export class IrisMFChecklist extends IrisMFFilterBase {
  checks: any[];

  constructor(fieldName, options: IrisChecklistPropertyOptions = {}) {
    super(fieldName, options);
    this.checks = options.checks || [];
  }

  isInFilter(filter) {
    let res = false;
    if (filter[this.formFieldName]) {
      this.checks.forEach(check => {
        if (filter[this.formFieldName][check.f]) { res = true; }
      });
    }
    return res;
  }

  getFilterValue(val): string[] {
    const res = [];
    this.checks.forEach(check => {
      if (val[check.f]) { res.push(check.v); }
    });
    return res;
  }

  getFilterParameters() {
    return { s: false };
  }

  getFilterDescription(filter): Observable<string> {
    return of(this.checks
      .filter(check => filter[this.formFieldName][check.f])
      .map((check) => check.l)
      .join(` | `));
  }
}
