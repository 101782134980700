import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  HostListener,
  Optional,
  Inject,
} from '@angular/core';
import { IrisModuleFilterService } from '../../services/module-filter.service';
import { combineLatest, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IrisModuleSaveFilterModalComponent } from '../module-save-filter-modal/module-save-filter-modal.component';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import { IrisModuleFilterSandbox } from '@iris/common/modules/module-filter/store/module-filter.sandbox';
import { FilterValidatorFnI } from '@iris/common/modules/module-filter/models/interfaces/filter-validator-fn.interface';
import { SubscriptionMixin } from '@iris/common/mixins/subscription.mixin';
import { IrisActiveFilterService } from '@iris/modules/module-filters-v2/active-filter';
import { IRIS_ACTIVE_SIDEBAR_FILTER } from '@iris/modules/module-filters-v2/tokens';

@Component({
  selector: 'iris-module-filter-body',
  templateUrl: './module-filter-body.component.html',
  styleUrls: ['./module-filter-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisModuleFilterBodyComponent extends SubscriptionMixin() implements OnInit, OnDestroy {

  formTemplate: TemplateRef<unknown>;
  ctx = {
    filterModel: { } as {[key: string]: any},
  };
  fnValidationFilter: FilterValidatorFnI;

  private moduleName;
  private overlayElem: HTMLElement;

  constructor(
    private readonly moduleFilterService: IrisModuleFilterService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly moduleFilterSandbox: IrisModuleFilterSandbox,
    @Inject(IRIS_ACTIVE_SIDEBAR_FILTER) @Optional() private readonly activeFilterService: IrisActiveFilterService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(
      this.moduleFilterService.getFormTemplateRef(),
      (tmpl) => {
        this.formTemplate = tmpl;
        this.changeDetector.markForCheck();
      },
    );

    this.moduleFilterService.refreshFormTemplate();

    this.subscribe(
      combineLatest([
        this.moduleFilterSandbox.filterDirty$,
        this.moduleFilterSandbox.moduleName$,
        this.moduleFilterSandbox.validatorFn$,
        this.activeFilterService?.filterValues$ || of(null),
      ]),
      ([filterDirty, moduleName, fnValidator, activeFilterValues]) => {
        this.setFilterCtx(activeFilterValues || filterDirty);
        this.moduleName = moduleName;
        this.fnValidationFilter = fnValidator;
        this.changeDetector.markForCheck();
      },
    );
  }

  get isFilterNotValid(): boolean {
    return !this.fnValidationFilter ? false : !this.fnValidationFilter(this.ctx.filterModel);
  }

  applyFilter(): void {
    this.moduleFilterSandbox.applyFilter(
      omit(this.ctx.filterModel, ['pristine']),
    );

    if (this.activeFilterService) {
      this.activeFilterService.updateValues(this.ctx.filterModel);
    }
  }

  resetFilterToDefault(): void {
    this.moduleFilterSandbox.applyDefaultFilter();
    this.activeFilterService?.resetValues();
  }

  setFilterVisibility(visibility: boolean): void {
    this.moduleFilterSandbox.toggleFilterVisibility(visibility);
  }

  setOverlayElem(overlayElem: HTMLElement): void {
    this.overlayElem = overlayElem;
  }

  openSaveFilterModal(): void {
    this.subscribeOnce(
      this.dialog
        .open(IrisModuleSaveFilterModalComponent, {
          data: {
            userFilter: {
              isPinned: undefined,
              isPinnedByDefault: undefined,
              isPublic: undefined,
              isSystem: undefined,
              translations: undefined,
              name: undefined,
              id: undefined,
              createdBy: undefined,
              createdOn: undefined,
              updatedBy: undefined,
              updatedOn: undefined,
              filter: this.ctx.filterModel,
              moduleName: this.moduleName,
            },
          },
          maxWidth: '500px',
        })
        .componentInstance.result$,
      (filter) => {
        this.moduleFilterSandbox.saveFilter({
          ...filter,
          ...filter.isPublic && { isPinned: filter.isPinnedByDefault },
        });
      },
    );
  }

  private setFilterCtx(filterDirty): void {
    if (!!filterDirty) {
      this.ctx.filterModel = cloneDeep(filterDirty);
    }
  }

  @HostListener('document:mousedown', ['$event', '$event.target'])
  public onClick(_event: MouseEvent, targetElement: HTMLElement): void {
    if (
      !targetElement?.isConnected ||
      targetElement.classList.value.includes('filter-button') ||
      targetElement.ownerDocument.documentElement.getElementsByClassName('mat-mdc-dialog-content').length ||
      targetElement.closest('.ng-dropdown-panel')
    ) {
      return;
    }
    const clickedInside = this.overlayElem.contains(targetElement);

    if (!clickedInside) {
      this.moduleFilterSandbox.toggleFilterVisibility(false);
      this.moduleFilterSandbox.toggleFilterMenuVisibility(false);
    }
  }
}
