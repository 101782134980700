import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export class IrisTranslationLoader implements TranslateLoader {

  constructor(
    private readonly httpClient: HttpClient,
  ) { }

  getTranslation(locale: string): Observable<unknown> {
    return this.httpClient.get(`/ui/ui2/translations/${locale}.json`);
  }
}
