import { Constructor } from '@angular/cdk/schematics';
import { OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export function SubscriptionMixin<TBase extends Constructor<{}>>(Base: TBase = (class {} as any)) {

  /**
 * @deprecated Use DestroyRef and takeUntilDestroyed instead
 */
  @Injectable()
  class Mixin extends Base implements OnDestroy {
    protected __subscriptions: Subscription[] = [];

    protected subscribe<T>(subject: Observable<T>,
                           next?: (value: T) => void,
                           error?: (error: any) => void,
                           complete?: () => void): Subscription {
      const subscription = subject.subscribe(next, error, complete);
      this.__subscriptions.push(subscription);
      return subscription;
    }

    protected subscribeOnce<T>(subject: Observable<T>,
                               next?: (value: T) => void,
                               error?: (error: any) => void,
                               complete?: () => void): Subscription {
      return this.subscribe(subject.pipe(take(1)), next, error, complete);
    }

    ngOnDestroy(): void {
      this.__subscriptions.forEach(t => t.unsubscribe());
      super['ngOnDestroy']?.();
    }
  }
  return Mixin;
}
