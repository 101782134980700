import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IrisChipDropdownOption } from '@iris/common/modules/chip-dropdown/components/chip-dropdown.component';
import { Primitive } from '@iris/common/models/Primitive';
import { TagItemRemovedActionI } from '@iris/common/modules/module-filter/components/tags-chips/tag-item-remove-action.model';
import { zip } from 'rxjs';
import { ModuleFilterTagDataI } from '@iris/common/modules/module-filter/components/module-filter-tags/module-filter-tags.component';

@Component({
  selector: 'iris-module-filter-tag-chip-bulk',
  templateUrl: './module-filter-tag-chip-bulk.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisModuleFilterTagChipBulkComponent implements OnChanges {
  @Input() tag: ModuleFilterTagDataI;
  @Output() tagItemRemoved: EventEmitter<TagItemRemovedActionI> = new EventEmitter<TagItemRemovedActionI>();
  @Output() tagGroupRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() openFilterMenuClicked: EventEmitter<void> = new EventEmitter<void>();

  readonly chipMenuHeaderItems = [
    { label: 'label.RemoveThisGroup', iconClass: 'fa-times', onclick: () => this.removeGroup() },
    { label: 'label.OpenFilterSettings', iconClass: 'fa-filter', onclick: () => this.openFilterMenu() },
  ];

  dropdownOptions: IrisChipDropdownOption[] = [];

  private _initDropdownOptions(): void {
    zip(
      ...this.tag.items.map((item) => this.tag.meta.bulkModeItemLabel$(item)),
    ).subscribe((labels) => {
      this.dropdownOptions = labels.map((label: string, idx) => ({
        label,
        value: this.tag.items[idx],
      }));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tag) {
      this._initDropdownOptions();
    }
  }

  removeGroup(): void {
    this.tagGroupRemoved.emit(this.tag);
  }

  removeItem(itemToRemove: Primitive): void {
    this.tagItemRemoved.emit({
      field: this.tag.meta.formFieldName,
      updatedItems: this.tag.items.filter((item) => item !== itemToRemove),
      removedItem: itemToRemove,
    });
  }

  openFilterMenu(): void {
    this.openFilterMenuClicked.emit();
  }
}
