import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IrisQueryParams } from '@iris/api-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiUrl, IrisApiClientCore } from '@iris/api-query';
import { IrisPage } from '@iris/common/models/page';
import { IrisResourceRoleI } from '@iris/common/models/IrisResourceRole';

@Injectable({ providedIn: 'root' })
@ApiUrl('/security/roles')
export class IrisResourceRolesService extends IrisApiClientCore {

  constructor(private readonly httpClient: HttpClient) {
    super();
  }

  query(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisResourceRoleI[]> {
    return this.httpClient.get<IrisPage<IrisResourceRoleI>>(this.url(), { params: params.toObject() }).pipe(
      map(roles => roles.elements),
    );
  }

  @ApiUrl(`~/{id}`)
  getById(id: number, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisResourceRoleI> {
    return this.httpClient.get<IrisResourceRoleI>(this.url({ id }), { params: params.toObject() });
  }

  @ApiUrl('~/active/resource-type/{resourceType}')
  getEnabled(resourceType: string): Observable<IrisResourceRoleI[]> {
    return this.httpClient.get<IrisResourceRoleI[]>(this.url({ resourceType }));
  }

  @ApiUrl('~/{id}/toggle-enabled')
  toggleEnabled(id: number): Observable<IrisResourceRoleI> {
    return this.httpClient.post<IrisResourceRoleI>(this.url({ id }), {});
  }

  @ApiUrl('~/{id}/{scope}')
  activateScope(id: number,scope: string): Observable<IrisResourceRoleI> {
    return this.httpClient.post<IrisResourceRoleI>(this.url({ id, scope }), {});
  }

  @ApiUrl('~/{id}/{scope}')
  deactivateScope(id: number, scope: string): Observable<IrisResourceRoleI> {
    return this.httpClient.delete<IrisResourceRoleI>(this.url({ id, scope }));
  }
}
