import { IrisCountryI } from '@iris/common/models/IrisCountry';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { IrisFilterDefaultChipItemViewComponent } from '@iris/modules/module-filters-v2/chips/default-chip-item-view.component';
import { isObject } from 'lodash';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { IrisFilterChipRenderingOptions } from './chip-rendering-options';

type Country = IrisCountryI | IrisCountryI['id'];

export const COUNTRY_CHIP_RENDERING_OPTIONS: IrisFilterChipRenderingOptions<IrisFilterDefaultChipItemViewComponent, Country> = {
  component: IrisFilterDefaultChipItemViewComponent,
  initComponentFn: (component, country, injector) => {
    const service = injector.get(IrisGlobalSandbox);
    const id = isObject(country) ? country.id : country;

    component.text$ = service.countries$
      .pipe(
        map(countries => countries.find(country => country.id === id)?.name ?? ''),
        publishReplay(1),
        refCount(),
      );
  },
};
