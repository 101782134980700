import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';

export const isUserAuthorizedGuard: CanMatchFn = (_route: Route, _segments: UrlSegment[]) => {
  const authFacade = inject(AuthFacade);
  const isLoggedIn = authFacade.isLoggedIn();

  if (!isLoggedIn) {
    authFacade.lastHref = location.href;
    void inject(Router).navigate(['login']);
  }

  return isLoggedIn;
};
