import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { IrisSignatureFieldComponent } from "./signature-field.component";
import { MatToolbarModule } from '@angular/material/toolbar';
import { OverlayModule } from '@angular/cdk/overlay';
import { IrisSignatureCanvasComponent } from './signature-canvas/signature-canvas.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatToolbarModule,
    OverlayModule,
  ],
  declarations: [
    IrisSignatureFieldComponent,
    IrisSignatureCanvasComponent,
  ],
  exports: [
    ReactiveFormsModule,
    IrisSignatureFieldComponent,
  ],
})
export class IrisSignatureFieldModule {
}
