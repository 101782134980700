import { Injectable } from '@angular/core';
import { IrisUserSettingsService } from '@iris/common/services/user-settings.service';
import { IrisUserService } from '@iris/common/services/user.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { catchError, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { IrisEmailSettingsAlias, IrisEmailUserSettingsResponseI } from '../../models/IrisEmailSettings';
import * as emailsReducer from '../index';
import { MarkMessageAsReadStart } from '../messages/emails-messages.actions';
import {
  SetSelectedEmail,
  EmailSelected,
  GetEmailsUserSettingsStart,
  GetEmailsUserSettingsSuccess,
  GetEmailsUserSettingsError,
  GetFolderByShortcutStart,
  GetFolderByShortcutSuccess,
  GetFolderByShortcutError,
  GetUnreadCountStart,
  GetUnreadCountSuccess,
  GetUnreadCountError,
} from './emails-global.actions';
import { IrisEmailsService } from '../../services/emails.service';

@Injectable()
export class IrisEmailsGlobalEffects {

  setSelectedEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetSelectedEmail),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(emailsReducer.getMessageById(action.emailId)))),
      )),
      switchMap(([action, message]) => {
        const actions: TypedAction<string>[] = [
          EmailSelected(action),
        ];
        if (!!message && !message.markedAsRead) {
          actions.push(MarkMessageAsReadStart({ messageId: action.emailId }));
        }
        return actions;
      }),
    ),
  );

  GetEmailsUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetEmailsUserSettingsStart),
      switchMap(() =>
        this.userSettingsService
          .getUserSettingsById(IrisEmailSettingsAlias.global, this.userId)
          .pipe(
            map((response: IrisEmailUserSettingsResponseI) => GetEmailsUserSettingsSuccess({ settings: response.settings })),
            catchError(() => of(GetEmailsUserSettingsError())),
          ),
      ),
    ),
  );

  getFolderByShortcut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetFolderByShortcutStart),
      mergeMap(({ folderShortcut }) => this.emailsService.getCustomFolder(folderShortcut).pipe(
        map(folder => GetFolderByShortcutSuccess({ folderId: folder.id, folderShortcut })),
        catchError(() => of(GetFolderByShortcutError())),
      )),
    ),
  );

  getUnreadCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetUnreadCountStart),
      mergeMap(() => this.emailsService.getUnreadCount().pipe(
        map(unreadCount => GetUnreadCountSuccess({ unreadCount })),
        catchError(() => of(GetUnreadCountError())),
      )),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<emailsReducer.EmailsCommonState>,
    private readonly userSettingsService: IrisUserSettingsService,
    private readonly userService: IrisUserService,
    private readonly emailsService: IrisEmailsService,
  ) {}

  get userId(): number {
    return this.userService?.me?.id;
  }
}
