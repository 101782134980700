import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth.component';
import { RestorePasswordComponent } from './restore-password/restore-password.component';
import { SsoLoginComponent } from './sso/sso.component';
import { isUserLoggedInGuard } from '@iris/modules/auth/login/is-user-logged-in.guard';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
        canMatch: [isUserLoggedInGuard],
        pathMatch: 'full',
      },
      {
        path: 'auth/reset',
        canMatch: [isUserLoggedInGuard],
        component: RestorePasswordComponent,
      },
      {
        path: 'sso',
        component: SsoLoginComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
}
