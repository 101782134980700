import { Injectable } from '@angular/core';
import { IrisPage } from '@iris/common/models/page';
import { IrisNgSelectFieldSearchEngine } from '@iris/common/modules/fields/ng-select-field';
import { IrisTimeSandbox } from '@iris/common/modules/time';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, share, switchMap } from 'rxjs/operators';
import { NgSelectComponent } from '@natlex/ng-select';

@Injectable()
export class IrisDatetimeSelectEngine implements IrisNgSelectFieldSearchEngine<IrisTimeFormatI, string> {
  readonly result$: Observable<IrisPage<IrisTimeFormatI>>;
  readonly getMissingItemLabelFn: (keyCode: string) => Observable<string>;

  constructor(
    private readonly select: NgSelectComponent,
    timeSandbox: IrisTimeSandbox,
  ) {
    this.result$ = timeSandbox.dateTimeFormats$.pipe(
      map(elements => (<IrisPage<IrisTimeFormatI>>{ elements })),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );

    this.getMissingItemLabelFn = bindValue => {
      return timeSandbox.dateTimeFormats$.pipe(
        filter(elements => elements.length > 0),
        switchMap((elements) => {
          return of(elements.find(e => e[this.select.bindValue] === bindValue));
        }),
        map(dateFormat => dateFormat?.title),
      ) as Observable<string>;
    };
  }
}
