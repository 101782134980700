import { Injectable } from '@angular/core';
import { Observable, of, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GetGlobalSettingsError, GetGlobalSettingsStart, GetGlobalSettingsSuccess, UpdateGlobalSettingsError, UpdateGlobalSettingsStart, UpdateGlobalSettingsSuccess } from '@iris/common/redux/actions/settings-actions';
import { Action, select, Store } from '@ngrx/store';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { IrisGlobalSettingsService } from '@iris/common/services/settings/global-settings.service';
import * as fromGlobal from '@iris/common/redux/reducers';
import { IrisGlobalSettingsI } from '@iris/common/models/IrisGlobalSettings';

@Injectable()
export class IrisSettingsEffects {
  getGlobalSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GetGlobalSettingsStart),
    exhaustMap(({ alias }) => {
      return this.settingsService.getGlobalSettingsById$(alias).pipe(
        map((settings) => GetGlobalSettingsSuccess(settings)),
        catchError(() => of(GetGlobalSettingsError())),
      );
    }),
  ));

  updateGlobalSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(UpdateGlobalSettingsStart),
    withLatestFrom(this.store.pipe(select(fromGlobal.getGlobalSettingsMap))),
    exhaustMap(([{ alias, settings }, settingsMap]) => {
      return this.settingsService.updateGlobalSettings(alias, {
        ...settingsMap[alias] ?? {},
        ...settings,
        value: {
          ...settingsMap[alias]?.value ?? {},
          ...settings?.value ?? {},
        },
      } as IrisGlobalSettingsI<unknown>).pipe(
        map((res) => UpdateGlobalSettingsSuccess(res)),
        catchError(() => of(UpdateGlobalSettingsError())),
      );
    }),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<fromGlobal.State>,
    private readonly settingsService: IrisGlobalSettingsService,
  ) { }
}
