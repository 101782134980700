import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IrisProjectSelectDropdownGroupItemComponent } from './dropdown-group-item.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [IrisProjectSelectDropdownGroupItemComponent],
  exports: [IrisProjectSelectDropdownGroupItemComponent],
})
export class IrisProjectSelectDropdownGroupItemComponentModule { }
