import { FormControl } from '@angular/forms';
import { IrisQueryParamsBuilder } from '@iris/api-query';
import { IrisDeviceI } from '@iris/common/models/IrisDevice';
import { IrisDeviceTypeI } from '@iris/common/models/IrisDeviceType';
import { IrisFileIconFillType } from '@iris/common/models/IrisFileIcon';
import { IrisProjectI, IrisProjectPage } from '@iris/common/models/IrisProject';
import { IrisProjectTypeI } from '@iris/common/models/irisProjectType';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';
import { IrisDMSFileI } from '@iris/common/modules/dms/models/IrisDMSFile';
import { IrisDMSFileQueryParam, IrisDMSFileQueryParamAction } from '@iris/common/modules/dms/models/IrisDMSFileQueryParam';
import { IrisFilesService } from '@iris/common/modules/dms/services/files.service';
import { IrisPreviewFileService } from '@iris/common/modules/preview-file/services/preview-file.service';
import { IrisSecurityService } from '@iris/common/modules/security/services/security.service';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { IrisAdminModulesService } from '@iris/common/services/admin-modules.service';
import { IrisDeviceTypeService } from '@iris/common/services/devices/device-type.service';
import { IrisDevicesService } from '@iris/common/services/devices/devices.service';
import { IrisProjectsService } from '@iris/common/services/projects.service';
import { IrisModules, IrisModulesActions, IrisModulesSubjects } from '@iris/common/models/IrisModulesEnums';
import { IrisProjectsRouting } from '@iris/modules/projects/routing';
import { IrisCompaniesService } from '@iris/common/services/companies.service';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisCompanyI } from '@iris/common/models/IrisCompany';
import { IrisUserInfoI } from '@iris/common/modules/user-common/models/IrisUserInfo';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataItem, Link, SecondaryDataItem } from './search-results-cards/card-template/card-template.component/card-template.component';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  catchError,
  debounceTime,
  map,
  take,
  takeUntil,
  tap,
  mergeMap,
} from 'rxjs/operators';
import { ColumnsIds } from '@iris/modules/projects/projects-list/models/projects-list-columns';
import { IrisProjectsUtils } from '@iris/common/modules/projects/utils/projects-utils';
import { MatMenuTrigger } from '@angular/material/menu';

interface ProjectSearchInfo {
  titleData: DataItem;
  secondaryData?: SecondaryDataItem[];
  cardLink?: Link;
}

interface FileSearchInfo {
  titleData: DataItem;
  secondaryData?: SecondaryDataItem[];
  cardLink?: Link;
  icon: string;
}

interface SearchingType {
  name: string;
  isModule: boolean;
  label: string;
  searchFn?: (value: string) => Observable<unknown>;
}

@Component({
  selector: 'iris-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IrisHeaderSearchComponent implements OnInit, OnDestroy {
  @Output() searchClick = new EventEmitter<boolean>();

  @ViewChild('searchDropdownTrigger') searchDropdown: MatMenuTrigger;
  @ViewChild('searchInput') searchInput: ElementRef<HTMLElement>;

  @HostListener('keydown.enter', ['$event'])
  preventPressEnter(event: KeyboardEvent): void {
    event.preventDefault();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement): void {
    const searchInput = target.closest('iris-header-search') || target.closest('ng-dropdown-panel');

    if (!searchInput) {
      this.isSearching = false;
      this.searchDropdown.closeMenu();
      this.searchClick.emit(this.isSearching);
    }
  }

  searchStr = '';
  searchFormControl = new FormControl();
  files: FileSearchInfo[] = [];
  companies: IrisCompanyI[] = [];
  projects: ProjectSearchInfo[] = [];
  users: IrisUserInfoI[] = [];
  projectTypes: IrisProjectTypeI[] = [];
  isOpen = false;
  isSearching= false;
  isLoading = false;
  devices: IrisDeviceI[] = [];
  languages: string[] = [];
  noSearchResults = false;
  isSearchingError = false;
  limit = 5;
  availableSearchingTypes: SearchingType[];
  selectedSearchingType: SearchingType;
  public MIN_SEARCH_LEN = 3;
  private deviceTypes: IrisDeviceTypeI[] = [];
  private readonly unsubscribe$: Subject<void> = new Subject();
  private readonly searchingDelay = 800;

  private readonly searchingTypes: SearchingType[] = [{
    name: 'all',
    isModule: false,
    label: this.translateService.instant('label.All'),
  }, {
    name: IrisModules.DEVICE_DATA,
    isModule: true,
    label: this.translateService.instant('label.Devices'),
    searchFn: this.searchDevices,
  }, {
    name: IrisModules.DMS,
    isModule: true,
    label: this.translateService.instant('label.Files'),
    searchFn: this.searchFiles,
  }, {
    name: 'users',
    isModule: false,
    label: this.translateService.instant('label.People'),
    searchFn: this.searchUsers,
  }, {
    name: IrisModules.PROJECTS,
    isModule: true,
    label: this.translateService.instant('label.Projects'),
    searchFn: this.searchProjects,
  }];

  availableSearchingTypes$: Observable<SearchingType[]>;
  projectModule$ = this.adminModulesService.getModuleByModuleCode(IrisModules.PROJECTS);

  constructor(
    private readonly securityService: IrisSecurityService,
    private readonly companiesService: IrisCompaniesService,
    private readonly filesService: IrisFilesService,
    private readonly previewFileService: IrisPreviewFileService,
    private readonly projectsService: IrisProjectsService,
    private readonly deviceService: IrisDevicesService,
    private readonly deviceTypeService: IrisDeviceTypeService,
    private readonly alertify: IrisAlertService,
    private readonly translateService: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly adminModulesService: IrisAdminModulesService,
    private readonly userService: IrisUserService,
    private readonly globalSandbox: IrisGlobalSandbox,
    private readonly projectsUtils: IrisProjectsUtils,
  ) {
    combineLatest([this.globalSandbox.languages$, this.globalSandbox.projectTypes$]).pipe(
      tap(([languages, projectTypes]) => {
        if (languages != null) {
          this.languages = languages.map(lang => lang.name);
        }

        this.projectTypes = projectTypes;
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  ngOnInit(): void {
    this.availableSearchingTypes = this.searchingTypes
      .filter(type => {
        if (!type.isModule) { return true; }

        return this.securityService.hasPermissions(
          type.name,
          IrisModulesSubjects.Module,
          IrisModulesActions.access,
        );
      });

    if (this.availableSearchingTypes.find(type => type.name === IrisModules.PROJECTS)) {
      this.availableSearchingTypes$ = this.projectModule$.pipe(
        take(1),
        map(projectModule => {
          if (projectModule.enabled) { return this.availableSearchingTypes; }
          this.availableSearchingTypes = this.availableSearchingTypes.filter(t => t.name !== IrisModules.PROJECTS);
          return this.availableSearchingTypes;
        }),
      );
    } else this.availableSearchingTypes$ = of(this.availableSearchingTypes);

    this.selectedSearchingType = this.searchingTypes[0];

    this.searchFormControl.valueChanges.pipe(
      tap(() => {
        this.isOpen = true;
        this.isLoading = false;
      }),
      debounceTime(this.searchingDelay),
      tap(newValue => {
        this.searchStr = newValue;
        this.search(newValue);
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  public search(searchName: string): void {
    if (!searchName || searchName.length < this.MIN_SEARCH_LEN) { return; }
    this.isLoading = true;
    this.noSearchResults = false;
    this.changeDetectorRef.detectChanges();

    const requests = this.selectedSearchingType.name !== 'all'
      ? [this.selectedSearchingType.searchFn.call(this, searchName)]
      : this.availableSearchingTypes.filter(type => type.name !== 'all').map(type => type.searchFn.call(this, searchName));

    forkJoin(requests as Observable<unknown>[]).pipe(
      tap((results: Array<unknown[]>) => {
        this.isOpen = true;
        this.isLoading = false;
        this.isSearchingError = false;
        this.searchDropdown.openMenu();

        const resultsCount = results.reduce((count, res) => count + res.length, 0);
        this.noSearchResults = resultsCount === 0;

        this.changeDetectorRef.markForCheck();
      }),
    ).subscribe();
  }

  private searchDevices(searchName: string): Observable<IrisDeviceI[]> {
    const searchDevices$ = this.deviceService.searchDevices(new IrisQueryParamsBuilder()
      .filterOr([
        or => or.filter('name', [searchName], t => t.strict(false)),
        or => or.filter('machineId', [searchName], t => t.strict(false)),
      ])
      .onlyFields(['id', 'machineId', 'name', 'contactUserId', 'isActive', 'companyId', 'deviceTypeId'])
      .limit(this.limit).toStructure());

    return forkJoin([searchDevices$, this.refreshDeviceTypes(), this.getCompanies()]).pipe(
      catchError(() => this.catchError()),
      map(([devices, ..._]) => {
        this.devices = this.prepareDevices(devices, this.companies, this.deviceTypes);
        return this.devices;
      }),
    );
  }

  private processFiles(files: IrisDMSFileI[], projects: IrisProjectI[]): FileSearchInfo[] {
    return files.map(file => {
      const res: FileSearchInfo = {
        titleData: { text: file.name },
        secondaryData: [],
        cardLink: {
          routerLink: this.getLinkToFilePage(file.parentId),
          routerLinkQueryParams: {
            action: IrisDMSFileQueryParamAction.Select,
            file: file.id,
          },
        },
        icon: this.getFileIconClass(file),
      };

      if (file.projectId) {
        res.secondaryData.push({
          labelIconClass: 'far fa-briefcase',
          items: [{
            text: projects.find(p => p.id === file.projectId)?.name,
          }],
        });
      }

      if (file.path) {
        res.secondaryData.push({
          labelIconClass: 'far fa-folder-open',
          alignRight: true,
          items: [{
            text: file.path,
          }],
        });
      }

      return res;
    });
  }

  private searchFiles(searchName: string): Observable<FileSearchInfo[]> {
    return this.filesService.searchFiles(new IrisQueryParamsBuilder()
      .filter('name', [`${searchName}`], t => t.strict(false))
      .filter('content', [`${searchName}`], t => t.strict(false))
      .limit(this.limit)
      .toStructure(), null)
      .pipe(
        map(({ searchHits }) => searchHits),
        catchError(() => this.catchError()),
        mergeMap((files: IrisDMSFileI[]) => {
          const projectIds = files.filter(f => f.projectId).map(f => f.projectId);

          if (projectIds && projectIds.length) {
            return this.projectsService.getProjectsPage(
              new IrisQueryParamsBuilder()
                .filter('id', projectIds, t => t.strict(true))
                .onlyFields(['elements', 'id', 'name'])
                .urlParam('kind', 'ALL')
                .toStructure())
              .pipe(
                map(projects => this.processFiles(files, projects.elements)),
              );
          } else {
            return of(this.processFiles(files, []));
          }
        }),
        tap((files: FileSearchInfo[]) => this.files = files),
      );
  }

  private searchUsers(searchName: string): Observable<IrisUserInfoI[]> {
    if (!searchName) { return of([]); }

    const params = new IrisQueryParamsBuilder()
      .urlParam('value', searchName)
      .filter('enabled', [true])
      .orderBy('firstname', 'asc')
      .orderBy('lastname', 'asc')
      .limit(this.limit);

    return this.userService.elasticQuery(params.toStructure()).pipe(
      catchError(() => this.catchError()),
      tap(users => this.users = users),
    );
  }

  private searchProjects(searchName: string): Observable<ProjectSearchInfo[]> {
    return this.projectsService.getProjectsPageInfo(new IrisQueryParamsBuilder()
      .filterOr([
        or => or.filter('name', [`%${searchName}%`], t => t.strict(false)),
        or => or.filter('shortName', [`%${searchName}%`], t => t.strict(false)),
        or => or.filter('strathekNumber', [`%${searchName}%`], t => t.strict(false)),
        or => or.filter('costCenter', [`%${searchName.trim()}%`], t => t.strict(false)),
      ])
      .limit(this.limit)
      .onlyFields([ColumnsIds.Name, ColumnsIds.LD, ColumnsIds.FCO, ColumnsIds.KOST, 'elements', 'id'])
      .toStructure(),
    ).pipe(
      catchError(() => this.catchError()),
      map((projects: IrisProjectPage) => projects.elements.map(p => {
        return {
          titleData: { text: p.name },
          secondaryData: p.ld || p.fco || p.kost ? [{ label: this.translateService.instant('label.FCOAndCostCenter'), items: [{ text: this.projectsUtils.createCostCenterString(p) }] }] : null,
          cardLink: { routerLink: IrisProjectsRouting.getLinkToProjectView(p.id) },
        };
      })),
      tap(projects => this.projects = projects),
    );
  }

  private catchError(): Observable<[]> {
    if (!this.isSearchingError) {
      this.alertify.error(this.translateService.instant('error.ElasticNotAvailable'));
    }

    this.isLoading = false;
    this.isSearchingError = true;
    return of([]);
  }

  private getCompanies(): Observable<IrisCompanyI[]> {
    if (this.companies.length) {
      return of([...this.companies]);
    } else {
      return this.companiesService.getAll().pipe(
        tap(companies => this.companies = companies),
      );
    }
  }

  private refreshDeviceTypes(): Observable<IrisDeviceTypeI[]> {
    if (this.deviceTypes.length) {
      return of([...this.deviceTypes]);
    } else {
      return this.deviceTypeService.query().pipe(
        tap(deviceTypes => this.deviceTypes = deviceTypes),
      );
    }
  }

  private prepareDevices(devices: IrisDeviceI[], companies: IrisCompanyI[], deviceTypes: IrisDeviceTypeI[]): IrisDeviceI[] {
    return devices.map(device => {
      const company = companies.find(comp => comp.id === device.companyId);
      const companyName = company?.nameTranslated || company?.name || '-';
      const deviceType = deviceTypes.find(type => type.id === device.deviceTypeId);
      let deviceTypeName = '-';

      if (deviceType) {
        deviceTypeName = deviceType.nameTranslated || deviceType.name;
      }

      return {
        ...device,
        companyName,
        deviceTypeName,
      };
    });
  }

  public getDeviceUrl(deviceId: number): string {
    return this.deviceService.getDeviceUrl(deviceId);
  }

  public goToDevice(deviceId: number): void {
    location.assign(this.getDeviceUrl(deviceId));
  }

  public canPreviewFile(file: IrisDataRepoFileI): boolean {
    return this.filesService.isPreviewReady(file);
  }

  public openPreviewFile(file: IrisDataRepoFileI): void {
    this.previewFileService.openPreviewFileModal({ fileInfo: file }).componentInstance.close$.pipe(
      take(1),
      tap(() => this.setFocused()),
    ).subscribe();
  }

  public getFileDMSUrl(file: IrisDataRepoFileI): string {
    return this.filesService.goToDMSFile(file);
  }

  public getFileIconClass(file: IrisDMSFileI): string {
    return this.filesService.getIconClass(file, IrisFileIconFillType.Solid);
  }

  public getFolderDMSUrl(file: IrisDMSFileI): string {
    return `${this.filesService.goToDMSFile(file)}&${IrisDMSFileQueryParam.Action}=${IrisDMSFileQueryParamAction.Select}`;
  }

  public getFilesShowMoreUrl(searchName: string): string {
    return `/ui/ui2/dms/folders/search?filter=${encodeURIComponent(JSON.stringify({
      name: searchName, content: searchName }))}`;
  }

  public getProjectsShowMoreUrl(searchName: string): string {
    return `/ui/ui2/projects/my?filter=${encodeURIComponent(JSON.stringify({
      name: searchName }))}`;
  }

  public goToProject(project: IrisProjectI): void {
    location.assign(this.projectsService.getProjectViewUrl(project.id));
  }

  public onSearchClickEvent($event: Event, isDropdownOpen: boolean): void {
    this.deviceTypes = [];
    this.isSearching = true;
    this.searchClick.emit(this.isSearching);
    if (isDropdownOpen) {
      $event.stopPropagation();
    }
  }

  public clearSearchInput($event: Event): void {
    $event.stopPropagation();
    this.searchStr = '';
    this.searchDropdown.closeMenu();
    this.isSearching = false;
  }

  public setFocused($event?: UIEvent): void {
    if ($event) { $event.stopPropagation(); }
    setTimeout(() => {
      this.isSearching = true;
      this.searchInput.nativeElement.focus();
      this.searchDropdown.openMenu();
    });
  }

  public searhResultsClose(): void {
    this.searchDropdown.closeMenu();
  }

  public onTypeChange(event: SearchingType): void {
    this.files = [];
    this.projects = [];
    this.devices = [];
    this.users = [];
    this.selectedSearchingType = event;
    this.search(this.searchStr);
    this.searchDropdown.openMenu();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getLinkToFilePage(folderId: string): string[] {
    const moduleRoute = Object.freeze(['/', 'dms']);
    return [...moduleRoute, 'folders', folderId, 'files'];
  }
}
