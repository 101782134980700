<div class="modal-content-wrapper">
  <header class="modal-header bordered-bottom flex-valign" *ngIf="title || headerTitleTmpl">
    <div mat-dialog-title class="text-ellipsis">
      <ng-container *ngIf="headerTitleTmpl; else defaultHeaderTitleTmpl"
                    [cdkPortalOutlet]="headerTitleTmpl"></ng-container>

      <ng-template #defaultHeaderTitleTmpl>
        <span class="modal-title">{{ title }}</span>
      </ng-template>
    </div>

    <button
      *ngIf="enableResize"
      class="btn btn-flat expand-button"
      [matTooltip]="(isExpanded ? 'label.Collapse' : 'label.Expand') | translate"
      (click)="onResize()"
      data-test="modal-header-expand-button">
      <i class="fal" [ngClass]="{'fa-compress': isExpanded, 'fa-expand': !isExpanded}"></i>
    </button>
    <button
      class="btn btn-flat close-button"
      *ngIf="!isHideCross"
      [matTooltip]="'label.Close' | translate"
      data-test="modal-header-close-button"
      (click)="onClose($event)">
      <i [ngClass]="headerCloseIcon"></i>
    </button>
  </header>

  <section
    mat-dialog-content
    cdkScrollable
    class="modal-body">
      <ng-content select="[id=iris-modal-body]"></ng-content>
  </section>

  <footer mat-dialog-actions class="modal-footer bordered-top flex-valign">
      <div class="left-side">
        <ng-content select="[id=iris-modal-footer-buttons-left]"></ng-content>
      </div>
      <div class="btn-toolbar">
        <ng-content select="[id=iris-modal-footer-buttons]"></ng-content>
        <button
          type="button"
          class="btn btn-default"
          *ngIf="!isHideCloseBtn"
          data-test="modal-footer-close-button"
          (click)="onClose($event)">{{ cancelBtnText | translate }}</button>
      </div>
  </footer>
</div>
