import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { LoadUserByEmail, LoadUserByEmailComplete, LoadUserInfo, LoadUserInfoComplete, UsersActions, UsersActionTypes } from '../actions/users.actions';
import { bufferTime, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { IrisUserService } from '@iris/common/services/user.service';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../reducers/index';
import { IrisQueryParamsBuilder } from '@iris/api-query';

@Injectable()
export class IrisUsersEffects {
  getUsersInfo$: Observable<UsersActions> = createEffect(() => this.actions$.pipe(
    ofType<LoadUserInfo>(UsersActionTypes.LoadUserInfo),
    map((action) => action.payload),
    bufferTime(100),
    withLatestFrom(this.store$.pipe(select(fromRoot.getGlobalUsersInfoIds))),
    map(([usersIds, inStoreUsersInfoIds]) => usersIds
      .filter((userId) => !inStoreUsersInfoIds.includes(userId)),
    ),
    filter((usersIds) => usersIds.length > 0),
    map((usersIds: number[]) => [...new Set(usersIds)]),
    mergeMap((usersIds) => {
      return this.userService.fetchUsersInfo(new IrisQueryParamsBuilder().filter('id', usersIds).toStructure()).pipe(
        map((users) => new LoadUserInfoComplete(users)),
      );
    }),
  ));

  getUsersByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadUserByEmail>(UsersActionTypes.LoadUserByEmail),
      map((action) => action.payload),
      bufferTime(100),
      withLatestFrom(this.store$.pipe(select(fromRoot.getGlobalUsersEmails))),
      map(([usersEmails, usersInfoEmails]) => usersEmails.filter(email => !usersInfoEmails.includes(email))),
      filter((emails) => !!emails.length),
      map((emails: string[]) => [...new Set(emails)]),
      mergeMap((emails) => {
        return this.userService.query(new IrisQueryParamsBuilder().filter('email', emails).toStructure()).pipe(
          map((users) => new LoadUserByEmailComplete(users)),
        );
      }),
    ),
  );

  constructor(
    private readonly store$: Store<fromRoot.State>,
    private readonly actions$: Actions,
    private readonly userService: IrisUserService,
  ) { }
}
