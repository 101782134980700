import { StaticProvider, inject } from '@angular/core';
import { BIND_LABEL, BIND_VALUE, ITEMS } from '../ng-select-field/properties-tokens';
import { IrisTimeSandbox } from '../../time';

interface TimeZoneSelectionItemI {
  name: string;
  id: string;
}

const UTC_ZERO_REGION: TimeZoneSelectionItemI = {
  name: 'UTC',
  id: 'Atlantic/Reykjavik',
};

export const TIMEZONE_SELECT_PROVIDERS: StaticProvider[] = [
  {
    provide: ITEMS,
    useFactory: () => {
      const timeSandbox = inject(IrisTimeSandbox);
      const timeZones = [UTC_ZERO_REGION].concat(timeSandbox.getSortedAllTimeZones().map(n => ({ name: n, id: n })));
      return timeZones;
    },
  },
  {
    provide: BIND_LABEL,
    useValue: 'name',
  },
  {
    provide: BIND_VALUE,
    useValue: 'id',
  },
];
