<div class="iris-menu">
  <div class="global-menu-container">
    <button
      type="button"
      class="btn btn-link global-menu-toggler"
      (click)="toggleSidenav()"
      id="showSystemMenu">
      <div class="hamburger">
        <div class="hamburger-box">
          <div class="hamburger-inner"></div>
        </div>
      </div>
    </button>
  </div>

  <a href="/" title="Homepage" class="navbar-brand">
    <span class="app-logo">
      <img *ngIf="imageUrl$ | async"
           [src]="imageUrl$ | async | safeResourceUrl"
           alt="Logo"
           class="app-logo-dots" />
    </span>
  </a>
</div>
