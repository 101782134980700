import { NgModule } from '@angular/core';
import { IrisModalContentComponent } from './component/iris-modal-content.component';
import { CommonModule } from '@angular/common';
import { IrisCommonDirectivesModule } from '@iris/common/modules/directives-common/common-directives.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkPortalOutlet } from '@angular/cdk/portal';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IrisCommonDirectivesModule,
    ScrollingModule,
    MatDialogModule,
    MatTooltipModule,
    CdkPortalOutlet,
  ],
  declarations: [IrisModalContentComponent],
  exports: [IrisModalContentComponent],
})
export class IrisModalContentModule {
  static readonly type = 'IrisModalContentModule';
}
