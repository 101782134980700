import { createAction, props } from '@ngrx/store';
import { EmailMessageChangeEventI, IrisEmailMessageAttachmentI, IrisEmailMessageI, IrisEmailMessageResponseI } from '../../models/IrisEmail';
import { IrisDMSFileI } from '@iris/common/modules/dms/models/IrisDMSFile';

const prefixed = (type: string): string => `[EmailsMessages] ${type}`;

export const MessagesLoadingStart = createAction(
  prefixed('Messages Loading Start'),
);

export const MessagesLoadingEnd = createAction(
  prefixed('Messages Loading End'),
);

export const AddEmailMessagesSuccess = createAction(
  prefixed('Add Email Messages Success'),
  props<{ messages: IrisEmailMessageResponseI[]; replace: boolean; offset?: number; limit?: number }>(),
);

export const MarkMessageAsReadStart = createAction(
  prefixed('Mark Message As Read Start'),
  props<{ messageId: string }>(),
);

export const MarkMessageAsReadSuccess = createAction(
  prefixed('Mark Message As Read Success'),
  props<{ message: IrisEmailMessageI }>(),
);

export const MarkMessageAsReadError = createAction(
  prefixed('Mark Message As Read Success'),
);

export const MarkMessageAsUnreadStart = createAction(
  prefixed('Mark Message As Unread Start'),
  props<{ messageId: string }>(),
);

export const MarkMessageAsUnreadSuccess = createAction(
  prefixed('Mark Message As Unread Success'),
  props<{ message: IrisEmailMessageI }>(),
);

export const MarkMessageAsUnreadError = createAction(
  prefixed('Mark Message As Unread Success'),
);

export const ToggleMessageReadMark = createAction(
  prefixed('Toggle Message Read Mark'),
  props<{ messageId: string }>(),
);

export const SetMessageReadMarkBulkStart = createAction(
  prefixed('Set Messages Read Mark Bulk'),
  props<{ read: boolean }>(),
);

export const SetMessageReadMarkBulkSuccess = createAction(
  prefixed('Set Messages Read Mark Success'),
  props<{ read: boolean; selectedMessagesIds: string[]; unselectedMessagesIds: string[] }>(),
);

export const SetMessageReadMarkBulkError = createAction(
  prefixed('Set Messages Read Mark Error'),
);

export const SaveMessageAsDraftStart = createAction(
  prefixed('Save Message As Draft Start'),
  props<{ message: Partial<IrisEmailMessageResponseI>; silent: boolean }>(),
);

export const SaveMessageAsDraftSuccess = createAction(
  prefixed('Save Message As Draft Success'),
  props<{ message: IrisEmailMessageI }>(),
);

export const SaveMessageAsDraftError = createAction(
  prefixed('Save Message As Draft Error'),
);

export const SendOpenedDraftEmailStart = createAction(
  prefixed('Send Opened Draft Email Start'),
  props<{ message: Partial<IrisEmailMessageResponseI> }>(),
);

export const SendOpenedDraftEmailError = createAction(
  prefixed('Send Opened Draft Email Error'),
);

export const RemoveEmailMessageStart = createAction(
  prefixed('Remove Email Message Start'),
  props<{ message: IrisEmailMessageI }>(),
);

export const RemoveEmailMessageSuccess = createAction(
  prefixed('Remove Email Message Success'),
  props<{ messageId: string }>(),
);

export const RemoveEmailMessageError = createAction(
  prefixed('Remove Email Message Error'),
);

export const RemoveSelectedEmailMessagesStart = createAction(
  prefixed('Remove Selected Email Messages Start'),
);

export const RemoveSelectedEmailMessagesSuccess = createAction(
  prefixed('Remove Selected Email Messages Success'),
  props<{ selectedMessagesIds: string[]; unselectedMessagesIds: string[] }>(),
);

export const RemoveSelectedEmailMessagesError = createAction(
  prefixed('Remove Selected Email Messages Error'),
);

export const SaveMessageToDmsStart = createAction(
  prefixed('Save Message To Dms Start'),
  props<{ messageId: string; folderId: string }>(),
);

export const SaveMessageToDmsSuccess = createAction(
  prefixed('Save Message To Dms Success'),
);

export const SaveMessageToDmsError = createAction(
  prefixed('Save Message To Dms Error'),
);

export const SaveMessagesToDmsStart = createAction(
  prefixed('Save Messages To DMS Start'),
  props<{
    folderId: string;
    dmsFolderId: string;
    includedIds: string[];
    excludedIds: string[];
    dropped: boolean;
  }>(),
);

export const SaveMessagesToDmsSuccess = createAction(
  prefixed('Save Messages To DMS Success'),
);

export const SaveMessagesToDmsError = createAction(
  prefixed('Save Messages To DMS Error'),
);

export const SaveMessageAttachmentsToDmsStart = createAction(
  prefixed('Save Message Attachments To Dms Start'),
  props<{ messageId: string; folderId: string }>(),
);

export const SaveMessageAttachmentsToDmsSuccess = createAction(
  prefixed('Save Message Attachments To Dms Success'),
);

export const SaveMessageAttachmentsToDmsError = createAction(
  prefixed('Save Message Attachments To Dms Error'),
);

export const MoveMessagesToFolderStart = createAction(
  prefixed('Move MessagesTo Folder Start'),
  props<{ messagesIds: string[]; folderId: string }>(),
);

export const MoveMessagesToFolderSuccess = createAction(
  prefixed('Move MessagesTo Folder Success'),
  props<{ messages: IrisEmailMessageI[] }>(),
);

export const MoveMessagesToFolderError = createAction(
  prefixed('Move MessagesTo Folder Error'),
);

export const GetMessageAttachmentsStart = createAction(
  prefixed('Get Message Attachments Start'),
  props<{ messageId: string }>(),
);

export const GetMessageAttachmentsSuccess = createAction(
  prefixed('Get Message Attachments Success'),
  props<{ messageId: string; attachments: IrisEmailMessageAttachmentI[] }>(),
);

export const GetMessageAttachmentsError = createAction(
  prefixed('Get Message Attachments Error'),
);

export const UploadMessageAttachmentsStart = createAction(
  prefixed('Upload Message Attachments Start'),
  props<{ messageId: string; files: File[] }>(),
);

export const UploadMessageAttachmentsSuccess = createAction(
  prefixed('Upload Message Attachments Success'),
  props<{ messageId: string; attachments: IrisEmailMessageAttachmentI[] }>(),
);

export const UploadMessageAttachmentsError = createAction(
  prefixed('Upload Message Attachments Error'),
);

export const UploadAttachmentsFromDmsStart = createAction(
  prefixed('Upload Attachments From Dms Start'),
  props<{ messageId: string; files: IrisDMSFileI[] }>(),
);

export const UploadAttachmentsFromDmsSuccess = createAction(
  prefixed('Upload Attachments From Dms Success'),
  props<{ messageId: string; attachments: IrisEmailMessageAttachmentI[] }>(),
);

export const UploadAttachmentsFromDmsError = createAction(
  prefixed('Upload Attachments From Dms Error'),
);

export const CancelDmsAttachmentUpload = createAction(
  prefixed('Cancel Dms Attachment Upload'),
  props<{ fileId: string }>(),
);

export const RemoveMessageAttachmentStart = createAction(
  prefixed('Remove Message Attachment Start'),
  props<{ messageId: string; attachmentId: string }>(),
);

export const RemoveMessageAttachmentSuccess = createAction(
  prefixed('Remove Message Attachment Success'),
  props<{ messageId: string; attachmentId: string }>(),
);

export const RemoveMessageAttachmentError = createAction(
  prefixed('Remove Message Attachment Error'),
);

export const SaveMessageAttachmentToDmsStart = createAction(
  prefixed('Save Message Attachment To DMS Start'),
  props<{ messageId: string; attachmentId: string; folderId: string }>(),
);

export const SaveMessageAttachmentToDmsSuccess = createAction(
  prefixed('Save Message Attachment To DMS Success'),
);

export const SaveMessageAttachmentToDmsError = createAction(
  prefixed('Save Message Attachment To DMS Error'),
);

export const ForwardMessageStart = createAction(
  prefixed('Forward Message Start'),
  props<{
    messageId: string;
    message: Partial<IrisEmailMessageResponseI>;
    silent: boolean;
  }>(),
);

export const ForwardMessageSuccess = createAction(
  prefixed('Forward Message Success'),
);

export const ForwardMessageError = createAction(
  prefixed('Forward Message Error'),
);

export const ReplyToMessageStart = createAction(
  prefixed('Reply To Message Start'),
  props<{
    messageId: string;
    message: Partial<IrisEmailMessageResponseI>;
    silent: boolean;
  }>(),
);

export const ReplyToMessageSuccess = createAction(
  prefixed('Reply To Message Success'),
);

export const ReplyToMessageError = createAction(
  prefixed('Reply To Message Error'),
);

export const ReplyToAllStart = createAction(
  prefixed('Reply To All Start'),
  props<{
    messageId: string;
    message: Partial<IrisEmailMessageResponseI>;
    silent: boolean;
  }>(),
);

export const ReplyToAllSuccess = createAction(
  prefixed('Reply To All Success'),
);

export const ReplyToAllError = createAction(
  prefixed('Reply To All Error'),
);

export const FetchMessagesRangeStart = createAction(
  prefixed('Fetch Messages Range Start'),
  props<{ offset: number; limit: number }>(),
);

export const EmailsMessageChangeEvent = createAction(
  prefixed('Emails User Change Event'),
  props<{ event: EmailMessageChangeEventI }>(),
);

export const GetEmailsMessageStart = createAction(
  prefixed('Get Emails Message Start'),
  props<{ messageId: string }>(),
);

export const GetEmailsMessageSuccess = createAction(
  prefixed('Get Emails Message Success'),
  props<{ message: IrisEmailMessageI }>(),
);

export const GetEmailsMessageError = createAction(
  prefixed('Get Emails Message Error'),
);

export const GetEmailsMessageConversationStart = createAction(
  prefixed('Get Emails Message Conversation Start'),
  props<{ messageId: string; conversationId: string }>(),
);

export const GetEmailsMessageConversationSuccess = createAction(
  prefixed('Get Emails Message Conversation Success'),
  props<{ messageId: string; relatedMessages: IrisEmailMessageI[] }>(),
);

export const GetEmailsMessageConversationError = createAction(
  prefixed('Get Emails Message Conversation Error'),
);
