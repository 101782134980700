import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  InjectionToken,
  OnDestroy,
  Optional,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  DatetimePlaceholder,
  DatetimePlaceholderRelPart,
  DatetimePlaceholderRelType,
  DatetimePlaceholderValueType } from '@iris/common/modules/date/datetime-placeholder';
import { IrisFieldDatetimeComponent } from '../field-datetime.component';
import { MatDatetimepickerType } from '@iris/common/modules/fields/field-datetime/mat-datetimepicker/datetimepicker';

export type DatetimeInputType = IrisFieldDatetimeComponent['type'];
type OnChangeFunction = (obj: DatetimePlaceholder) => void;

export const DATETIME_INPUT_TYPE = new InjectionToken<DatetimeInputType>('Datetime input type');

@Component({
  selector: 'iris-relative-datetime-input',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: [
    'datetimeInputType',
  ],
  exportAs: 'irisRelativeDatetimeExtension',
})
export class IrisRelativeDatetimeInputComponent implements ControlValueAccessor, OnDestroy {
  @Input() type: MatDatetimepickerType;
  @Input() relativeDatetime: DatetimePlaceholder;

  readonly DatetimePlaceholderValueType = DatetimePlaceholderValueType;
  readonly DatetimePlaceholderRelType = DatetimePlaceholderRelType;
  readonly DatetimePlaceholderRelPart = DatetimePlaceholderRelPart;

  readonly formGroup = new FormBuilder().group({
    valueType: null,
    type: null,
    shift: null,
    part: null,
    val: null,
  });

  private onChange: OnChangeFunction = () => {};
  private readonly destroyed = new Subject<void>();

  get isEnabled(): boolean {
    return this.formGroup.value.valueType === DatetimePlaceholderValueType.Relative;
  }

  get showRelativeExtraOptions(): boolean {
    return this.formGroup.value.type != null && this.formGroup.value.type != DatetimePlaceholderRelType.Now;
  }

  get shiftIsRequired(): boolean {
    return this.formGroup.value.type === DatetimePlaceholderRelType.Add;
  }

  get showTimeOptions(): boolean {
    return ['time', 'datetime', 'long-datetime'].includes(this.type);
  }

  constructor(
    @Optional() ngControl: NgControl,
    @Inject(DATETIME_INPUT_TYPE) @Optional() readonly datetimeInputType: DatetimeInputType,
  ) {
    if (ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }

  apply(): void {
    this.onChange({
      valueType: this.formGroup.value?.valueType ?? null,
      type: this.formGroup.value?.type ?? null,
      shift: this.formGroup.value?.shift ?? null,
      part: this.formGroup.value?.part ?? null,
      val: this.formGroup.value?.val ?? null,
    } as DatetimePlaceholder);
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  writeValue(obj: DatetimePlaceholder): void {
    this.formGroup.setValue({
      valueType: obj?.valueType ?? null,
      type: obj?.type ?? null,
      shift: obj?.shift ?? null,
      part: obj?.part ?? null,
      val: obj?.val ?? null,
    }, {
      emitEvent: false,
    });
  }

  registerOnChange(fn: OnChangeFunction): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {
  }

  setDisabledState(isDisabled: boolean): void {
    const fn = isDisabled ? this.formGroup.disable : this.formGroup.enable;
    fn.bind(this.formGroup)({ emitEvent: false });
  }
}
