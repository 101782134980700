import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalAnnouncementBannerComponent } from './global-announcement-banner.component/global-announcement-banner.component';
import { SafeHtmlPipeModule } from '@iris/common/modules/pipes-common/pipes/dom-sanitizer/safe-html.module';

@NgModule({
  imports: [
    CommonModule,
    SafeHtmlPipeModule,
  ],
  declarations: [
    GlobalAnnouncementBannerComponent,
  ],
  exports: [
    GlobalAnnouncementBannerComponent,
  ],
})
export class GlobalAnnouncementBannerModule { }
