import { Injectable } from '@angular/core';
import { ApiUrl, IrisApiClient, IrisQueryParams } from '@iris/api-query';
import { IrisDeviceTypeI } from '../../models/IrisDeviceType';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IrisPage } from '@iris/common/models/page';
import { IrisFilterPropertyMeta, textFilterMeta } from '@iris/modules/module-filters-v2/filter-meta';

export type DeviceTypesPage = IrisPage<IrisDeviceTypeI>;

@Injectable({
  providedIn: 'root',
})
@ApiUrl('/device-types/{id}')
export class IrisDeviceTypeService extends IrisApiClient<IrisDeviceTypeI> {

  private readonly _filterMeta = [
    textFilterMeta('name', 'label.Name', 'name', false, true),
  ];

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getFilterMeta(): IrisFilterPropertyMeta[] {
    return this._filterMeta;
  }

  query(queryParams?: IrisQueryParams): Observable<IrisDeviceTypeI[]> {
    return super.queryCore({}, queryParams);
  }

  @ApiUrl('/device-types/v2')
  queryV2(params: IrisQueryParams = new IrisQueryParams()): Observable<DeviceTypesPage> {
    return this.httpClient.get<DeviceTypesPage>(this.url(), { params: params.toObject() });
  }

  get(id: number, queryParams?: IrisQueryParams): Observable<IrisDeviceTypeI> {
    return super.getCore({ id }, queryParams);
  }
  
  save(deviceType: Partial<IrisDeviceTypeI>): Observable<IrisDeviceTypeI> {
    return this.httpClient.post<IrisDeviceTypeI>(this.url({ id: deviceType.id }), deviceType);
  }

  delete(id: number): Observable<void> {
    return super.deleteCore({ id });
  }
}
