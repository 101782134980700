import { ErrorHandler, NgModule } from '@angular/core';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { BrowserModule } from '@angular/platform-browser';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { take, tap } from 'rxjs/operators';

@NgModule({
  imports: [ApmModule, BrowserModule],
  providers: [ApmService, {
    provide: ErrorHandler,
    useClass: ApmErrorHandler,
  }],
})
export class RumIntegrationModule {
  constructor(
    apmService: ApmService,
    userService: IrisUserService,
    envService: IrisEnvironmentService,
  ) {
    const irisEnv = envService.env;

    if (irisEnv.rumActivated) {
      const apm = apmService.init({
        serviceName: irisEnv.instanceName,
        environment: irisEnv.rumEnvironment,
        serverUrl: irisEnv.rumUrl,
        serviceVersion: irisEnv.version,
        logLevel: irisEnv.rumLogLevel,
      });

      userService.me$.pipe(
        take(1),
        tap(user => {
          apm.setUserContext({
            id: `${irisEnv.instanceId}-${user.id}`,
          });

          apm.setCustomContext({
            accountType: user.accountType,
            administrator: user.isAdmin,
            version: irisEnv.version,
            instanceId: irisEnv.instanceId,
            internal: user.isInternal,
            be: user.org?.be,
            di: user.org?.di,
            fco: user.org?.fco,
            gr: user.org?.gr,
            ld: user.org?.ld,
            ub: user.org?.ub,
            vss: user.org?.vss,
            syncObjId: user.syncObjId,
          });
        }),
      ).subscribe();
    }
  }
}
