import { Pipe, PipeTransform } from '@angular/core';
import { AbstractDatetime } from '@iris/common/modules/date';
import { IrisUserService } from '@iris/common/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'irisDateAgo' })
export class IrisDateAgoPipe implements PipeTransform {

  constructor(
    private readonly translateService: TranslateService,
    private readonly userService: IrisUserService,
  ) {}

  private get currentUser(): IdentUserI {
    return this.userService.me;
  }

  transform(value: string | number): unknown {
    const
      justNowForm = 29,
      milliseconds = 1000;
    const timeZoneValue = value
      ? AbstractDatetime.parse(value).setTimezone(this.currentUser.timeZone).toString()
      : '';
    if (timeZoneValue) {
      const seconds = Math.floor((+new Date() - +new Date(timeZoneValue)) / milliseconds);
      if (seconds < justNowForm) {
        return this.translateService.instant('label.JustNow');
      }
      const intervals = {
        'label.Year': 31536000,
        'label.Month': 2592000,
        'label.Week': 604800,
        'label.Day': 86400,
        'label.Hour': 3600,
        'label.Minute': 60,
        'label.Second': 1,
      };
      let counter: string | number;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          const agoTime = `${counter} ${this.translateService.instant(i)}`;
          if (counter === 1) {
            return this.translateService.instant('label.AgoSingular', { agoTime });
          } else {
            return this.translateService.instant('label.AgoPlural', { agoTime });
          }
        }
         
      }
    }
    return timeZoneValue;
  }
}
