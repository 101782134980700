import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrl, IrisBaseCRUDService } from '@iris/api-query';
import { IrisBuildingI } from '@iris/common/models/IrisBuilding';

@Injectable({
  providedIn: 'root',
})
@ApiUrl('/construction/buildings')
export class IrisBuildingsService extends IrisBaseCRUDService<IrisBuildingI> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

}
