import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';

@Injectable()
export class IrisBasePathInterceptor implements HttpInterceptor {
  constructor(
    private readonly envService: IrisEnvironmentService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('/ui/ui2') === -1 && req.url.indexOf('http://') === -1 && req.url.indexOf('https://') === -1) {
      return next.handle(req.clone({
        url: this.envService.get('apiUrl') + req.url,
      }));
    }
    return next.handle(req);
  }
}
