import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IrisNumberFieldComponent } from './number-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IrisCommonDirectivesModule } from '../../directives-common/common-directives.module';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { IrisNumbersOnlyDirective } from './numbers-only.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PortalModule } from '@angular/cdk/portal';
import { IrisInfoIconComponent } from '../../ui/modules/info-icon/info-icon.component';

@NgModule({
  declarations: [
    IrisNumbersOnlyDirective,
    IrisNumberFieldComponent,
  ],
  imports: [
    CommonModule,

    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,

    ReactiveFormsModule,
    TranslateModule,
    IrisCommonDirectivesModule,
    PortalModule,
    IrisInfoIconComponent,
  ],
  exports: [
    IrisNumberFieldComponent,
    IrisNumbersOnlyDirective,
  ],
})
export class IrisNumberFieldModule { }
