import { dateRangeChipRenderingOptions } from '@iris/modules/module-filters-v2/chips/rendering-options';
import { appendDateRangeQueryParamsFn } from '@iris/modules/module-filters-v2/query-params';
import { filterValueObjectIsEmpty, stringifyDate } from '@iris/modules/module-filters-v2/utils';
import { IrisFilterPropertyMeta } from './filter-property-meta';
import { DatetimeUnit } from '@iris/common/modules/date';
import { IrisTimeSandbox } from '@iris/common/modules/time';

export function dateRangeFilterMeta(
  propertyId: string,
  label: string,
  timeSandbox: IrisTimeSandbox,
  timeZone: string,
  accuracy: DatetimeUnit = 'day',
  fieldName: string = propertyId,
): IrisFilterPropertyMeta {
  return {
    propertyId,
    label,
    appendFilterQueryParamsFn: appendDateRangeQueryParamsFn(fieldName, timeZone, accuracy),
    chipRenderingOptions: dateRangeChipRenderingOptions(date => stringifyDate(date, timeSandbox)),
    filterValueIsEmpty: filterValueObjectIsEmpty,
  };
}
