<div class="fields-container" [class.readonly]="readonly" data-test="iris_field-text">
  <mat-form-field
    [floatLabel]="floatLabel"
    [appearance]="$any(enhancedControl.fieldAppearance)">
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <input matInput #input
             data-test="iris_field-text_input"
             [type]="inputType"
             autocomplete="off"
             name="iris-text"
             [formControl]="valueControl"
             [placeholder]="placeholder"
             [required]="isFieldRequired"
             [pattern]="pattern"
             (blur)="onBlurEvent()"
             [readonly]="readonly">
    <ng-container matSuffix *ngIf="showSuffix">
      <span *ngIf="valueControl.invalid"
            class="icon fal fa-exclamation-circle iris-form-control-append"
            aria-hidden="true"></span>

      <button mat-icon-button aria-label="Clear"
              type="button"
              *ngIf="showClear && valueControl.value"
              [disabled]="valueControl.disabled"
              (click)="clearValue($event)">
        <i class="icon fa-lg fal fa-times"></i>
      </button>

      <ng-container *ngIf="enhancedControl.fieldSuffix">
        <ng-template [cdkPortalOutlet]="enhancedControl.fieldSuffix"></ng-template>
      </ng-container>
    </ng-container>

    <ng-container matSuffix *ngIf="languages && translations">
      <button id="showTranslations"
              class="btn btn-link show-translations ms-auto"
              [disabled]="valueControl.disabled"
              [matTooltip]="hideTranslationText ? ('label.Translations' | translate) : '' "
              (click)="openTranslationModal()">
        <i class="icon fas fa-fw fa-globe"></i>
        <span *ngIf="!hideTranslationText">{{'label.Translations' | translate}}</span>
      </button>
    </ng-container>

    <mat-error *ngIf="valueControl.errors">
      {{ getValidationMessage() }}
    </mat-error>

    <mat-hint align="start">{{ hint }}</mat-hint>
    <mat-hint align="end">{{ hintRight }}</mat-hint>

    <iris-info-icon
      matPrefix
      class="iris-field-prefix-hint"
      *ngIf="hintPrefix"
      [message]="hintPrefix"
    ></iris-info-icon>
  </mat-form-field>
</div>
