import { StaticProvider } from '@angular/core';
import { SEARCH_ENGINE } from '@iris/common/modules/fields/ng-select-field';
import { BIND_LABEL } from '@iris/common/modules/fields/ng-select-field/properties-tokens';
import { IrisDatetimeSelectEngine } from './datetime-format-select.engine';

export const DATETIME_FORMAT_SELECT_PROVIDERS: StaticProvider[] = [
  {
    provide: IrisDatetimeSelectEngine,
    useClass: IrisDatetimeSelectEngine,
  },
  {
    provide: SEARCH_ENGINE,
    useExisting: IrisDatetimeSelectEngine,
  },
  {
    provide: BIND_LABEL,
    useValue: 'title',
  },
];
