import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IrisCountryI } from '../models/IrisCountry';
import { Observable } from 'rxjs';
import { ApiUrl, IrisBaseCRUDService, IrisQueryParams } from '@iris/api-query';

@Injectable({ providedIn: 'root' })
@ApiUrl('/system/countries/active')
export class IrisCountriesService extends IrisBaseCRUDService<IrisCountryI> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  postProcessing(country: IrisCountryI): IrisCountryI {
    country.originalName = country.name;
    country.name = country.translated?.name || country.name;
    return super.postProcessing(country);
  }

  @ApiUrl('/system/countries')
  getAll(): Observable<IrisCountryI[]> {
    return this.httpClient.get<IrisCountryI[]>(this.url());
  }

  @ApiUrl('/system/countries/{id}/set-active')
  setActive(id: number): Observable<void> {
    return this.httpClient.post<void>(this.url({ id }), {});
  }

  @ApiUrl('/system/countries/{id}/set-active')
  setInactive(id: number): Observable<void> {
    return this.httpClient.delete<void>(this.url({ id }), {});
  }
}
