import { AbstractControl } from '@angular/forms';

export const INTEGER_ONLY_VALIDATOR_NAME = 'integerOnly';

export function integerOnlyValidator(control: AbstractControl): Record<string, unknown> | null {
  if (control.value && !new RegExp('^-?[0-9]*$').test(control.value)) {
    return { [INTEGER_ONLY_VALIDATOR_NAME]: true };
  }
  return null;
}
