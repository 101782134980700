import { Directive, HostListener, Input } from '@angular/core';
import { isBoolean } from 'lodash';

@Directive({
  selector: '[clickStopPropagation]',
})
export class IrisClickStopPropagationDirective {
  @Input() set clickStopPropagation(condition: boolean) {
    this.cancelStopPropagation = isBoolean(condition) ? !condition : false;
  }

  cancelStopPropagation = false;

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent): void {
    if (this.cancelStopPropagation) { return; }
    event.stopPropagation();
  }

  @HostListener('dblclick', ['$event'])
  dblClickEvent(event: MouseEvent): void {
    if (this.cancelStopPropagation) { return; }
    event.stopPropagation();
  }
}
