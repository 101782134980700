import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import { IrisUserService } from '@iris/common/services/user.service';

const LOCALES = {
  en: {
    locale: 'en-US',
    localeForRegister: localeEn,
  },
  de: {
    locale: 'de-DE',
    localeForRegister: localeDe,
  },
};

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  constructor(
    private readonly userService: IrisUserService,
  ) { }

  getLocale(): string {
    let language = this.userService.me?.language ?? 'en';

    if (!LOCALES[language]) {
      language = 'en';
    }

    registerLocaleData(LOCALES[language].localeForRegister);
    return LOCALES[language].locale;
  }
}
