export enum IrisModules {
  ADMINISTRATION = 'ADMIN',
  ALARMING = 'ALARMING',
  BIM = 'BIM',
  BIM_ACTIONS = 'BIM_ACTIONS',
  BIM_COCKPIT = 'BIM_COCKPIT',
  CHARTS = 'CHARTTOOLAJS',
  CLIENTS = 'CLIENTS',
  DASHBOARDS = 'DASHBOARDS_BETA',
  DISCUSSIONS = 'DISCUSSIONS',
  DEVICE_DATA = 'DEVICE_DATA',
  EMAIL = 'EMAIL',
  SIA = 'SIA',
  DMS = 'DMS',
  DMS2 = 'dms2',
  DYNAMIC_FORMS = 'DYNAMIC_FORMS',
  DZY = 'DZY',
  HOME = 'HOME',
  IMS = 'IMS',
  PERSONNEL_MANAGEMENT = 'PERSONNEL_MGMT',
  PROJECTS = 'PROJECT_HIERARCHY',
  RESOURCE_PLANNING = 'RESOURCE_PLANNING',
  SENSORBOARDS = 'SENSORBOARDS',
  SENSORBOARD = 'BOARD',
  SYSTEMCONFIG = 'SYSTEMCONFIG',
}

export enum IrisModulesSubjects {
  Module = 'Module',
  Project = 'Project',
  Company = 'Company',
  DocumentTemplate = 'DocumentTemplate',
  Device = 'Device',
  ChartToolCollection = 'ChartToolCollection',
  Personnel = 'Personnel',
  IdentUser = 'IdentUser',
  ResoursePlanning = 'RESOURCE_PLANNING',
}

export enum IrisModulesActions {
  access = 'access',
  config = 'config',
  read = 'read',
  update = 'update',
}
