import { NgModule } from '@angular/core';
import { IrisHeaderSearchResultCardTemplateComponentModule } from './card-template.component';
import { IrisHeaderSearchResultCardPrimaryIconDirective } from './primary-icon.directive';
import { IrisHeaderSearchResultCardSecondaryIconDirective } from './secondary-icon.directive';

@NgModule({
  imports: [
    IrisHeaderSearchResultCardTemplateComponentModule,
  ],
  declarations: [
    IrisHeaderSearchResultCardPrimaryIconDirective,
    IrisHeaderSearchResultCardSecondaryIconDirective,
  ],
  exports: [
    IrisHeaderSearchResultCardTemplateComponentModule,
    IrisHeaderSearchResultCardPrimaryIconDirective,
    IrisHeaderSearchResultCardSecondaryIconDirective,
  ],
})
export class IrisHeaderSearchResultCardTemplateModule { }
