<iris-modal-content
  [isHideCross]="true"
  [isHideCloseBtn]="true"
  [title]="'label.shiftmanagement.UnlockShiftProtocol' | translate"
>
  <ng-container id="iris-modal-body">
    <div class="clearfix">
      <div class="col-md-7 message">
        {{user.firstname}} {{user.lastname}} - {{user.companyName}} - {{user.email}} - {{user.phone}}:<br>
        {{'label.ShiftMgmt.UnlockProtocolRequest'|translate}}
        <a href="/ui/ui/shift-mgmt/protocol/{{protocolId}}" target="_blank">
          <i class="far fa-external-link"></i>
        </a>
        <p *ngIf="countDown">
          {{'label.ShiftManagement.ProtocolUnlockMessage' | translate}}
          {{'label.inSeconds' | translate}}
          <strong>{{countDown}}</strong>
        </p>
      </div>
    </div>
  </ng-container>

  <ng-container id="iris-modal-footer-buttons">
    <button
      type="button"
      class="btn btn-success"
      data-test="modal-footer-confirm-button"
      (click)="unlock()">
      <i class="fas fa-unlock"></i>
      <span>{{'label.Unlock' | translate}}</span>
    </button>
    <button
      type="button"
      class="btn btn-default"
      data-test="modal-footer-cancel-button"
      (click)="reject()">
      <i class="fas fa-ban"></i>
      <span>{{'label.Reject' | translate}}</span>
    </button>
  </ng-container>
</iris-modal-content>
