import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { IrisAlertModule } from '@iris/common/modules/alert/alert.module';
import { IrisGlobalNavigationMenuModule } from '@iris/common/modules/global-navigation-menu/global-navigation-menu.module';
import { IrisHeaderModule } from '@iris/modules/header/header.module';
import { AppComponent } from './app.component';
import { GlobalAnnouncementBannerModule } from '@iris/common/modules/ui/modules/global-announcement-banner/global-announcement-banner.module';

@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    RouterModule,
    IrisAlertModule,
    IrisGlobalNavigationMenuModule,
    IrisHeaderModule,
    GlobalAnnouncementBannerModule,
  ],
  declarations: [AppComponent],
  exports: [AppComponent],
})
export class AppComponentModule {
}
