import { StaticProvider } from '@angular/core';
import { SEARCH_ENGINE, templateFromComponentProvider } from '@iris/common/modules/fields/ng-select-field';
import { BIND_LABEL, BIND_VALUE, DROPDOWN_FOOTER_TEMPLATE } from '@iris/common/modules/fields/ng-select-field/properties-tokens';
import { IrisDocumentTemplateSelectEngine } from './document-template-select.engine';
import { IrisNgSelectFieldShownElementsCountComponent } from '@iris/common/modules/fields/ng-select-field/common/components';

export const DOCUMENT_TEMPLATE_SELECT_PROVIDERS: StaticProvider[] = [
  {
    provide: IrisDocumentTemplateSelectEngine,
    useClass: IrisDocumentTemplateSelectEngine,
  },
  {
    provide: SEARCH_ENGINE,
    useExisting: IrisDocumentTemplateSelectEngine,
  },
  {
    provide: DROPDOWN_FOOTER_TEMPLATE,
    ...templateFromComponentProvider(IrisNgSelectFieldShownElementsCountComponent, 'template'),
  },
  {
    provide: BIND_LABEL,
    useValue: 'name',
  },
  {
    provide: BIND_VALUE,
    useValue: 'id',
  },
];
