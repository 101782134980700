import { ServerBaseEntity } from './ServerBaseEntity';
import { IrisTagI } from '@iris/common/models/IrisTag';
import { copyValues } from '@iris/common/utils/object.utils';
import { ServerBaseEntityI } from '@iris/api-query';
import { IrisPage } from '@iris/common/models/page';
import { IrisFieldsTranslationSet } from '@iris/common/models/translations';

export interface IrisCompanyI extends ServerBaseEntityI {
  name: string;
  street?: string;
  hausNumber?: string;
  zip?: number;
  location?: string;
  website?: string;
  phone?: string;
  fax?: string;
  email?: string;
  ssoEnabled?: boolean;
  ssoDomain?: string;
  ssoSetting?: IrisCompanySSOSettingI;
  irisCompanyId?: number | null;
  tags?: IrisTagI[];
  translations?: IrisFieldsTranslationSet;
  nameTranslated?: string;
}

export interface IrisCompanySSOSettingI {
  createUserIfAbsent: boolean;
  newUserLanguage: string;
  newUserTimeZone: string;
  newUserGroupsIds: number[];
  newUserStartPageId: number;
}
export type IrisCompanyPage = IrisPage<IrisCompanyI>;

export class IrisCompany extends ServerBaseEntity<IrisCompanyI> implements IrisCompanyI {
  id: number = undefined;
  name: string = undefined;
  street: string = undefined;
  hausNumber: string = undefined;
  zip: number = undefined;
  location: string = undefined;
  website: string = undefined;
  phone: string = undefined;
  fax: string = undefined;
  email: string = undefined;
  ssoEnabled: boolean = undefined;
  ssoDomain: string = undefined;
  ssoSetting: IrisCompanySSOSettingI = {
    createUserIfAbsent: undefined,
    newUserLanguage: undefined,
    newUserTimeZone: undefined,
    newUserGroupsIds: [],
    newUserStartPageId: undefined,
  };
  irisCompanyId: number | null = undefined;
  tags?: IrisTagI[] = [];
  translations: IrisFieldsTranslationSet = {};
  nameTranslated: string = undefined;

  constructor(params?: IrisCompanyI) {
    super(params);
    if (params) {
      copyValues(this, params);

      if (!params.ssoSetting) {
        this.ssoSetting = {
          createUserIfAbsent: undefined,
          newUserLanguage: undefined,
          newUserTimeZone: undefined,
          newUserGroupsIds: [],
          newUserStartPageId: undefined,
        };
      };
    }
  }
}
