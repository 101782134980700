import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app-root.component.html',
})
export class AppRootComponent {
  readonly isLoadingSubject = new BehaviorSubject<boolean>(false);

  constructor(
    readonly translate: TranslateService,
  ) {
    if (!translate.store.translations[translate.currentLang]) {
      this.isLoadingSubject.next(true);

      translate.onLangChange.pipe(
        take(1),
        tap(() => {
          this.isLoadingSubject.next(false);
        }),
      ).subscribe();
    }
  }
}
