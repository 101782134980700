import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { IrisModules } from '@iris/common/models/IrisModulesEnums';
import { LABEL_MARKETPLACE } from '@iris/modules/marketplace/common/constants/marketplace.constants';
import { canAccessModuleGuard } from '@iris/common/guards/can-access-module.guard';
import { canConfigModuleGuard } from '@iris/common/guards/can-config-module.guard';
import { isUserAuthorizedGuard } from '@iris/modules/auth/is-user-authorized.guard';

const ROUTES_AFTER_LOGIN: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'profile',
  }, {
    path: 'profile',
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/profile/profile.module').then(m => m.IrisProfileModule),
    title: 'label.UserProfile',
  }, {
    path: 'projects',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/projects/projects.module').then(m => m.ProjectsModule),
    title: 'label.ProjectManagement',
  }, {
    path: 'plc-errors',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/plc-messages/plc-messages.module').then(m => m.PLCMessagesModule),
    title: 'label.PLCErrors',
  }, {
    path: 'resource-planning',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/resource-planning/resource-planning.module').then(m => m.ResourcePlanningModule),
    data: { moduleCode: IrisModules.RESOURCE_PLANNING },
    title: 'label.ResourcePlanning',
  }, {
    path: 'personnel-management',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/personnel-management/personnel-management.module').then(m => m.IrisPersonnelManagementModule),
    data: { moduleCode: IrisModules.PERSONNEL_MANAGEMENT },
  }, {
    path: 'devices',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/devices/devices.module').then(m => m.DevicesModule),
    data: { moduleCode: IrisModules.DEVICE_DATA },
    title: 'label.Devices',
  }, {
    path: 'marketplace',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import ('./iris-modules/marketplace/marketplace.module').then(m => m.MarketplaceModule),
    title: LABEL_MARKETPLACE,
  },
  {
    path: 'sensors',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/sensors/sensors.module').then(m => m.SensorsModule),
    data: { moduleName: 'label.Sensors' },
    title: 'label.Sensors',
  },
  {
    path: 'dataseries',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/dataseries/dataseries.module').then(m => m.DataseriesModule),
    title: 'label.DataSeries',
  },
  {
    path: 'data-visualization',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('@iris/modules/data-visualization/data-visualization.module').then(m => m.IrisDataVisualizationModule),
    title: 'label.DataVisualization',
  },
  {
    path: 'dms',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/dms/dms.module').then(m => m.DmsModule),
    title: 'label.DocumentManagement',
  }, {
    path: 'bim',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/bim/bim.module').then(m => m.IrisBimModule),
    data: { moduleCode: IrisModules.BIM },
    title: 'label.BimViewer',
  }, {
    path: 'bimpact',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/bimpact/bimpact.module').then(m => m.BimpactModule),
    data: { moduleCode: IrisModules.BIM },
    title: 'BIMPACT',
  }, {
    path: 'bim-actions',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/bim-actions/bim-actions.module').then(m => m.IrisBimActionsModule),
    data: { moduleCode: IrisModules.BIM_ACTIONS },
    title: 'label.BimActions',
  }, {
    path: 'dashboards',
    canMatch: [isUserAuthorizedGuard],
    canActivate: [canAccessModuleGuard],
    loadChildren: () => import('./iris-modules/dashboards/dashboards.module').then(m => m.DashboardsModule),
    title: 'label.DashboardBeta',
  }, {
    path: 'styleguide',
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/styleguide/styleguide.module').then(m => m.IrisStyleguideModule),
    title: 'label.Styleguide',
  }, {
    path: 'dynamic-forms',
    canMatch: [isUserAuthorizedGuard],
    canActivate: [canAccessModuleGuard],
    loadChildren: () => import('./iris-modules/dynamic-forms/dynamic-forms.module').then(m => m.IrisDynamicFormsModule),
    data: { moduleCode: IrisModules.DYNAMIC_FORMS },
  },
  {
    path: 'interval-scanners',
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/interval-scanners/interval-scanners.module').then(m => m.IrisIntervalScannersModule),
    title: 'label.IntervalScanner',
  },
  {
    path: 'integrations',
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/integrations/integrations.module').then(m => m.IrisIntegrationsModule),
    title: 'label.Integrations',
  },
  {
    path: 'forbidden',
    loadChildren: () => import('./iris-modules/forbidden-pages/forbidden-pages.module').then(m => m.IrisForbiddenPagesModule),
  },
  {
    path: 'charts',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/charts/charts.module').then(m => m.IrisChartsModule),
    data: { moduleCode: IrisModules.CHARTS },
    title: 'label.Charts2',
  },
  {
    path: 'home',
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/home/home.module').then(m => m.IrisHomeModule),
    data: { moduleCode: IrisModules.HOME },
    title: 'label.Home',
  },
  {
    path: 'user-notifications',
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/user-notifications/user-notifications.module').then(m => m.IrisUserNotificationsModule),
    title: 'label.UserNotifications',
  },
  {
    path: 'administration',
    canActivate: [canConfigModuleGuard()],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/administration/administration.module').then(m => m.IrisAdministrationModule),
    data: { moduleCode: IrisModules.ADMINISTRATION },
    title: 'label.Administration',
  },
  {
    path: 'dzy',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/dzy/dzy.module').then(m => m.IrisDzyModule),
    data: { moduleCode: IrisModules.DZY },
    title: 'label.ShiftReport',
  },
  {
    path: 'emails',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/emails/emails.module').then(m => m.IrisEmailModule),
    data: { moduleCode: IrisModules.EMAIL },
    title: 'label.Email',
  },
  {
    path: 'sia',
    canActivate: [canAccessModuleGuard],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/sia/sia.module').then(m => m.IrisSiaModule),
    data: { moduleCode: IrisModules.SIA },
    title: 'label.SIA',
  },
  {
    path: 'configuration',
    canActivate: [canConfigModuleGuard()],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/system-configuration/system-configuration.module').then(m => m.IrisSystemConfigurationModule),
    data: { moduleCode: IrisModules.SYSTEMCONFIG },
    title: 'label.SystemConfiguration',
  },
  {
    path: 'sensorboards',
    canActivate: [canConfigModuleGuard()],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/sensorboards/sensorboards.module').then(m => m.SensorboardsModule),
    data: { moduleCode: IrisModules.SENSORBOARD },
    title: 'label.Sensorboards',
  },
  {
    path: 'alarm/alarm-levels',
    canActivate: [canConfigModuleGuard()],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/alarm/alarm-levels/alarm-levels.module').then(m => m.AlarmLevelsModule),
    data: { moduleCode: IrisModules.ALARMING },
    title: 'label.Alarming.AlarmLevels',
  },
  {
    path: 'alarm/alarm-limits',
    canActivate: [canConfigModuleGuard()],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/alarm/alarm-limits/alarm-limits.module').then(m => m.AlarmLimitsModule),
    data: { moduleCode: IrisModules.ALARMING },
    title: 'label.AlarmLimits',
  },
  {
    path: 'alarm/alarm-schemas',
    canActivate: [canConfigModuleGuard()],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/alarm/alarm-schemas/alarm-schemas.module').then(m => m.AlarmSchemasModule),
    data: { moduleCode: IrisModules.ALARMING },
    title: 'label.AlarmSchemas',
  },
  {
    path: 'alarm/alarm-notifications',
    canActivate: [canConfigModuleGuard()],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/alarm/alarm-notifications/alarm-notifications.module').then(m => m.AlarmNotificationsModule),
    data: { moduleCode: IrisModules.ALARMING },
    title: 'label.AlarmNotifications',
  },
  {
    path: 'sensor-groups',
    canActivate: [canConfigModuleGuard()],
    canMatch: [isUserAuthorizedGuard],
    loadChildren: () => import('./iris-modules/sensor-groups/sensor-groups.module').then(m => m.SensorGroupsModule),
    title: 'label.SensorGroups',
  },
  {
    path: '**',
    redirectTo: 'profile',
  },
];

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./iris-modules/auth/auth.module').then(m => m.AuthModule),
    title: 'label.Login',
  },
  {
    path: '',
    canActivate: [isUserAuthorizedGuard],
    component: AppComponent,
    children: ROUTES_AFTER_LOGIN,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    bindToComponentInputs: true,
    paramsInheritanceStrategy: 'always',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
