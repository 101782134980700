<mat-form-field [ngClass]="{ 'subscript-box-visible': hint || valueControl.hasError('required') }"
                [appearance]="$any(enhancedControl.fieldAppearance)">
  <mat-checkbox #input
                class="checkbox-input"
                [ngClass]="{ 'invalid': isFieldInvalid(), 'valid' : isFieldValid() }"
                name="value"
                color="primary"
                [required]="isRequired"
                [(indeterminate)]="indeterminate"
                [formControl]="valueControl">{{ label }}</mat-checkbox>
  <input matInput type="text" hidden />

  <mat-error *ngIf="valueControl.hasError('required')">
    {{ 'label.validation.required' | translate }}
  </mat-error>

  <mat-hint align="start">{{ hint }}</mat-hint>

  <iris-info-icon
    matPrefix
    class="iris-field-prefix-hint"
    *ngIf="hintPrefix"
    [message]="hintPrefix"
  ></iris-info-icon>
</mat-form-field>
