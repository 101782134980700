import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorIsDark',
})
export class ColorIsDarkPipe implements PipeTransform {

  transform(color: string): boolean {
    if (!color) { return false; }

    let rgb = [];
    const isHexCode = color.slice(0, 1) === '#';

    if (isHexCode) {
      rgb = this._parseHex(color);
    }
    else {
      rgb = this._parseRgbA(color);
    }

    const o = Math.round(((parseInt(rgb[0], 10) * 299) + (parseInt(rgb[1], 10) * 587) + (parseInt(rgb[2], 10) * 114)) / 1000);
    return o < 125;
  }

  /**
   *  Parse rgb() or rgba() string to an array of color components (numbers)
   */
  private _parseRgbA(rgb: string): number[] {
    const rgbChannels: string[] = rgb.replace(/[^\d,.]/g, '').split(',');
    return [
      parseInt(rgbChannels[0], 16),
      parseInt(rgbChannels[1], 16),
      parseInt(rgbChannels[2], 16),
    ];
  }

  /**
   *  Parse hex string to an array of color components (numbers)
   */
  private _parseHex(hex: string): number[] {
    const rgbChannels: string[] = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return [
      parseInt(rgbChannels[1], 16),
      parseInt(rgbChannels[2], 16),
      parseInt(rgbChannels[3], 16),
    ];
  }
}
