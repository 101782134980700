import { InjectionToken } from '@angular/core';
import { NgSelectComponent } from '@natlex/ng-select';

function createPropertyToken<T>(propName: string): InjectionToken<T> {
  return new InjectionToken<T>(`ng-select property: ${propName}`);
}

export const DROPDOWN_HEADER_TEMPLATE = createPropertyToken<NgSelectComponent['headerTemplate']>('headerTemplate');
export const DROPDOWN_FOOTER_TEMPLATE = createPropertyToken<NgSelectComponent['footerTemplate']>('footerTemplate');
export const LABEL_TEMPLATE = createPropertyToken<NgSelectComponent['labelValueTemplate']>('labelValueTemplate');
export const OPTION_TEMPLATE = createPropertyToken<NgSelectComponent['optionTemplate']>('optionTemplate');
export const OPTGROUP_TEMPLATE = createPropertyToken<NgSelectComponent['optgroupTemplate']>('optgroupTemplate');
export const BIND_LABEL = createPropertyToken<NgSelectComponent['bindLabel']>('bindLabel');
export const BIND_VALUE = createPropertyToken<NgSelectComponent['bindValue']>('bindValue');
export const GROUP_BY = createPropertyToken<NgSelectComponent['groupBy']>('groupBy');
export const SELECTABLE_GROUP = createPropertyToken<NgSelectComponent['selectableGroup']>('selectableGroup');
export const SELECTABLE_GROUP_AS_MODEL = createPropertyToken<NgSelectComponent['selectableGroupAsModel']>('selectableGroupAsModel');
export const MULTIPLE = createPropertyToken<NgSelectComponent['multiple']>('multiple');
export const PLACEHOLDER = createPropertyToken<NgSelectComponent['placeholder']>('placeholder');
export const ITEMS = createPropertyToken<NgSelectComponent['items']>('items');
export const ADD_TAG = createPropertyToken<NgSelectComponent['addTag']>('addTag');
export const APPEND_TO = createPropertyToken<NgSelectComponent['appendTo']>('appendTo');
export const AUTO_OPEN = createPropertyToken<boolean>('autoOpen');
export const EDITABLE_SEARCH_TERM = createPropertyToken<NgSelectComponent['editableSearchTerm']>('editableSearchTerm');
