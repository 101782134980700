import { Buffer } from 'buffer';
import { inflate } from 'pako';

export function extractPayloadFromToken<T> (accessToken: string): T {
  const payloadPart = accessToken.split('.')[1];
  const payloadBytes = Buffer.from(payloadPart, 'base64');
  const decodedPayload = inflate(payloadBytes);
  const payload: T = JSON.parse(Buffer.from(decodedPayload).toString('utf-8'));
  return payload;
}
