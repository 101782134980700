import { ModuleWithProviders, NgModule } from '@angular/core';
import { RequiredValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/resolvers/required.validation-message-resolver';
import { MinValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/resolvers/min.validation-message-resolver';
import { MaxValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/resolvers/max.validation-message-resolver';
import { MinLengthValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/resolvers/min-length.validation-message-resolver';
import { MaxLengthValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/resolvers/max-length.validation-message-resolver';
import { MinDatetimeValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/resolvers/min-datetime.validation-message-resolver';
import { MaxDatetimeValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/resolvers/max-datetime.validation-message-resolver';
import { IntegerValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/resolvers/integer.validation-message-resolver';
import { applyValidationMessageResolvers } from './provider';
import { UrlValidationMessageResolver } from './resolvers/url.validation-message-resolver';
import { EmailValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/resolvers/email-validation-message-resolver';
import { DivisibleByValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/resolvers/divisible-by.validation-message-resolver';

@NgModule()
export class ValidationMessageResolverModule {
  static forModule(): ModuleWithProviders<ValidationMessageResolverModule> {
    return {
      ngModule: ValidationMessageResolverModule,
      providers: [
        ...applyValidationMessageResolvers(
          RequiredValidationMessageResolver,
          MinValidationMessageResolver,
          MaxValidationMessageResolver,
          MinLengthValidationMessageResolver,
          MaxLengthValidationMessageResolver,
          MinDatetimeValidationMessageResolver,
          MaxDatetimeValidationMessageResolver,
          MaxDatetimeValidationMessageResolver,
          IntegerValidationMessageResolver,
          UrlValidationMessageResolver,
          EmailValidationMessageResolver,
          DivisibleByValidationMessageResolver,
        ),
      ],
    };
  }
}
