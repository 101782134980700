import { Injectable } from '@angular/core';
import { IrisQueryParams, IrisQueryParamsBuilder } from '@iris/api-query';
import { IrisPage } from '@iris/common/models/page';
import { IrisNgSelectFieldSearchEngine } from '@iris/common/modules/fields/ng-select-field';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  publishReplay,
  refCount,
  switchMap,
  tap,
} from 'rxjs/operators';
import { IrisDocumentTemplateI } from '@iris/common/models/IrisDocumentTemplate';
import { IrisDocumentTemplateService } from '@iris/common/services/dynamic-forms/document-template.service';

export interface IrisDocumentTemplateSelectEngineInterface extends IrisNgSelectFieldSearchEngine<IrisDocumentTemplateI, number> {
  getMissingItemLabelFn: (documentTemplateId: number) => Observable<string>;
}

@Injectable()
export class IrisDocumentTemplateSelectEngine implements IrisDocumentTemplateSelectEngineInterface {
  readonly typeahead = new BehaviorSubject('');
  readonly result$: Observable<IrisPage<IrisDocumentTemplateI>>;
  readonly getMissingItemLabelFn: (documentTemplateId: number) => Observable<string>;

  _queryParamsSubject = new BehaviorSubject<IrisQueryParams | null>(null);

  private readonly _loading = new Subject<boolean>();
  readonly loading$ = this._loading.asObservable();

  constructor(
    private readonly documentTemplatesService: IrisDocumentTemplateService,
  ) {
    this.result$ = this._searchDocumentTemplates()
      .pipe(
        publishReplay(1),
        refCount(),
      );
    this.getMissingItemLabelFn = documentTemplateId => this._getDocumentTemplateName(documentTemplateId);
  }

  setExtraQueryParams(queryParams: IrisQueryParams | null): void {
    this._queryParamsSubject.next(queryParams);
  }

  _searchDocumentTemplates(): Observable<IrisPage<IrisDocumentTemplateI>> {
    const term$ = this.typeahead
      .pipe(
        debounceTime(200),
        map(i => i ?? ''),
        distinctUntilChanged(),
      );

    return combineLatest([
      term$,
      this._queryParamsSubject,
    ]).pipe(
      tap(() => this._loading.next(true)),
      switchMap(([term, queryParams]) => {
        const params = this._buildQueryParamsForSearch(term, queryParams);
        return this.documentTemplatesService.getAll(params).pipe(
          catchError(() => of([] as IrisDocumentTemplateI[])),
        );
      }),
      tap(() => this._loading.next(false)),
      map(elements => <IrisPage<IrisDocumentTemplateI>> { elements }),
    );
  }

  _buildQueryParamsForSearch(term: string, queryParams: IrisQueryParams | null): IrisQueryParams {
    const builder = new IrisQueryParamsBuilder(queryParams)
      .filter('name', [`%${term}%`], t => t.strict(false))
      .limit(10)
      .orderBy('name');
    return builder.toStructure();
  }

  _getDocumentTemplateName(documentTemplateId: number): Observable<string> {
    const params = new IrisQueryParamsBuilder()
      .onlyFields(['name'])
      .toStructure();
    return this.documentTemplatesService.getById(documentTemplateId, params)
      .pipe(
        map(documentTemplate => documentTemplate.name),
        catchError(() => of(null)),
      );
  }
}
