import { FactorySansProvider, Injector, Type, ViewContainerRef } from '@angular/core';

export function templateFromComponentProvider<T>(componentType: Type<T>, templatePropertyName: keyof T, initFn?: (component: T) => void): FactorySansProvider {
  return <FactorySansProvider> {
    useFactory: (
      viewContainerRef: ViewContainerRef,
      injector: Injector,
    ) => {
      const componentRef = viewContainerRef.createComponent(componentType, { injector });

      if (initFn) {
        initFn(componentRef.instance);
      }

      return componentRef.instance[templatePropertyName];
    },
    deps: [
      ViewContainerRef,
      Injector,
    ],
  };
}
