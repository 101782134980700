import { IrisQueryParamsBuilder } from '@iris/api-query';
import { IrisBuildingI } from '@iris/common/models/IrisBuilding';
import { IrisBuildingsService } from '@iris/common/services/buildings.service';
import { IrisFilterDefaultChipItemViewComponent } from '@iris/modules/module-filters-v2/chips/default-chip-item-view.component';
import { isObject } from 'lodash';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { IrisFilterChipRenderingOptions } from './chip-rendering-options';

type Building = IrisBuildingI | IrisBuildingI['id'];

export const BUILDING_CHIP_RENDERING_OPTIONS: IrisFilterChipRenderingOptions<IrisFilterDefaultChipItemViewComponent, Building> = {
  component: IrisFilterDefaultChipItemViewComponent,
  initComponentFn: (component, building, injector) => {
    const service = injector.get(IrisBuildingsService);
    const id = isObject(building) ? building.id : building;
    const params = new IrisQueryParamsBuilder().onlyFields(['name']).toStructure();

    component.text$ = service.getById(id as unknown as number, params)
      .pipe(
        map(entity => entity.name),
        publishReplay(1),
        refCount(),
      );
  },
};
