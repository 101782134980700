import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrl, IrisBaseCRUDService, IrisQueryParams } from '@iris/api-query';
import { IrisCompanyI, IrisCompanyPage } from '@iris/common/models/IrisCompany';
import { Observable } from 'rxjs';

@Injectable()
@ApiUrl('/system/companies')
export class IrisCompaniesService extends IrisBaseCRUDService<IrisCompanyI> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  @ApiUrl('~/updatable')
  getUpdatableCompanies(): Observable<IrisCompanyI[]> {
    return this.httpClient.get<IrisCompanyI[]>(this.url());
  }

  @ApiUrl('~/page')
  getCompaniesPage(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisCompanyPage> {
    return this.httpClient.get<IrisCompanyPage>(this.url(), { params: params.toObject() });
  }
}
