import { DfFilterInfo } from '@iris/common/modules/dynamic-forms/df-filter/models/df-filter-info';
import { DfPropConfig } from '@iris/common/modules/dynamic-forms/df-prop/types';
import { IrisFilterChipRenderingOptions } from '@iris/modules/module-filters-v2/chips/rendering-options';
import { IrisFilterPropertyMeta } from '@iris/modules/module-filters-v2/filter-meta';
import { defaultAppendQueryParamsFn } from '@iris/modules/module-filters-v2/query-params';
import { isEmpty, isNil, isObject } from 'lodash';
import { convertToArray } from '@iris/common/utils/array.utils';

export function getFieldNameForFilter<DfPropData>(dataPath: string, dataFieldName?: keyof DfPropData): string {
  return dataPath + (dataFieldName ? `.${String(dataFieldName)}` : '');
}

export function getUserFieldNameForFilter<DfPropData>(filterInfo: DfFilterInfo, dataFieldName?: keyof DfPropData): string {
  if (filterInfo.type === 'META') {
    return filterInfo.property.path;
  }
  const suffix = dataFieldName ? `.${String(dataFieldName)}` : '';
  return `data.${filterInfo.property.path}${suffix}`;
}

export function mapFilterValue<TValue>(value: TValue, useInnerPropKey?: keyof TValue): unknown {
  return useInnerPropKey ? value[useInnerPropKey] : value;
}

export type FilterFieldNameResolver<TSettings> = (filterInfo: DfFilterInfo<TSettings>) => string;
export type ChipRenderingOptionsResolver<TSettings> = (filterInfo: DfFilterInfo<TSettings>) => IrisFilterChipRenderingOptions;

export function buildDefaultEntityFilterMeta<TSettings>(
  chipRenderingOptionsResolver: ChipRenderingOptionsResolver<TSettings>,
  filterFieldNameResolver: FilterFieldNameResolver<TSettings>,
): DfPropConfig['buildFilterMeta']{
  return {
    filter: buildDefaultEntitySidebarFilterMeta(chipRenderingOptionsResolver, filterFieldNameResolver),
    floatingFilter: buildDefaultEntityFloatingFilterMeta(filterFieldNameResolver),
  };
}

export function buildDefaultEntitySidebarFilterMeta<TValue extends { id: TId }, TId, TSettings>(
  chipRenderingOptionsResolver: ChipRenderingOptionsResolver<TSettings>,
  filterFieldNameResolver: FilterFieldNameResolver<TSettings>,
): (filterInfo: DfFilterInfo<TSettings>) => IrisFilterPropertyMeta<(TValue | TValue['id'])[]> {
  return filterInfo => {
    const fieldNameForFilter = filterFieldNameResolver(filterInfo);
    const valueConverter = (values: (TValue | TValue['id'])[]) => convertToArray(values, true).map(idExtractor);

    return {
      propertyId: filterInfo.id,
      label: filterInfo.name,
      chipRenderingOptions: chipRenderingOptionsResolver(filterInfo),
      filterValueIsEmpty: isEmpty,
      appendFilterQueryParamsFn: defaultAppendQueryParamsFn(fieldNameForFilter, valueConverter),
    };
  };
}

export function buildDefaultEntityFloatingFilterMeta<TValue extends { id: TId }, TId, TSettings>(
  filterFieldNameResolver: FilterFieldNameResolver<TSettings>,
): (filterInfo: DfFilterInfo<TSettings>) => IrisFilterPropertyMeta<TValue | TValue['id']> {
  return filterInfo => {
    const fieldNameForFilter = filterFieldNameResolver(filterInfo);
    const valueConverter = (value: (TValue | TValue['id'])) => [idExtractor(value)];

    return {
      propertyId: filterInfo.id,
      filterValueIsEmpty: isNil,
      appendFilterQueryParamsFn: defaultAppendQueryParamsFn(fieldNameForFilter, valueConverter),
    };
  };
}

export function idExtractor<TId>(value: { id: TId } | TId): TId {
  return isObject(value) ? value.id : value;
}
