import { IrisMFFilterBase } from './IrisMFFilterBase';
import { IrisFilterFieldPipe } from '@iris/common/modules/pipes-common/pipes';
import { IrisMultiselectPropertyOptions } from './IrisModuleFilterPropertyOptionsParams';
import { IrisTranslatePipe } from '../../pipes-common/pipes/translate/translate.pipe';
import * as  _ from 'lodash';
import { Observable, of } from 'rxjs';
import isNil from 'lodash/isNil';

export class IrisMFMultiselect extends IrisMFFilterBase {

  private readonly filterFieldPipe = new IrisFilterFieldPipe();
  private readonly directoryFieldName: string;
  private readonly textFieldName: string;
  private readonly fullDescription: boolean;
  private readonly valueFieldName: string;
  private readonly locale: string;

  constructor(fieldName, options: IrisMultiselectPropertyOptions = {}, private readonly translatePipe: IrisTranslatePipe = new IrisTranslatePipe()) {
    super(fieldName, options);
    this.directoryFieldName = options.directoryFieldName;
    this.textFieldName = options.textFieldName;
    this.fullDescription = options.fullDescription;
    this.valueFieldName = options.valueFieldName;
    this.locale = options.locale;
  }

  isInFilter(filter) {
    return filter[this.formFieldName] && filter[this.formFieldName].length;
  }

  getFilterValue(val) {
    return _.cloneDeep(val);
  }

  getFilterDescription(filter, filterScope): Observable<string> {
    if (filter[this.formFieldName].length > 1) {
      return of(`${this.label}: ${filter[this.formFieldName].length}`);
    } else {
      return of((this.fullDescription ? `${this.label}: ` : '')
        + (filterScope[this.directoryFieldName] ?
          this.filterFieldPipe.transform(filter[this.formFieldName][0], [filterScope[this.directoryFieldName], this.textFieldName || 'name', this.valueFieldName || 'id'])
          : 1));
    }
  }

  getFilterTooltip(filter, filterScope = {}): Observable<string> {
    if (filter[this.formFieldName].length > 1) {
      if (!filterScope[this.directoryFieldName]) { return null; }
      let res = '';
      filter[this.formFieldName].forEach(f => {
        if (res !== '') { res += '<br>'; }

        const field = this.textFieldName || 'name';
        const filteredValue = this.filterFieldPipe.transform(f, [filterScope[this.directoryFieldName], field]);
        const translated = this.filterFieldPipe.transform(f, [filterScope[this.directoryFieldName], `${field}Translated`]);
        const translations = this.filterFieldPipe.transform(f, [filterScope[this.directoryFieldName], 'translations']) || {};

        if (!isNil(translated)) {
          res += translated;
        } else {
          res += this.translatePipe.transform('name', translations[field] || translations, filteredValue, this.locale);
        }
      });
      return of(res);
    } else {
      return this.fullDescription ? null : of(this.label);
    }
  }
}
