<div class="login iris-dark">
  <div class="bg">
    <img src="assets/map.svg" />
  </div>
  <div class="login-content">
    <language-select class="position-absolute top-0 end-0 mt-2 me-2"></language-select>

    <div class="login-logo">
      <img *ngIf="imageUrl$ | async"
           [src]="imageUrl$ | async | safeResourceUrl"
           alt="Logo">
    </div>
    <router-outlet></router-outlet>
  </div>
  <div class="footer py-2 text-center text-rich text-secondary-light d-none">
    <button
      data-test="show-imprint-modal"
      class="btn btn-link"
      type="button"
      (click)="showInfoModal()">{{'label.Imprint' | translate}}</button>
  </div>
</div>
