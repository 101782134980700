import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { IrisEmailsService } from '../../services/emails.service';
import {
  GetChildrenFoldersError,
  GetChildrenFoldersStart,
  GetChildrenFoldersSuccess,
  GetCustomFolderError,
  GetCustomFoldersError,
  GetCustomFoldersStart,
  GetCustomFoldersSuccess,
  GetCustomFolderStart,
  GetCustomFolderSuccess,
  RefreshCustomFoldersStart,
} from './emails-navigation.actions';
import { of } from 'rxjs';
import { IrisAlertService } from '@iris/common/modules/alert/service/alert.service';

@Injectable()
export class IrisEmailsNavigationEffects {
  getCustomFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCustomFoldersStart, RefreshCustomFoldersStart),
      switchMap(() => this.emailsService.getCustomFolders().pipe(
        map(response => GetCustomFoldersSuccess({ folders: response.currentPage, total: response.count })),
        catchError((err) => {
          const errorMessage = err?.messages?.[0];
          if (!!errorMessage) {
            this.alert.error(errorMessage);
          }
          return of(GetCustomFoldersError());
        }),
      )),
    ),
  );

  getCustomFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCustomFolderStart),
      mergeMap((action) => this.emailsService.getCustomFolder(action.folderId).pipe(
        map(folder => GetCustomFolderSuccess({ folder })),
        catchError(() => of(GetCustomFolderError())),
      )),
    ),
  );

  getChildrenFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetChildrenFoldersStart),
      switchMap((action) => this.emailsService.getChildrenFolders(action.parentFolderId).pipe(
        map(response => GetChildrenFoldersSuccess({ folders: response.currentPage })),
        catchError(() => of(GetChildrenFoldersError())),
      )),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly emailsService: IrisEmailsService,
    private readonly alert: IrisAlertService,
  ) {}
}
