import { Pipe, PipeTransform } from '@angular/core';
import { IrisTimeSandbox } from '../time.sandbox';
import { AbstractDatetime } from '../../date';

export type outputModes = 'datetime' | 'date' | 'time';

const DEFAULT_DATETIME_FORMAT = 'dd.MM.yyyy HH:mm:ss';
const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

@Pipe({
  name: 'irisUserTime',
  standalone: true,
})
export class IrisUserTimePipe implements PipeTransform {
  constructor(
    private readonly timeSandbox: IrisTimeSandbox,
  ) {}

  transform(value: unknown, mode: outputModes = 'datetime', timeZone?: string): string {
    const format = this._getFormatByOutputMode(mode);
    return value ? AbstractDatetime.parse(value).setTimezone(timeZone || this.timeSandbox.currentUserTimeZone).format(format) : '';
  }

  private _getFormatByOutputMode(mode: outputModes): string {
    switch (mode) {
      case 'datetime': return this.timeSandbox.currentUserDateTimeFormat?.luxonFormatString || DEFAULT_DATETIME_FORMAT;
      case 'date': return this.timeSandbox.currentUserDateFormat?.luxonFormatString || DEFAULT_DATE_FORMAT;
      case 'time': return this.timeSandbox.currentUserTimeFormat?.luxonFormatString || DEFAULT_TIME_FORMAT;
      default: return '';
    }
  }
}
