import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IrisCountrySelectDropdownGroupItemComponent } from './dropdown-group-item.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [IrisCountrySelectDropdownGroupItemComponent],
  exports: [IrisCountrySelectDropdownGroupItemComponent],
})
export class IrisCountrySelectDropdownGroupItemComponentModule { }
