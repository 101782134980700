import { NgModule } from '@angular/core';
import { IrisNgSelectFieldModule } from '@iris/common/modules/fields/ng-select-field';
import { IrisNgSelectFieldShownElementsCountComponentModule } from '@iris/common/modules/fields/ng-select-field/common/components';
import { NgSelectModule } from '@natlex/ng-select';
import { IrisDocumentTemplateSelectDirective } from '@iris/common/modules/fields/document-template-select/document-template-select.directive';

@NgModule({
  imports: [
    NgSelectModule,
    IrisNgSelectFieldModule,
    IrisNgSelectFieldShownElementsCountComponentModule,
  ],
  declarations: [
    IrisDocumentTemplateSelectDirective,
  ],
  exports: [
    IrisDocumentTemplateSelectDirective,
    IrisNgSelectFieldModule,
    NgSelectModule,
  ],
})
export class IrisDocumentTemplateSelectModule {
}
