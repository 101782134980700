import { copyValues } from '@iris/common/utils/object.utils';

export enum LanguageCodes {
  DE = 'de-DE',
  US = 'en-US',
  DE_SHORT = 'de',
  EN_SHORT = 'en'
}

export interface IrisLanguageI {
  name: string;
  title: string;
  flagFile: string;
  id?: string;
}

export class IrisLanguage implements IrisLanguageI {
  name: string = undefined;
  title: string = undefined;
  flagFile: string = undefined;
  id: string = undefined;
  constructor(params?: IrisLanguageI) {
    if (params) {
      copyValues(this, params);
    }
  }
}
