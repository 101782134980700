import { Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { Properties } from './interfaces';
import { defaultProperties, backwardCompatibilityProperties } from './properties';
import { IvyPinch } from './ivypinch';
import { IrisColorService } from '@iris/styles';

interface ComponentProperties extends Properties {
  disabled?: boolean;
  overflow?: 'hidden' | 'visible';
  disableZoomControl?: 'disable' | 'never' | 'auto';
  backgroundColor?: string;
}

type PropertyName = keyof ComponentProperties;

@Component({
  selector: 'pinch-zoom, [pinch-zoom]',
  exportAs: 'pinchZoom',
  templateUrl: './pinch-zoom.component.html',
  styleUrls: ['./pinch-zoom.component.scss'],
})
export class PinchZoomComponent implements OnInit, OnChanges, OnDestroy {
  pinchZoom: any;
  _properties!: ComponentProperties;
  defaultComponentProperties!: ComponentProperties;
  _transitionDuration!: number;
  _doubleTap!: boolean;
  _doubleTapScale!: number;
  _autoZoomOut!: boolean;
  _limitZoom!: number | 'original image size';
  _defaultComponentProperties: ComponentProperties = {
    overflow: 'hidden',
    disableZoomControl: 'auto',
    backgroundColor: this.irisColors.colors['$secondaryGrey600'],
  };

  @Input('properties') set properties(value: ComponentProperties) {
    if (value) {
      this._properties = value;
    }
  }
  get properties(): ComponentProperties {
    return this._properties;
  }

  // transitionDuration
  @Input('transition-duration') set transitionDurationBackwardCompatibility(value: number) {
    if (value) {
      this._transitionDuration = value;
    }
  }
  @Input('transitionDuration') set transitionDuration(value: number) {
    if (value) {
      this._transitionDuration = value;
    }
  }
  get transitionDuration(): number {
    return this._transitionDuration;
  }

  // doubleTap
  @Input('double-tap') set doubleTapBackwardCompatibility(value: boolean) {
    if (value) {
      this._doubleTap = value;
    }
  }
  @Input('doubleTap') set doubleTap(value: boolean) {
    if (value) {
      this._doubleTap = value;
    }
  }
  get doubleTap(): boolean {
    return this._doubleTap;
  }

  // doubleTapScale
  @Input('double-tap-scale') set doubleTapScaleBackwardCompatibility(value: number) {
    if (value) {
      this._doubleTapScale = value;
    }
  }
  @Input('doubleTapScale') set doubleTapScale(value: number) {
    if (value) {
      this._doubleTapScale = value;
    }
  }
  get doubleTapScale(): number {
    return this._doubleTapScale;
  }

  // autoZoomOut
  @Input('auto-zoom-out') set autoZoomOutBackwardCompatibility(value: boolean) {
    if (value) {
      this._autoZoomOut = value;
    }
  }
  @Input('autoZoomOut') set autoZoomOut(value: boolean) {
    if (value) {
      this._autoZoomOut = value;
    }
  }
  get autoZoomOut(): boolean {
    return this._autoZoomOut;
  }

  // limitZoom
  @Input('limit-zoom') set limitZoomBackwardCompatibility(value: number | 'original image size') {
    if (value) {
      this._limitZoom = value;
    }
  }
  @Input('limitZoom') set limitZoom(value: number | 'original image size') {
    if (value) {
      this._limitZoom = value;
    }
  }
  get limitZoom(): number | 'original image size' {
    return this._limitZoom;
  }

  @Input() disabled!: boolean;
  @Input() disablePan!: boolean;
  @Input() overflow!: 'hidden' | 'visible';
  @Input() zoomControlScale!: number;
  @Input() disableZoomControl!: 'disable' | 'never' | 'auto';
  @Input() backgroundColor!: string;
  @Input() limitPan!: boolean;
  @Input() minPanScale!: number;
  @Input() minScale!: number;
  @Input() listeners!: 'auto' | 'mouse and touch';
  @Input() wheel!: boolean;
  @Input() autoHeight!: boolean;
  @Input() wheelZoomFactor!: number;
  @Input() draggableImage!: boolean;

  @HostBinding('style.overflow')
  get hostOverflow(): 'hidden' | 'visible' {
    return this.properties['overflow'];
  }

  @HostBinding('style.background-color')
  get hostBackgroundColor(): string {
    return this.properties['backgroundColor'];
  }

  get isTouchScreen(): boolean {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = (query: string): boolean => { return window.matchMedia(query).matches; };
    if (('ontouchstart' in window)) { return true; }
    // include the 'heartz' as a way to have a non-matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  }

  get isDragging(): boolean | undefined {
    return this.pinchZoom ? this.pinchZoom.isDragging() : undefined;
  }

  get isDisabled(): boolean {
    return this.properties['disabled'];
  }

  get scale(): number {
    return this.pinchZoom.scale;
  }

  get isZoomedIn(): boolean {
    return this.scale > 1;
  }

  get scaleLevel(): number {
    return Math.round(this.scale / this._zoomControlScale);
  }

  get maxScale(): number {
    return this.pinchZoom.maxScale;
  }

  get isZoomLimitReached(): boolean {
    return this.scale >= this.maxScale;
  }

  get _zoomControlScale(): number {
    return this.getPropertiesValue('zoomControlScale');
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly irisColors: IrisColorService,
  ) {
    this.defaultComponentProperties = this.getDefaultComponentProperties();
    this.applyPropertiesDefault(this.defaultComponentProperties, {});
  }

  ngOnInit(): void {
    this.initPinchZoom();

    /* Calls the method until the image size is available */
    this.detectLimitZoom();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let changedProperties = this.getProperties(changes);
    changedProperties = this.renameProperties(changedProperties);

    this.applyPropertiesDefault(this.defaultComponentProperties, changedProperties);
  }

  initPinchZoom(): void {
    if (this.properties['disabled']) {
      return;
    }

    this.properties['element'] = this.elementRef.nativeElement.querySelector('.pinch-zoom-content');
    this.pinchZoom = new IvyPinch(this.properties);
  }

  getProperties(changes: SimpleChanges): any {
    let properties: any = {};

    for (const prop in changes) {
      if (prop !== 'properties') {
        properties[prop] = changes[prop].currentValue;
      }
      if (prop === 'properties') {
        properties = changes[prop].currentValue;
      }
    }
    return properties;
  }

  renameProperties(properties: any): any {
    for (const prop in properties) {
      if (backwardCompatibilityProperties[prop]) {
        properties[backwardCompatibilityProperties[prop]] = properties[prop];
        delete properties[prop];
      }
    }
    return properties;
  }

  applyPropertiesDefault(defaultProperties: ComponentProperties, properties: ComponentProperties): void {
    this.properties = Object.assign({}, defaultProperties, properties);
  }

  toggleZoom(): void {
    this.pinchZoom.toggleZoom();
  }

  isControl(): boolean {
    if (this.isDisabled) {
      return false;
    }
    if (this.properties['disableZoomControl'] === 'disable') {
      return false;
    }
    if (this.isTouchScreen && this.properties['disableZoomControl'] === 'auto') {
      return false;
    }
    return true;
  }

  detectLimitZoom(): void {
    if (this.pinchZoom) {
      this.pinchZoom.detectLimitZoom();
    }
  }

  getPropertiesValue(propertyName: PropertyName): any{
    if (this.properties && this.properties[propertyName]) {
      return this.properties[propertyName];
    } else {
      return this.defaultComponentProperties[propertyName];
    }
  }

  getDefaultComponentProperties(): ComponentProperties {
    return { ...defaultProperties, ...this._defaultComponentProperties };
  }

  destroy(): void {
    this.pinchZoom.destroy();
  }

  ngOnDestroy(): void {
    this.destroy();
  }
}
