<span class="iris-user-avatar" [ngClass]="avatarSizeClass">
    <span *ngIf="hasAvatar && !avatarLoadingError"
          class="image">
        <img [src]="avatarLink" alt="" (error)="avatarLoadingError=true" />
    </span>
    <span *ngIf="(!hasAvatar || avatarLoadingError) && userExists"
          class="user-no-avatar first-letters-avatar">
        <span>{{ userInitials }}</span>
    </span>
    <span *ngIf="!userExists"
          class="user-no-avatar gray">
        <i class="fas fa-user"></i>
    </span>
</span>
