import { ValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { IrisUserTimePipe } from '@iris/common/modules/time';

@Injectable()
export class MinDatetimeValidationMessageResolver implements ValidationMessageResolver<{ min: unknown }> {
  readonly errorCode = 'matDatepickerMin';

  constructor(
    private readonly translateService: TranslateService,
    private readonly irisUserTimePipe: IrisUserTimePipe,
  ) { }

  getMessage(error?: { min: unknown }): string {
    return this.translateService.instant('label.validation.min', { value: this.irisUserTimePipe.transform(error?.min) });
  }
}
