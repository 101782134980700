import { ValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver';
import { TranslateService } from '@ngx-translate/core';
import { Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class MinValidationMessageResolver implements ValidationMessageResolver<{ min: unknown }> {
  readonly errorCode = Validators.min.name;

  constructor(
    private readonly translateService: TranslateService,
  ) { }

  getMessage(error?: { min: unknown }): string {
    return this.translateService.instant('label.validation.min', { value: error?.min });
  }
}
