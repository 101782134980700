import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectConfig, NgSelectModule } from '@natlex/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IrisNgSelectFieldDirective } from './ng-select-field.directive';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    TranslateModule,
  ],
  declarations: [
    IrisNgSelectFieldDirective,
  ],
  providers: [
    {
      provide: NgSelectConfig,
      useFactory: (translateService: TranslateService) => {
        return <NgSelectConfig> {
          ...new NgSelectConfig(),
          typeToSearchText: translateService.instant('label.TypeToSearch'),
          notFoundText: translateService.instant('label.NoResultsFound'),
          addTagText: translateService.instant('label.AddNew'),
        };
      },
      deps: [TranslateService],
    },
  ],
  exports: [
    IrisNgSelectFieldDirective,
  ],
})
export class IrisNgSelectFieldModule {
}
