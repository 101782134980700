import { IrisQueryParamsBuilder } from '@iris/api-query';
import { IrisFilterDefaultChipItemViewComponent } from '@iris/modules/module-filters-v2/chips/default-chip-item-view.component';
import { IrisCompaniesService } from '@iris/common/services/companies.service';
import { IrisCompanyI } from '@iris/common/models/IrisCompany';
import { isObject } from 'lodash';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { IrisFilterChipRenderingOptions } from './chip-rendering-options';

type Company = IrisCompanyI | IrisCompanyI['id'];

export const COMPANY_CHIP_RENDERING_OPTIONS: IrisFilterChipRenderingOptions<IrisFilterDefaultChipItemViewComponent, Company> = {
  component: IrisFilterDefaultChipItemViewComponent,
  initComponentFn: (component, company, injector) => {
    const service = injector.get(IrisCompaniesService);
    const id = isObject(company) ? company.id : company;
    const params = new IrisQueryParamsBuilder().onlyFields(['name']).toStructure();

    component.text$ = service.getById(id, params)
      .pipe(
        map(entity => entity.name),
        publishReplay(1),
        refCount(),
      );
  },
};
