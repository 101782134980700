import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'irisHighlightUrl',
  pure: false,
})
export class IrisHighlightUrlPipe implements PipeTransform {
  private readonly urlPattern = /(http|https):\/\/[\w-]+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/gi;
  transform(text: string, placeholder?: string, target?: string): any {
    if (!text) {
      return text;
    }
    return text.replace(this.urlPattern, '<a class="btn-link" target="' + (target || '_black') + '" href="$&">' + (placeholder || '$&') + '</a>');
  }
}
