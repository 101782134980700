<div class="login-form">
  <h3 [innerHTML]="headings[state] | translate"></h3>
  <div *ngIf="error" class="alert error" data-test="login-error">
    {{error}}
  </div>
  <div *ngIf="message" class="alert alert-success" data-test="login-message">
    {{message}}
  </div>
  <ng-container [ngSwitch]="state">
    <ng-template ngSwitchCase="sso">
      <button
        type="submit"
        class="btn btn-light btn-login"
        data-test="sso-btn-login"
        (click)="checkAccountAndRedirect()"
      >{{'label.CompanyLogin' | translate}}</button>
      <div class="divider text-rich">{{'label.or' | translate}}</div>
      <button
        class="btn btn-link text-rich"
        (click)="setState('login')"
        data-test="remembered-btn-back"
      >{{'label.LoginWithYourIRISAccount' | translate}}</button>
    </ng-template>
    <ng-template ngSwitchCase="forgot">
      <form (ngSubmit)="sendResetLink()" autocomplete="off">
        <iris-field-text
          [(ngModel)]="email"
          name="username"
          [showSuffix] = 'false'
          [label]="'label.EmailOrUsername' | translate"
          data-test="forgot-email"
        ></iris-field-text>
        <button
          type="submit"
          class="btn btn-light btn-login"
          [disabled]="!email"
          data-test="forgot-btn-submit"
        >{{'label.RestorePassword' | translate}}</button>
      </form>
      <button
        type="button"
        class="btn btn-link text-rich"
        (click)="setState('login')"
        data-test="forgot-btn-back"
      >{{'label.BackToLoginPage' | translate}}</button>
    </ng-template>
    <ng-template ngSwitchDefault>
      <form (ngSubmit)="submitForm()">
        <div class="field mb-2">
          <iris-field-text
            [(ngModel)]="email"
            name="username"
            [showSuffix] = 'false'
            [label]="'label.EmailOrUsername' | translate"
            data-test="login-email"
          ></iris-field-text>
        </div>
        <div class="field mb-0">
          <iris-field-password
            [(ngModel)]="pass"
            name="pass"
            [label]="'label.Password' | translate"
            data-test="login-password"
          ></iris-field-password>
        </div>
        <button
          type="button"
          class="btn btn-link login-btn-forgot mb-3"
          (click)="setState('forgot')"
          data-test="login-btn-forgot"
        >{{'label.ForgotPasswordNew' | translate}}</button>
        <button
          type="submit"
          class="btn btn-light btn-login"
          data-test="login-btn-submit"
        >{{'label.LoginNew' | translate}}</button>
      </form>
      <button
        type="button"
        class="btn btn-link text-rich"
        (click)="setState('sso')"
        data-test="login-btn-sso"
      >{{'label.BackToLoginOptionsSelection' | translate}}</button>
    </ng-template>
  </ng-container>
</div>
