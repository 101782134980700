<div class="iris-header-search"
     [class.expand]="isSearching">
  <form class="flex-valign">
    <ng-select *ngIf="isSearching"
               class="ng-select-inline ng-select-header searching-types"
               data-test="header-searching-types"
               appendTo="body"
               [searchable]="false"
               [clearable]="false"
               [items]="availableSearchingTypes$ | async"
               [ngModel]="selectedSearchingType"
               (ngModelChange)="onTypeChange($event)"
               [ngModelOptions]="{standalone: true}">
    </ng-select>
    <div class="navbar-form dms radius">
      <div class="input-group"
        #searchDropdownTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu">
        <input type="text"
               #searchInput
               [formControl]="searchFormControl"
               (click)="onSearchClickEvent($event, searchDropdownTrigger.menuOpen)"
               autocomplete="off"
               class="form-control at-search-field"
               placeholder="{{'label.Search' | translate}}">
        <span class="input-group-btn">
          <button class="btn btn-link search-button"
                  (click)="setFocused($event);"
                  type="button">
            <span class="far fa-fw fa-search"></span>
          </button>
          <button class="btn btn-link clear-button"
                  [matTooltip]="'label.Clear' | translate"
                  (click)="clearSearchInput($event);"
                  type="reset">
            <span class="fa-fw" [ngClass]="{'fal fa-times' : !isLoading , 'fas fa-spinner fa-spin': isLoading }"></span>
          </button>
        </span>
      </div>
      <mat-menu class="iris-dropdown-menu header-search-overlay"
                #menu="matMenu"
                [hasBackdrop]="false"
                [class.while-loading-and-error]="isLoading || isSearchingError || searchStr.length < MIN_SEARCH_LEN">
        <ul class="items-container" *ngIf="searchStr.length >= MIN_SEARCH_LEN && !noSearchResults">
          <ng-container *ngIf="files.length">
            <li class="header-group justify-content-between">
              <div *ngIf="selectedSearchingType.name === 'all'">
                <i class="fas fa-briefcase fa-fw"></i>&nbsp;{{ 'label.Files' | translate }}
              </div>
              <a [href]="getFilesShowMoreUrl(searchStr)"
                 class="btn btn-xs btn-link">{{ 'label.ShowMore' | translate }}</a>
            </li>
            <div>
              <li *ngFor="let file of files" class="border-bottom">
                <iris-header-search-result-card-template
                  (closeClick)="searhResultsClose()"
                  [titleData]="file.titleData"
                  [secondaryData]="file.secondaryData"
                  [cardLink]="file.cardLink">
                  <ng-template irisHeaderSearchResultCardPrimaryIcon>
                    <div class="item-left-sidebar">
                      <i class="icon-iris dms-icon-color"
                         [ngClass]="file.icon"></i>
                    </div>
                  </ng-template>
                </iris-header-search-result-card-template>
              </li>
            </div>
          </ng-container>

          <ng-container *ngIf="devices.length">
            <li *ngIf="selectedSearchingType.name === 'all'"
                class="header-group">
              <i class="fas fa-fw fa-train"></i>&nbsp;{{ 'label.Devices' | translate }}
            </li>
            <li *ngFor="let device of devices" class="item">
              <div class="item-content">
                <div class="content-header">
                  <h4 class="title title-device-search flex-middle" (click)="goToDevice(device.id)">
                    <div class="led-status" [ngClass]="device.isActive? 'green' : 'red'"
                         [matTooltip]="(device.isActive? 'label.Active' : 'label.NotActive') | translate">
                    </div>
                    <a class="title-link" [href]="getDeviceUrl(device.id)">
                      {{device.name}} <i class="far fa-external-link"></i>
                    </a>
                  </h4>
                </div>

                <div class="content-body">
                  <p>{{ 'label.DeviceType' | translate }}: {{ device['deviceTypeName'] }}</p>
                  <p>{{ 'label.Company' | translate }}: {{ device['companyName'] }}</p>
                  <p>{{ 'label.MachineId' | translate }}: {{ device.machineId || '-' }}</p>
                </div>
              </div>
            </li>
          </ng-container>

          <ng-container *ngIf="projects.length">
            <li class="header-group justify-content-between">
              <div *ngIf="selectedSearchingType.name === 'all'">
                <i class="fas fa-briefcase fa-fw"></i>&nbsp;{{ 'label.Projects' | translate }}
              </div>
              <a class="btn btn-xs btn-link"
                [href]="getProjectsShowMoreUrl(searchStr)">
                {{ 'label.projects.ToProjectManagement' | translate }}
              </a>
            </li>
            <div>
              <li *ngFor="let project of projects" class="border-bottom">
                <iris-header-search-result-card-template
                  (closeClick)="searhResultsClose()"
                  [titleData]="project.titleData"
                  [secondaryData]="project.secondaryData"
                  [cardLink]="project.cardLink">
                  <ng-template irisHeaderSearchResultCardPrimaryIcon>
                    <div class="text-medium">
                      <i class="fas fa-briefcase fa-3x text-disabled"></i>
                    </div>
                  </ng-template>
                </iris-header-search-result-card-template>
              </li>
            </div>
          </ng-container>

          <ng-container *ngIf="users.length">
            <li *ngIf="selectedSearchingType.name === 'all'"
                class="header-group">
              <i class="fas fa-users fa-fw"></i>&nbsp;{{ 'label.Users' | translate }}
            </li>

            <li *ngFor="let user of users" class="item">
              <iris-header-search-result-for-user [userInfo]="user">
              </iris-header-search-result-for-user>
            </li>
          </ng-container>
        </ul>
        <ul class="items-container" *ngIf="searchStr.length >= MIN_SEARCH_LEN && noSearchResults">
          <li class="header-group"> {{'label.NothingFound' | translate}}</li>
        </ul>
      </mat-menu>
    </div>
  </form>
</div>
