import { NgModule } from '@angular/core';
import { IrisNgSelectFieldModule } from '@iris/common/modules/fields/ng-select-field';
import { IrisNgSelectFieldShownElementsCountComponentModule } from '@iris/common/modules/fields/ng-select-field/common/components/shown-elements-count';
import { NgSelectModule } from '@natlex/ng-select';
import { IrisProjectSelectDropdownHeaderComponentModule } from './dropdown-header';
import { IrisProjectSelectDropdownFooterComponentModule } from './dropdown-footer';
import { IrisProjectSelectDropdownItemComponentModule } from './dropdown-item';
import { IrisProjectSelectDropdownGroupItemComponentModule } from './dropdown-group-item';
import { IrisProjectSelectDirective } from './project-select.directive';

@NgModule({
  imports: [
    NgSelectModule,
    IrisNgSelectFieldModule,
    IrisProjectSelectDropdownHeaderComponentModule,
    IrisProjectSelectDropdownFooterComponentModule,
    IrisProjectSelectDropdownItemComponentModule,
    IrisProjectSelectDropdownGroupItemComponentModule,
    IrisNgSelectFieldShownElementsCountComponentModule,
  ],
  declarations: [
    IrisProjectSelectDirective,
  ],
  exports: [
    IrisProjectSelectDirective,
    IrisNgSelectFieldModule,
    NgSelectModule,
  ],
})
export class IrisProjectSelectModule {
}
