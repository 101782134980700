import { ValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver';
import { TranslateService } from '@ngx-translate/core';
import { Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class MaxValidationMessageResolver implements ValidationMessageResolver<{ max: unknown }> {
  readonly errorCode = Validators.max.name;

  constructor(
    private readonly translateService: TranslateService,
  ) { }

  getMessage(error?: { max: unknown }): string {
    return this.translateService.instant('label.validation.max', { value: error?.max });
  }
}
