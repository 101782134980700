import { IrisMFFilterBase } from '@iris/common/modules/module-filter/models/IrisMFFilterBase';
import { cloneDeep } from 'lodash';
import { Observable, of } from 'rxjs';

export class IrisMFEq extends IrisMFFilterBase {

  isInFilter(filter): boolean {
    if (Array.isArray(filter[this.formFieldName])) {
      return !!filter[this.formFieldName].length;
    } else {
      return !!filter[this.formFieldName];
    }
  }

  getFilterValue(val) {
    if (Array.isArray(val)) {
      return cloneDeep(val);
    } else {
      return [val];
    }
  }

  getFilterValueMongo(val) {
    if (Array.isArray(val)) {
      return { '$in': cloneDeep(val) };
    } else {
      return val;
    }
  }

  getFilterDescription(filter): Observable<string> {
    if (Array.isArray(filter[this.formFieldName]) && filter[this.formFieldName].length > 1) {
      return of(`${this.label}: ${filter[this.formFieldName].length}`);
    } else {
      return of((this.options.fullDescription ? `${this.label}: ` : '') + (Array.isArray(filter[this.formFieldName]) ? filter[this.formFieldName][0] : filter[this.formFieldName]));
    }
  }

  getFilterTooltip(filter): Observable<string> | null {
    if (Array.isArray(filter[this.formFieldName]) && filter[this.formFieldName].length > 1) {
      return of(filter[this.formFieldName].reduce((res, val) => `${res}${res ? '<br>' : ''}${val}`, ''));
    } else {
      return this.options.fullDescription ? null : of(this.label);
    }
  }
}
