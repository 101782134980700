import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IrisHeaderSearchResultCardTemplateComponent } from './card-template.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [IrisHeaderSearchResultCardTemplateComponent],
  exports: [IrisHeaderSearchResultCardTemplateComponent],
})
export class IrisHeaderSearchResultCardTemplateComponentModule { }
