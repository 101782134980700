import { Pipe, PipeTransform } from '@angular/core';
import { get, isArray } from 'lodash';

@Pipe({
  name: 'irisFilterField',
  pure: false,
})
export class IrisFilterFieldPipe implements PipeTransform {
  transform(id: any, opts: any[], nullable = false): any {
    if (!isArray(opts) || opts.length < 1) {
      return nullable ? null : id;
    }

    let directory = opts[0];
    const field = opts[1] || 'name',
      idField = opts[2] || 'id';
    // For Immutable structures
    if (directory && directory.toJS) { directory = directory.toJS(); }
    if (!isArray(directory) || !(directory.length > 0)) {
      return nullable ? null : id;
    }

    for (const dir of directory) {
      if (get(dir, idField) == id) {
        return get(dir, field);
      }
    }
    return nullable ? null : id;
  }
}
