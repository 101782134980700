import { IrisCompany, IrisCompanyI } from '../../models/IrisCompany';
import { InitActions, InitActionTypes } from '../../modules/init-store/actions/init.actions';
import { IrisLanguage, IrisLanguageI } from '../../models/IrisLanguage';
import { IrisUserInfo, IrisUserInfoI } from '../../modules/user-common/models/IrisUserInfo';
import { UnitsActions, UnitsActionsTypes } from '../actions/units-actions';
import { IrisIdentUser } from '../../models/IrisIdentUser';
import { UsersActions, UsersActionTypes } from '../actions/users.actions';
import { isArray, orderBy } from 'lodash';
import { IrisCountry, IrisCountryI } from '../../models/IrisCountry';
import { UnitsI } from '@iris/common/models/Units';
import { IrisUserGroupI } from '@iris/common/modules/user-common/models/IrisUserGroup';
import { IrisGlobalSettingsI } from '@iris/common/models/IrisGlobalSettings';
import { IrisModuleAliasType } from '@iris/common/services/settings/global-settings.service';
import { UserGroupsAction, UserGroupsActionTypes } from '@iris/common/redux/actions/user-groups.action';
import * as SettingsActions from '@iris/common/redux/actions/settings-actions';
import { createReducer, on, union } from '@ngrx/store';
import { IrisProjectI } from '@iris/common/models';

export interface State {
  companies: IrisCompanyI[];
  languages: IrisLanguageI[];
  users: IdentUserI[];
  usersById: Record<number, IdentUserI>;
  usersInfoById: Record<number, IrisUserInfoI>;
  countries: IrisCountryI[];
  units: UnitsI;
  userGroups: IrisUserGroupI[];
  settings: Record<IrisModuleAliasType, IrisGlobalSettingsI<Partial<Record<string, unknown>>>>;
  projects: IrisProjectI[];
}

export const initialState: State = {
  users: null,
  languages: null,
  companies: null,
  usersById: {},
  usersInfoById: {},
  countries: null,
  units: null,
  userGroups: [],
  settings: {} as Record<IrisModuleAliasType, IrisGlobalSettingsI<Partial<Record<string, unknown>>>>,
  projects: null,
};

const functionalReducer = createReducer(
  initialState,
  on(SettingsActions.GetGlobalSettingsSuccess, (state, { settings }) => ({
    ...state,
    settings: { ...state.settings, [settings.alias]: settings },
  })),
  on(SettingsActions.UpdateGlobalSettingsSuccess, (state, { settings }) => ({
    ...state,
    settings: { ...state.settings, [settings.alias]: settings },
  })),
);

const SettingsActionsUnion = union({ ...SettingsActions });
type Actions =
  | InitActions
  | UsersActions
  | UnitsActions
  | UserGroupsAction
  | typeof SettingsActionsUnion;

export function reducer(
  state = initialState,
  action: Actions,
): State {
  switch (action.type) {
    case InitActionTypes.InitCompanies: {
      return {
        ...state,
        companies: action.payload.map(company => new IrisCompany(company)),
      };
    }
    case InitActionTypes.InitLanguages : {
      return {
        ...state,
        languages: action.payload.map(languages => new IrisLanguage(languages)),
      };
    }
    case InitActionTypes.InitUsers: {
      return {
        ...state,
        users: action.payload.map(users => new IrisIdentUser(users)),
      };
    }
    case UserGroupsActionTypes.UpdateUserGroup : {
      const index = state.userGroups.findIndex(usergroup => usergroup.id === action.userGroup.id);
      const userGroups = [...state.userGroups];
      userGroups[index] = { ...action.userGroup };
      
      return {
        ...state,
        userGroups,
      };
    }
    case UsersActionTypes.LoadUserByEmailComplete: {
      const usersById = { ...state.usersById };
      if (isArray(action.payload)) {
        for (const user of action.payload) {
          usersById[user.id] = new IrisIdentUser(user);
        }
      }
      return {
        ...state,
        usersById,
      };
    }
    case UsersActionTypes.LoadUserInfoComplete: {
      const usersInfoById = { ...state.usersInfoById };
      if (isArray(action.payload)) {
        for (const user of action.payload) {
          usersInfoById[user.id] = new IrisUserInfo(user);
        }
      }
      return {
        ...state,
        usersInfoById,
      };
    }
    case InitActionTypes.InitCountries: {
      return {
        ...state,
        countries: action.payload.map(countries => new IrisCountry(countries)),
      };
    }
    case UnitsActionsTypes.LoadUnitsComplete: {
      return {
        ...state,
        units: action.payload,
      };
    }
    case InitActionTypes.LoadUserGroupsSuccess: {
      return {
        ...state,
        userGroups: action.userGroups,
      };
    }
    default:
      return functionalReducer(state, action);
  }
}

export const getCompanies = (state: State): typeof state.companies => state.companies;
export const getLanguages = (state: State): typeof state.languages => state.languages;
export const getUsers = (state: State): typeof state.users => state.users;
export const getUsersById = (state: State): typeof state.usersById => state.usersById;
export const getUsersInfoById = (state: State): typeof state.usersInfoById => state.usersInfoById;
export const getGlobalCountries = (state: State): typeof state.countries => state.countries;
export const getProjects = (state: State): typeof state.projects => state.projects;
export const getUnits = (state: State): typeof state.units => state.units;
export const getUserGroups = (state: State, companyId?: number): typeof state.userGroups => {
  const userGroups = !companyId ?
    state.userGroups :
    state.userGroups.filter(group => group.companyId === companyId);

  return orderBy(userGroups, ['userGroup', group => group.name.toLowerCase()], ['asc']);
};
export const getGlobalSettings = (state: State): typeof state.settings => state.settings;
