import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IrisCommonDirectivesModule } from '../../directives-common/common-directives.module';
import { IrisFieldTranslateComponent } from './field-translate.component';
import { TranslationsModalComponent } from './translations-modal/translations-modal.component';
import { IrisLoaderModule } from '@iris/common/modules/loader';
import { IrisSimpleLoaderModule } from '@iris/common/modules/iris-simple-loader/simple-loader.module';
import { IrisModalContentModule } from '@iris/common/modules/iris-modal-content/iris-modal-content.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatTooltipModule,
    IrisModalContentModule,
    IrisLoaderModule,
    IrisSimpleLoaderModule,
    IrisCommonDirectivesModule,
  ],
  declarations: [
    IrisFieldTranslateComponent,
    TranslationsModalComponent,
  ],
  exports: [
    IrisFieldTranslateComponent,
  ],
})
export class IrisFieldTranslateModule { }
