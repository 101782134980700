import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { IrisSecurityService } from '@iris/common/modules/security/services/security.service';
import { showForbiddenPage } from '@iris/common/guards/utils';

export const canConfigModuleGuard = (moduleResolver: ((route: ActivatedRouteSnapshot) => string) = route => route.routeConfig.data?.moduleCode): CanActivateFn => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const location = inject(Location);
    const module = moduleResolver(route);
    const hasConfigRights = inject(IrisSecurityService).hasConfigRights(module);

    if (!hasConfigRights) {
      showForbiddenPage(router, location, state.url);
    }

    return hasConfigRights;
  };
};
