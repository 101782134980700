<div class="iris-alerts-container">
  <div
    *ngFor="let alert of alerts"
    id="iris-alert-{{ alert.id }}"
    class="animate__animated animate__bounceInRight alert alert-{{ alert.type }}"
    (click)="onClose(alert)">
    <div class="iris-alert-body">
      <i class="far {{ getAlertIconByType(alert.type) }} iris-alert-icon-container"></i>
      <div class="iris-alert-content">
        <ng-container *ngIf="!alert.rendererFramework; else rendererFramework">
          <div [innerHtml]="alert.message"></div>
        </ng-container>
        <ng-template
          #rendererFramework
          [alertRenderer]="{ rendererFramework: alert.rendererFramework, message: alert.message }"></ng-template>
      </div>
    </div>
  </div>
</div>
