import { Injectable } from '@angular/core';
import {
  IrisPermissionI,
  IrisPermissionMapI,
  IrisPermissionSubjectTemplateI,
} from '@iris/common/models/IrisPermission';
import { IrisSubjectPermissions } from '@iris/common/services/subject-permissions.service';
import { IrisModules, IrisModulesActions, IrisModulesSubjects } from '@iris/common/models/IrisModulesEnums';
import { IrisUserService } from '@iris/common/services/user.service';
import { isArray, isNull, isUndefined } from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';

type SubjectPermissionKey = number | string;

type SubjectPermissionSet = {
  [key in SubjectPermissionKey]: string[];
};

type Rights = Record<string, unknown>;

@Injectable({ providedIn: 'root' })
export class IrisSecurityService {

  private rights: Rights;

  constructor(
    private readonly userService: IrisUserService,
    private readonly subjectPermissions: IrisSubjectPermissions,
    private readonly httpClient: HttpClient,
    private readonly envService: IrisEnvironmentService,
  ) { }

  public get isAdmin(): boolean {
    return this.userService.me.isAdmin;
  }

  public get hasOnlyAccessRightsForDms(): boolean {
    if (this.isAdmin) { return false; }
    return this.hasOnlyOneActionRights(IrisModules.DMS, IrisModulesSubjects.Module, IrisModulesActions.access);
  }

  public hasPermissions(subjectId: string | number, subjectName: string, action: string): boolean {
    if (this.isAdmin) { return true; }
    if (!subjectName || !action || isUndefined(subjectId)) { return false; }

    if (!this.rights[subjectName]) { return false; }

    if (this.rights[subjectName]['*'] && isArray(this.rights[subjectName]['*'])
      && this.rights[subjectName]['*'].indexOf(action) > -1) { return true; }

    if (subjectId && this.rights[subjectName][subjectId] && isArray(this.rights[subjectName][subjectId])
      && this.rights[subjectName][subjectId].indexOf(action) > -1) { return true; }

    return false;
  }

  someSubjectPermissionsLoaded(subjectName: string): boolean {
    return !!this.rights?.[subjectName];
  }

  refreshSubjectPermissionsCache(scope: string): Observable<void> {
    return this.getAllSubjectPermissionsByUserAndSubjectType(scope).pipe(
      map(remotePermissions => this._convertRemotePermissionsToLocal(remotePermissions)),
      tap(permissions => this._updateLocalPermissions(scope, permissions)),
      map(() => {}),
    );
  }

  getSubjectPermissions(id: number, scope: string): Observable<IrisPermissionSubjectTemplateI[]> {
    return this.subjectPermissions.getPermissions(id, scope);
  }

  getAllSubjectPermissions(scope: string) {
    return this.subjectPermissions.getAllPermissions(scope);
  }

  getAllSubjectPermissionsByUserAndSubjectType(scope: string, trackBy = 'subjectEntityId'): Observable<IrisPermissionMapI> {
    const { id } = this.userService.me;
    return this.subjectPermissions.getAllPermissionsByUserAndSubjectType(id, scope)
      .pipe(
        map((permissions: IrisPermissionI[]) => {
          const _permissions: IrisPermissionMapI = {};
          permissions.forEach((el) => {
            let keyField;
            if (isNull(el[trackBy])) {
              keyField = 'GLOBAL';
            } else {
              keyField = el[trackBy];
            }
            const subjectPermissions = permissions.filter(item => item[trackBy] == el[trackBy]);
            _permissions[keyField] = subjectPermissions.map(({ allowed, action }) => ({ allowed, action }));
          });
          return _permissions;
        }),
      );
  }

  /**
   * @Info this method works with getAllSubjectPermissionsByUserAndSubjectType method
   * */
  isAllowPermission(entity: {id: number}, action: string, permissions: IrisPermissionMapI): boolean {
    if (this.isAdmin) { return true; }
    if (!Object.keys(permissions).length) { return false; }
    let isAllow = false;

    if (Array.isArray(permissions['GLOBAL'])) {
      isAllow = permissions['GLOBAL'].some(item => item.action === action);
    }

    if (Array.isArray(permissions[entity.id]) && !isAllow) {
      isAllow = permissions[entity.id].some(item => item.action === action);
    }

    return isAllow;
  }

  public hasAccessRights(moduleName: string): boolean {
    return this.hasPermissions(moduleName, 'Module', 'access');
  }

  public hasConfigRights(moduleName: string): boolean {
    return this.hasPermissions(moduleName, 'Module', 'config');
  }

  public hasOnlyOneActionRights(subjectId: string | number, subjectName: string, action: string): boolean {
    const rights = this.rights[subjectName]['*'] || this.rights[subjectName][subjectId];
    return this.hasPermissions(subjectId, subjectName, action) && isArray(rights) && rights.length === 1;
  }

  getRights(): Observable<Rights> {
    return this.httpClient.get<Rights>(`${this.envService.env.apiUrl}/security/subjects/permissions?subject-types=["Module","Project","Report","Company"]`)
      .pipe(
        tap(rights => this.rights = rights),
        catchError(() => EMPTY),
      );
  }

  private _convertRemotePermissionsToLocal(remotePermissions: IrisPermissionMapI): SubjectPermissionSet {
    const newPermissions = <SubjectPermissionSet> {};

    Object.keys(remotePermissions).forEach(key => {
      const localKey: SubjectPermissionKey = key == 'GLOBAL' ? '*' : key;

      newPermissions[localKey] = remotePermissions[key]
        .filter(i => i.allowed)
        .map(i => i.action);
    });

    return newPermissions;
  }

  private _updateLocalPermissions(subjectName: string, permissions: SubjectPermissionSet): void {
    this.rights[subjectName] = permissions;
  }
}
