import { Injectable } from '@angular/core';
import { IMessage, StompHeaders } from '@stomp/stompjs';
import { Observable, switchMap } from 'rxjs';
import { NotificationsStompService } from './notifications.stomp-service';
import { BackendStompService } from '@iris/common/services/websockets/backend.stomp-service';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';

@Injectable({ providedIn: 'root' })
export class WebsocketService {
  constructor(
    private readonly backendStompService: BackendStompService,
    private readonly notificationsStompService: NotificationsStompService,
    private readonly authSandbox: AuthFacade,
    private readonly envService: IrisEnvironmentService,
  ) { }

  connect(url: string): Observable<IMessage> {
    return this.backendStompService.watch(url);
  }

  watchNotificationsWs(url: string): Observable<IMessage> {
    return this.authSandbox.actualToken().pipe(
      switchMap(token => {
        const subHeaders = (): StompHeaders => ({ 'Authorization': `Bearer ${token}` });
        const unsubHeaders = (): StompHeaders => ({ 'instanceId': `${this.envService.env.instanceId}` });
        return this.notificationsStompService.watch({ destination: url, subHeaders, unsubHeaders });
      }),
    );
  }

  send(url: string, payload: Record<string, unknown>): void {
    return this.backendStompService.publish({
      destination: url,
      body: JSON.stringify(payload),
    });
  }
}
