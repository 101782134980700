import { Component, Inject, Input, Optional } from '@angular/core';
import { BaseControlAccessorComponent } from '@iris/common/modules/fields/base-control/base-control-accessor.component';
import { NG_VALIDATORS, NgControl, Validator } from '@angular/forms';

@Component({
  selector: 'iris-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent extends BaseControlAccessorComponent {

  @Input() label = '';
  @Input() labelPosition: 'before' | 'after' = 'after';

  constructor(
    ngControl: NgControl,
    @Optional() @Inject(NG_VALIDATORS) validators: (Function | Validator)[],
  ) {
    super(ngControl, validators);
  }

}
