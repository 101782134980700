export interface IrisEmailUserSettingsI {
  navigationOrders?: IrisEmailNavigationOrderSettingsI[];
}

export interface IrisEmailNavigationOrderSettingsI {
  folderId: string;
  sortIndex: number;
}

export enum IrisEmailSettingsAlias {
  global = 'emails-global',
}

export interface IrisEmailUserSettingsResponseI {
  createdBy?: number;
  createdOn?: Date;
  id?: number;
  module?: string;
  settings: IrisEmailUserSettingsI;
  updatedBy?: number;
  updatedOn?: Date;
  userId?: number;
}
