import { AbstractDatetime, DatetimeUnit } from '@iris/common/modules/date';
import { MatDatetimepickerType } from '@iris/common/modules/fields/field-datetime/mat-datetimepicker/datetimepicker';

export class DatetimePlaceholder {
  val?: string | null;
  constructor(public type?: DatetimePlaceholderRelType,
              public part?: DatetimePlaceholderRelPart,
              public shift?: number,
              public valueType?: DatetimePlaceholderValueType) {
    this.valueType = valueType ?? DatetimePlaceholderValueType.Relative;
  }
}

export enum DatetimePlaceholderRelPart {
  Hour = 'HOUR',
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
}

export enum DatetimePlaceholderRelType {
  Now = 'NOW',
  Add = 'ADD',
  StartOf = 'START',
  EndOf = 'END',
}

export enum DatetimePlaceholderValueType {
  Absolute = 'ABS',
  Relative = 'REL',
}

export type NowInTimeZoneFactory = (timezone: string) => AbstractDatetime;

export function mapPlaceholderToAbstractDatetime(
  placeholder: DatetimePlaceholder | string,
  timezone: string,
  nowInTimezone: NowInTimeZoneFactory = AbstractDatetime.nowInTimezone,
): AbstractDatetime {
  if (placeholder == null) {
    return null;
  }

  if (typeof placeholder === 'string') {
    return AbstractDatetime.parse(placeholder).setTimezone(timezone);
  }

  switch (placeholder.valueType) {
    case DatetimePlaceholderValueType.Absolute:
      return placeholder.val ? AbstractDatetime.parse(placeholder.val).setTimezone(timezone) : null;

    case DatetimePlaceholderValueType.Relative:
      switch (placeholder.type) {
        case DatetimePlaceholderRelType.Now:
          return nowInTimezone(timezone);

        case DatetimePlaceholderRelType.Add:
          return shiftDate(nowInTimezone(timezone), placeholder.shift, mapRelPartToUnit(placeholder.part));

        case DatetimePlaceholderRelType.StartOf:
          return shiftDate(nowInTimezone(timezone), placeholder.shift, mapRelPartToUnit(placeholder.part)).startOf(mapRelPartToUnit(placeholder.part));

        case DatetimePlaceholderRelType.EndOf:
          return shiftDate(nowInTimezone(timezone), placeholder.shift, mapRelPartToUnit(placeholder.part)).endOf(mapRelPartToUnit(placeholder.part));

        default:
          throw new Error('Unsupported type.');
      }

    default:
      throw new Error('Unsupported type.');
  }
}

export function absDateValueIsEmpty(val: DatetimePlaceholder): boolean {
  return val?.valueType === DatetimePlaceholderValueType.Absolute && !val.val;
}

export function relativeDateValueIsEmpty(val: DatetimePlaceholder): boolean {
  if (val?.valueType === DatetimePlaceholderValueType.Relative) {
    switch (val.type) {
      case DatetimePlaceholderRelType.StartOf:
      case DatetimePlaceholderRelType.EndOf:
        return (!val.type || !val.part);

      case DatetimePlaceholderRelType.Add:
        return (!val.type || !val.part || !val.shift);

      case DatetimePlaceholderRelType.Now:
        return !val.type;

      default:
        return !val.type;
    }
  }
  return true;
}

export function datetimePlaceholderIsEmpty(placeholder: DatetimePlaceholder): boolean {
  return placeholder?.valueType == null || (absDateValueIsEmpty(placeholder) && relativeDateValueIsEmpty(placeholder));
}

function shiftDate(value: AbstractDatetime, shift: number | null, unit: DatetimeUnit): AbstractDatetime {
  if (shift === 0 || shift == null) {
    return value;
  }
  else if (shift > 0) {
    return value.plus({ [unit]: shift });
  }
  else {
    return value.minus({ [unit]: -shift });
  }
}

function mapRelPartToUnit(part: DatetimePlaceholderRelPart): DatetimeUnit {
  switch (part) {
    case DatetimePlaceholderRelPart.Day:
      return 'day';

    case DatetimePlaceholderRelPart.Hour:
      return 'hour';

    case DatetimePlaceholderRelPart.Month:
      return 'month';

    case DatetimePlaceholderRelPart.Week:
      return 'week';

    case DatetimePlaceholderRelPart.Year:
      return 'year';

    default:
      throw new Error('Unsupported type.');
  }
}

export function selectedLabels(placeholder: DatetimePlaceholder, type: MatDatetimepickerType): string[] {
  const labels: string[] = [];

  if(placeholder?.valueType !== DatetimePlaceholderValueType.Relative) {
    return labels;
  }

  switch (placeholder.type) {
    case DatetimePlaceholderRelType.Now:
      labels.push(['date', 'month', 'year'].includes(type) ? 'label.Today' : 'label.Now');
      return labels;

    case DatetimePlaceholderRelType.Add:
      labels.push('label.Add');
      break;

    case DatetimePlaceholderRelType.StartOf:
      labels.push('label.df.StartOf');
      break;

    case DatetimePlaceholderRelType.EndOf:
      labels.push('label.df.EndOf');
      break;
  }

  switch (placeholder.part) {
    case DatetimePlaceholderRelPart.Day:
      labels.push('label.Day');
      break;

    case DatetimePlaceholderRelPart.Hour:
      labels.push('label.Hour');
      break;

    case DatetimePlaceholderRelPart.Month:
      labels.push('label.Month');
      break;

    case DatetimePlaceholderRelPart.Week:
      labels.push('label.Week');
      break;

    case DatetimePlaceholderRelPart.Year:
      labels.push('label.Year');
      break;
  }

  if(placeholder.shift) {
    labels.push(`${placeholder.shift > 0 ? '+' : ''}${placeholder.shift}`);
  }

  return labels;
}
