import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';

export function copyValues<T>(target, value) {
  Object.keys(target).forEach((key) => target[key] = value[key]);
}

export function toMap(obj) {
  if (isNil(obj)) { return new Map(); }
  if (obj instanceof Map) { return obj; }
  return Object.keys(obj).reduce((map, key) => map.set(key, obj[key]), new Map());
}

export function desctructureObject(object: any, fields: string[]) {
  if (isNil(fields) || !Array.isArray(fields)) { return {}; }
  return fields.reduce((acc, val) => Object.assign(acc, { [val]: object[val] }), {});
}

export function reduceToMap(result, item) {
  return result.set(item.id, item);
}

export function simpleDeepCopy(value: any) {
  return JSON.parse(JSON.stringify(value));
}

export function deleteEmptyFields(obj) {
  const copyObj = { ...obj };
  for (const key in copyObj) {
    if (copyObj.hasOwnProperty(key)) {
      if (isNil(copyObj[key]) || copyObj[key] === '') {
        delete copyObj[key];
      }
    }
  }
  return copyObj;
}

export const fromArrayOfObjects = <T extends Record<string, any>>(
  array: T[],
  key: string | ((item: T) => string),
  value: string | ((item: T) => any),
) => {
  return array.reduce((obj, item) => {
    const formedKey = isFunction(key) ? key(item) : item[key];
    return {
      ...obj,
      [formedKey]: isFunction(value) ? value(item) : item[value],
    };
  }, {});
};
