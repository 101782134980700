import { InjectionToken } from '@angular/core';
import { IrisPage } from '@iris/common/models/page';
import { Observable, Subject } from 'rxjs';

export interface IrisNgSelectFieldSearchEngine<TItem, TValue> {
  result$: Observable<IrisPage<TItem>>;

  loading$?: Observable<boolean>;
  typeahead?: Subject<string>;
  getMissingItemLabelFn?: (value: TValue) => string | Observable<string>;

  resultOnInit?: boolean;
  needScrollToTop?: (prev: TItem[], curr: TItem[]) => boolean;
  dispose?: () => void;
}

export const SEARCH_ENGINE = new InjectionToken<IrisNgSelectFieldSearchEngine<unknown, unknown>>('Ng-select-field search engine');
