import { Action } from '@ngrx/store';

export enum UnitsActionsTypes {
  LoadUnits = '[Units] LoadUnits',
  LoadUnitsComplete = '[Units] LoadUnitsComplete',
}

export class LoadUnits implements Action {
  readonly type = UnitsActionsTypes.LoadUnits;
}

export class LoadUnitsComplete implements Action {
  readonly type = UnitsActionsTypes.LoadUnitsComplete;

  constructor(public payload: any) { }
}

export type UnitsActions = LoadUnits | LoadUnitsComplete;
