import { ChangeDetectionStrategy, Component, Input, OnDestroy, computed, signal } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromGlobal from '../../../../../redux/reducers/index';
import { LoadUserInfo } from '@iris/common/redux/actions/users.actions';
import { Subscription } from 'rxjs';
import { IRIS_CURRENT_USER_ID, IrisUserInfo, IrisUserInfoI } from '@iris/common/modules/user-common/models/IrisUserInfo';
import { IrisCompanyI } from '@iris/common/models/IrisCompany';
import { TranslateService } from '@ngx-translate/core';
import { NgxTippyModule, NgxTippyProps } from 'ngx-tippy-wrapper';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { map, tap } from 'rxjs/operators';
import { sticky } from 'tippy.js';
import { uniqueId } from 'lodash';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisCommonModule } from '@iris/common/modules/common/common.module';
import { IrisUserAvatarModule } from '@iris/common/modules/user-common/modules/user-avatar/user-avatar.module';

const SYSTEM_USER_ID = -1;
const SYSTEM_USER_LABEL = 'label.irisUserName';

@Component({
  selector: 'iris-user-ws',
  templateUrl: './user-ws.component.html',
  styleUrls: ['./user-ws.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IrisCommonModule, IrisUserAvatarModule, NgxTippyModule],
})
export class IrisUserWSComponent implements OnDestroy {

  @Input() showOnlyAvatar: boolean;

  @Input() avatarSize: 'sm' | 'md' | 'lg' | 'xl';
  @Input() showAvatar = true;
  @Input() showCompany = false;
  @Input() showDepartment = false;
  @Input() showEmail = false;

  @Input() noUserLabel = 'label.NoUser';
  @Input() departments: IrisDepartmentI[] = [];
  @Input() noHint = false;
  @Input() popoverSticky = true;
  @Input() cssClass: string;

  userInfo = signal<IrisUserInfoI>(null);
  userName = computed(() => {
    if (this.userInfo()?.id === IRIS_CURRENT_USER_ID) { return this.translateService.instant('label.CurrentUser'); }
    if (this.userInfo()?.id === SYSTEM_USER_ID) {
      return this.translateService.instant(SYSTEM_USER_LABEL);
    }

    if (!this.isUserExists(this.userInfo())) {
      return this.translateService.instant(this.noUserLabel);
    }

    let username = this.userInfo()?.fullName || this.userInfo()?.username;

    if (!this.userInfo()?.enabled) {
      username = `${username} (${this.translateService.instant('label.NotActive')})`;
    }

    return username;
  });
  userEmail = computed(() => this.userInfo()?.email);
  userMobileNumber = computed(() => this.userInfo()?.mobileNumber);
  userPhone = computed(() => this.userInfo()?.phone);
  departmentId = computed(() => this.userInfo()?.departmentId);
  companyName$ = computed(() => this.globalSandbox.getCompanyById(this.userInfo()?.companyId).pipe(
    map(company => company?.name),
  ));

  companies: IrisCompanyI[];
  userSubscription: Subscription;

  @Input()
  set userId(value: number) {
    if (value === IRIS_CURRENT_USER_ID) {
      this.userInfo.set(this.createCurrentUserPlaceholder(value));
      return;
    }
    if (value > 0) {
      this.store.dispatch(new LoadUserInfo(value));
      if (this.userSubscription) {
        this.userSubscription.unsubscribe();
      }
      this.userSubscription = this.store.pipe(
        select(fromGlobal.getGlobalUserInfoById(value)),
        tap((userInfo) => {
          if (this.isUserExists(userInfo)) {
            this.userInfo.set(userInfo);
          }
        }),
      ).subscribe();
    }
  }

  get tippyName(): string {
    return uniqueId();
  }

  readonly popoverProps: NgxTippyProps = {
    arrow: false,
    theme: 'light-border',
    appendTo: document.body,
    interactive: true,
    delay: [1200, null],
    sticky: this.popoverSticky,
    plugins: [sticky],
  };

  get showPopover(): boolean {
    return !this.noHint && this.isUserExists(this.userInfo());
  }

  constructor(
    private readonly store: Store<fromGlobal.State>,
    private readonly globalSandbox: IrisGlobalSandbox,
    private readonly irisUserService: IrisUserService,
    private readonly translateService: TranslateService,
  ) { }

  private isUserExists(userInf: IrisUserInfoI): boolean {
    let userExists = false;

    if (!!userInf) {
      // if the user was deleted from IRIS but was not unlinked from the entity
      const keys = Object.keys(userInf);
      userExists = !!keys.filter(key => key !== 'id').find(key => userInf[key] !== undefined);
    }

    return userExists;
  }

  private createCurrentUserPlaceholder(id: number): IrisUserInfoI {
    return new IrisUserInfo({
      id,
      firstname: this.irisUserService.me.firstname,
      lastname: this.irisUserService.me.lastname,
      email: null,
      departmentId: null,
      username: null,
      fullName: this.irisUserService.me.fullName,
      companyId: null,
      avatarFileId: null,
      enabled: null,
      mobileNumber: null,
      phone: null,
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
