import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { difference, uniq, chain } from 'lodash';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, shareReplay, Subject, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { IrisEmailMessageI } from '../../models/IrisEmail';
import { EmailWellKnownFolderName } from '../../models/IrisEmailNavigation';
import { IrisEmailsAdvancedSearchFilterI, IrisEmailsPaginationI } from '../../models/IrisEmailPagination';
import * as emailsReducer from '../index';
import {
  DeselectEmailsMessages,
  GetEmailsUserSettingsStart,
  GetFolderByShortcutStart,
  GetUnreadCountStart,
  ResetMessages,
  ResetMessagesPagination,
  ResetMessagesSelection,
  SelectEmailsMessages,
  SetMessagesCheckAll,
  SetMessagesPagination,
  SetOpenedDraftMessage,
  SetSelectedEmail,
  SetSelectedMenuItem,
  SetTotalItemsCount,
} from './emails-global.actions';
import { IrisModuleFilterSandbox } from '@iris/common/modules/module-filter/store/module-filter.sandbox';
import { IrisEmailsService } from '../../services/emails.service';
import { IrisAuthService } from '@iris/modules/auth/utils/auth.service';
import { IrisUserService } from '@iris/common/services/user.service';

@Injectable({ providedIn: 'root' })
export class IrisEmailsGlobalSandbox {
  private currentSelectionMessagesSpan: string[] = [];
  private lastSelectedMessage: string = null;

  totalMessagesCount$ = this.store.pipe(select(emailsReducer.getMessageTotalItemsCount));
  selectedMenuItemId$ = this.store.pipe(select(emailsReducer.getSelectedMenuItemId));

  unreadCount$ = this.store.pipe(select(emailsReducer.unreadCount));

  selectedMessageId$ = this.store.pipe(
    select(emailsReducer.getSelectedMessageId),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  selectedMessagesIds$ = this.store.pipe(
    select(emailsReducer.getSelectedMessagesIds),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  deselectedMessagesIds$ = this.store.pipe(
    select(emailsReducer.getDeselectedMessagesIds),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  checkAll$ = this.store.pipe(
    select(emailsReducer.getCheckAll),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  selectedMessagesCount$ = combineLatest([
    this.selectedMessagesIds$,
    this.deselectedMessagesIds$,
    this.checkAll$,
    this.totalMessagesCount$,
  ]).pipe(
    map(([selectedMessagesIds, deselectedMessagesIds, checkAll, totalItemsCount]) => {
      if (!checkAll) {
        return selectedMessagesIds?.length;
      }

      if (!!deselectedMessagesIds.length) {
        return totalItemsCount - deselectedMessagesIds.length;
      }

      return totalItemsCount;
    }),
  );

  anyMessageSelected$ = combineLatest([
    this.selectedMessagesIds$,
    this.checkAll$,
  ]).pipe(
    map(([selectedMessagesIds, checkAll]) => !!selectedMessagesIds.length || checkAll),
  );

  openedDraftMessage$ = this.store.pipe(select(emailsReducer.getOpenedDraftMessage));

  messagesPagination$ = this.store.pipe(
    select(emailsReducer.getMessagesPagination),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  hasMessagesNextPage$ = this.store.pipe(
    select(emailsReducer.hasMessagesNextPage),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  advancedFilter$ = this.messagesPagination$.pipe(
    map(pagination => pagination.filter),
  );

  isFilterActive$ = this.messagesPagination$.pipe(
    map(pagination => {
      const wrappedValue = chain(pagination.filter);
      return !!pagination.searchText || !!wrappedValue.values().compact().value().length;
    }),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  view$: BehaviorSubject<string> = new BehaviorSubject<string>('desktop-view');

  private readonly scrollMessagesToSubject = new Subject<ScrollToOptions>();

  readonly messagesScrolled$ = this.scrollMessagesToSubject.asObservable();

  private readonly viewMap = new Map([
    [Breakpoints.Small, 'mobile-view'],
    [Breakpoints.Medium, 'tablet-view'],
    [Breakpoints.Large, 'desktop-view'],
  ]);

  constructor(
    private readonly store: Store<emailsReducer.EmailsCommonState>,
    protected readonly breakpointObserver: BreakpointObserver,
    private readonly filterSandbox: IrisModuleFilterSandbox,
    private readonly emailsService: IrisEmailsService,
    private readonly authService: IrisAuthService,
    private readonly userService: IrisUserService,
  ) {
    breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large])
      .pipe(
        map((result) => {
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query]) {
              this.view$.next(this.viewMap.get(query) ?? 'desktop-view');
            }
          }
        }),
      )
      .subscribe();
  }

  get user(): IdentUserI {
    return this.userService.me;
  }

  get userId(): number {
    return this.user?.id;
  }

  isNavigationFolderSelected(title: string): Observable<boolean> {
    return this.store.pipe(
      select(emailsReducer.getNavigationFolders),
      filter(folders => !!folders.length),
      take(1),
      switchMap(() => this.store.pipe(select(emailsReducer.isNavigationFolderSelected(title)), take(1))),
    );
  }

  setSelectedMenuItem(menuItemId: string): void {
    this.store.dispatch(SetSelectedMenuItem({ menuItemId }));
  }

  setSelectedMessage(emailId: string, resetSelectedEmails = true): void {
    this.store.dispatch(SetSelectedEmail({ emailId, resetSelectedEmails }));
  }

  setSelectedMessages(messagesIds: string[]): void {
    this.store.dispatch(SelectEmailsMessages({ messagesIds }));
  }

  toggleMultipleMessageSelection(messageId: string, event?: MouseEvent): Observable<void> {
    return combineLatest([
      this.selectedMessagesIds$,
      this.deselectedMessagesIds$,
      this.checkAll$,
    ]).pipe(
      take(1),
      withLatestFrom(this.store.pipe(select(emailsReducer.getMessagesFlatList))),
      tap(([[selectedMessagesIds, deselectedMessagesIds, checkAll], messages]) => {
        const anyKeyClicked = event?.shiftKey || event?.ctrlKey || event?.metaKey;
        const shiftSelect = event?.shiftKey &&
          (this.lastSelectedMessage || this.lastSelectedMessage === messages[0]?.id) &&
          messageId !== this.lastSelectedMessage;

        const messagesIds = messages.map(message => message.id);

        const messagesToSelect = [];
        let messagesToDeselect = [];

        if (!selectedMessagesIds.length && !this.lastSelectedMessage && !checkAll) {
          messagesToSelect.push(messageId);
          this.lastSelectedMessage = messageId;
        } else if (event?.metaKey || event?.ctrlKey) {
          if (selectedMessagesIds.includes(messageId) || checkAll && !deselectedMessagesIds.includes(messageId)) {
            messagesToDeselect.push(messageId);
            this.lastSelectedMessage = null;
          } else {
            messagesToSelect.push(...selectedMessagesIds, messageId);
            this.lastSelectedMessage = messageId;
          }
        } else if (shiftSelect) {
          const selectedMessageIndex = messagesIds.indexOf(messageId);
          const lastSelectedMessageIndex = messagesIds.indexOf(this.lastSelectedMessage);

          // if holding shift, add group to selection and currentSelectionSpan
          const newSelectionBefore = selectedMessageIndex < lastSelectedMessageIndex;
          const count = (
            newSelectionBefore ? lastSelectedMessageIndex - (selectedMessageIndex - 1) :
              (selectedMessageIndex + 1) - lastSelectedMessageIndex
          );

          // clear previous shift selection
          if (this.currentSelectionMessagesSpan && this.currentSelectionMessagesSpan.length > 0) {
            messagesToDeselect.push(...this.currentSelectionMessagesSpan);
            this.currentSelectionMessagesSpan = [];
          }

          for(let i = 0; i < count; i++) {
            const newIndex = newSelectionBefore ? lastSelectedMessageIndex - i : lastSelectedMessageIndex + i;
            const spanMessageId = messagesIds[newIndex];
            this.currentSelectionMessagesSpan.push(spanMessageId);
            messagesToSelect.push(spanMessageId);
          }

          messagesToDeselect = difference(messagesToDeselect, messagesToSelect);
        } else {
          // Select only this item or clear selections.
          const alreadySelected = selectedMessagesIds.includes(messageId);
          if ((!alreadySelected && !event?.shiftKey) || alreadySelected) {
            this.setSelectedMessage(messageId);
            this.lastSelectedMessage = messageId;
          } else if (alreadySelected) {
            this.resetMessagesSelection();
          }
        }

        if (!event?.shiftKey) {
          this.currentSelectionMessagesSpan = [];
        }

        if (messagesToDeselect.length) {
          this.store.dispatch(DeselectEmailsMessages({ messagesIds: uniq(messagesToDeselect) }));
        }
        if (messagesToSelect.length && anyKeyClicked) {
          this.store.dispatch(SelectEmailsMessages({ messagesIds: uniq(messagesToSelect) }));
        }
      }),
      map(() => void 0),
    );
  }

  setCheckAll(checkAll: boolean): void {
    this.store.dispatch(SetMessagesCheckAll({ checkAll }));
  }

  setMessagesPagination(pagination: IrisEmailsPaginationI): void {
    this.store.dispatch(SetMessagesPagination({ pagination }));
  }

  setTotalItemsCount(count: number): void {
    this.store.dispatch(SetTotalItemsCount({ count }));
  }

  resetTotalItemsCount(): void {
    this.setTotalItemsCount(0);
  }

  resetMessagesPagination(): void {
    this.store.dispatch(ResetMessagesPagination({}));
    this.filterSandbox.resetFilter();
  }

  resetMessagesSelection(): void {
    this.store.dispatch(ResetMessagesSelection());
  }

  resetMessages(): void {
    this.store.dispatch(ResetMessages());
  }

  setOpenedDraftMessage(message: IrisEmailMessageI): void {
    this.store.dispatch(SetOpenedDraftMessage({ message }));
  }

  applyAdvancedSearchFilter(filter: IrisEmailsAdvancedSearchFilterI): void {
    this.setMessagesPagination({ filter, offset: 0 });
  }

  resetSearchFilter(): void {
    this.setMessagesPagination({ searchText: null, filter: null, offset: 0 });
  }

  getUserSettings(): void {
    this.store.dispatch(GetEmailsUserSettingsStart());
  }

  getFolderByShortcut(folderShortcut: EmailWellKnownFolderName): void {
    this.store.dispatch(GetFolderByShortcutStart({ folderShortcut }));
  }

  getInboxFolderId(): void {
    this.getFolderByShortcut(EmailWellKnownFolderName.Inbox);
  }

  getDraftFolderId(): void {
    this.getFolderByShortcut(EmailWellKnownFolderName.Drafts);
  }

  getSentFolderId(): void {
    this.getFolderByShortcut(EmailWellKnownFolderName.SentItems);
  }

  getDeletedFolderId(): void {
    this.getFolderByShortcut(EmailWellKnownFolderName.DeletedItems);
  }

  checkUserAccessToSharedMailbox(): Observable<boolean> {
    return this.authService.checkSSO(this.user.email).pipe(
      switchMap((ssoInfo) => {
        if (!ssoInfo.singleSignOnActive) {
          return of(false);
        }
        return this.emailsService.fetchJwtToken().pipe(
          switchMap(() => this.emailsService.checkUserAccess()),
        );
      }),
    );
  }

  scrollMessagesTo(left: number, top: number): void {
    this.scrollMessagesToSubject.next({ left, top });
  }

  getUnreadCount(): void {
    this.store.dispatch(GetUnreadCountStart());
  }
}
