import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InstanceStatus, InstanceStatusDTO } from '@iris/common/models/InstanceStatus';
import { API_ENV } from '@iris/common/tokens';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IrisEnvironmentService {
  get env(): IrisEnv {
    return this._env;
  }

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(API_ENV) private readonly _env: IrisEnv,
  ) { }

  get<T>(field: string): T {
    return this._env[field];
  }

  getInstanceStatus(): Observable<InstanceStatus> {
    return this.httpClient.get<InstanceStatusDTO>(`${this._env.apiUrl}/applicationdetails/status`).pipe(
      map((res: InstanceStatusDTO) => {
        return {
          ...res,
          isProd: res.isProd !== 'false',
        };
      }),
      catchError(() => EMPTY),
    );
  }

}
