import { StaticProvider } from '@angular/core';
import { SEARCH_ENGINE, templateFromComponentProvider } from '@iris/common/modules/fields/ng-select-field';
import {
  BIND_LABEL,
  BIND_VALUE,
  DROPDOWN_FOOTER_TEMPLATE,
  DROPDOWN_HEADER_TEMPLATE,
  OPTION_TEMPLATE,
  OPTGROUP_TEMPLATE,
  GROUP_BY,
} from '@iris/common/modules/fields/ng-select-field/properties-tokens';
import { IrisProjectSelectDropdownHeaderComponent } from './dropdown-header';
import { IrisProjectSelectDropdownFooterComponent } from './dropdown-footer';
import { IrisProjectSelectDropdownItemComponent } from './dropdown-item';
import { IrisProjectSelectDropdownGroupItemComponent } from './dropdown-group-item';
import { IrisProjectSelectEngine } from './project-select.engine';

export const PROJECT_SELECT_PROVIDERS: StaticProvider[] = [
  {
    provide: IrisProjectSelectEngine,
    useClass: IrisProjectSelectEngine,
  },
  {
    provide: SEARCH_ENGINE,
    useExisting: IrisProjectSelectEngine,
  },
  {
    provide: DROPDOWN_FOOTER_TEMPLATE,
    ...templateFromComponentProvider(IrisProjectSelectDropdownFooterComponent, 'template'),
  },
  {
    provide: OPTION_TEMPLATE,
    ...templateFromComponentProvider(IrisProjectSelectDropdownItemComponent, 'template'),
  },
  {
    provide: OPTGROUP_TEMPLATE,
    ...templateFromComponentProvider(IrisProjectSelectDropdownGroupItemComponent, 'template'),
  },
  {
    provide: DROPDOWN_HEADER_TEMPLATE,
    ...templateFromComponentProvider(IrisProjectSelectDropdownHeaderComponent, 'template'),
  },
  {
    provide: BIND_LABEL,
    useValue: 'nameTranslated',
  },
  {
    provide: BIND_VALUE,
    useValue: 'id',
  },
  {
    provide: GROUP_BY,
    useValue: 'projects',
  },
];
