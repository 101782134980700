import { ValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { URL_VALIDATOR_NAME } from '../../validators/url.validator';

@Injectable()
export class UrlValidationMessageResolver implements ValidationMessageResolver {
  readonly errorCode = URL_VALIDATOR_NAME;

  constructor(
    private readonly translateService: TranslateService,
  ) { }

  getMessage(): string {
    return this.translateService.instant('label.validation.url');
  }
}
