import { Component, ChangeDetectionStrategy, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'iris-project-select-dropdown-group-item',
  templateUrl: './dropdown-group-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisProjectSelectDropdownGroupItemComponent {

  @ViewChild('template', { static: true }) template: TemplateRef<unknown>;

  constructor() {
  }
}
