import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiUrl, IrisApiClient } from '@iris/api-query';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export interface BrandConfigI {
  id: number;
  data: string;
  isActive?: boolean;
  brand: string;
}

@Injectable({
  providedIn: 'root',
})
export class IrisLogoService extends IrisApiClient<unknown> {

  brands$: BehaviorSubject<BrandConfigI[]> = new BehaviorSubject([]);
  activeBrand$: Observable<BrandConfigI> = this.brands$.pipe(
    map((brands) => brands.find(b => b.isActive)),
    filter((activeBrand) => !!activeBrand),
  );
  imageUrl$: Observable<string> = this.activeBrand$.pipe(
    map((activeBrand: BrandConfigI) => `data:image/png;base64,${activeBrand.data}`),
  );

  private readonly linkAttributes = [
    {
      'rel': 'icon',
      'type': 'image/ico',
      'file': 'favicon.ico',
      'sizes': '16x16',
      'title': 'web-icon',
    },
    {
      'rel': 'apple-touch-icon',
      'type': 'image/png',
      'file': 'apple-touch-icon.png',
      'sizes': '180x180',
      'title': 'web-icon',
    },
    {
      'rel': 'icon',
      'type': 'image/png',
      'file': 'favicon-16x16.png',
      'sizes': '16x16',
      'title': 'web-icon',
    },
    {
      'rel': 'icon',
      'type': 'image/png',
      'file': 'favicon-32x32.png',
      'sizes': '32x32',
      'title': 'web-icon',
    },
  ];

  constructor(
    readonly httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  @ApiUrl('/branding/v2')
  public loadBrands(): Observable<BrandConfigI[]>   {
    return this.httpClient.get<BrandConfigI[]>(this.url()).pipe(
      tap((brands) => this.brands$.next(brands)),
    );
  }

  @ApiUrl('/branding/{id}')
  public setActiveBrand(brand: BrandConfigI): Observable<BrandConfigI> {
    brand.isActive = true;
    return this.httpClient.post<BrandConfigI>(this.url({ id: brand.id }), brand);
  }

  public appendWebIcons(brandName: string): void {
    this.linkAttributes.forEach(item => {
      const linkTag = document.createElement('link');
      Object.entries(item).forEach(([k, v]) => {
        if (k === 'file') {
          const path = `assets/${brandName.toLowerCase()}/${v}`;
          linkTag.setAttribute('href', path);
        } else {
          linkTag.setAttribute(k, v);
        }
      });
      document.head.appendChild(linkTag);
    });
  }

}
