import { NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import { PROJECT_MEMBERS_CHANGED_EVENT_EMITTER_TOKEN } from './members-changed-event-emitter.token';
import { PROJECT_MEMBERS_CHANGED_EVENT_TOKEN } from './members-changed-event.token';

@NgModule({
  providers: [
    {
      provide: PROJECT_MEMBERS_CHANGED_EVENT_EMITTER_TOKEN,
      useValue: new Subject<void>(),
    },
    {
      provide: PROJECT_MEMBERS_CHANGED_EVENT_TOKEN,
      deps: [PROJECT_MEMBERS_CHANGED_EVENT_EMITTER_TOKEN],
      useFactory: (subject: Subject<any>) => subject.asObservable(),
    },
  ],
})
export class IrisProjectsMembersChangedNotificationModule {
}
