import { DOCUMENT, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Inject, LOCALE_ID, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LangChangeEvent, TranslateLoader, TranslateModule, TranslateParser, TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { metaReducers, reducers } from '@iris/common/redux/reducers';
import { IrisLoaderService } from './common/services/loader.service';
import { IrisAuthInterceptor } from './common/utils/handlers/iris-auth.interceptor';
import { IrisBasePathInterceptor } from './common/utils/handlers/iris-base-path.interceptor';
import { IrisErrorHandlerInterceptor } from './common/utils/handlers/iris-error-handler.interceptor';
import { IrisEncodeUrlParamsSafelyInterceptor } from './common/utils/handlers/iris-encode-url-params-safely.interceptor';
import { IrisTranslationLoader } from './common/utils/iris-translation.loader';
import { IrisInitEffects } from './common/modules/init-store/effects/init.effects';
import { IrisResourceRolesEffects } from '@iris/common/redux/effects/resource-roles.effects';
import { IrisAlertService } from './common/modules/alert/service/alert.service';
import { IrisUsersEffects } from './common/redux/effects/users.effects';
import { UnitsEffects } from './common/redux/effects/units.effects';
import { IrisLoaderEffects } from './common/redux/effects/loaders.effects';
import { IrisTimePipe, IrisUserTimePipe } from '@iris/common/modules/time';
import { HotkeyModule, HotkeysService } from 'angular2-hotkeys';
import 'hammerjs';
import { IrisSettingsEffects } from '@iris/common/redux/effects/settings.effects';
import { ProjectsEffects } from '@iris/common/redux/effects/projects.effects';
import { CustomGestureConfig } from './custom-gesture-config';
import { ApiQueryModule, IrisApiQueryInitializerService } from '@iris/api-query';
import { AppInitializer } from './app.init';
import { IrisGoogleMapsIntegrationModule } from '@iris/common/modules/google-maps-integration/for-root.module';
import { IrisProjectsMembersChangedNotificationModule } from '@iris/modules/projects/details/project-changes-notification/members';
import { IrisCompaniesService } from '@iris/common/services/companies.service';
import { AppComponentModule } from 'app/app.component.module';
import { AppRootComponent } from 'app/app-root.component';
import { AppRootComponentModule } from 'app/app-root.component.module';
import { AuthModule } from 'app/iris-modules/auth/auth.module';
import { CookieService } from 'ngx-cookie-service';
import { NgSelectModule } from '@natlex/ng-select';
import { TIMEZONE } from '@iris/common/tokens';
import { of } from 'rxjs';
import * as Sentry from '@sentry/angular-ivy';
import { TitleStrategy } from '@angular/router';
import { IrisSentryIntegrationModule } from './common/modules/sentry/sentry.module';
import { ChunkLoadErrorHandlerService } from '@iris/common/services/system/chunk-load-error-handler.service';
import { LocaleService } from '@iris/common/services/locale.service';
import { RumIntegrationModule } from '@iris/common/modules/rum/rum-integration.module';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialog } from '@angular/material/dialog';
import { ValidationMessageResolverModule } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver.module';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { PageTitleStrategyService } from '@iris/common/services/page-name/page-title-strategy.service';
import { BackendStompService, backendStompServiceFactory } from '@iris/common/services/websockets/backend.stomp-service';
import { NotificationsStompService, notificationsStompServiceFactory } from '@iris/common/services/websockets/notifications.stomp-service';
import { IrisMsalModule } from '@iris/modules/msal/msal.module';
import { datetimeFormatsFactory, IRIS_DATETIME_FORMATS } from '@iris/common/modules/fields/field-datetime/date-time-formats';
import { IrisHotkeysService } from './common/services/utils/iris-hotkeys.service';
import { MAT_LIST_CONFIG } from '@angular/material/list';
import { Settings } from 'luxon';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { IrisTranslationParser } from '@iris/common/utils/iris-translation.parser';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const developmentModeImports: (any[] | Type<any> | ModuleWithProviders<Record<string, any>>)[] = [
  StoreDevtoolsModule.instrument({
    name: 'IRIS Store DevTools',
    logOnly: environment.production,
  }),
];
@NgModule({
  imports: [
    IrisSentryIntegrationModule,
    RumIntegrationModule,
    HammerModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: IrisTranslationLoader,
        deps: [HttpClient],
      },
      parser: {
        provide: TranslateParser,
        useClass: IrisTranslationParser,
      },
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    AppRootComponentModule,
    AppComponentModule,
    AppRoutingModule,
    IrisGoogleMapsIntegrationModule.forRoot({
      deps: [IrisEnvironmentService],
      useFactory: (envService: IrisEnvironmentService) => envService.env.gmapsApiKey,
    }),
    HotkeyModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      IrisInitEffects,
      IrisUsersEffects,
      IrisLoaderEffects,
      UnitsEffects,
      IrisSettingsEffects,
      IrisResourceRolesEffects,
      ProjectsEffects,
    ]),
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),

    ...(environment.production ? [] : developmentModeImports),

    ApiQueryModule.forRoot({
      withMicroserviceRedirect: !environment.production,
    }),
    IrisProjectsMembersChangedNotificationModule,
    AuthModule,
    NgSelectModule,
    ValidationMessageResolverModule.forModule(),
    IrisMsalModule,
  ],
  providers: [
    IrisCompaniesService,
    {
      provide: BackendStompService,
      useFactory: backendStompServiceFactory,
    },
    {
      provide: NotificationsStompService,
      useFactory: notificationsStompServiceFactory,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IrisBasePathInterceptor,
      multi: true,
      deps: [
        IrisEnvironmentService,
      ],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IrisAuthInterceptor,
      multi: true,
      deps: [
        IrisEnvironmentService,
        AuthFacade,
      ],
    },
    // Possibly should be removed or changed after and if
    // will be merged some of this PRs
    // https://github.com/angular/angular/pull/32598
    // https://github.com/angular/angular/pull/37385
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IrisEncodeUrlParamsSafelyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IrisErrorHandlerInterceptor,
      multi: true,
      deps: [
        TranslateService,
        IrisLoaderService,
        IrisAlertService,
      ],
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomGestureConfig,
    },
    IrisTimePipe,
    IrisUserTimePipe,
    IrisApiQueryInitializerService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializer,
      multi: true,
    },
    CookieService,
    {
      provide: TIMEZONE,
      useFactory: (userService: IrisUserService) => of(userService.me.timeZone),
      deps: [IrisUserService],
    },
    {
      provide: IRIS_DATETIME_FORMATS,
      useFactory: datetimeFormatsFactory,
    },
    { provide: LOCALE_ID,
      useFactory: (localeService: LocaleService): string => localeService.getLocale(),
      deps: [LocaleService],
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        role: 'dialog',
        autoFocus: false,
        hasBackdrop: true,
      },
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { disableTooltipInteractivity: true },
    },
    {
      provide: MAT_LIST_CONFIG,
      useValue: { hideSingleSelectionIndicator: true },
    },
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategyService,
    },
    {
      provide: IrisHotkeysService,
      useClass: IrisHotkeysService,
      deps: [HotkeysService, MatDialog],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
  ],
  bootstrap: [AppRootComponent],
})
export class AppModule {
  constructor(
    @Inject(DOCUMENT) document: Document,
    translate: TranslateService,
    chunkLoadErrorHandler: ChunkLoadErrorHandlerService,
  ) {
    chunkLoadErrorHandler.activate();

    translate.onLangChange.pipe(
      tap((event: LangChangeEvent) => {
        document.documentElement.lang = event.lang;
        this._configDate(event.lang);
      }),
    ).subscribe();
  }

  private _configDate(currentLocale: string): void {
    Settings.defaultLocale = currentLocale;
  }
}
