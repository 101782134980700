import { Injectable } from '@angular/core';
import { ServiceWorkerMessage } from '@iris/common/models/service-worker-message';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SWService {
  private readonly authSWInitSubject = new Subject<void>();
  readonly authSWInit$ = this.authSWInitSubject.asObservable();

  constructor() {
    const channel = new BroadcastChannel('auth-sw-channel');
    channel.addEventListener('message', () => this.authSWInitSubject.next());
  }

  updateToken(accessToken: string): void {
    this.sendMessage({
      type: 'UPDATE_TOKEN',
      payload: accessToken,
    });
  }

  private sendMessage(message: ServiceWorkerMessage): void {
    navigator.serviceWorker?.ready?.then(() => {
      navigator.serviceWorker?.controller?.postMessage(message);
    });
  }
}
