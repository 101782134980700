import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'iris-simple-loader',
  templateUrl: './iris-simple-loader.component.html',
  styleUrls: ['./iris-simple-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisSimpleLoaderComponent {

  constructor() { }

}
