import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IrisModalBase } from '@iris/common/utils/iris-modal.base';
import { IrisFilesService, IrisFilePreviewType } from '@iris/common/modules/dms/services/files.service';
import { Router } from '@angular/router';
import { IrisDMSFileI } from '@iris/common/modules/dms/models/IrisDMSFile';
import { IrisDMSFileQueryParam, IrisDMSFileQueryParamAction } from '@iris/common/modules/dms/models/IrisDMSFileQueryParam';
import { goToPage } from '@iris/common/utils/router.utils';
import { PdfLoadedEvent, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs';

const OUT_OF_MEMORY_ERROR_TEXT = 'Out of memory';
const INVALID_STRUCTURE_ERROR_TEXT = 'Invalid PDF structure';

export interface FilesPreviewModalDataI {
  filesList: IrisDataRepoFileI[] | IrisDMSFileI[];
  file: IrisDataRepoFileI;
  openDms: boolean;
  showGoToFolder: boolean;
  saveRecentFile: boolean;
}

@Component({
  selector: 'files-preview-modal',
  templateUrl: './files-preview-modal.component.html',
  styleUrls: ['./files-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesPreviewModalComponent extends IrisModalBase implements OnInit {

  set filesList(filesList: IrisDataRepoFileI[] | IrisDMSFileI[]) {
    if (!!filesList && filesList.length > 0) {
      this._filesList = filesList;
      this.currentIndex = this.filesList.findIndex(({ id }) => id == this.file.id);
      this.changeDetector.markForCheck();
    }

    if (this.currentIndex === undefined || this.currentIndex === -1) {
      this.currentIndex = 0;
      this._filesList = [this.file];
    }
  }

  get filesList(): IrisDataRepoFileI[] | IrisDMSFileI[] {
    return this._filesList;
  }

  file: IrisDataRepoFileI;
  currentIndex: number;
  showGoToFolder = true;
  previewReady = false;
  sidebarVisible: boolean;
  isError: boolean;

  private _filesList: IrisDataRepoFileI[] | IrisDMSFileI[];
  private openDms = false;

  constructor(
    readonly dialogRef: MatDialogRef<FilesPreviewModalComponent>,
    private readonly filesService: IrisFilesService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(MAT_DIALOG_DATA) public data: FilesPreviewModalDataI,
  ) {
    super(dialogRef);
    pdfDefaultOptions.annotationEditorMode = -1;
  }

  get listLength(): number {
    return this.filesList?.length;
  }

  get currentFile(): IrisDataRepoFileI | IrisDMSFileI {
    return this.filesList[this.currentIndex] ?? this.file;
  }

  get previewFileType(): IrisFilePreviewType {
    return this.filesService.getPreviewType(this.currentFile);
  }

  get prevButtonDisabled(): boolean {
    if (this.isPdf && this.sidebarVisible) { return true; }
    return false;
  }

  get nextButtonDisabled(): boolean {
    if (this.isPdf) {
      const secondaryToolbar = this.document.getElementById('secondaryToolbar');
      const secondaryToolbarVisible = !secondaryToolbar?.classList?.contains('hidden');
      if (secondaryToolbarVisible) { return true; }
    }
    return false;
  }

  get fileLink(): string {
    if (this.data.file.id === this.currentFile.id && !!this.data.file.fileLink) {
      return this.data.file.fileLink;
    }
    return this.filesService.getFilePreviewUrl(this.currentFile.id, false, this.data.saveRecentFile);
  }

  get isExcelFile(): boolean {
    return this.filesService.isExcelFile(this.currentFile.mimeType);
  }

  get isImage(): boolean {
    return this.previewFileType === IrisFilePreviewType.Image;
  }

  get isPdf(): boolean {
    return this.previewFileType === IrisFilePreviewType.Pdf;
  }

  get isDocument(): boolean {
    return this.previewFileType === IrisFilePreviewType.Document;
  }

  get fileName(): string {
    return this.currentFile.name;
  }

  get fileInfoHref(): string {
    return this.filesService.generateFileInfoLink(this.file);
  }

  get isPdfError(): boolean {
    return this.isError;
  }

  ngOnInit(): void {
    this.file = this.data.file;
    this.filesList = this.data.filesList;
    this.openDms = this.data.openDms;
    this.showGoToFolder = this.data.showGoToFolder;
    this.changeURL();
  }

  onPdfLoaded(ev: PdfLoadedEvent): void {
    if (ev.pagesCount > 0) {
      this._showPreview();
    }
  }

  onImageLoaded(_ev: Event): void {
    this._showPreview();
  }

  onPdfLoadingFailed(ev: Error): void {
    const m = ev.message;
    if (m?.includes(OUT_OF_MEMORY_ERROR_TEXT) || m?.includes(INVALID_STRUCTURE_ERROR_TEXT)) {
      this.isError = true;
      this.previewReady = true;
    }
  }

  nextFile(): void {
    let index = this.getNextIndex(this.currentIndex);
    while (this.filesService.isOfficeDocument(this.filesList[index].mimeType)) {
      index = this.getNextIndex(index);
    }
    this.currentIndex = index;
    this._showLoader();
    this.changeURL();
  }

  prevFile(): void {
    let index = this.getPreviousIndex(this.currentIndex);
    while (this.filesService.isOfficeDocument(this.filesList[index].mimeType)) {
      index = this.getPreviousIndex(index);
    }
    this.currentIndex = index;
    this._showLoader();
    this.changeURL();
  }

  changeURL(): void {
    if (!this.openDms) { return; }
    const fileId = this.currentFile.id;

    goToPage(this.router, [], {
      queryParams: { [IrisDMSFileQueryParam.File]: fileId },
      queryParamsHandling: 'merge',
    });
  }

  goToParentFolder(): void {
    const parentFolderId = this.currentFile.parentId;

    goToPage(
      this.router,
      ['dms', 'folders', parentFolderId, 'files'],
      { queryParams: { file: this.currentFile.id, action: IrisDMSFileQueryParamAction.Select } },
    );

    this.closeWithoutResult();
  }

  downloadFile(): void {
    this.filesService.downloadFile(this.currentFile.id, this.currentFile.name).pipe(
      take(1),
    ).subscribe();
  }

  private _showPreview(): void {
    this.previewReady = true;
    this.changeDetector.detectChanges();
  }

  private _showLoader(): void {
    if (this.isPdf || this.isImage) {
      this.previewReady = false;
      this.changeDetector.detectChanges();
    }
  }

  private getNextIndex(index: number): number {
    if (index === (this.listLength - 1)) {
      return 0;
    } else {
      return ++index;
    }
  }

  private getPreviousIndex(index: number): number {
    if (index > 0) {
      return --index;
    } else {
      return this.listLength - 1;
    }
  }
}
