import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, computed } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ApiUrl, IrisApiClient, IrisQueryParams, IrisQueryParamsBuilder } from '@iris/api-query';
import { Observable, of, map, switchMap, tap } from 'rxjs';
import {
  IrisEmailMessageI,
  IrisEmailMessageAttachmentI,
  IrisEmailRecipientI,
  IrisEmailMessageResponseI,
  IrisEmailMessage,
  IrisEmailMessageAttachmentsResponseI,
  IrisEmailsContactI,
} from '../models/IrisEmail';
import { IrisEmailFolderResponseI } from '../models/IrisEmailNavigation';
import { IrisDMSFolderI } from '../../dms/models/IrisDMSFolder';
import { IrisEmailsPaginationI } from '../models/IrisEmailPagination';
import { IrisDMSFileI } from '../../dms/models/IrisDMSFile';
import { IrisModuleFilterService } from '../../module-filter/services/module-filter.service';
import { IrisMFEq } from '../../module-filter/models/IrisMFEq';
import { TranslateService } from '@ngx-translate/core';
import { IrisMFContains } from '../../module-filter/models/IrisMFContains';
import { IrisTimePipe } from '../../time';
import { IrisMFBetween } from '../../module-filter/models/IrisMFBetween';
import { IrisMsalService } from '@iris/modules/msal/msal.service';
import { IrisGlobalSandbox } from '@iris/common/redux/global.sandbox';
import { isUndefined } from 'lodash';
import { AuthFacade } from '@iris/modules/auth/utils/auth.facade';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisEnvironmentService } from '@iris/common/services/environment.service';
import { IrisSharedMailboxI } from '../models/shared-mailbox';

export interface IrisEmailsClientListResponseI<T> {
  count: number;
  currentPage: Array<T>;
  nextPage?: Record<string, T>;
}

@Injectable({ providedIn: 'root' })
@ApiUrl('/mail-client/{instanceId}')
export class IrisEmailsService extends IrisApiClient<IrisEmailMessageI> {
  protected messages: IrisEmailMessageI[] = [];
  protected draftMessages: IrisEmailMessageI[] = [];
  protected attachments: IrisEmailMessageAttachmentI[] = [];

  public sharedMailbox = '';

  readonly emailsFilterMeta = [
    new IrisMFContains('subject', { label: this.translateService.instant('label.Subject'), useRawString: true }),
    new IrisMFEq('periodFrom', {
      label: this.translateService.instant('label.PeriodFrom'),
    }),
    new IrisMFEq('periodTo', {
      label: this.translateService.instant('label.PeriodTo'),
    }),
    new IrisMFBetween('period', {
      label: this.translateService.instant('label.CreatedBetween'),
      stringifyFn: value =>  value ? this.timePipe.transform(value, 'DD.MM.YYYY', 'UTC') : '',
    }),
    new IrisMFContains('body', { label: this.translateService.instant('label.email.Keywords'), useRawString: true }),
    new IrisMFContains('hasAttachments', { label: this.translateService.instant('label.HasAttachments'), useRawString: true }),
    new IrisMFEq('unread', {
      label: this.translateService.instant('label.Unread'),
      splittable: {
        singleMode: {
          itemLabel$: () => of(this.translateService.instant('label.Unread')),
        },
      },
    }),
  ];

  private readonly azureAccessToken = computed(() => this.authFacade.azureAccessToken());

  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly moduleFilterService: IrisModuleFilterService,
    private readonly translateService: TranslateService,
    private readonly timePipe: IrisTimePipe,
    private readonly msalService: IrisMsalService,
    private readonly globalSandbox: IrisGlobalSandbox,
    private readonly authFacade: AuthFacade,
    private readonly userService: IrisUserService,
    private readonly envService: IrisEnvironmentService,
  ) {
    super(httpClient);

    this.handleProjectChange();
  }

  get userId(): number {
    return this.userService.me.id;
  }

  get userEmailAddress(): IrisEmailRecipientI {
    return {
      emailAddress: {
        address: this.userService.me.email,
        name: this.userService.me.fullName,
      },
    };
  }

  get instanceId(): string {
    return this.envService.env.instanceId;
  }

  @ApiUrl('~/users/{userId}')
  checkUserAccess(): Observable<boolean> {
    return this.httpClient.get<boolean>(this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    }), { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/folders')
  getCustomFolders(limit = 50, offset = 0, includeHiddenFolders = true): Observable<IrisEmailsClientListResponseI<IrisEmailFolderResponseI>> {
    const params = { offset, limit, includeHiddenFolders };
    return this.httpClient.get<IrisEmailsClientListResponseI<IrisEmailFolderResponseI>>(this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    }), { params, headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/folders/{folderId}')
  getCustomFolder(folderId: string): Observable<IrisEmailFolderResponseI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      folderId,
    });
    return this.httpClient.get<IrisEmailFolderResponseI>(url, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/folders/{parentFolderId}/child')
  getChildrenFolders(parentFolderId: string): Observable<IrisEmailsClientListResponseI<IrisEmailFolderResponseI>> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      parentFolderId,
    });
    return this.httpClient.get<IrisEmailsClientListResponseI<IrisEmailFolderResponseI>>(url, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/folders/{folderId}/messages')
  getMessagesByAlias(
    alias: string,
    pagination: IrisEmailsPaginationI,
  ): Observable<IrisEmailsClientListResponseI<IrisEmailMessageResponseI>> {
    const { offset, limit, searchText, filter, orderBy } = pagination;

    const extractedFilter = this.moduleFilterService.extractFilter(
      this.emailsFilterMeta.filter(item => !['body', 'subject', 'hasAttachments'].includes(item.fieldName)),
      filter ?? {},
    );
    const extraParams = new IrisQueryParamsBuilder(extractedFilter);

    const searchBody = filter?.body ?? '';
    const searchSubject = filter?.subject ?? '';
    const hasAttachments = filter?.hasAttachments;
    
    extraParams.urlParam('offset', offset);
    if (!!limit) {
      extraParams.limit(limit);
    }
    if (!!orderBy?.length && !searchText && !searchBody && !searchSubject && isUndefined(hasAttachments)) {
      extraParams.urlParam('order-by', orderBy);
    }
    if (!!searchText) {
      extraParams.urlParam('search', searchText);
    }
    if (!isUndefined(hasAttachments)) {
      extraParams.urlParam('hasAttachments', hasAttachments);
    }
    if (!!searchSubject) {
      extraParams.urlParam('subject', searchSubject);
    }
    if (!!searchBody) {
      extraParams.urlParam('body', searchBody);
    }
    const params = extraParams.toObject();
    return this.httpClient.get<IrisEmailsClientListResponseI<IrisEmailMessageResponseI>>(this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      folderId: alias,
    }), { params, headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}')
  getMessageById(messageId: string, attachments = false): Observable<IrisEmailMessageI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });
    return this.httpClient.get<IrisEmailMessageResponseI>(url, { headers: this.getRequestHeaders(), params: { attachments }  }).pipe(
      map(message => new IrisEmailMessage().fromResponse(message)),
    );
  }

  markMessageAsRead(messageId: string): Observable<IrisEmailMessageI> {
    return this.updateMessage(messageId, { isRead: true }).pipe(
      map(message => new IrisEmailMessage().fromResponse(message)),
    );
  }

  markMessageAsUnread(messageId: string):  Observable<IrisEmailMessageI> {
    return this.updateMessage(messageId, { isRead: false }).pipe(
      map(message => new IrisEmailMessage().fromResponse(message)),
    );
  }

  @ApiUrl('~/users/{userId}/bulk/mark-read/folder/{folderId}')
  setMessagesReadMarkBulk(folderId: string, read: boolean, includeIds: string[], excludeIds: string[]): Observable<void> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      folderId,
    });

    const params = { read, includeIds, excludeIds };

    return this.httpClient.post<void>(url, null, { params, headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments')
  getMessageAttachments(messageId: string): Observable<IrisEmailMessageAttachmentI[]> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.get<IrisEmailMessageAttachmentsResponseI>(url, { headers: this.getRequestHeaders() }).pipe(
      map(response => response.currentPage),
    );
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments?jwt={jwt}')
  getUploadAttachmentUrl(messageId: string): string {
    return this.fullUrl({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      jwt: this.azureAccessToken(),
    });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/list?jwt={jwt}')
  getUploadAttachmentsBulkUrl(messageId: string): string {
    return this.fullUrl({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      jwt: this.azureAccessToken(),
    });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments')
  uploadAttachment(messageId: string, attachment: File): Observable<IrisEmailMessageAttachmentI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    const formData = new FormData();

    formData.append('attachment', attachment);

    return this.httpClient.post<IrisEmailMessageAttachmentI>(url, formData, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/list')
  uploadAttachmentsBulk(messageId: string, attachments: File[]): Observable<IrisEmailMessageAttachmentI[]> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    const formData = new FormData();

    attachments.forEach(attachment => {
      formData.append('file', attachment);
    });

    return this.httpClient.post<IrisEmailMessageAttachmentI[]>(url, formData, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/dms-files/{fileId}')
  uploadAttachmentFromDms(messageId: string, fileId: string): Observable<IrisEmailMessageAttachmentI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      fileId,
    });

    return this.httpClient.post<IrisEmailMessageAttachmentI>(url, null, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/dms-file-list')
  uploadAttachmentFromDmsBulk(messageId: string, filesIds: string[]): Observable<IrisEmailMessageAttachmentI[]> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.post<IrisEmailMessageAttachmentI[]>(url, filesIds, { headers: this.getRequestHeaders() });
  }
  
  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/{attachmentId}')
  removeAttachment(messageId: string, attachmentId: string): Observable<string> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      attachmentId,
    });

    return this.httpClient.delete<string>(url, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/{attachmentId}/dms-folders/{folderId}')
  saveMessageAttachmentToDms(messageId: string, attachmentId: string, folderId: string): Observable<IrisDMSFileI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      attachmentId,
      folderId,
    });

    return this.httpClient.post<IrisDMSFileI>(url, null, { headers: this.getRequestHeaders() });
  }

  toggleMailViewFullWidthMode(isFullwidth: boolean): void {
    const module = this.document.querySelector('iris-emails-main') as HTMLElement;
    const navigation = this.document.querySelector('.mail-navigation-box') as HTMLElement;
    const list = this.document.querySelector('.mail-messages-list-box') as HTMLElement;

    const sideWidth = navigation.offsetWidth + list.offsetWidth;
    navigation.style.marginLeft = !isFullwidth  ? '0px' : `-${sideWidth}px`;
    module.classList.toggle('fullscreen', isFullwidth);
  }

  @ApiUrl('~/users/{userId}/messages')
  saveMessageAsDraft(message: Partial<IrisEmailMessageResponseI>): Observable<IrisEmailMessageResponseI> {
    const messageToUpdate = message.id ? this.draftMessages.find(_message => _message.id === message.id) : null;
    const messageBody = {
      ...message,
      from: messageToUpdate?.from ?? this.userEmailAddress,
      isRead: true,
      isDraft: true,
    };

    if (message.id) {
      return this.updateMessage(message.id, messageBody);
    }

    const url = this.url({ instanceId: this.instanceId, userId: this.sharedMailbox });

    return this.httpClient.post<IrisEmailMessageResponseI>(url, messageBody, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages')
  sendDraftMessage(message: Partial<IrisEmailMessageResponseI>): Observable<IrisEmailMessageI> {
    let url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    });

    const body = {
      isDraft: false,
    };

    return this.saveMessageAsDraft(message).pipe(
      switchMap((savedMessage) => {
        url += `/${savedMessage.id}`;
        return this.httpClient.post<IrisEmailMessageI>(url, body, { headers: this.getRequestHeaders() });
      }),
    );
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/forward')
  forwardMessage(
    messageId: string,
    message: Partial<IrisEmailMessageResponseI>,
  ): Observable<IrisEmailMessageResponseI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.post<IrisEmailMessageResponseI>(url, message, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/reply')
  replyToMessage(
    messageId: string,
    message: Partial<IrisEmailMessageResponseI>,
  ): Observable<IrisEmailMessageResponseI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.post<IrisEmailMessageResponseI>(url, message, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/reply-all')
  replyToAll(
    messageId: string,
    message: Partial<IrisEmailMessageResponseI>,
  ): Observable<IrisEmailMessageResponseI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.post<IrisEmailMessageResponseI>(url, message, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/conversation/{conversationId}')
  getMessageConversation(conversationId: string): Observable<IrisEmailMessageResponseI[]> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      conversationId,
    });

    return this.httpClient.get<IrisEmailsClientListResponseI<IrisEmailMessageResponseI>>(url, { headers: this.getRequestHeaders() }).pipe(
      map(response => response.currentPage),
    );
  }
 
  @ApiUrl('~/users/{userId}/messages/{messageId}')
  updateMessage(messageId: string, body: Partial<IrisEmailMessageI | IrisEmailMessageResponseI>): Observable<IrisEmailMessageResponseI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });

    return this.httpClient.put<IrisEmailMessageResponseI>(url, body, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}')
  removeMessage(messageId: string): Observable<unknown> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
    });
    return this.httpClient.delete(url, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/folders/{folderId}/move')
  moveMessageToFolder(messageId: string, folderId: string): Observable<IrisEmailMessageI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      folderId,
    });
    return this.httpClient.post<IrisEmailMessageI>(url, null, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/bulk/folder/{folderId}')
  removeMessagesBulk(folderId: string, includeIds: string[], excludeIds: string[]): Observable<void> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      folderId,
    });
    const params = { includeIds, excludeIds };
    return this.httpClient.delete<void>(url, { params, headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/dms-folders/{folderId}')
  saveMessageToDms(messageId: string, folderId: string): Observable<IrisDMSFolderI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      folderId,
    });
    return this.httpClient.post<IrisDMSFolderI>(url, null, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/bulk/export/folder/{folderId}/dms-folder/{dmsFolderId}')
  saveMessageToDmsBulk(
    folderId: string,
    dmsFolderId: string,
    includeIds: string[],
    excludeIds: string[],
  ): Observable<IrisDMSFolderI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      folderId,
      dmsFolderId,
    });
    const body = {
      includeIds,
      excludeIds,
    };
    return this.httpClient.post<IrisDMSFolderI>(url, body, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/messages/{messageId}/attachments/dms-folders/{folderId}')
  saveMessagesAttachmentsToDms(messageId: string, folderId: string): Observable<IrisDMSFolderI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      messageId,
      folderId,
    });
    return this.httpClient.post<IrisDMSFolderI>(url, null, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/contacts')
  getContacts(searchParams: IrisQueryParams): Observable<IrisEmailsClientListResponseI<IrisEmailsContactI>> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    });
    return this.httpClient.get<IrisEmailsClientListResponseI<IrisEmailsContactI>>(
      url,
      { params: searchParams.toObject(), headers: this.getRequestHeaders() },
    );
  }

  @ApiUrl('~/users/{userId}/contacts/{contactId}')
  getContactById(contactId: string, queryParams: IrisQueryParams): Observable<IrisEmailsContactI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      contactId,
    });
    return this.httpClient.get<IrisEmailsContactI>(url, { params: queryParams.toObject(), headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/contacts')
  createContact(contact: Partial<IrisEmailsContactI>): Observable<IrisEmailsContactI> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    });
    return this.httpClient.post<IrisEmailsContactI>(url, contact, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/users/{userId}/contacts/{contactId}')
  deleteContact(contactId: string): Observable<unknown> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
      contactId,
    });
    return this.httpClient.delete<unknown>(url, { headers: this.getRequestHeaders() });
  }

  @ApiUrl(`~/users/{userId}/folders/unread-emails`)
  getUnreadCount(): Observable<number> {
    const url = this.url({
      instanceId: this.instanceId,
      userId: this.sharedMailbox,
    });
    return this.httpClient.get<{ count: number }>(url, { headers: this.getRequestHeaders() }).pipe(
      map(response => response.count),
    );
  }

  @ApiUrl('~/smb')
  createSharedMailBox(sharedMailbox: Partial<IrisSharedMailboxI>): Observable<unknown> {
    const url = this.url({
      instanceId: this.instanceId,
    });

    return this.httpClient.post<unknown>(url, sharedMailbox, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/smb/user/add')
  addUsersToSmb(userIds: number[]): Observable<unknown> {
    if (!this.sharedMailbox) { return of(null); }
    const url = this.url({
      instanceId: this.instanceId,
    });

    return this.httpClient.post<unknown>(url, { userIds, mailbox: this.sharedMailbox }, { headers: this.getRequestHeaders() });
  }

  @ApiUrl('~/smb/user/delete')
  removeUsersFromSmb(userIds: number[]): Observable<unknown> {
    if (!this.sharedMailbox) { return of(null); }
    const url = this.url({
      instanceId: this.instanceId,
    });

    return this.httpClient.post<unknown>(url, { userIds, mailbox: this.sharedMailbox }, { headers: this.getRequestHeaders() });
  }

  fetchJwtToken(): Observable<boolean> {
    if (this.azureAccessToken()) { return of(true); }
    return this.msalService.acquireTokenSilent();
  }

  private getRequestHeaders(extend?: Record<string, unknown>): HttpHeaders | {[header: string]: string | string[]} {
    return {
      'x-iris-azure-token': this.azureAccessToken(),
      ...(extend ?? {}),
    };
  }

  private handleProjectChange(): void {
    this.globalSandbox.currentProject$.pipe(
      tap(project => {
        this.sharedMailbox = project?.sharedMailbox;
      }),
    ).subscribe();
  }
}
