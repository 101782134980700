import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { IrisAlert, IrisAlertType } from '../model/AlertModel';
import { IrisAlertService } from '../service/alert.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'iris-alert',
  templateUrl: './alert.component.html',
})
export class IrisAlertComponent implements OnInit, OnDestroy {
  alerts: IrisAlert[] = [];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly alertService: IrisAlertService,
    private readonly _elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.alertService.getAlert().pipe(
      tap(alert => {
        const currentAlertId = alert.id;
        alert.message = (alert.message as string).replace('{{config.baseUrl}}', '');
        this.alerts.push(alert);
        // For fadeOut animations
        setTimeout(() => {
          this.fadeOut(alert.id);
        }, alert.settings.dismissTimeout - 300);
        // Remove this Alert after dismiss
        setTimeout(() => {
          this.alerts = this.alerts.filter(alertItem => alertItem.id !== currentAlertId);
        }, alert.settings.dismissTimeout);

        this.changeDetector.markForCheck();
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  onClose(alert: IrisAlert): void {
    this.fadeOut(alert.id);
  }

  fadeOut(alertId: string): void {
    const element = this._elementRef.nativeElement.querySelector(`#iris-alert-${alertId}`);
    if (element) {
      this.renderer.removeClass(element, 'animate__bounceInRight');
      this.renderer.addClass(element, 'animate__bounceOutRight');
    }
  }

  getAlertIconByType(type: IrisAlertType): string {
    switch (type) {
      case IrisAlertType.Success :
        return 'fa-check-circle';
      case IrisAlertType.Error :
        return 'fa-exclamation-circle';
      case IrisAlertType.Log:
        return 'fa-exclamation-triangle';
      default:
        return 'fa-info-circle';
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
