import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { IrisFilesService } from '@iris/common/modules/dms/services/files.service';
import { IrisUserInfo } from '@iris/common/modules/user-common/models/IrisUserInfo';

@Component({
  selector: 'iris-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisUserAvatarComponent implements OnChanges {

  @Input() userInfo: IrisUserInfo = null;
  @Input() avatarSize: 'sm' | 'md' | 'lg' | 'xl';

  userExists = false;
  hasAvatar = false;
  userInitials = null;
  avatarLoadingError = false;

  get avatarSizeClass(): string {
    switch (this.avatarSize) {
      case 'sm':
        return 'avatar-24x24';
      default:
        return '';
    }
  }

  get avatarLink(): string {
    return this.filesService.getFileContentUrl(this.userInfo.avatarFileId);
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly filesService: IrisFilesService,
  ) {}

  ngOnChanges(changes): void {
    if (changes.userInfo && !!changes.userInfo.currentValue) {
      this.userExists = true;
      this.userInitials = this._createUserInitials();
      if (!!this.userInfo?.avatarFileId) { this.hasAvatar = true; }
      this.cdr.detectChanges();
    } else {
      this.userExists = false;
      this.hasAvatar = false;
    }
  }

  /**
   *  If the avatar's src is somehow broken - show initials instead
   */
  handleMissingImage(_event: Event): void {
    this.hasAvatar = false;
    this.cdr.detectChanges();
  }

  private _createUserInitials(): string {
    let firstname = '';
    let lastname = '';

    if (this.userInfo.firstname?.length && this.userInfo.lastname?.length) {
      firstname = this.userInfo.firstname.split(' ')[0];
      lastname = this.userInfo.lastname.split(' ')[0];
    } else if (this.userInfo.fullName?.length) {
      const splittedFullName = this.userInfo.fullName.split(' ');
      firstname = splittedFullName[0];
      lastname = splittedFullName[splittedFullName.length - 1];
    }

    return `${firstname?.charAt(0)}${lastname?.charAt(0)}`.toUpperCase();
  }
}
