import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRootComponent } from './app-root.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
  ],
  declarations: [AppRootComponent],
  exports: [AppRootComponent],
})
export class AppRootComponentModule {
}
