import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IrisAlertComponent } from './component/alert.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { IrisModalContentModule } from '../iris-modal-content/iris-modal-content.module';
import { IrisCommonDirectivesModule } from '@iris/common/modules/directives-common/common-directives.module';
import { IrisAlertRendererDirective } from '@iris/common/modules/alert/directive/alert-renderer.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    IrisModalContentModule,
    IrisCommonDirectivesModule,
  ],
  declarations: [
    IrisAlertComponent,
    ConfirmModalComponent,
    IrisAlertRendererDirective,
  ],
  exports: [
    IrisAlertComponent,
  ],
})
export class IrisAlertModule {
}
