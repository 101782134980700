import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, merge, Observable, Subject, timer } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { IrisFillLoaderComponent } from './fill-loader.component';
import { IrisInlineLoaderComponent } from './inline-loader.component';
import { IrisSkeletonLoaderComponent } from './skeleton-loader.component';

const DEFAULT_DELAY = 300;

enum ShowingTemplate {
  Content,
  Loader,
}

type LoaderComponent = IrisInlineLoaderComponent | IrisFillLoaderComponent | IrisSkeletonLoaderComponent;
export type LoaderType = 'inline' | 'fill' | 'skeleton';

@Directive({
  selector: '[irisIfLoaded]',
})
export class IrisIfLoadedDirective<T = unknown> implements OnChanges, OnInit, OnDestroy {
  @Input('irisIfLoaded') loadedCondition: T;
  @Input('irisIfLoadedLoaderText') loaderText: string;
  @Input('irisIfLoadedLoaderType') loaderType: LoaderType;
  @Input('irisIfLoadedLoaderHeight') loaderHeight: string; // for skeleton loader only
  @Input('irisIfLoadedLoaderWidth') loaderWidth: string; //for skeleton loader only
  @Input('irisIfLoadedDelayTime') delayTime = DEFAULT_DELAY;

  _showingTemplateSubject = new BehaviorSubject<ShowingTemplate>(null);
  _destroyedSubject = new Subject<void>();
  _delayShowingContentUntilDate: Date = new Date(); // Now
  _loaderComponentRef: ComponentRef<LoaderComponent>;

  constructor(
    private readonly _templateRef: TemplateRef<unknown>,
    private readonly _viewContainer: ViewContainerRef,
    private readonly _changeDetector: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this._renderInnerAsync()
      .pipe(
        takeUntil(this._destroyedSubject),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loaderType) {
      if (this._showingTemplateSubject.getValue() == ShowingTemplate.Loader) {
        this._renderLoader();
      }
    }

    if (changes.delayTime
      || (changes.loadedCondition && !changes.loadedCondition.currentValue)) {
      this._delayShowingContentUntilDate = new Date(Date.now() + this.delayTime);
    }

    if (changes.loadedCondition) {
      const showingTemplate = changes.loadedCondition.currentValue ? ShowingTemplate.Content : ShowingTemplate.Loader;
      this._showingTemplateSubject.next(showingTemplate);
    }
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();

    this._showingTemplateSubject.complete();
  }

  _renderInnerAsync(): Observable<void> {
    return merge(
      this._getShowingContentPipe(),
      this._getShowingLoaderPipe(),
    )
      .pipe(
        distinctUntilChanged(),
        tap(mode => {
          if (mode == ShowingTemplate.Loader) {
            this._renderLoader();
          }
          else if (mode == ShowingTemplate.Content) {
            this._renderContent();
          }
        }),
        map(() => null),
      );
  }

  _renderContent(): void {
    this._loaderComponentRef = null;

    this._viewContainer.clear();
    this._viewContainer.createEmbeddedView(this._templateRef, { $implicit: this.loadedCondition, irisIfLoaded: this.loadedCondition });
    this._changeDetector.markForCheck();
  }

  _renderLoader(): void {
    const loaderComponentType = this._getLoaderComponentType();

    if (this._loaderComponentRef?.componentType != loaderComponentType) {

      this._viewContainer.clear();
      this._loaderComponentRef = this._viewContainer.createComponent(loaderComponentType);
    }

    if ('text' in this._loaderComponentRef.instance ) {
      this._loaderComponentRef.instance.text = this.loaderText;
    }
    if ('height' in this._loaderComponentRef.instance) {
      this._loaderComponentRef.instance.height = this.loaderHeight;

      if (this.loaderWidth) {
        this._loaderComponentRef.instance.width = this.loaderWidth;
      }
    }
    this._changeDetector.markForCheck();
  }

  _getShowingContentPipe(): Observable<ShowingTemplate> {
    return this._showingTemplateSubject
      .pipe(
        filter(mode => mode == ShowingTemplate.Content),
        switchMap(() => timer(this._delayShowingContentUntilDate)),
        map(() => this._showingTemplateSubject.getValue()), // previous value can be outdated due to delay
        filter(mode => mode == ShowingTemplate.Content),
      );
  }

  _getShowingLoaderPipe(): Observable<ShowingTemplate> {
    return this._showingTemplateSubject
      .pipe(
        filter(mode => mode == ShowingTemplate.Loader),
      );
  }

  _getLoaderComponentType(): Type<LoaderComponent> {
    switch (this.loaderType) {
      case 'inline': return IrisInlineLoaderComponent;
      case 'fill': return IrisFillLoaderComponent;
      case 'skeleton': return IrisSkeletonLoaderComponent;
      default: return IrisInlineLoaderComponent;
    }
  }
}
