<div class="position-relative">

  @if (showPopover) {
    <div class="local-popover-trigger"
         [ngxTippy]="popoverTemplate"
         [tippyName]="tippyName"
         [tippyProps]="popoverProps">
      <div #popoverTemplate>
        <div id="user-popover">
          <iris-user-avatar [userInfo]="userInfo()"></iris-user-avatar>
          <div class="info">
            <div class="name">{{userName()}}</div>

            @if (userPhone()) {
              <a href="tel:{{userPhone()}}"
                 class="phone padded-top">
                <i class="fas fa-sm fa-phone"></i>
                <span class="padded-left">{{userPhone()}}</span>
              </a>
            }

            @if (userMobileNumber()) {
              <a href="tel:{{userMobileNumber()}}"
                 class="phone padded-top">
                <i class="fas fa-sm fa-phone"></i>
                <span class="padded-left">{{userMobileNumber()}}</span>
              </a>
            }

            @if (userEmail()) {
              <a class="email padded-top"
                 href="mailto:{{userEmail()}}">{{userEmail()}}
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  }

  <span class="iris-user-wrapper"
        [ngClass]="{
      'with-department': showDepartment,
      'with-company': showCompany,
      'with-email': showEmail
    }">

    @if (showAvatar) {
      <iris-user-avatar [avatarSize]="showAvatar ? avatarSize : undefined"
                        [userInfo]="userInfo()"
      ></iris-user-avatar>
    }

    @if (!showOnlyAvatar) {
      <span class="iris-user"
            [ngClass]="cssClass"
            [class.spaced-left-x2]="showAvatar">
        <span class="iris-user-name">
          {{ userName() | translate }}
        </span>

        @if (showCompany && companyName$() | async) {
          <span class="iris-user-company">
            {{ companyName$() | async }}
          </span>
        }

        @if (showEmail && userEmail()) {
          <span class="iris-user-email">
            {{ userEmail() }}
          </span>
        }
      </span>
    }

    @if (showDepartment && departmentId()) {
      <span class="iris-user-department">
          <span>{{departmentId() ? (departmentId() | irisFilterField: [departments]) : 'label.NoDivision' | translate}}</span>
      </span>
    }
  </span>
</div>
