import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IrisLogoService } from '@iris/common/services/logo.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ImprintInfoModalComponent } from './imprint-info-modal/imprint-info-modal.component';

@Component({
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnDestroy {

  private readonly destroySubject: Subject<void> = new Subject();

  imageUrl$ = this.logoService.imageUrl$;

  constructor(
    private readonly logoService: IrisLogoService,
    private readonly dialog: MatDialog,
  ) {
    logoService.loadBrands().pipe(
      switchMap(() => logoService.activeBrand$),
      tap((activeBrand) => logoService.appendWebIcons(activeBrand.brand)),
      takeUntil(this.destroySubject),
    ).subscribe();
  }

  showInfoModal(): void {
    this.dialog.open<ImprintInfoModalComponent>(ImprintInfoModalComponent);
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
