import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'global-announcement-banner',
  templateUrl: './global-announcement-banner.component.html',
  styleUrls: ['./global-announcement-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalAnnouncementBannerComponent {
  
  readonly SAFE_HTML_OPTIONS = {
    ADD_ATTR: ['target'],
    FORBID_ATTR: ['style', 'class'],
    ALLOWED_TAGS: ['a', 'br', 'strong', 'b'],
  };

  @Input() message = '';

  constructor() { }
}
