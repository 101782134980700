<div class="b-window" (mouseenter)="mouseEnter()"  (mouseleave)="mouseLeave()">
  <div class="b-content">
    <div class="scrollable" #scrollBox>
      <mat-tree class="iris-menu-list"
                [dataSource]="navigationMenuDS"
                [treeControl]="navigationMenuTreeControl">
        <mat-tree-node *matTreeNodeDef="let menuItem" class="menu-item-container" [ngClass]="{ 'active': menuItem.$active }">
          <a class="btn btn-link menu-item"
             *ngIf="!getUi2SubPathOrNull(menuItem.url); else routerLink"
             [href]="menuItem.url"
             (click)="handleMenuClick($event, menuItem)"
             [matTooltip]="showTooltipIfNeeded(menuItem)"
             [matTooltipPosition]="'after'">
            <span class="menu-item-icon">
              <i class="fa-fw {{ menuItem.icon }}"></i>
            </span>
            <span class="menu-item-caption">
              {{ menuItem.$title }}
            </span>
          </a>

          <ng-template #routerLink>
            <a class="btn btn-link menu-item position-relative"
               [routerLink]="[getUi2SubPathOrNull(menuItem.url)]"
               (click)="handleMenuClick($event, menuItem)"
               [matTooltip]="showTooltipIfNeeded(menuItem)"
               [matTooltipPosition]="'after'">
                <span class="menu-item-icon">
                  <i class="fa-fw {{ menuItem.icon }} position-relative">
                    <span *ngIf="menuItem.name === 'email-client' && !!emailUnreadMessagesCount()" class="position-absolute top-0 start-100 translate-middle p-1 bg-danger badge-color rounded-circle">
                      <span class="visually-hidden">New alerts</span>
                    </span>
                  </i>
                </span>
                <span class="menu-item-caption">
                  {{ menuItem.$title }}
                </span>
                <span *ngIf="menuItem.name === 'email-client' && !!emailUnreadMessagesCount()" class="emails-count text-center">
                  {{ emailUnreadMessagesCount() }}
                </span>
            </a>
          </ng-template>
        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let menuItem; when: hasChild" class="menu-item-container"
                              [ngClass]="{
                                'active': menuItem.$active,
                                'expanded': navigationMenuTreeControl.isExpanded(menuItem)
                              }">
          <button class="btn btn-link menu-item"
                  (click)="toggleSubMenu(menuItem)"
                  [matTooltip]="showTooltipIfNeeded(menuItem)"
                  [matTooltipPosition]="'after'">
            <span class="menu-item-icon">
              <i class="fa-fw {{ menuItem.icon }}"></i>
            </span>
            <span class="menu-item-caption">
              {{ menuItem.$title }}
            </span>
            <span class="menu-item-more">
              <i class="fal"
                 [ngClass]="{
                  'fa-chevron-down': !navigationMenuTreeControl.isExpanded(menuItem),
                  'fa-chevron-up': navigationMenuTreeControl.isExpanded(menuItem)
                 }"
              ></i>
            </span>
          </button>

          <div [@slideVertical]="navigationMenuTreeControl.isExpanded(menuItem) ? 'show' : null"
               class="menu-group-container">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
  </div>

  <div class="b-footer">
    <div class="btn-toolbar">
      <iris-toggle
        class="iris-menu-pin iris-dark"
        [label]="'label.PinMainMenu' | translate"
        [labelPosition]="'before'"
        [ngModel]="pinned"
        (ngModelChange)="togglePinned($event)"
      ></iris-toggle>
    </div>
  </div>
</div>
