<div *ngIf="userInfo"
     class="d-flex align-items-center local-card-width">
  <iris-user-avatar
    [avatarSize]="'lg'"
    [userInfo]="userInfo">
  </iris-user-avatar>

  <div class="d-flex flex-column local-fields spaced-left-x2">
    <p class="fw-bold mb-0">
      {{userInfo.fullName}}
    </p>

    <a *ngIf="userInfo.phone"
       class="local-phone-field"
       href="tel:{{userInfo.phone}}">
      <i class="fas fa-sm fa-phone"></i>

      <span class="local-phone-number spaced-left">
        {{userInfo.phone}}
      </span>
    </a>

    <a *ngIf="userInfo.mobileNumber"
       class="local-phone-field"
       href="tel:{{userInfo.mobileNumber}}">
      <i class="fas fa-sm fa-phone"></i>

      <span class="local-phone-number spaced-left">
        {{userInfo.mobileNumber}}
      </span>
    </a>

    <a *ngIf="userInfo.email"
       class="local-email-field"
       href="mailto:{{userInfo.email}}">
      {{userInfo.email}}
    </a>
  </div>
</div>
