import { NgModule } from '@angular/core';
import { NgSelectModule } from '@natlex/ng-select';
import { IrisNgSelectFieldModule } from '@iris/common/modules/fields/ng-select-field';
import { IrisUserSelectDirective } from './field-user-select.directive';
import { IrisUserSelectInputItemComponentModule } from './input-item';
import { IrisUserSelectDropdownItemComponentModule } from './dropdown-item';

@NgModule({
  imports: [
    NgSelectModule,
    IrisNgSelectFieldModule,
    IrisUserSelectDropdownItemComponentModule,
    IrisUserSelectInputItemComponentModule,
  ],
  declarations: [
    IrisUserSelectDirective,
  ],
  exports: [
    IrisUserSelectDirective,
    NgSelectModule,
    IrisNgSelectFieldModule,
  ],
})
export class IrisUserSelectModule {
}
