import { ValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { INTEGER_ONLY_VALIDATOR_NAME } from '../../validators/integer-only.validator';

@Injectable()
export class IntegerValidationMessageResolver implements ValidationMessageResolver {
  readonly errorCode = INTEGER_ONLY_VALIDATOR_NAME;

  constructor(
    private readonly translateService: TranslateService,
  ) { }

  getMessage(): string {
    return this.translateService.instant('label.validation.integer');
  }
}
