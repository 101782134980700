import {
  ComponentRef,
  Directive,
  Input,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { IrisOverlayLoaderComponent } from './overlay-loader.component';

@Directive({
  selector: '[irisOverlayLoader]',
})
export class IrisOverlayLoaderDirective implements OnDestroy {

  private readonly overlayLoaderSubject$ = new BehaviorSubject(false);
  private readonly overlayLoaderSubscription: Subscription;
  private overlayComponentRef: ComponentRef<IrisOverlayLoaderComponent>;

  @Input('irisOverlayLoader')
  set overlayLoader(value: boolean) {
    this.overlayLoaderSubject$.next(!!value);
  }

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    this.overlayLoaderSubscription = this.overlaySubscription();
  }

  private overlaySubscription(): Subscription {
    return this.overlayLoaderSubject$.pipe(
      distinctUntilChanged(),
      tap(value => {
        this.manageLoader(value);
      })).subscribe();
  }

  private manageLoader(overlayLoader: boolean): void {
    if (overlayLoader) {
      this.overlayComponentRef = this.viewContainerRef.createComponent(IrisOverlayLoaderComponent);
    } else {
      if (!this.overlayComponentRef) return;

      this.overlayComponentRef.destroy();
      this.overlayComponentRef = null;
    }
  }

  start(): void {
    this.overlayLoaderSubject$.next(true);
  }

  stop(): void {
    this.overlayLoaderSubject$.next(false);
  }

  ngOnDestroy(): void {
    this.overlayLoaderSubscription?.unsubscribe();
    this.overlayComponentRef = null;
  }
}
