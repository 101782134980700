export class ServerBaseEntity<T extends ServerBaseEntityI> implements ServerBaseEntityI {
  id: number = undefined;
  createdBy: number = undefined;
  createdOn: string = undefined;
  updatedBy: number = undefined;
  updatedOn: string = undefined;

  constructor(params?: T) {
    if (params) {
      Object.assign(this, params);
    }
  }

}
