import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { InitActionTypes, InitCompanies, InitCompaniesStart, InitCountries, InitCountriesStart, InitLanguages, InitUsers, LoadInitLanguages, LoadInitUsers, LoadUserGroupsStart, LoadUserGroupsSuccess } from '../actions/init.actions';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { IrisLanguageService } from '../../../services/utils/language.service';
import * as fromRoot from '../../../redux/reducers/index';
import { IrisUserService } from '@iris/common/services/user.service';
import { IrisCompaniesService } from '@iris/common/services/companies.service';
import { IrisCountriesService } from '@iris/common/services/countries.service';
import { IrisUserGroupsService } from '../../user-common/services/user-groups.service';
import isNil from 'lodash/isNil';

@Injectable()
export class IrisInitEffects {

  loadCompanies$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<InitCompaniesStart>(InitActionTypes.InitCompaniesStart),
    switchMap(() => {
      return this.companiesService.getAll();
    }),
    map(companies => new InitCompanies(companies)),
  ));

  loadLanguages$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadInitLanguages>(InitActionTypes.LoadInitLanguages),
    switchMap(() => {
      return this.languageService.getAll();
    }),
    map(languages => new InitLanguages(languages)),
  ));

  loadCountries$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<InitCountriesStart>(InitActionTypes.InitCountriesStart),
    switchMap(() => {
      return this.countriesService.getAll();
    }),
    map(countries => new InitCountries(countries)),
  ));

  loadUsers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadInitUsers>(InitActionTypes.LoadInitUsers),
    withLatestFrom(this.store$.pipe(select(fromRoot.getGlobalUsers))),
    switchMap(([_, oldUsers]) => {
      if (isNil(oldUsers)) {
        return this.usersService.getUsers().pipe(
          map((users: IdentUserI[]) => new InitUsers(users)),
        );
      } else {
        return of(new InitUsers(JSON.parse(JSON.stringify(oldUsers))));
      }
    }),
  ));

  loadUserGroups$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LoadUserGroupsStart>(InitActionTypes.LoadUserGroupsStart),
    switchMap(({ params }) => this.userGroupsService.getUserGroups(params)),
    map(userGroups => new LoadUserGroupsSuccess(userGroups)),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly languageService: IrisLanguageService,
    private readonly usersService: IrisUserService,
    private readonly companiesService: IrisCompaniesService,
    private readonly countriesService: IrisCountriesService,
    private readonly userGroupsService: IrisUserGroupsService,
    private readonly store$: Store<fromRoot.State>,
  ) { }
}
