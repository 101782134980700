import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { IrisProjectSelectDropdownHeaderComponent } from './dropdown-header.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatSlideToggleModule,
  ],
  declarations: [IrisProjectSelectDropdownHeaderComponent],
  exports: [IrisProjectSelectDropdownHeaderComponent],
})
export class IrisProjectSelectDropdownHeaderComponentModule { }
