import { createAction, props } from '@ngrx/store';
import { IrisResourceRoleI } from '@iris/common/models/IrisResourceRole';

export const LoadEnabledResourceRoles = createAction(
  '[ResourceRoles] Load enabled resource roles',
  props<{ resourceType: string }>(),
);

export const LoadEnabledResourceRolesComplete = createAction(
  '[ResourceRoles] Load enabled resource roles complete',
  props<{ resourceRoles: IrisResourceRoleI[]; resourceType: string }>(),
);

export const LoadEnabledProjectResourceRoles = createAction(
  '[ProjectRoles] Load enabled project resource roles',
  props<{ projectId: number }>(),
);

export const LoadEnabledProjectResourceRoleComplete = createAction(
  '[ProjectRoles] Load enabled project resource roles complete',
  props<{ projectRoles: IrisResourceRoleI[]; projectId: number }>(),
);

export const ResetResourceRolesState = createAction(
  '[ResourceRoles] Reset resource roles state',
);
