import { createAction, props } from '@ngrx/store';
import { IrisModuleAliasType } from '@iris/common/services/settings/global-settings.service';
import { IrisGlobalSettingsI } from '@iris/common/models/IrisGlobalSettings';

export const GetGlobalSettingsStart = createAction(
  '[Settings] Get Global Settings Start',
  props<{ alias: IrisModuleAliasType }>(),
);

export const GetGlobalSettingsSuccess = createAction(
  '[Settings] Get Global Settings Success',
  <T extends Record<keyof T, unknown>>(settings: IrisGlobalSettingsI<T>) => ({ settings }),
);

export const GetGlobalSettingsError = createAction(
  '[Settings] Get Global Settings Error',
);

export const UpdateGlobalSettingsStart = createAction(
  '[Settings] Update Global Settings Start',
  <T extends Record<keyof T, unknown>>(alias: IrisModuleAliasType, settings: Partial<IrisGlobalSettingsI<T>>) => ({ alias, settings }),
);

export const UpdateGlobalSettingsSuccess = createAction(
  '[Settings] Update Global Settings Success',
  <T extends Record<keyof T, unknown>>(settings: IrisGlobalSettingsI<T>) => ({ settings }),
);

export const UpdateGlobalSettingsError = createAction(
  '[Settings] Update Global Settings Error',
);
