import { NgModule } from '@angular/core';
import { IrisHeaderSearchResultForUserComponentModule } from './user/user.component.module';

const EXPORTING_MODULES = [
  IrisHeaderSearchResultForUserComponentModule,
];

@NgModule({
  imports: [
    ...EXPORTING_MODULES,
  ],
  exports: [
    ...EXPORTING_MODULES,
  ],
})
export class IrisHeaderSearchResultsCardsModule { }
