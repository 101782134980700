import { defaultAppendQueryParamsFn, IrisFilterAppendQueryParamsFn } from './append-filter-query-params-fn';

export function appendTextQueryParamsFn(fieldNames: string | string[],
  isStrict = false,
  hasTextSearchPattern = false,
  method = '',
): IrisFilterAppendQueryParamsFn {
  return defaultAppendQueryParamsFn<string>(
    fieldNames,
    text => textSearchPattern(text, hasTextSearchPattern),
    t => t.strict(isStrict).method(method),
  );
}

function textSearchPattern(value: string, hasTextSearchPattern: boolean): string[] {
  const valueConverter = (text: string) => hasTextSearchPattern ? `%${text}%` : text;
  if (Array.isArray(value)) {
    return value.reduce((acc, tA) => {
      return [...acc, valueConverter(tA)];
    }, []);
  }
  return [valueConverter(value)];
}
