import { Inject, inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MatDatetimeFormats } from '@mat-datetimepicker/core';
import { IrisTimeSandbox } from '../../time';

export interface IrisDatetimeFormats extends MatDatetimeFormats {
  parse: {
    dateInput?: string;
    monthInput?: string;
    timeInput?: string;
    datetimeInput?: string;
    longDatetimeInput?: string;
    yearInput?: string;
  };
  display: {
    dateInput: string;
    monthInput: string;
    yearInput: string;
    timeInput: string;
    datetimeInput: string;
    longDatetimeInput: string;
    monthYearLabel: string;
    dateA11yLabel: string;
    monthYearA11yLabel: string;
    popupHeaderDateLabel: string;
  };
}

export const IRIS_DATETIME_FORMATS = new InjectionToken<IrisDatetimeFormats>('IRIS mat-datetimepicker formats');

const YEAR_INPUT = 'yyyy';
const MONTH_INPUT = 'yyyy-MM';
const MONTH_YEAR_LABEL = 'MMM yyyy';
const DATE_A11Y_LABEL = 'DDD';
const MONTH_YEAR_A11Y_LABEL = 'MMMM yyyy';
const POPUP_HEADER_DATE_LABEL = 'dd LLL, EEE';

function hasSeconds(datetimeFormat: string): boolean {
  return /:ss/.test(datetimeFormat);
}

function hasMinutes(datetimeFormat: string): boolean {
  return /mm/.test(datetimeFormat);
}

function addSeconds(datetimeFormat: string): string {
  return !hasSeconds(datetimeFormat) && hasMinutes(datetimeFormat) ? datetimeFormat.replace(/mm/, 'mm:ss') : datetimeFormat;
}

function removeSeconds(datetimeFormat: string): string {
  return hasSeconds(datetimeFormat) ? datetimeFormat.replace(/:ss/, '') : datetimeFormat;
}

export function datetimeFormatsFactory(): IrisDatetimeFormats {
  const timeSandbox = inject(IrisTimeSandbox);
  const timeFormat = timeSandbox.currentUserTimeFormat;
  const dateTimeFormat = timeSandbox.currentUserDateTimeFormat;
  const dateFormat = timeSandbox.currentUserDateFormat;

  return {
    parse: {
      dateInput: dateFormat.luxonFormatString,
      datetimeInput: removeSeconds(dateTimeFormat.luxonFormatString),
      longDatetimeInput: addSeconds(dateTimeFormat.luxonFormatString),
      timeInput: timeFormat.luxonFormatString,
      monthInput: MONTH_INPUT,
      yearInput: YEAR_INPUT,
    },
    display: {
      dateInput: dateFormat.luxonFormatString,
      datetimeInput: removeSeconds(dateTimeFormat.luxonFormatString),
      longDatetimeInput: addSeconds(dateTimeFormat.luxonFormatString),
      timeInput: timeFormat.luxonFormatString,
      monthInput: MONTH_INPUT,
      yearInput: YEAR_INPUT,
      monthYearLabel: MONTH_YEAR_LABEL,
      dateA11yLabel: DATE_A11Y_LABEL,
      monthYearA11yLabel: MONTH_YEAR_A11Y_LABEL,
      popupHeaderDateLabel: POPUP_HEADER_DATE_LABEL,
    },
  };
}

@Injectable()
export class IrisDatetimeFormatsService {
  constructor(
    @Inject(IRIS_DATETIME_FORMATS) @Optional() private readonly datetimeFormats: IrisDatetimeFormats,
  ) { }

  public getDisplayFormat(type: string): string {
    switch (type) {
      case 'date':
        return this.datetimeFormats?.display.dateInput;
      case 'datetime':
        return this.datetimeFormats?.display.datetimeInput;
      case 'long-datetime':
        return this.datetimeFormats?.display.longDatetimeInput;
      case 'time':
        return this.datetimeFormats?.display.timeInput;
      case 'month':
        return this.datetimeFormats?.display.monthInput;
      case 'year':
        return this.datetimeFormats?.display.yearInput;
      default:
        return this.datetimeFormats?.display.dateInput;
    }
  }

  public getParseFormat(type: string): string {
    switch (type) {
      case 'date':
        return this.datetimeFormats?.parse.dateInput;
      case 'datetime':
        return this.datetimeFormats?.parse.datetimeInput;
      case 'long-datetime':
        return this.datetimeFormats?.parse.longDatetimeInput;
      case 'time':
        return this.datetimeFormats?.parse.timeInput;
      case 'month':
        return this.datetimeFormats?.parse.monthInput;
      case 'year':
        return this.datetimeFormats?.parse.yearInput;
      default:
        return this.datetimeFormats?.parse.dateInput;
    }
  }
}
