import { ContentChild, Directive, HostBinding, Input } from '@angular/core';
import { FieldSuffixDirective } from '@iris/common/modules/fields/text-field/field-suffix.directive';

@Directive({
  selector: '[iris-enhanced-control]',
  standalone: true,
})
export class EnhancedControlDirective {
  @HostBinding('class.iris-form-field') irisFormFieldClass = true;
  @ContentChild(FieldSuffixDirective) fieldSuffix!: FieldSuffixDirective;
  @Input() @HostBinding('class') stylingMode: 'customized' | 'underlined' | 'unformed' | 'filled' = 'underlined';

  get fieldAppearance(): string {
    switch(this.stylingMode) {
      case 'customized':
      case 'unformed':
        return 'outline';
      default:
        return 'fill';
    }
  }
}
