import { Injectable } from '@angular/core';
import { IrisUserService } from '@iris/common/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class IrisLocalStorageService {

  constructor(
    private readonly userService: IrisUserService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItem<T = any>(key: string): T {
    const item = localStorage.getItem(this.getLocalStorageKey(key));
    return item ? (JSON.parse(item) as T) : null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem<T = any>(key: string, value: T): T {
    if (value === undefined) {
      this.removeItem(key);
    } else {
      localStorage.setItem(this.getLocalStorageKey(key), JSON.stringify(value));
    }
    return value;
  }

  removeItem(key: string):void {
    localStorage.removeItem(this.getLocalStorageKey(key));
  }

  private getLocalStorageKey(key: string): string {
    const userId = this.userService.me?.id || 'anonymous';
    return `${userId}_${key}`;
  }
}
