import { signal } from '@angular/core';
import { extractPayloadFromToken } from '@iris/modules/auth/utils/extract-payload-from-token';
import { LoginResult } from '@iris/modules/auth/utils/login-result';

const ACCESS_TOKEN_KEY = 'iris_access_token';
const REFRESH_TOKEN_KEY = 'iris_refresh_token';
const ACCESS_TOKEN_EXPIRED_AT_KEY = 'iris_access_token_expired_at';
const ACCESS_TOKEN_EXPIRES_SHIFT = 450;

export class TokenStorage {
  accessTokenKey = `${ACCESS_TOKEN_KEY}_${this.instanceId}`;
  refreshTokenKey = `${REFRESH_TOKEN_KEY}_${this.instanceId}`;
  expiresAtTokenKey = `${ACCESS_TOKEN_EXPIRED_AT_KEY}_${this.instanceId}`;

  constructor(private readonly instanceId: string) { }

  get accessToken(): string {
    return localStorage.getItem(this.accessTokenKey);
  }

  private set accessToken(val: string) {
    localStorage.setItem(this.accessTokenKey, val ?? '');
  }

  get accessTokenExpiredAt(): number {
    return +localStorage.getItem(this.expiresAtTokenKey);
  }

  private set accessTokenExpiredAt(val: number) {
    localStorage.setItem(this.expiresAtTokenKey, String(val ?? 0));
  }

  get refreshToken(): string {
    return localStorage.getItem(this.refreshTokenKey);
  }

  private set refreshToken(val: string) {
    localStorage.setItem(this.refreshTokenKey, val ?? '');
  }

  public readonly azureAccessToken = signal<string>('');

  setLoginResult(loginResult: LoginResult): void {
    this.accessToken = loginResult?.accessToken;
    this.accessTokenExpiredAt = this.extractAccessTokenExpiredAt(loginResult?.accessToken);
    this.refreshToken = loginResult?.refreshToken;
    this.azureAccessToken.set(loginResult.azureAccessToken ?? this.azureAccessToken());
  }

  setAzureAccessToken(azureAccessToken: string): void {
    this.azureAccessToken.set(azureAccessToken ?? this.azureAccessToken());
  }

  accessTokenIsNotExpired(): boolean {
    if (this.accessToken && !this.accessTokenExpiredAt) {
      this.accessTokenExpiredAt = this.extractAccessTokenExpiredAt(this.accessToken);
    }
    return !!this.accessToken && !!this.accessTokenExpiredAt && (Date.now() < this.accessTokenExpiredAt);
  }

  clear(): void {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.expiresAtTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    this.azureAccessToken.set('');
  }

  private extractAccessTokenExpiredAt(accessToken: string): number {
    try {
      if (!accessToken) { return null; }
      const payload = extractPayloadFromToken<{ iat: number; exp: number }>(accessToken);
      const expiresIn = (payload.exp - payload.iat - ACCESS_TOKEN_EXPIRES_SHIFT) * 1000;
      return Date.now() + expiresIn;
    } catch (err: unknown) {
      this.clear();
      return null;
    }
  }
}
