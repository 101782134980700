import { createReducer, Action, on } from '@ngrx/store';
import { IrisResourceRoleI } from '@iris/common/models/IrisResourceRole';
import {
  LoadEnabledProjectResourceRoleComplete,
  LoadEnabledProjectResourceRoles,
  LoadEnabledResourceRoles,
  LoadEnabledResourceRolesComplete,
  ResetResourceRolesState,
} from '../actions/resource-roles.actions';

export interface State {
  [key: string]: IrisResourceRoleI[];
}

const initialState: State = {
};

const ResourceRolesReducer = createReducer(
  initialState,
  on(LoadEnabledResourceRoles, (state, action) => ({
    ...state,
    [action.resourceType]: [],
  })),
  on(LoadEnabledResourceRolesComplete, (state, action) => ({
    ...state,
    [action.resourceType]: action.resourceRoles,
  })),
  on(LoadEnabledProjectResourceRoles, (state, action) => ({
    ...state,
    [action.projectId]: [],
  })),
  on(LoadEnabledProjectResourceRoleComplete, (state, action) => ({
    ...state,
    [action.projectId]: action.projectRoles,
  })),
  on(ResetResourceRolesState, () => initialState),
);

export function reducer(state: State, action: Action) {
  return ResourceRolesReducer(state, action);
}

export const getEnabledResourceRoles = (state: State, resourceType: string): IrisResourceRoleI[] => {
  return state[resourceType];
};

export const getEnabledProjectResourceRoles = (state: State, projectId: number): IrisResourceRoleI[] => {
  return state[projectId];
};
