import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IrisClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { IrisDisableControlDirective } from './directives/disable-control.directive';
import { IrisDragAndDropDirective } from './directives/drag-and-drop.directive';
import { IrisClickDisabledDirective } from './directives/click-disabled.directive';
import { IrisDraggableDirective } from './directives/iris-draggable.directive';
import { AutoScrollDirective } from './directives/auto-scroll.directive';
import { IrisLoadingButtonDirective } from '@iris/common/modules/directives-common/directives/iris-loading-button.directive';
import { IrisZoomDirective } from '@iris/common/modules/directives-common/directives/zoom.directive';
import { IrisRequiredMarkerDirective } from '@iris/common/modules/directives-common/directives/required-marker.directive';
import { IrisAutoFocusDirective } from './directives/iris-auto-focus.directive';
import { NgTemplateNameDirective } from '@iris/common/modules/directives-common/directives/ng-template-name.directive';
import { IrisClickOutsideDirective } from './directives/click-outside.directive';
import { IrisFormValidDirective } from './directives/iris-form-valid.directive';
import { ConfirmByKeypressDirective } from '@iris/common/modules/directives-common/directives/confirm-by-keypress.directive';
import { ScrollOnMeDirective } from '@iris/common/modules/directives-common/directives/scroll-on-me.directive';
import { IrisInputValidatorDirective } from './directives/validators/input-validator.directive';
import { ResizeObserverDirective } from './directives/resize-observer.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    IrisClickStopPropagationDirective,
    IrisDisableControlDirective,
    IrisDragAndDropDirective,
    IrisDraggableDirective,
    IrisClickDisabledDirective,
    AutoScrollDirective,
    IrisLoadingButtonDirective,
    IrisZoomDirective,
    IrisRequiredMarkerDirective,
    IrisAutoFocusDirective,
    NgTemplateNameDirective,
    IrisClickOutsideDirective,
    IrisFormValidDirective,
    ConfirmByKeypressDirective,
    ScrollOnMeDirective,
    IrisInputValidatorDirective,
    ResizeObserverDirective,
  ],
  exports: [
    IrisClickStopPropagationDirective,
    IrisDisableControlDirective,
    IrisDragAndDropDirective,
    IrisDraggableDirective,
    IrisClickDisabledDirective,
    AutoScrollDirective,
    IrisLoadingButtonDirective,
    IrisZoomDirective,
    IrisRequiredMarkerDirective,
    IrisAutoFocusDirective,
    NgTemplateNameDirective,
    IrisClickOutsideDirective,
    IrisFormValidDirective,
    ConfirmByKeypressDirective,
    ScrollOnMeDirective,
    IrisInputValidatorDirective,
    ResizeObserverDirective,
  ],
})
export class IrisCommonDirectivesModule {
}
