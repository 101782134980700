/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */

import {
  DoCheck,
  Input,
  OnInit,
  Directive,
  ViewChild,
  ElementRef,
  OnDestroy, HostBinding, ChangeDetectorRef,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, RequiredValidator, ValidatorFn } from '@angular/forms';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isEqual } from 'lodash';

@Directive()
export class IrisFieldBaseComponent implements OnInit, DoCheck, ControlValueAccessor, OnDestroy {
  @Input() label = '';
  @Input() hint = '';
  @Input() hintRight = '';
  @Input() hintPrefix = '';
  @Input() @HostBinding('class.readonly') readonly = false;
  @Input() valueChanger: <T, U>(value: T) => U;
  @Input() floatLabel: 'always' | 'never' | 'auto' = 'auto';
  @Input() emitEventOnSetValue = true;

  @ViewChild('input') input: ElementRef;
  @HostBinding('class') classList = 'iris-form-field';

  valueControl: FormControl = new FormControl();
  control: AbstractControl;
  validators: ValidatorFn[] = [];
  isRequired;
  newRequiredValue = new Subject();
  propagateChange = (_: any) => {};
  propagateBlur = (_: any) => {};

  unsubscribe$: Subject<void> = new Subject();

  constructor(
    validators: Array<any> = [],
    formControl?: FormControl,
    changeDetector?: ChangeDetectorRef,
  ) {
    this.setValidators(validators);

    formControl?.statusChanges.pipe(
      tap(() => this.setValidators(formControl.validator ? [formControl.validator] : [])),
      tap(() => changeDetector?.markForCheck()),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  protected setValidators(validators: Array<any> = []): void {
    this.valueControl.setValidators(validators);
    this.validators = validators;
  }

  get isFieldRequired(): boolean {
    // cast boolean value. Otherwise, it can return undefined and emits value
    return !!(this.isRequired || this.valueControl?.validator?.({} as AbstractControl)?.required);
  }

  ngOnInit(): void {
    this.valueControl.valueChanges.pipe(
      distinctUntilChanged(isEqual),
      tap((newValue) => this.propagateChange(this.valueChanger ? this.valueChanger(newValue) : newValue)),
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  focus(): void {
    this.input.nativeElement.focus();
  }

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.emitEventOnSetValue ? this.valueControl.setValue(obj) : this.valueControl.setValue(obj, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateBlur = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled && this.valueControl.enabled) { this.valueControl.disable(); }
    if (!isDisabled && this.valueControl.disabled) { this.valueControl.enable(); }
  }

  isHintsDisplayed(): boolean {
    return !!this.hint || !!this.hintRight;
  }

  ngDoCheck(): void {
    if (isArray(this.validators)) {
      const reqValidator: any = this.validators.find((val) => val.constructor === RequiredValidator);
      if (!isNil(reqValidator)) {
        this.isRequired = reqValidator.required;
        this.newRequiredValue.next(this.isRequired);
      }
    }
  }

  onBlurEvent(): void {
    this.propagateBlur(this.valueControl.value);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected correctNewValue(value: any): any {
    return value;
  }
}
