<ng-template #template>
  <div class="d-flex justify-content-center">
    <span *ngIf="(context$ | async) as context">
      {{ 'label.autocomplete.ShowingNumOfTotal' | translate: {
      'found': context.shownCount,
      'total': context.totalCount || ('label.autocomplete.OfAll' | translate)
      } }}
    </span>
  </div>
</ng-template>
