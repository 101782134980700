import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map, Observable, shareReplay, switchMap, withLatestFrom } from 'rxjs';
import { EmailWellKnownFolderName, IrisEmailNavigationI } from '../../models/IrisEmailNavigation';
import { CollapseNavigationFolderStart, GetChildrenFoldersStart, GetCustomFoldersStart, GetCustomFolderStart } from './emails-navigation.actions';
import * as emailsReducer from '../index';

@Injectable()
export class IrisEmailsNavigationSandbox {
  navigationFolders$ = this.store.pipe(
    select(emailsReducer.getNavigationFolders),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  inboxNavigationItemId$ = this.getFolderIdByShortcut$(EmailWellKnownFolderName.Inbox).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  inboxNavigationItem$ = this.inboxNavigationItemId$.pipe(
    filter(Boolean),
    switchMap(inboxFolderId => this.store.pipe(select(emailsReducer.getNavigationFolder(inboxFolderId)))),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  draftNavigationItemId$ = this.getFolderIdByShortcut$(EmailWellKnownFolderName.Drafts).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  sentNavigationItemId$ = this.getFolderIdByShortcut$(EmailWellKnownFolderName.SentItems).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  deletedNavigationItemId$ = this.getFolderIdByShortcut$(EmailWellKnownFolderName.DeletedItems).pipe(
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  isDeletedFolderSelected$ = this.store.pipe(
    select(emailsReducer.getSelectedMenuItemId),
    withLatestFrom(this.deletedNavigationItemId$),
    map(([selectedNavigationId, deletedNavigationItemId]) => selectedNavigationId === deletedNavigationItemId),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  sortedNavigationFolders$ = this.store.pipe(
    select(emailsReducer.buildSortedVisibleFlatNavigationTree),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
  
  childrenFolders$ = (parentFolderId: string): Observable<IrisEmailNavigationI[]> =>
    this.store.pipe(select(emailsReducer.getNavigationChildrenFolders(parentFolderId)));

  constructor(
    private readonly store: Store<emailsReducer.EmailsCommonState>,
  ) { }

  getCustomFolders(): void {
    this.store.dispatch(GetCustomFoldersStart());
  }
  
  getChildrenFolders(parentFolderId: string): void {
    this.store.dispatch(GetChildrenFoldersStart({ parentFolderId }));
  }

  getFolderIdByShortcut$(folderShortcut: EmailWellKnownFolderName): Observable<string> {
    return this.store.pipe(select(emailsReducer.getFolderByShortcut(folderShortcut)));
  }

  fetchCustomFolder(folderId: string | EmailWellKnownFolderName): void {
    this.store.dispatch(GetCustomFolderStart({ folderId }));
  }

  fetchInboxFolder(): void {
    this.fetchCustomFolder(EmailWellKnownFolderName.Inbox);
  }

  collapseFolder(folderId: string): void {
    this.store.dispatch(CollapseNavigationFolderStart({ folderId }));
  }
}
