import { IrisProjectTypeI } from '@iris/common/models/irisProjectType';
import * as ProjectActions from '@iris/common/redux/actions/projects.actions';
import { IrisDepartmentWithParentsI } from '@iris/common/services/department.service';
import { Action, createReducer, on } from '@ngrx/store';
import { IrisProjectI } from '@iris/common/models/IrisProject';

function buildPath(department: IrisDepartmentWithParentsI, path?: string): string {
  if (department) {
    path = path || department.name;
    if (department.parentDepartmentInfo) {
      path = `${department.parentDepartmentInfo.name} > ${path}`;
      return buildPath(department.parentDepartmentInfo, path);
    }
    return path;
  }
  return null;
}

export interface State {
  projectTypes: IrisProjectTypeI[];
  departmentPaths: Record<string, string>;
  recentProjectsIds: number[];
  recentProjects: IrisProjectI[];
  currentProject: IrisProjectI;
  currentProjectId: number;
}

const initialState: State = {
  projectTypes: [],
  departmentPaths: {},
  recentProjectsIds: [],
  recentProjects: [],
  currentProject: null,
  currentProjectId: null,
};

const globalProjectsReducer = createReducer(
  initialState,
  on(ProjectActions.loadProjectTypes, (state, _action) => ({
    ...state,
    projectTypes: [],
  })),
  on(ProjectActions.loadProjectTypesComplete, (state, action) => ({
    ...state,
    projectTypes: action.projectTypes,
  })),
  on(ProjectActions.loadDepartmentPathsComplete, (state, action) => {
    const departmentPaths = { ...state.departmentPaths };
    if (Array.isArray(action.departments)) {
      for (const department of action.departments) {
        const path = buildPath(department);
        departmentPaths[department.id] = path;
      }
    }

    return {
      ...state,
      departmentPaths,
    };
  }),
  on(ProjectActions.setRecentProjectsIds, (state, action) => ({
    ...state,
    recentProjectsIds: action.projectsIds,
  })),
  on(ProjectActions.setRecentProjects, (state, action) => ({
    ...state,
    recentProjects: action.projects,
  })),
  on(ProjectActions.changeCurrentProjectId, (state, { projectId }) => ({
    ...state,
    currentProjectId: projectId,
  })),
  on(ProjectActions.setCurrentProject, (state, action) => ({
    ...state,
    currentProject: action.project,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return globalProjectsReducer(state, action);
}

export const getProjectTypes = (state: State): IrisProjectTypeI[] => state.projectTypes;
export const getDepartmentPaths = (state: State): Record<string, string> => state.departmentPaths;
export const getRecentProjectsIds = (state: State): number[] => state.recentProjectsIds;
export const getRecentProjects = (state: State): IrisProjectI[] => state.recentProjects;
export const getCurrentProjectId = (state: State): number => state.currentProjectId;
export const getCurrentProject = (state: State): IrisProjectI => state.currentProject;
export const getLastProjectIdFormRecent = (state: State): number => state.recentProjectsIds[0] || null;
