import { inject, InjectionToken, Provider, StaticProvider, Type } from '@angular/core';
import { ValidationMessageResolver } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver';
import { ValidationMessageResolverService } from '@iris/common/modules/fields/validation/validation-message-resolver/validation-message-resolver.service';
import { v4 as uuid } from 'uuid';

const VALIDATION_MESSAGE_RESOLVER = new InjectionToken<ValidationMessageResolver>('Validation message resolver');
export const VALIDATION_MESSAGE_RESOLVER_LIST = new InjectionToken<ValidationMessageResolver[]>('Validation message resolver list');

function validationMessageResolverListFactory(): ValidationMessageResolver[] {
  const resolvers = inject<ValidationMessageResolver[]>(VALIDATION_MESSAGE_RESOLVER);
  const resolverList = inject(VALIDATION_MESSAGE_RESOLVER_LIST, { optional: true, skipSelf: true });
  return [...resolvers, ...(resolverList ?? []).filter(x => !resolvers.includes(x))];
}

// Static Provider

function provideValidationMessageResolversStatic(...args: Type<ValidationMessageResolver>[]): StaticProvider[] {
  const resolverProviders = args?.reduce((res, resolver) => {
    const uniqueToken = uuid();
    return [
      ...res,
      { provide: uniqueToken, useClass: resolver },
      { provide: VALIDATION_MESSAGE_RESOLVER, useExisting: uniqueToken, multi: true },
    ];
  }, []) ?? [];
  return resolverProviders.length ? [
    ...resolverProviders,
    {
      provide: VALIDATION_MESSAGE_RESOLVER_LIST,
      useFactory: validationMessageResolverListFactory,
    },
  ] : [];
}

export function applyValidationMessageResolversStatic(...args: Type<ValidationMessageResolver>[]): StaticProvider[] {
  return [
    ...provideValidationMessageResolversStatic(...args),
    { provide: ValidationMessageResolverService, useClass: ValidationMessageResolverService },
  ];
}

// Reflective Provider

function provideValidationMessageResolvers(...args: Type<ValidationMessageResolver>[]): Provider[] {
  const resolverProviders = args?.map(resolver => (
    { provide: VALIDATION_MESSAGE_RESOLVER, useClass: resolver, multi: true }
  )) ?? [];
  return resolverProviders.length ? [
    ...resolverProviders,
    {
      provide: VALIDATION_MESSAGE_RESOLVER_LIST,
      useFactory: validationMessageResolverListFactory,
    },
  ] : [];
}

export function applyValidationMessageResolvers(...args: Type<ValidationMessageResolver>[]): Provider[] {
  return [
    ...provideValidationMessageResolvers(...args),
    ValidationMessageResolverService,
  ];
}
