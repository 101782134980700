import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IrisQueryParams, IrisQueryParamsBuilder } from '@iris/api-query';
import { map, Observable } from 'rxjs';
import { IrisUserGroupI } from '../models/IrisUserGroup';
import { ApiUrl, IrisApiClient } from '@iris/api-query';
import { IrisPage } from '@iris/common/models/page';
import { UserGroupPayloadToCopyI } from '@iris/modules/system-configuration/system-configuration-list/security/user-groups-page/models';

@Injectable({
  providedIn: 'root',
})
@ApiUrl('/security/user-groups')
export class IrisUserGroupsService extends IrisApiClient<IrisUserGroupI> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  newUserGroup(userGroup: IrisUserGroupI): Observable<IrisUserGroupI> {
    return this.httpClient.post<IrisUserGroupI>(this.url(), userGroup);
  }

  @ApiUrl('~/count')
  getUserGroupsCount(params: IrisQueryParams = new IrisQueryParams()): Observable<number> {
    return this.httpClient.get<{ count: number }>(this.url(), { params: params.toObject() }).pipe(map((result) => result.count));
  }

  @ApiUrl('~/copying')
  copyUserGroup(payload: Partial<UserGroupPayloadToCopyI>): Observable<IrisUserGroupI> {
    return this.httpClient.post<IrisUserGroupI>(this.url(), payload);
  }

  @ApiUrl('~/{usergroupId}/users/info')
  getUsersOfUsergroup(usergroupId: number, params: IrisQueryParams = new IrisQueryParams()): Observable<IdentUserI[]> {
    const url = this.url({ usergroupId });
    return this.httpClient.get<IdentUserI[]>(url, { params: params.toObject() });
  }

  @ApiUrl('~/{usergroupId}/users/v2')
  getUsersOfUserGroupPage(usergroupId: number, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisPage<IdentUserI>> {
    return this.httpClient.get<IrisPage<IdentUserI>>(this.url({ usergroupId }), { params: params.toObject() });
  }

  getUserGroups(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisUserGroupI[]> {
    return this.httpClient.get<IrisUserGroupI[]>(this.url(), { params: params.toObject() });
  }

  @ApiUrl('~/page')
  getUserGroupsPage(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisPage<IrisUserGroupI>> {
    return this.httpClient.get<IrisPage<IrisUserGroupI>>(this.url(), { params: params.toObject() });
  }

  @ApiUrl('~/{userGroupId}')
  getById(userGroupId: number, params: IrisQueryParams = new IrisQueryParams()): Observable<IrisUserGroupI> {
    return this.httpClient.get<IrisUserGroupI>(this.url({ userGroupId }), { params: params.toObject() });
  }

  @ApiUrl('~/{userGroupId}')
  updateUserGroups(userGroupId: number, entity: unknown): Observable<IrisUserGroupI> {
    return this.httpClient.post<IrisUserGroupI>(this.url({ userGroupId }), entity);
  }

  @ApiUrl('~/{userGroupId}')
  removeUserGroup(userGroupId: number): Observable<IrisUserGroupI> {
    return this.httpClient.delete<IrisUserGroupI>(this.url({ userGroupId }));
  }

  @ApiUrl('~/{userGroupId}/permissions')
  updatePermission(userGroupId: number, permission: unknown): Observable<IrisUserGroupI> {
    return this.httpClient.post<IrisUserGroupI>(this.url({ userGroupId }), permission);
  }

  @ApiUrl('~/{userGroupId}/permissions/list')
  updatePermissionsList(userGroupId: number, permission: unknown[]): Observable<IrisUserGroupI> {
    return this.httpClient.post<IrisUserGroupI>(this.url({ userGroupId }), permission);
  }

  @ApiUrl('~/{userGroupId}/permissions/{permissionId}')
  removePermission(userGroupId: number, permissionId: number): Observable<IrisUserGroupI> {
    return this.httpClient.delete<IrisUserGroupI>(this.url({ userGroupId, permissionId }));
  }

  @ApiUrl('~/{userGroupId}/permissions/delete-list')
  removePermissionList(userGroupId: number, permissions: unknown[]): Observable<IrisUserGroupI> {
    return this.httpClient.post<IrisUserGroupI>(this.url({ userGroupId }), permissions);
  }

  @ApiUrl('~/{groupId}/users/{userId}')
  addUserToGroup(groupId: number, userId: number): Observable<IrisUserGroupI> {
    return this.httpClient.post<IrisUserGroupI>(this.url({ groupId, userId }), { id: groupId, userId });
  }

  @ApiUrl('~/{groupId}/users/{userId}')
  removeUserFromGroup(groupId: number, userId: number): Observable<IrisUserGroupI> {
    return this.httpClient.delete<IrisUserGroupI>(this.url({ groupId, userId }));
  }

  @ApiUrl('/security/users/{userId}/user-groups')
  public getUserUserGroups(userId: number): Observable<IrisUserGroupI[]> {
    return this.httpClient.get<IrisUserGroupI[]>(this.url({ userId }));
  }

  isUserInTheGroup(userGroupId: number, userId: number): Observable<boolean> {
    const params = new IrisQueryParamsBuilder()
      .limit(1)
      .onlyFields(['username'])
      .filter('id', [userId])
      .toStructure();

    return this.getUsersOfUsergroup(userGroupId, params).pipe(
      map(users => !!users.length),
    );
  }
}
