import { DfColumnKey } from '@iris/common/modules/dynamic-forms/df-list/enums';
import { DATA_PATH_PREFIX } from '@iris/common/modules/dynamic-forms/utils/df-path.utils';

export function isDataColumn(columnId: string): boolean {
  return columnId.startsWith(DATA_PATH_PREFIX);
}

export function isWorkflowStateColumn(columnId: string): boolean {
  return columnId === DfColumnKey.workflowState;
}
