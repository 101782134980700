import { Injectable } from '@angular/core';
import { ApiUrl, IrisApiClientCore } from '@iris/api-query';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IrisQueryParams } from '@iris/api-query';
import { IrisModuleDescriptorsI, IrisModuleI, IrisModulesI } from '@iris/common/models/IrisModules';
import { IrisPage } from '@iris/common/models/page';

export type PagesPage = IrisPage<IrisModuleI>;

@Injectable({ providedIn: 'root' })
@ApiUrl('/system/modules')
export class IrisAdminModulesService extends IrisApiClientCore {

  constructor(private readonly httpClient: HttpClient) { super(); }

  getModulesEnabledForUser(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisModuleDescriptorsI[]> {
    return this.httpClient.get<IrisModuleDescriptorsI[]>(this.url(), { params: params.toObject() });
  }

  @ApiUrl('~/all')
  getAllAdminModules(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisModulesI[]> {
    return this.httpClient.get<IrisModulesI[]>(this.url(), { params: params.toObject() });
  }

  @ApiUrl('~/all/{moduleCode}/toggle-enabled')
  toggleEnabled(moduleCode: string): Observable<IrisModuleI> {
    return this.httpClient.post<IrisModuleI>(this.url({ moduleCode }), { code: moduleCode });
  }

  @ApiUrl('~/{moduleCode}/module')
  getModuleByModuleCode(moduleCode: string): Observable<IrisModuleI> {
    return this.httpClient.get<IrisModuleI>(this.url({ moduleCode: moduleCode.toUpperCase() }));
  }

  @ApiUrl('~/{moduleCode}')
  updateModuleByModuleCode(moduleCode: string, item: IrisModuleI): Observable<IrisModuleI> {
    return this.httpClient.post<IrisModuleI>(this.url({ moduleCode }), item);
  }

  @ApiUrl('~/all/{type}/page')
  getByTypeWithCount(type: string, params: IrisQueryParams = new IrisQueryParams()): Observable<PagesPage> {
    return this.httpClient.get<PagesPage>(this.url({ type }), { params: params.toObject() });
  }

  @ApiUrl('~/descriptors/all')
  getModulesDescriptors(params: IrisQueryParams = new IrisQueryParams()): Observable<IrisModuleDescriptorsI[]> {
    return this.httpClient.get<IrisModuleDescriptorsI[]>(this.url(), { params: params.toObject() });
  }

  @ApiUrl('~/{code}')
  saveModule(page: Partial<IrisModuleI>): Observable<IrisModuleI> {
    const code = page?.id ? page.code : null;
    return this.httpClient.post<IrisModuleI>(this.url({ code }), page);
  }

  @ApiUrl('~/{code}')
  deleteModule(code: string): Observable<void> {
    return this.httpClient.delete<void>(this.url({ code }));
  }
}
