/* eslint-disable @typescript-eslint/no-explicit-any */
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface IrisApiClientConfig {
  apiUrl: string;
  serverUrl: string;
  actualToken?: () => Observable<string>;
  urlParams?: { [t: string]: any };
}

export const IRIS_API_CLIENT_CONFIG_TOKEN = new InjectionToken<IrisApiClientConfig>('Configuration object for IrisApiService logic');
