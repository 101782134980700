import { IrisQueryParamsBuilder, IrisQueryParamsFilterBuilder } from '@iris/api-query';
import { IrisUserInfoI } from '@iris/common/modules/user-common/models/IrisUserInfo';

export type IrisFilterAppendQueryParamsContext = { timezone: string; currentUser: IrisUserInfoI };

export type IrisFilterAppendQueryParamsFn<TValue = unknown> = (builder: IrisQueryParamsBuilder, value: TValue, context?: IrisFilterAppendQueryParamsContext) => IrisQueryParamsBuilder;

export function defaultAppendQueryParamsFn<TValue>(
  fieldNames: string | string[],
  valueConverter: (value: TValue, context?: IrisFilterAppendQueryParamsContext) => unknown[],
  builderFn?: (t: IrisQueryParamsFilterBuilder) => IrisQueryParamsFilterBuilder,
): IrisFilterAppendQueryParamsFn {
  if (Array.isArray(fieldNames)) {
    return (builder: IrisQueryParamsBuilder, value: TValue, context: IrisFilterAppendQueryParamsContext) =>
      builder.filterOr(
        fieldNames.map(fieldName =>
          or => or.filter(fieldName, valueConverter(value, context), builderFn)),
      );
  }
  else {
    return (builder: IrisQueryParamsBuilder, value: TValue, context: IrisFilterAppendQueryParamsContext) =>
      builder.filter(fieldNames, valueConverter(value, context), builderFn);
  }
}
