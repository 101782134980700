/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { API_ENV } from '@iris/common/tokens';
import { AppModule } from 'app/app.module';
import { createEnvironment } from 'create-environment.util';
import { environment } from 'environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch(environment.configURL)
  .then(response => response.json())
  .then(createEnvironment)
  .then((env: IrisEnv) => platformBrowserDynamic([{ provide: API_ENV, useValue: env }]).bootstrapModule(AppModule))
  .catch(err => console.error(err));

// set specific class for print mode
const urlParams = new URLSearchParams(window.location.search);
const printParam = urlParams.get('print');
if (printParam) {
  const body = document.querySelector('body');
  body.classList.add('print-mode');
}

// polyfill for printing
window['irisPrintingStack'] = {
  isEmpty: () => true,
};
