import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IrisProjectSelectDropdownItemComponent } from './dropdown-item.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [IrisProjectSelectDropdownItemComponent],
  exports: [IrisProjectSelectDropdownItemComponent],
})
export class IrisProjectSelectDropdownItemComponentModule { }
