<div class="mat-datetimepicker-calendar-header" [class.show-seconds]="showSeconds" *ngIf="type !== 'year'">
  <div (click)="_yearClicked()"
       *ngIf="type !== 'time'"
       [class.active]="currentView === 'year' || currentView === 'multi-year'"
       class="mat-datetimepicker-calendar-header-year"
       role="button">{{ _yearLabel }}
  </div>
  <div class="mat-datetimepicker-calendar-header-date-time">
    <span (click)="_dateClicked()" *ngIf="type !== 'time'"
          [class.active]="currentView === 'month'"
          [class.not-clickable]="type === 'month'"
          class="mat-datetimepicker-calendar-header-date"
          role="button">{{ _dateLabel }}</span>

    <span *ngIf="type.endsWith('time')"
          class="mat-datetimepicker-calendar-header-time">
      <span *ngIf="twelvehour"
            class="mat-datetimepicker-calendar-header-ampm-container">
        <span (click)="_ampmClicked('AM')"
              [class.active]="_AMPM === 'AM'"
              class="mat-datetimepicker-calendar-header-ampm">AM</span>/<span (click)="_ampmClicked('PM')"
                                                                              [class.active]="_AMPM === 'PM'"
                                                                              class="mat-datetimepicker-calendar-header-ampm">PM</span>
      </span>
    </span>

    <div *ngIf="type.endsWith('time')"
          class="iris-datetime-time-selector">
      <div class="hours-selector">
        <button mat-icon-button
                class="btn-plus"
                (click)="_addHours(1)">
          <i class="icon fal fa-chevron-up"></i>
        </button>

        <input type="text"
               [inputAllowedCharacters]="'[0-9]'"
               [selectTextOnFocus]="true"
               [(ngModel)]="_activeHours"
               (ngModelChange)="_updateHours($event)"
               (wheel)="_scrollHours($event)"
               tabindex="1">

        <button mat-icon-button
                class="btn-minus"
                (click)="_addHours(-1)">
          <i class="icon fal fa-chevron-down"></i>
        </button>
      </div>

      <div class="time-separator"></div>

      <div class="minutes-selector">
        <button mat-icon-button
                class="btn-plus"
                (click)="_addMinutes(1)">
          <i class="icon fal fa-chevron-up"></i>
        </button>

        <input type="text"
               [inputAllowedCharacters]="'[0-9]'"
               [selectTextOnFocus]="true"
               [(ngModel)]="_activeMinutes"
               (ngModelChange)="_updateMinutes($event)"
               (wheel)="_scrollMinutes($event)"
               tabindex="2">

        <button mat-icon-button
                class="btn-minus"
                (click)="_addMinutes(-1)">
          <i class="icon fal fa-chevron-down"></i>
        </button>
      </div>

      <div class="time-separator" *ngIf="showSeconds"></div>

      <div class="seconds-selector" *ngIf="showSeconds">
        <button mat-icon-button
                class="btn-plus"
                (click)="_addSeconds(1)">
          <i class="icon fal fa-chevron-up"></i>
        </button>

        <input type="text"
               [inputAllowedCharacters]="'[0-9]'"
               [selectTextOnFocus]="true"
               [(ngModel)]="_activeSeconds"
               (ngModelChange)="_updateSeconds($event)"
               (wheel)="_scrollSeconds($event)"
               tabindex="3">

        <button mat-icon-button
                class="btn-minus"
                (click)="_addSeconds(-1)">
          <i class="icon fal fa-chevron-down"></i>
        </button>
      </div>
    </div>

    <div *ngIf="type.endsWith('time') && timezone"
         class="iris-datetime-timezone-info text-ellipsis" [matTooltip]="('label.TimeZone' | translate) + ': ' + timezone">
      {{ timezone }}
    </div>
  </div>
</div>
<div [ngSwitch]="currentView" class="mat-datetimepicker-calendar-content">
  <div *ngIf="currentView === 'month' || currentView === 'year' || currentView === 'multi-year'"
       class="mat-month-content">
    <div class="flex-row justify-content-between">
      <button mat-icon-button
              data-test="previous-month-button"
              (click)="_previousClicked()"
              [attr.aria-disabled]="!_previousEnabled()"
              [attr.aria-label]="_ariaLabelPrev"
              [class.disabled]="!_previousEnabled()">
        <i class="icon fal fa-chevron-left"></i>
      </button>

      <div (@slideCalendar.done)="_calendarStateDone()" [@slideCalendar]="_calendarState">

        <button mat-button
                [attr.data-test]="currentView === 'year' ? 'back-to-month-button' : currentView === 'multi-year' ? 'back-to-years-button' : ''"
                *ngIf="currentView !== 'month'"
                (click)="currentView === 'multi-year' ? _dateClicked() : currentView === 'year' ? _yearClicked() : null">
          <strong>{{ _monthYearLabel }}</strong>
        </button>

        <button mat-button
                data-test="month-selector-button"
                *ngIf="currentView === 'month'" [matMenuTriggerFor]="listOfMonths">
          <strong>{{ _monthYearLabel }}</strong>
        </button>
        <mat-menu #listOfMonths="matMenu">
          <button mat-menu-item
                  *ngFor="let monthName of monthNames; let numOfMonth = index"
                  (click)="_toggleMonth(numOfMonth + 1)"
                  [disabled]="_currentMonth == (numOfMonth + 1) || !_shouldEnableMonth(numOfMonth + 1)"
                  [attr.aria-disabled]="_currentMonth == (numOfMonth + 1) || !_shouldEnableMonth(numOfMonth + 1)">{{ monthName }}</button>
        </mat-menu>

        <button mat-button
                data-test="year-selector-button"
                (click)="_yearClicked()" *ngIf="currentView === 'month'">
          <strong>{{ _yearLabel }}</strong>
        </button>
      </div>

      <button mat-icon-button
              data-test="next-month-button"
              (click)="_nextClicked()"
              [attr.aria-disabled]="!_nextEnabled()"
              [attr.aria-label]="_ariaLabelNext"
              [class.disabled]="!_nextEnabled()">
        <i class="icon fal fa-chevron-right"></i>
      </button>
    </div>
  </div>

  <div class="iris-datetime-calendar-body">
    <mat-datetimepicker-month-view-extended (_userSelection)="_userSelected()"
                                            (selectedChange)="_dateSelected($event)"
                                            *ngSwitchCase="'month'"
                                            [activeDate]="_activeDate"
                                            [dateFilter]="_dateFilterForViews"
                                            [selected]="selected"
                                            [type]="type"
                                            [showWeekNumbers]="showWeekNumbers"
                                            [timezone]="timezone">
    </mat-datetimepicker-month-view-extended>

    <mat-datetimepicker-year-view-extended (_userSelection)="_userSelected()"
                                           (selectedChange)="_monthSelected($event)"
                                           *ngSwitchCase="'year'"
                                           [activeDate]="_activeDate"
                                           [dateFilter]="_dateFilterForViews"
                                           [selected]="selected"
                                           [type]="type"
                                           [timezone]="timezone">
    </mat-datetimepicker-year-view-extended>
    <mat-datetimepicker-multi-year-view-extended (_userSelection)="_userSelected()"
                                                 (selectedChange)="_yearSelected($event)"
                                                 *ngSwitchCase="'multi-year'"
                                                 [activeDate]="_activeDate"
                                                 [dateFilter]="_dateFilterForViews"
                                                 [maxDate]="maxDate"
                                                 [minDate]="minDate"
                                                 [selected]="selected"
                                                 [type]="type"
                                                 [timezone]="timezone">
    </mat-datetimepicker-multi-year-view-extended>
  </div>

  <mat-toolbar class="iris-datetime-calendar-footer">
    <button mat-button
            color="primary"
            *ngIf="currentView !== 'month' && type !== 'year'"
            (click)="currentView === 'multi-year' ? _dateClicked() : currentView === 'year' ? _yearClicked() : null">
      <i class="fal fa-arrow-left"></i>
      {{ 'label.Back' | translate }}
    </button>

    <button mat-button (click)="_today()" *ngIf="(type !== 'year') && (type !== 'month')">
      {{ 'label.Today' | translate }}
    </button>

    <span class="spacer"></span>

    <button mat-button
            color="accent"
            (click)="_apply()"
            *ngIf="type.endsWith('time')">
      {{ 'label.Apply' | translate }}
    </button>
  </mat-toolbar>
</div>
