/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ModuleFilterState } from '@iris/common/modules/module-filter/store/module.filter.reducer';
import * as fromModuleFilter from '@iris/common/modules/module-filter/store/module.filter.reducer';
import { cloneResult } from '@iris/common/utils/ngrx.utils';
import {
  ModuleFilterActionTypes,
  applyDefaultFilter,
  applyFilter,
  applyFloatingFilter,
  fetchUserFilters,
  initRecentlyFilters,
  refreshRecentlyFilters,
  removeFilter,
  saveFilter,
  setFilter, setFilterValidatorFn,
  setFilterVisibility,
  setMenuVisibility,
  setModuleName,
  setPropertiesMeta,
  pinFilter,
} from '@iris/common/modules/module-filter/store/module-filter.actions';
import { FilterValidatorFnI } from '@iris/common/modules/module-filter/models/interfaces/filter-validator-fn.interface';
import { IrisModuleFilterService } from '@iris/common/modules/module-filter/services/module-filter.service';
import { map, withLatestFrom, filter } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class IrisModuleFilterSandbox {
  constructor(
    private readonly store: Store<ModuleFilterState>,
    private readonly moduleFilterService: IrisModuleFilterService,
    private readonly actions$: Actions,
  ) {}

  filter$ = this.store.pipe(select(fromModuleFilter.getModuleFilter));
  propertiesMeta$ = this.store.pipe(select(fromModuleFilter.getPropertiesMeta), cloneResult());
  filterDirty$ = this.store.pipe(select(fromModuleFilter.getModuleFilterDirty), cloneResult());
  floatingFilter$ = this.store.pipe(select(fromModuleFilter.getModuleFloatingFilter), cloneResult());
  floatingFilterDirty$ = this.store.pipe(select(fromModuleFilter.getModuleFloatingFilterDirty), cloneResult());

  moduleName$ = this.store.pipe(select(fromModuleFilter.getModuleName));
  isModuleVisible$ = this.store.pipe(select(fromModuleFilter.getModuleVisibility));
  isMenuVisible$ = this.store.pipe(select(fromModuleFilter.getMenuVisibility));
  validatorFn$ = this.store.pipe(select(fromModuleFilter.getModuleFilterValidatorFn));
  userFilters$ = this.store.pipe(select(fromModuleFilter.getModuleFilterUserFilters));
  pinnedUserFilters$ = this.store.pipe(select(fromModuleFilter.getPinnedUserFilters));
  recentFilters$ = this.store.pipe(select(fromModuleFilter.getModuleFilterRecentlyFilters));

  filterApplied$ = this.actions$.pipe(
    ofType(
      applyFilter,
      applyFloatingFilter,
    ),
    withLatestFrom(
      this.filter$,
      this.floatingFilter$,
    ),
    map(([, filter, floatingFilter]) => {
      return this.moduleFilterService.mergeFilters(filter ?? {}, floatingFilter ?? {});
    }),
  );

  defaultFilterApplied$ = this.actions$.pipe(
    ofType(ModuleFilterActionTypes.ApplyDefaultFilter),
    withLatestFrom(
      this.floatingFilter$,
    ),
    map(([, floatingFilter]) => {
      return floatingFilter;
    }),
  );

  watchForDifferentFilter$(initFilter) {
    return this.filter$.pipe(
      filter((newFilter: any) => newFilter && JSON.stringify(initFilter) !== JSON.stringify(newFilter)),
    );
  }

  applyDefaultFilter() {
    this.store.dispatch(applyDefaultFilter());
  }

  applyFilter(filter: any) {
    this.store.dispatch(applyFilter({ filter }));
  }

  resetFilter() {
    this.applyFilter({});
  }

  applyFloatingFilter(floatingFilter: any) {
    this.store.dispatch(applyFloatingFilter({ floatingFilter }));
  }

  resetFloatingFilter() {
    this.applyFloatingFilter({});
  }

  setFilter(filter: any) {
    this.store.dispatch(setFilter({ filter }));
  }

  toggleFilterVisibility(visibility: boolean = null) {
    this.store.dispatch(setFilterVisibility({ visibility }));
  }

  toggleFilterMenuVisibility(visibility: boolean = null) {
    this.store.dispatch(setMenuVisibility({ visibility }));
  }

  saveFilter(filter: any) {
    this.store.dispatch(saveFilter({ filter }));
  }

  setPropertiesMeta(propertiesMeta: any) {
    this.store.dispatch(setPropertiesMeta({ propertiesMeta }));
  }

  setModuleName(moduleName: string) {
    this.store.dispatch(setModuleName({ moduleName }));
  }

  /** @param moduleName - if null uses the current module name **/
  fetchUsersFilters(moduleName: string = null) {
    this.store.dispatch(fetchUserFilters({ moduleName }));
  }

  initRecentlyFilters(filters: any[]) {
    this.store.dispatch(initRecentlyFilters({ filters }));
  }

  setValidatorFn(fn: FilterValidatorFnI) {
    this.store.dispatch(setFilterValidatorFn({ fn }));
  }

  removeFilter(filterId: number) {
    this.store.dispatch(removeFilter({ filterId }));
  }

  refreshRecentlyFilters(filter) {
    this.store.dispatch(refreshRecentlyFilters({ filter }));
  }

  pinFilter(filterId: number, isPin: boolean) {
    this.store.dispatch(pinFilter({ filterId, isPin }));
  }
}
