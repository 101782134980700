<iris-modal-content
  [title]="'label.FilterSettings' | translate"
  [parent]="this"
  [cancelBtnText]="'label.Close'"
>
  <ng-container id="iris-modal-body">
    <form [formGroup]="userFilterForm">
      <iris-field-text
        [label]="'label.Title' | translate"
        [formControlName]="'name'"
        [languages]="languages$ | async"
        [(translations)]="translationsName"
        required="true">
      </iris-field-text>

      <iris-field-checkbox
        [label]="'label.IsPublic' | translate"
        [formControlName]="'isPublic'">
      </iris-field-checkbox>

      <iris-field-checkbox
        *ngIf="userFilterForm.get('isPublic').value"
        [label]="'label.IsPinnedByDefault' | translate"
        [formControlName]="'isPinnedByDefault'">
      </iris-field-checkbox>
    </form>
  </ng-container>

  <ng-container id="iris-modal-footer-buttons">
    <button 
      type="button"
      class="btn btn-success"
      data-test="modal-footer-confirm-button"
      [disabled]="userFilterForm.invalid"
      (click)="onFilterSave()">
      <i class="fas fa-check"></i>
      <span>{{ 'label.Save' | translate }}</span>
    </button>
  </ng-container>
</iris-modal-content>
