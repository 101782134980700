import { Directive, Injectable, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { noop, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive()
@Injectable({
  providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class IrisModalBase<TComponent = any, TResult = any> implements OnDestroy {
  protected readonly resultSubject: Subject<TResult>;
  get result$(): Observable<TResult> {
    return this.resultSubject;
  }

  private readonly closeSubject: Subject<TResult>;
  get close$(): Observable<TResult> {
    return this.closeSubject;
  }

  private readonly enableDragSubject: Subject<boolean>;
  get draggable$(): Observable<void> {
    return this.enableDragSubject.pipe(map(noop));
  }

  constructor(readonly dialogRef: MatDialogRef<TComponent, TResult>) {
    this.resultSubject = new Subject();
    this.closeSubject = new Subject();
    this.enableDragSubject = new Subject();
    dialogRef.disableClose = true;
  }

  public toggleStickModal(param: boolean): void {
    this.enableDragSubject.next(param);
  }

  public closeWithResult(data: TResult): void {
    this.resultSubject.next(data);
    this.closeSubject.next(data);
    this.close(data);
  }

  public closeWithoutResult(): void {
    this.closeSubject.next(null);
    this.close();
  }

  public close(data?: TResult): void {
    this.complete();
    this.dialogRef.close(data);
  }

  /**
   * @PAY_ATTENTION
   * You have to close the modal window in your handler method.
   * Need to call the method 'close' from this instance.
   *
   * @Description
   * This method may be needed for validation on the backend side
   * when you should not close the modal window while the data is invalid.
   */
  public emitDataWithoutClosing(data: TResult): void {
    this.resultSubject.next(data);
  }

  public complete(): void {
    this.resultSubject.complete();
    this.closeSubject.complete();
    this.enableDragSubject.complete();
  }

  ngOnDestroy(): void {
    this.complete();
  }
}
