import { NgModule } from '@angular/core';
import { IrisNgSelectFieldModule } from '@iris/common/modules/fields/ng-select-field';
import { IrisNgSelectFieldShownElementsCountComponentModule } from '@iris/common/modules/fields/ng-select-field/common/components';
import { NgSelectModule } from '@natlex/ng-select';
import { IrisCompanySelectDirective } from './company-select.directive';

@NgModule({
  imports: [
    NgSelectModule,
    IrisNgSelectFieldModule,
    IrisNgSelectFieldShownElementsCountComponentModule,
  ],
  declarations: [
    IrisCompanySelectDirective,
  ],
  exports: [
    IrisCompanySelectDirective,
    IrisNgSelectFieldModule,
    NgSelectModule,
  ],
})
export class IrisCompanySelectModule {
}
