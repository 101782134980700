import { IrisQueryParams } from '@iris/api-query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IrisFieldsTranslationSet } from '@iris/common/models';
import { transformTranslate } from '@iris/common/modules/pipes-common/pipes/translate/translate.utils';

export function stringifyServerEntityFn<TEntityId>(
  currentLanguage: string,
  fetchEntityByIdFn: (id: TEntityId, queryParams?: IrisQueryParams) => Observable<{
    name?: string;
    nameTranslated?: string;
    translations?: IrisFieldsTranslationSet;
  }>,
): (id: TEntityId) => Observable<string> {
  return id => fetchEntityByIdFn(id)
    .pipe(
      map(i => {
        return transformTranslate(i.name, i.translations, undefined, currentLanguage)?.name || i.nameTranslated || i.name;
      }),
    );
}
