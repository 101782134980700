import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IrisQueryParams } from '../query-params/iris-query-params';
import { IrisApiClientCore } from './iris-api-client.core';
import { map } from 'rxjs/operators';

export abstract class IrisApiClient<T> extends IrisApiClientCore {
  protected constructor(protected httpClient: HttpClient, apiUrl: string = null) {
    super(apiUrl);
  }

  postProcessing(item: T): T {
    return item;
  }

  postProcessingList(items: T[]): T[] {
    return Array.isArray(items) ? items.map(t => this.postProcessing(t)) : items;
  }

  protected queryCore(params?: { [key: string]: any }, queryParams?: IrisQueryParams): Observable<T[]> {
    const options = queryParams ? { params: queryParams.toObject()} : {};
    return this.httpClient.get<T[]>(this.url(params), options).pipe(map(t => this.postProcessingList(t)));
  }

  protected getCore(params?: { [key: string]: any }, queryParams?: IrisQueryParams): Observable<T> {
    const options = queryParams ? { params: queryParams.toObject()} : {};
    return this.httpClient.get<T>(this.url(params), options).pipe(map(t => this.postProcessing(t)));
  }

  protected saveCore<TSave = T>(entity: Partial<T>, params?: { [key: string]: any }): Observable<TSave> {
    return this.httpClient.post<TSave>(this.url({ ...entity, ...params }), entity);
  }

  protected deleteCore<TDelete = T>(params?: { [key: string]: any }): Observable<TDelete> {
    return this.httpClient.delete<TDelete>(this.url(params));
  }
}
