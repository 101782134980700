import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { IrisModuleFilterService } from '../../services/module-filter.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { TagItemRemovedActionI } from '@iris/common/modules/module-filter/components/tags-chips/tag-item-remove-action.model';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { IrisModuleFilterSandbox } from '@iris/common/modules/module-filter/store/module-filter.sandbox';
import { isEveryTruthy } from '@iris/common/utils/array.utils';
import { IrisModuleFilterPropertyBase } from '@iris/common/modules/module-filter/models/IrisModuleFilterPropertyBase';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Primitive } from '@iris/common/models/Primitive';

export interface ModuleFilterTagDataI {
  label: string;
  tooltip: string;
  meta: IrisModuleFilterPropertyBase;
  items: Primitive[];
  isBulk: boolean;
}

@Component({
  selector: 'iris-module-filter-tags',
  templateUrl: './module-filter-tags.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IrisModuleFilterTagsComponent implements OnInit {
  @Input() tags: ModuleFilterTagDataI[] = [];
  @Input() cssClass: string;
  @Input() filterContext: Record<string, unknown> = {};
  filterModel: Record<string, unknown> = {};
  floatingFilterModel: Record<string, unknown> = {};

  protected readonly MIN_COUNT_FOR_BULK_DISPLAY = 6;

  constructor(
    private readonly moduleFilterService: IrisModuleFilterService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly moduleFilterSandbox: IrisModuleFilterSandbox,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.moduleFilterSandbox.propertiesMeta$,
      this.moduleFilterSandbox.filterDirty$,
      this.moduleFilterSandbox.floatingFilterDirty$,
    ]).pipe(
      filter(items => isEveryTruthy(items)),
      filter(([, filterDirty, floatingFilter]) => {
        return !isEqual(filterDirty, this.filterModel) ||
          !isEqual(floatingFilter, this.floatingFilterModel);
      }),
      switchMap(([propertiesMeta, filterDirty, floatingFilter]) => {
        const mergedFilter = this.moduleFilterService.mergeFilters(filterDirty, floatingFilter);

        const filteredPropMeta = propertiesMeta
          .filter(propertyMeta => !propertyMeta.hidden)
          .filter(propertyMeta => propertyMeta.isInFilter(mergedFilter));

        const tooltipObservables: Observable<ModuleFilterTagDataI>[] = filteredPropMeta
          .map(propertyMeta => {
            const description$: Observable<string> = propertyMeta.getFilterDescription(cloneDeep(mergedFilter), true) ?? of(null);
            const tooltip$: Observable<string> = propertyMeta.getFilterTooltip(cloneDeep(mergedFilter), this.filterContext) ?? of(null);

            propertyMeta.setFilter(filterDirty);

            return combineLatest([description$, tooltip$]).pipe(
              map(([description, tooltip]) => ({
                tooltip,
                label: description,
                meta: propertyMeta,
                items: propertyMeta.fieldItems,
                isBulk: propertyMeta.fieldItems.length >= this.MIN_COUNT_FOR_BULK_DISPLAY,
              } as ModuleFilterTagDataI)),
            );
          });

        let observableToReturn: Observable<ModuleFilterTagDataI[]>;
        if (!tooltipObservables.length) {
          observableToReturn = of([]);
        } else {
          observableToReturn = combineLatest(tooltipObservables);
        }

        return observableToReturn.pipe(
          tap((tagsResult) => {
            this.filterModel = filterDirty;
            this.floatingFilterModel = floatingFilter;
            this.tags = tagsResult.filter((p) => p.label);
            this.changeDetector.markForCheck();
          }),
        );
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();
  }

  removeTagItem(removeAction: TagItemRemovedActionI): void {
    const clonedModel = {
      ...cloneDeep(this.filterModel),
      [removeAction.field]: removeAction.updatedItems,
    };
    this.moduleFilterSandbox.applyFilter(clonedModel);
  }

  private composeFilterAfterRemoving(filterModel: Readonly<Record<string, unknown>>, fieldToRemove: string): Record<string, unknown> {
    return omit(cloneDeep(filterModel), fieldToRemove);
  }

  removeTag(tag: ModuleFilterTagDataI): void {
    this.removeTagFromFilter(tag);
    this.removeTagFromFloatingFilter(tag);
  }

  private removeTagFromFloatingFilter(tag: ModuleFilterTagDataI): void {
    if (isNil(this.floatingFilterModel?.[tag.meta.formFieldName])) {
      return;
    }

    const clonedFloatingFilterModel = this.composeFilterAfterRemoving(this.floatingFilterModel, tag.meta.formFieldName);
    this.moduleFilterSandbox.applyFloatingFilter(clonedFloatingFilterModel);
  }

  private removeTagFromFilter(tag: ModuleFilterTagDataI): void {
    if (isNil(this.filterModel?.[tag.meta.formFieldName])) {
      return;
    }

    const clonedFilterModel = this.composeFilterAfterRemoving(this.filterModel, tag.meta.formFieldName);

    if (this.tags.length === 1) {
      this.resetFilterToDefault();
    } else {
      this.moduleFilterSandbox.applyFilter(clonedFilterModel);
    }
  }

  resetFilterToDefault(): void {
    if (!isEmpty(this.filterModel)) {
      this.moduleFilterSandbox.resetFilter();
    }

    if (!isEmpty(this.floatingFilterModel)) {
      this.moduleFilterSandbox.resetFloatingFilter();
    }
  }

  openFilterMenu(): void {
    this.moduleFilterSandbox.toggleFilterVisibility(true);
  }
}
